import {
	Avatar, Box,
	Card, CardActionArea, CardContent, Grid, Link, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import "react-multi-carousel/lib/styles.css";


const useStyles = theme => ({
	
	large: {
		width: theme.spacing(18),
		height: theme.spacing(18),
		border: '2px solid #d8d8d8 !important'
	  },
	
	 orange: {
		width: theme.spacing(18),
		height: theme.spacing(18),
		backgroundColor:'#f9bb02',
	 },
});
	
 

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		/*let path="https://opengrowth.university/University/assets/images/";
		{'img':path+'Launch.png','title':'Launch Event','subtitle1':'',
		subtitle2:'08th October 2021,',subtitle3:"09:00 - 10:00 PM IST | 08:30 - 09:30 AM PST ",
		subtitle4:'','href':'/webinar-description/opengrowth-academy-launch-event-schedule'},*/
		
		//let data=[
		
	let path="/assets/images/homepage/images/events/";
		let data=[
		
		{'img':path+'anamika.png','title':'Anamika Sengupta','subtitle1':'Anamika Sengupta is a social entrepreneur, an inspiring environmentalist, and the co-founder of Almitra Sustainables.  She has also been a speaker at the Asian Women in Leadership Summit, TedX, and has had a short film made about her work by NFDC.',href:'https://www.opengrowth.com/article/anamika-sengupta-promoting-a-sustainable-lifestyle'},
				
		{'img':path+'anuva.jpeg','title':'Anuva Kakkar','subtitle1':'Anuva is the Founder of Tiggle, the Best Hot Chocolate Powder, she started her business at the age of 21 selling hot chocolates on the streets. Now she has established a brand and given employment to many women in the midst of lockdown.',
		href:'https://www.opengrowth.com/article/anuva-kakkar-founder-of-tiggle-the-best-hot-chocolate-powder'},
				
		{'img':path+'tamanna.png','title':'Tamanna Sharma','subtitle1':'Tamanna Sharma is the director and owner of the Earthling First, she manifested her idea into reality by incorporating Earthling First as a sustainability consultancy. The corporation organizes sustainable events, green weddings, waste management, sustainable spaces and eco-retreat.',href:"https://www.opengrowth.com/article/tamanna-sharma"}
	
		]
		
		
		let gray={color:'#c3c3c3'}
		let white={color:'white'}
		
		const classes = this.props.classes;	

		return (
		 <Box marginTop={7} marginBottom={10}>
		  <Card elevation={0} id="academy" style={{'background-color': '#fff'}}>
		  
		  <CardContent style={{padding:'0px 64px 0px 64px'}}>
		  <Box marginTop={2}></Box>

		  <Grid container spacing={3}>

			{
			data.map(({ img, title,href,subtitle1,subtitle2,subtitle3,subtitle4})=> {
				return (	
					<Grid item sm={12} lg={4}>

					<Card elevation={1} style={{height:"100%"}}>
					
									
					<CardActionArea style={{'background-image':'linear-gradient(to bottom, transparent,transparent  30%,#25387C  30%)',padding:'16px',height:"100%"}}>
					
					<Box  display="block" textAlign="center" justifyContent="center" >
					
					<Box marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.large} src={img} ></Avatar>
					</Box>
					
					<Typography  variant="h6"  > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5} style={{color:'#F9BB02'}}>
					    
						{title}
					  
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" style={gray} > 
					 <Box  marginTop={1.5}  style={{color:'white'}}lineHeight={1.5}> {subtitle1} 
					 
					 <Box display="inline" >
                      <Link style={{color:'white'}} target="_blank" href={href}>{" Read More"}</Link>
					  </Box> </Box>
					 </Typography> 
					
					</Box>
					</CardActionArea>
					</Card>
					

				</Grid> 
		)})	
		}
		</Grid>
		</CardContent>
		</Card>
		</Box>
		);
  	}
}
  
export default withStyles(useStyles)(Innovative_Platform)
