
import {
	Box,Grid,CardContent,Card,CardHeader, makeStyles, Typography, ThemeProvider
} from '@material-ui/core';
import React, { Component, Suspense , useContext } from 'react';
import Sticky from 'react-sticky-el';
import {
	Account_Skeleton,
	Course_Progress_Skeleton, Graph_Skeleton, Horizontal_Course_Skeleton,
	Quiz_Skeleton, Recommendation_Skeleton, Timeline_Skeleton,
	User_Course_Skeleton
} from '../../functions/Skeleton';
import { Academytheme } from "../../functions/useStyles";
import { getCacheDetails } from '../../functions/caching';
import { UserContext } from '../../functions/courses/UserContext';

const ActiveWebinar = React.lazy(() => import('./ActiveWebinar'));
const Webinar = React.lazy(() => import('../../functions/mentors/dashboard/webinars'));
const OurMentors = React.lazy(() => import('../../functions/mentors/pages/ViewMentors'));
const Scroll = React.lazy(() => import('../../functions/mentors/dashboard/Scroll'));
const CourseBlock = React.lazy(() => import('../../functions/dashboard/CourseBlock'));

const RecentWebinar = React.lazy(() => import('../../functions/mentors/dashboard/webinars/UpcomingWebinar'));
const PopularCourses = React.lazy(() => import('../../functions/dashboard/PopularCourses'));
const ArticleRead = React.lazy(() => import('../../functions/blogs/PopularBlogs'));
const Speakers = React.lazy(() => import('./Speakers'));

let student_id=null
let status=null
let is_mentor=null;
let mentor_req=null;
if(["admin",null,"webinar"].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_req']
	is_mentor=JSON.parse(localStorage.getItem('ac_login_user'))['is_mentor']
}
let is_mentor_count=1;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
class StudentDashboard extends Component {
	
	constructor(props) {
    super(props);
	this.state={msg:false}
	this.getCacheDetails = getCacheDetails.bind(this);
	}
	

  	componentDidMount()
	{
		
	}
	
render()
{   
	const classes = this.props.classes;
	return (
	
		   <ThemeProvider theme={Academytheme}>
		 
			<div className={classes.root}>
			<Grid container direction="row" spacing={3} >
			<Grid  xs={12} sm={12} md={8} lg={8}   item>
			<Grid container direction="column" spacing={3}>
			

					<Grid item  xs={12}>
					<Suspense fallback={<div></div>}>
					<ActiveWebinar />
					</Suspense>
					</Grid>
					
					<Grid item xs={12} style={{padding:'12px 12px 0px'}}>
					<Suspense fallback={<div></div>}>
					<Webinar />
					</Suspense>
					</Grid>
					
					
					<Grid item  xs={12}>
					<Suspense fallback={<div> </div>}>
					<Scroll />
					</Suspense>
					</Grid>
					
				
								
			</Grid>
		  </Grid>
		  <Grid  xs={12} sm={12} md={4}  lg={4}    className="block"  item>
			<Grid container direction="column" spacing={3}>
			 
			 
					
			
			<Grid item  xs={12}>
			<Suspense fallback={<div></div>}>
			<ArticleRead />
			</Suspense>
			</Grid>
			
			
			
			</Grid>
		  </Grid>
			
					
		</Grid>

				
			
		 
			</div>
			</ThemeProvider>

	
  );
}}

export default (props) => {
		const user = useContext(UserContext);
	    const classes = useStyles();

    return (
        <StudentDashboard classes={classes}   user={user}   />
    )
}

  