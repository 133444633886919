
import { Box, Button, Card, CardContent, CardHeader, FormControlLabel, Grid, TextField, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../functions/AlertBox";
import ManageQuery from "./ManageQuery";
import { api } from "../../functions/Helper";




const useStyles = theme => ({

  media: {
	'border-radius':'12px'
  },
 
 
});

class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
            settings:[],
            value:[],
            member:null,
            moderator:null,
            defaulturl:null,
            defaultenable:false,
            otpenable:false,
            interval:0,
			
			socket_origin:null,
			socket_endpoint:null,
			
            msg:false,
            msg_type:'success',
            errors: {
                member:"Initalize",moderator:"Initalize",
                defaulturl:"Initalize",defaultenable:"Initalize",
                otpenable:"Initalize", socket_origin:"Initalize" ,
				socket_endpoint:"Initalize" 
              }
      
        }
	}
    handleChange = event => {
        //event.preventDefault();
        let errors = this.state.errors;
        const { name, value } = event.target;
        this.state[name]=value;
        errors[name]=value==null?"this field is required":"";
        this.setState({ errors, [name]: value });
    };

	handleSwitch = (field) => {
        if(field=='otpenable')
        {
            this.setState({otpenable:!this.state.otpenable})  
        } 
        else
        {
            this.setState({defaultenable:!this.state.defaultenable})
        }    

    }
    validForm=()=>
	{
		const registerList = ['defaulturl','moderator','member','interval'];
		let allFilled=false;
		this.setState({reg:false});
		for (let i = 0; i < registerList.length; i++)
		{
			let value=registerList[i];
			allFilled=this.checkError(value,this.state[value]);
		    if (allFilled==false)
			{
				//if(value=="password" && location=="/editprofile"){allFilled=true;}
				//else if(value=="about" && location!="/editprofile"){allFilled=true;}
				//else{break;}
				break;
		    }
		}
		return allFilled
	}
    checkError=(name,value)=>
    {
   		this.state.errors[name]=value==null||value==''?"this field is required":"Initalize";
	}	
	update_setting = (settings,value) => {
        
        /*let isValidate=this.validForm()
        if(isValidate==false)
        {
             this.setState({msg:"Please fill all the fields"})
             this.setState({msg_type:'warning'})		
             setTimeout(() => {
                    this.setState({msg:false})
             }, 3000);
             
            
                'setting':this.state.member,
                'moderator':this.state.moderator,
                'defaulturl':this.state.defaulturl,
                'defaultenable':this.state.defaultenable,
                'otpenable':this.state.otpenable,
                'interval':this.state.interval,        }    
        else
        {*/
              
      		//alert(this.state.interval)
      		
           axios.post(api+'/manageSettings',{
                'settings':settings,
                'value':value
            })
            .then((response) => {
            this.setState({msg:response.data['msg']})
            this.setState({msg_type:response.data['type']})		
            //console.log(response.data);
            //alert("njknkj")
                setTimeout(() => {
                this.setState({msg:false})
                this.getSettings();
                }, 3000);
            } )
            this.getSettings();
      //  }
	}
	clearChats = () => {
    
		axios.post(api+'/clearChats')
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}
	clearCache = () => {
    
		axios.post(api+'/clearCache')
		.then((response) => {
		this.setState({msg:'Data cleared'})
		this.setState({msg_type:'success'})		
		//console.log(response.data);
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}
	clearAttendees = () => {
    
		axios.post(api+'/clearAttendees')
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	
	}
	getSettings = () => {
		axios.post(api+'/getSettings')
		.then((response) => {
            
		this.setState({settings:response.data[0]['settings']})
		this.setState({value:response.data[0]['value']})
        
		this.setState({otpenable:this.state.value[0]==0?false:true})
           // alert(this.state.otpenable)
        this.setState({defaultenable:this.state.value[1]==0?false:true})
		this.setState({defaulturl:this.state.value[2]})
		this.setState({moderator:this.state.value[3]})
        this.setState({member:this.state.value[4]})
        this.setState({interval:this.state.value[5]})
        this.setState({socket_origin:this.state.value[6]})
        this.setState({socket_endpoint:this.state.value[7]})

        //console.log(response)
        
        ////console.log(this.state.settings)
        })
	}
	
	componentDidMount()
	{
        this.getSettings();
		//alert(this.state.settings)
	}
  	render()
  	{
		

		const classes = this.props.classes;	
		const { errors } = this.state;

		
		
		return(
		<>
        <Card>
        { 	
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type==="success"?"success":"warning"} />
					: ''
				
        }
		
        <CardHeader title="General Settings"/>
        <CardContent>
        <>
        {
				 
            this.state.settings.map((e, index) => (
                 
            <Grid container spacing={2}>
            <Grid  xs={12} sm={2}>
             <Box margin={1}>
             <Typography variant="subtitle1" style={{color:'black'}}>
                 {e}         
             </Typography>		
             </Box>		
            </Grid>		
            
            
            <Grid  xs={12} sm={6} >
             {
                e=='OTP' 
                ?
                    <Box display="flex">
                  <Box>
                   <FormControlLabel
                    className="text-secondary"
                    control={
                    <Switch
                        checked={this.state.otpenable}
                        onChange={()=>this.handleSwitch('otpenable')}
                        color="primary"
                    />
                    }/>
                    </Box>
                     <Box>
                      <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('OTP',this.state.otpenable)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                :
                 e=='default url enable'
                 ?
            
               <Box display="flex">
                  <Box>
                 <FormControlLabel
                    className="text-secondary"
                    control={
                    <Switch
                        checked={this.state.defaultenable}
                        onChange={()=>this.handleSwitch('defaultenable')}
                        color="primary"
                    />
                    }/>
                     </Box>
                     <Box>
                      <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('default url enable',this.state.defaultenable)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                :
                e=='default url'
                ?
                   <Box display="flex">
                        <Box marginTop={2} marginBottom={2} flexGrow={1}>
                        <TextField label="Default URL" color="primary" variant="outlined"  name="defaulturl" type="text"  onChange={this.handleChange} 
                        error={ errors.defaulturl!="Initalize" ? errors.defaulturl: ''} value={this.state.defaulturl} fullWidth/>
                        <Box>
                        <Typography variant="subtitle2" align="left" color="error" >
                        { errors.defaulturl!="Initalize" ? errors.defaulturl: ''}
                        </Typography>
                        </Box>
                       </Box> 
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('default url',this.state.defaulturl)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                :
                e=='moderator url'
                ?
                  <Box display="flex">
                    <Box marginTop={2} marginBottom={2}  flexGrow={1}>
                    <TextField color="primary" variant="outlined"  name="moderator" label="Moderator url" type="text"  onChange={this.handleChange} 
					error={ errors.moderator!="Initalize" ? errors.moderator: ''}
                    value={this.state.moderator} 
                    fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
				    { errors.moderator!="Initalize" ? errors.moderator: ''}
				    </Typography>
			        </Box>
                   </Box>
                   
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('moderator url',this.state.moderator)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                :
                e=='member url'
                ?
                <Box display="flex">
                   <Box marginTop={2} marginBottom={2}  flexGrow={1}>
                    <TextField label="Member URL" color="primary" variant="outlined"  name="member" type="text"  onChange={this.handleChange} 
					error={ errors.member!="Initalize" ? errors.member: ''} 
                    value={this.state.member} fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
				    { errors.member!="Initalize" ? errors.member: ''}
				    </Typography>
			        </Box>
                   </Box>
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('member url',this.state.member)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                 :
                 e=='interval'
                ?
                   <Box display="flex">
                   <Box marginTop={2} marginBottom={2}  flexGrow={1}>
                    <TextField label="Interval" color="primary" variant="outlined"  name="interval" type="number"  onChange={this.handleChange} 
					error={ errors.interval!="Initalize" ? errors.interval: ''} 
                    value={this.state.interval} fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
				    { errors.interval!="Initalize" ? errors.interval: ''}
				    </Typography>
			        </Box>
                   </Box>
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('interval',this.state.interval)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                 :
				 e=='socket_origin'
                ?
                   <Box display="flex">
                   <Box marginTop={2} marginBottom={2}  flexGrow={1}>
                    <TextField label="Socket Origin" color="primary" variant="outlined"  name="socket_origin"
					onChange={this.handleChange} 
					error={ errors.socket_origin!="Initalize" ? errors.socket_origin: ''} 
                    value={this.state.socket_origin} fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
				    { errors.socket_origin!="Initalize" ? errors.socket_origin: ''}
				    </Typography>
			        </Box>
                   </Box>
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('socket_origin',this.state.socket_origin)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
                 : 
				 e=='socket_endpoint'
                ?
                   <Box display="flex">
                   <Box marginTop={2} marginBottom={2}  flexGrow={1}>
                    <TextField label="Socket Endpoint" color="primary" variant="outlined"  
					name="socket_endpoint" onChange={this.handleChange} 
					error={ errors.socket_endpoint!="Initalize" ? errors.socket_endpoint: ''} 
                    value={this.state.socket_endpoint} fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
				    { errors.socket_endpoint!="Initalize" ? errors.socket_endpoint: ''}
				    </Typography>
			        </Box>
                   </Box>
                   
                   <Box padding={3}> 
                    <Button variant="outlined" size="small"   color="primary" 
                      onClick={()=>{this.update_setting('socket_endpoint',this.state.socket_endpoint)}} >update
                     </Button> 
                   </Box> 
                   </Box> 
				   :''
                }
               </Grid>
               
               
             
                { e=='default url'
                || e=='member url'
                || e=='moderator url'
                ?
                <Grid  xs={12} sm={4}>
                {
                    <Box margin={2}>
                    <Typography variant="subtitle2"  color="textSecondary" >
                    {e=='default url'?'/assets/newjs/html/test/mod.html':''}
                    {e=='moderator url'?'https://opengrowth.academy/webinar/webco/':''}
                    {e=='member url'?'https://opengrowth.academy/webinar/web/':''}
                    </Typography>
                    </Box> 
                }
               </Grid>
               :""}
               
               
            </Grid>    
            ))
        }
        <Grid container spacing={2}>
       
 
		<Grid  xs={12} sm={2}>
          <Box margin={2}>
              <Button variant="contained" size="medium"   color="primary"  onClick={this.clearChats} >
                Clear Chats
             </Button> 
           </Box> 
		</Grid>

		<Grid  xs={12} sm={2}>
          <Box margin={2}>
              <Button variant="contained" size="medium"   color="primary"  onClick={this.clearCache} >
                Clear Cache
             </Button> 
           </Box> 
		</Grid>

		<Grid  xs={12} sm={2}>
          <Box margin={2}>
              <Button variant="contained" size="medium"   color="primary"  onClick={this.clearAttendees} >
                Clear Join Tab
             </Button> 
           </Box> 
		</Grid>

        
		</Grid>
        </>
	    </CardContent>
        </Card>

        <ManageQuery/>
		
		</>
            
	    );
	
  	}
}
export default withStyles(useStyles)(CourseBlock)
