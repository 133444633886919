import DateFnsUtils from '@date-io/date-fns';
import {
	Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
	Grid, InputLabel, makeStyles, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../AlertBox";
import { deleteLocal, getCacheDetails } from "../caching";
import { api, translateText } from "../Helper";
import * as serviceWorkerRegistration from '../../../serviceWorkerRegistration';
import { useHistory, useLocation } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiTextField-root': {
      margin: theme.spacing(1),
      width: '25ch',
    },
  },
  cardh:{height:'calc(100vh - 128px)'}

}));

class SubscriptionAdd extends Component {

    constructor(props) {
    super(props);
	this.translateText = translateText.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
   
	
	this.state={
		cou_coupon:this.init_state("cou_coupon"),
		cou_subscription:this.init_state("cou_subscription"),
		cou_amount:this.init_state("cou_amount"),
//		cou_start:this.init_state("cou_start"),
		cou_start:new Date(),
		cou_end:this.init_state("cou_end"),
		coupon_id:this.init_state("coupon_id"),
		open:'',subscription_show:[],
		loading:false,disable:false,
		msg:false,msg_type:'success',
		deletePop:false,
		coupon_data:false,
		errors: {cou_coupon:"Initalize",
		cou_subscription:"Initalize",cou_amount:"Initalize",cou_end:"Initalize",
		cou_start:"Initalize"}
		}
    }
	init_state=(variable)=>{
		
		if(window.location.pathname=='/coupon/add')
        {
			return null;
        
        }
		else 
		{
			let k=this.props.location.state[variable]
			////console.log(k);
			return k;
		}
	}
	
	deleteKeys=()=>{
		let keys=this.deleteLocal("allCoupons_")		
		return keys
	}
	handleChecked = event => {this.setState({checkedA: !this.state.checkedA})}
	handleChange = event => {event.preventDefault();
	let errors = this.state.errors;
	const { name, value } = event.target;
	errors[name]=value==null|| value==''?"Please fill out this field.":"Initalize";
	this.state[name]=value;
	let min=5
	let max=20
	if(name=="cou_coupon")
	{
		errors[name]= value.length<min || value.length>max ?" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters.":"Initalize"	

	}
	if(name=="cou_amount")
	{
		errors[name]= value>100 || value<1 ?"Discount % must be between 1 to 100.":"Initalize"	

	}
	this.setState({ errors, [name]: value });
	};
	
	
	handleClose()
	{
		setTimeout(() => {
			this.setState({ deletePop: false});
		}, 1000);
	}
	handleStartChange = (dob) => {
		this.setState({ cou_start: new Date(dob)});
		if(this.state.cou_start!=null){this.state.errors.cou_start='';}
		
	}
	
	handleEndChange = (dob) => {
		this.setState({ cou_end: new Date(dob)});
		if(this.state.cou_end!=null){this.state.errors.cou_end='';}
	}
	
	insertIntoDatabase =  (dataObject) =>  {
		var indexedDBOpenRequest = window.indexedDB.open('coupon-sync',  1)
		indexedDBOpenRequest.onupgradeneeded = function () {
			this.result.createObjectStore('coupon_requests', {
			autoIncrement:  true })
		}

		indexedDBOpenRequest.onsuccess = function () {
			let db = this.result
			let transaction = db.transaction("coupon_requests", "readwrite");
			let storeObj = transaction.objectStore("coupon_requests");
			storeObj.add(dataObject);
		}
		indexedDBOpenRequest.onerror = function (error) {
			console.error('IndexedDB error:', error)
		}
	  }
	  checkError=(name,value)=>
    {
		if(["Initalize",undefined].includes(this.state.errors[name]))
		{
			let val =[null,''].includes(value)?"Please fill out this field.":"Initalize";
			this.state.errors[name]=val
		}
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}			
	}
	validForm=(list)=>
	{
		let allFilled=false;
		for (let i = 0; i < list.length; i++)
		{
			let name=list[i];
			allFilled=this.checkError(name,this.state[name]);
			this.forceUpdate();

			if (allFilled===false)
			{
				break;
			}
		}
		return allFilled
	}
	saveData = async () => {	   
	   
		//e.preventDefault();			

		let isValidate=this.validForm(['cou_coupon','cou_subscription','cou_amount','cou_end'])
		//alert(isValidate==true?"d1":"k1")
		/*if(new Date(this.state.cou_start)>new Date(this.state.cou_end))
		{
			isValidate=false;
			this.setState({msg: "'Allowed to' date must be greater than 'Allowed from' date."});
			setTimeout(() => {this.setState({msg:false})}, 5000);
		}*/
		this.forceUpdate()
	//	alert(isValidate==true?"d":"k")
		if(isValidate==true)
		{	
			let payload = {
				'coupon': this.state.cou_coupon,
				'subscription': this.state.cou_subscription,
				'amount': this.state.cou_amount,
				'start_on': this.state.cou_start,
				'ends_on': this.state.cou_end,
				'keys':(this.deleteKeys()).toString()

				}
            //console.log(payload)
			let data=[]
			if(window.location.pathname!=="/coupon/add")
			{	
				payload.operation='update';
				payload.coupon_id=this.state.coupon_id;
			}
			else{
				
				payload.operation='insert';

			}
			this.setState({addStatus:'' });
			if(!navigator.onLine){
			// if(1>2){
					
					//console.log("Coupon Offline")
					serviceWorkerRegistration.registerSync(); // register background sync request with tag name order
					this.insertIntoDatabase(payload); // insert data into indexedb
					if ((this.state.coupon_data).length==0)
					{
						this.setState({ coupon_data: payload});

					}	
					else 
					{
						
						this.setState({ coupon_data: [...this.state.coupon_data, payload]});
					}
					this.state.loading=false;
					this.setState({msg:"You are offline! IndexDB Updated",loading:false})
		     }
			else 
			{
				this.state.loading=true;
				//console.log("Coupon Online")
				axios.post(api+'/addUpDeCoupon',payload)
				.then((response) => {
				//console.log("this is response");
				//console.log(response.data);
				this.state.loading=false;
				this.setState({msg:response.data['msg']})
				this.setState({msg_type:response.data['type']})
				});
	
				setTimeout(() => {
				this.props.history.goBack();
				},5000);	
			
			}
			
	
			
			
		}

	}
	

  	componentDidMount()
	{
		
		this.getCacheDetails("all_subscription_"+0+"_to_"+30,
		"getSubscription",[0,30])
		.then(data => {	
			this.setState({ subscription_show: data })
		});
			
	}
	
	handleClose = () => {
		this.setState({ deletePop: false});
	}
	handleDelete = () => {
		
		let payload = {
		'coupon_id': this.state.coupon_id,
		'operation':'delete',
		'keys':(this.deleteKeys()).toString()
		}
		axios.post(api+'/addUpDeCoupon',payload)
		.then((response) => {
		this.setState({ deletePop: false});
		
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
	
			setTimeout(() => {
            this.props.history.goBack();
            }, 3000);	
			
		});
	}
	
  	render()
  	{
		
		const { errors } = this.state;
			const DeletePopup = () =>
			{

			return(
			
			 <Dialog
			open={this.state.deletePop}
			onClose={this.handleClose} 
			fullWidth 
			maxWidth="xs">
			
			<DialogTitle >
			<Typography variant="h6" color="textPrimary">Are you sure ?</Typography>
			</DialogTitle>
			
			<DialogContent >
			  <DialogContentText >
			  	<Typography variant="subtitle1" color="textSecondary"  >
				Do you really want to delete these records? 
				This process cannot be undone.
			  </Typography>
			  </DialogContentText>
			</DialogContent>
			
			
			<DialogActions >
			  <Button  onClick={ this.handleDelete}  color="primary"  size="large">Delete </Button>
			  <Button onClick={ this.handleClose}   color="primary" size="large">Cancel</Button>
			</DialogActions>
			</Dialog>
			
			
			
			)
			
		}

		
		
		return (
				<Card >
				{ 	
					
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type==="success"?"success":"warning"} />
					: ''
				
				}
				<CardHeader title={"Coupons"}/>
				<CardContent>
				<form >
					 <Grid container spacing={2}>
					
					<Grid item xs={12} sm={6}>
					  	
						<FormControl variant="outlined" fullWidth
						error={ errors.cou_coupon!="Initalize" ? errors.cou_coupon: ''} >
						<TextField  label="Plan Name" color="primary" variant="outlined"  name="cou_coupon" type="text" onChange={this.handleChange} error={ errors.plan!="Initalize" ? errors.plan: ''} 
						defaultValue={this.state.cou_coupon}	error={ errors.cou_coupon!="Initalize" ? errors.cou_coupon: ''}
						fullWidth />
						</FormControl>
						
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.cou_coupon!="Initalize" ? errors.cou_coupon: ''}
						</Typography>
						</Box>
						
					</Grid>
					
					
					<Grid item xs={12} sm={6}>
					
					<FormControl variant="outlined" fullWidth
					error={ errors.cou_subscription!="Initalize" ? errors.cou_subscription: ''}  >
					<InputLabel>Subscription Plan</InputLabel>
					<Select
						 name="cou_subscription"
						onChange={this.handleChange}
						label="Subscription Plan"
						 error={ errors.cou_subscription!="Initalize" ? errors.cou_subscription: ''}  
						 value={this.state.cou_subscription}  >
						<MenuItem value={null}><em>None</em></MenuItem>
						 {this.state.subscription_show.map((e, index) => (
						<MenuItem value={e['subscription_id']}>{e['plan']}</MenuItem>
					))}
					</Select>
					</FormControl>
					
					
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.cou_subscription!="Initalize" ? errors.cou_subscription: ''}
					</Typography>
					</Box>
					
					</Grid>
					
					
					<Grid item xs={12} sm={6}>
					 <TextField label="Discount(1-100)%" color="primary" variant="outlined"  name="cou_amount" type="number"  onChange={this.handleChange} 
					error={ errors.cou_amount!="Initalize" ? errors.cou_amount: ''} defaultValue={this.state.cou_amount}   fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.cou_amount!="Initalize" ? errors.cou_amount	: ''}
					</Typography>
					</Box>
					</Grid>
					
					
					
					{/*<Grid item xs={6} sm={6}>
					 <FormControl variant="outlined" fullWidth
					error={ errors.cou_start!="Initalize" ? errors.cou_start: ''}  >
					<InputLabel id="cou_end-outlined-label"></InputLabel>
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
					 <KeyboardDatePicker //orientation="landscape" id="cou_end-box"
					autoOk
					minDate={new Date()}
					variant="inline"
					inputVariant="outlined"
					format="dd-MMM-yyyy"
					label="Allowed from"
					InputAdornmentProps={{ position: "end" }}
					value={this.state.cou_start}									
					name="cou_start" id="exampleDate" onChange={this.handleStartChange} 
					error={ errors.cou_start!="Initalize" ? errors.cou_start: ''} 
					/>
					</MuiPickersUtilsProvider>
					 </FormControl>
					 <Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.cou_start!="Initalize" ? errors.cou_start	: ''}
					</Typography>
					</Box>
					</Grid>*/}
					
					
					<Grid item xs={12} sm={6}>
					<FormControl variant="outlined" fullWidth
					error={ errors.cou_end!="Initalize" ? errors.cou_end: ''}  >
					<InputLabel id="cou_end-outlined-label"></InputLabel>
					<MuiPickersUtilsProvider utils={DateFnsUtils} >
					 <KeyboardDatePicker //orientation="landscape" id="cou_end-box"
						autoOk
						minDate={new Date()}
						variant="inline"
						inputVariant="outlined"
						format="dd-MMM-yyyy"
						label="Allowed till"
						InputAdornmentProps={{ position: "end" }}
						value={this.state.cou_end}									
						name="cou_end" id="exampleDate" onChange={this.handleEndChange} 
						error={ errors.cou_end!="Initalize" ? errors.cou_end: ''} 
					/>
					</MuiPickersUtilsProvider>
					</FormControl>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.cou_end!="Initalize" ? errors.cou_end	: ''}
					</Typography>
					</Box>
					</Grid>
					
					<Grid item xs={12}>
					<Box display="flex" flexDirection="row-reverse" >
					
					{window.location.pathname!='/coupon/add'?
					<Box marginLeft={1}>
					<Button variant="contained" size="large" color="secondary"  onClick={()=>{this.setState({deletePop:true})}}>
					Delete
					</Button>
					<DeletePopup />
					</Box>
					:''}
					
					<Box>
					<Button variant="contained" size="large"   color="primary"  onClick={this.saveData} fullWidth disabled={this.state.loading}>
					  {this.state.loading && <CircularProgress size={24} />}
					  {!this.state.loading && <span>{window.location.pathname=='/coupon/add'?"Add Coupon":"Update Coupon"}</span> }
					</Button>
					</Box>
					
					</Box>
					</Grid>
				  
				  </Grid>	
				
			    </form>
				</CardContent>
              </Card>
                    
            
	    );
  	}
}

export default () => {
    const classes = useStyles();	
	const history = useHistory();
	const location = useLocation();

    return (
        <SubscriptionAdd classes={classes}  history={history}  location={location}  />
    )
}

  
  