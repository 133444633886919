import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import { barColor, successColor } from "../../Helper";
export const chatStyle = makeStyles(theme => ({
  title:{fontWeight:'bold'},
	question:{
		'border-radius': '12px',
		border: "1px solid #c4c4c4",
		'width':'max-content'
	},
  read:{'background-color': '#FAFAFA'},
  unread:{'background-color': '#f2fff338'},
  root:{ 'height':'calc(100vh - 289px)','overflow-y':'auto',padding: '16px 0px'},
  msg:{'word-break': 'break-all'},
  avatar: {marginTop:"8px",minWidth:'48px'},
  
	reply:{
		marginLeft:"0px",
		'background-color': '#FAFAFA',
		'border-radius': '12px',
		 border: "1px solid #c4c4c4",
		'padding': '8px !important',
		'width':'99%',
		margin:'4px',
	},
  scrollroot:{'overflow-y':'auto',
  padding: '16px 0px 16px 0px'}
  
  }));

export const useStyles = makeStyles(theme => ({
  card:{padding: '0px 16px 0px 16px',	backgroundColor:barColor ,borderRadius:'0px'},
  txt:{fontSize:'0.75em !important',padding:'10.5px 10px'},
  btn:{padding:'0.5px'},
  endAdornment:{paddingRight:'6px'}
}));