import {
	Box, Button, Card, CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControl,
	Grid, InputLabel, MenuItem, Select, TextField, Typography} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../AlertBox";
import { deleteLocal } from "../caching";
import { api,  translateText } from "../Helper";
import { useHistory, useLocation } from 'react-router-dom';


class SubscriptionAdd extends Component {

    constructor(props) {
    super(props);
	this.translateText = translateText.bind(this);
    this.deleteLocal = deleteLocal.bind(this);


    this.state={
		sub_plan:this.init_state("sub_plan"),
		sub_duration:this.init_state("sub_duration"),
		sub_courses:this.init_state("sub_courses"),
		sub_amt:this.init_state("sub_amt"),
	//	sub_start:this.init_state("sub_start"),
	//	sub_end:this.init_state("sub_end"),
		sub_start:new Date(),
		sub_end:new Date(),
		subscription_id:this.init_state("subscription_id"),
		open:'',
		loading:false,disable:false,
		msg:false,msg_type:'success',
		deletePop:false,
		errors: {sub_plan:"Initalize",sub_duration:"Initalize",
		sub_courses:"Initalize",sub_amt:"Initalize",sub_end:"Initalize",
		sub_start:"Initalize"}
		}
    }
	
	init_state=(variable)=>{
		
		if(window.location.pathname=='/subscription/add')
        {
			return null;
        
        }
		else 
		{
			let k=this.props.location.state[variable]
			return k;
		}
	}
	
	deleteKeys=()=>{
		let keys=this.deleteLocal("all_subscription_")		
		return keys
	}
	handleChecked = () => {this.setState({checkedA: !this.state.checkedA})}
	handleChange = event => {event.preventDefault();
	let errors = this.state.errors;
	const { name, value } = event.target;
	errors[name]=value==null|| value==''?"Please fill out this field.":"Initalize";
	this.state[name]=value;
	let min=5
	let max=20
	if(name=="sub_plan")
	{
		errors[name]= value.length<min || value.length>max ?" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters.":"Initalize"	

	}
	
	this.setState({ errors, [name]: value });
	};
	
	
	handleClose()
	{
		setTimeout(() => {
			this.setState({ deletePop: false});
		}, 1000);
	}
	handleStartChange = (dob) => {
		this.setState({ sub_start: new Date(dob)});
		if(this.state.sub_start!=null){this.state.errors.sub_start='';}
		
	}
	
	handleEndChange = (dob) => {
		this.setState({ sub_end: new Date(dob)});
		if(this.state.sub_end!=null){this.state.errors.sub_end='';}
	}
	checkError=(name,value)=>
    {
		if(["Initalize",undefined].includes(this.state.errors[name]))
		{
			let val =[null,''].includes(value)?"Please fill out this field.":"Initalize";
			this.state.errors[name]=val
		}
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}			
	}
	validForm=(list)=>
	{
		let allFilled=false;
		for (let i = 0; i < list.length; i++)
		{
			let name=list[i];
			allFilled=this.checkError(name,this.state[name]);
			this.forceUpdate();

			if (allFilled===false)
			{
				break;
			}
		}
		return allFilled
	}
  handleSubmit = (e) =>
   {
	   
	   
		e.preventDefault();			

		let isValidate=this.validForm(['sub_plan','sub_duration','sub_courses','sub_amt'])
        
       /* if(this.state.sub_start>this.state.sub_end)
		{
			isValidate=false;
			this.setState({msg: "'Allowed to' date must be greater than 'Allowed from' date."});
			setTimeout(() => {this.setState({msg:false})}, 2000);
		}
        */
	//	alert(isValidate?"ii":"kj")
        
		
		if(isValidate==true)
		{	
			let payload = {
				'plan': this.state.sub_plan,
				'duration': this.state.sub_duration,
				'courses_allowed': this.state.sub_courses,
				'amount': this.state.sub_amt,
				'started_on': this.state.sub_start,
				'ends_on': this.state.sub_end,
				'keys':(this.deleteKeys()).toString()
				}
				
			
			if(window.location.pathname!="/subscription/add")
			{	
				payload.operation='update';
				payload.subscription_id=this.state.subscription_id;
			}
			else{
				
				payload.operation='insert';

			}
			this.setState({addStatus:'' });
			this.state.loading=true;
            //console.log(payload)
            
			axios
            .post(api+'/addUpDeSubscription',payload)
			.then((response) => {
			//console.log("this is response");
			//console.log(response.data);
			this.state.loading=false;
			this.setState({msg:response.data['msg']})
			this.setState({msg_type:response.data['type']})
			})
            .catch(error => { console.log(error.response.data.error) });
			
			setTimeout(() => {
			this.setState({ msg: false});
            this.props.history.goBack();
            }, 3000);	
	
			
			
		}

	}
	
	handleClose = () => {
		this.setState({ deletePop: false});
	}
	handleDelete = () => {
		
		let payload = {
		'subscription_id': this.state.subscription_id,
		'operation':'delete',
		'keys':(this.deleteKeys()).toString()
		}
		axios.post(api+'/addUpDeSubscription',payload)
		.then((response) => {
		this.setState({ deletePop: false});
		
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
		setTimeout(() => {
			localStorage.removeItem ('university_logo')
            this.props.history.goBack();
            }, 3000);
		});
	}
	
  	render()
  	{
		
		const { errors } = this.state;
			const DeletePopup = () =>
			{

			return(
				<Dialog
			open={this.state.deletePop}
			onClose={this.handleClose} 
			fullWidth 
			maxWidth="xs">
			
			<DialogTitle >
			<Typography variant="h6" color="textPrimary">Are you sure ?</Typography>
			</DialogTitle>
			
			<DialogContent >
			  <DialogContentText >
			  	<Typography variant="subtitle1" color="textSecondary"  >
				Do you really want to delete these records? 
				This process cannot be undone.
			  </Typography>
			  </DialogContentText>
			</DialogContent>
			
			
			<DialogActions >
			  <Button  onClick={ this.handleDelete}  color="primary"  size="large">Delete </Button>
			  <Button onClick={ this.handleClose}   color="primary" size="large">Cancel</Button>
			</DialogActions>
			</Dialog>
			
			
			)
			
		}

		
		
		return (
				<Card >
				{ 	
					
					this.state.msg!=false 
					?<AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type==="success"?"success":"warning"} />
					: ''
				
				}
				<CardHeader title={"Subscription"}/>
				<CardContent>
				<form >
					 <Grid container spacing={2}>
					
					<Grid item xs={12} sm={6}>
					  	
						<FormControl variant="outlined" fullWidth
						error={ errors.sub_plan!="Initalize" ? errors.sub_plan: ''} >
						<TextField  label="Plan Name" color="primary" variant="outlined"  name="sub_plan" type="text" onChange={this.handleChange} error={ errors.plan!="Initalize" ? errors.plan: ''} 
						defaultValue={this.state.sub_plan}	error={ errors.sub_plan!="Initalize" ? errors.sub_plan: ''}
						fullWidth/>
						</FormControl>
						
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.sub_plan!="Initalize" ? errors.sub_plan: ''}
						</Typography>
						</Box>
						
					</Grid>
					
					<Grid item xs={12} sm={6}>
					  	
						<FormControl variant="outlined" fullWidth
						error={ errors.sub_duration!="Initalize" ? errors.sub_duration: ''}  >
						<InputLabel>Duration</InputLabel>
						<Select
						 name="sub_duration"
						 onChange={this.handleChange}
						label="Duration"
						error={ errors.sub_duration!="Initalize" ? errors.sub_duration: ''}
						value={this.state.sub_duration}>
						<MenuItem value={null}>
						<em>None</em>
						</MenuItem>
						<MenuItem value={"1 Month"}>1 Month</MenuItem>
						<MenuItem value={"2 Months"}>2 Months</MenuItem>
						<MenuItem value={"3 Months"}>3 Months</MenuItem>
						<MenuItem value={"6 Months"}>6 Months</MenuItem>
						<MenuItem value={"12 Months"}>12 Months</MenuItem>
						</Select>
						</FormControl>
						
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.sub_duration!="Initalize" ? errors.sub_duration: ''}
						</Typography>
						</Box>
						
					</Grid>
					
					
					<Grid item xs={12} sm={6}>
					
					<TextField fullWidth label="No of allowed courses" color="primary" variant="outlined"  name="sub_courses" type="number"  onChange={this.handleChange} error={ errors.sub_courses!="Initalize" ? errors.sub_courses: ''} defaultValue={this.state.sub_courses}   fullWidth/>
					
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.sub_courses!="Initalize" ? errors.sub_courses: ''}
					</Typography>
					</Box>
					
					</Grid>
					
					
					<Grid item xs={12} sm={6}>
					<TextField fullWidth label="Price" color="primary" variant="outlined"  name="sub_amt" type="number"  onChange={this.handleChange} 
					error={ errors.sub_amt!="Initalize" ? errors.sub_amt: ''} defaultValue={this.state.sub_amt}   fullWidth/>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.sub_amt!="Initalize" ? errors.sub_amt	: ''}
					</Typography>
					</Box>
					</Grid>
					
					
					{/*
					<Grid item xs={6} sm={6}>
						<FormControl variant="outlined" fullWidth
						error={ errors.sub_start!="Initalize" ? errors.sub_start: ''}  >
						<InputLabel id="sub_end-outlined-label"></InputLabel>
						<MuiPickersUtilsProvider utils={DateFnsUtils} >
						<KeyboardDatePicker //orientation="landscape" id="sub_end-box"
						autoOk
						minDate={new Date()}
						variant="inline"
						inputVariant="outlined"
						format="dd-MMM-yyyy"
						label="Allowed from"
						InputAdornmentProps={{ position: "end" }}
						value={this.state.sub_start}									
						name="sub_start" id="exampleDate" onChange={this.handleStartChange} 
						error={ errors.sub_start!="Initalize" ? errors.sub_start: ''} 
						/>
						</MuiPickersUtilsProvider>
						</FormControl>
					 <Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.sub_start!="Initalize" ? errors.sub_start	: ''}
					</Typography>
					</Box>
					</Grid>
					
					
					<Grid item xs={6} sm={6}>
						<FormControl variant="outlined" fullWidth 
						error={ errors.sub_end!="Initalize" ? errors.sub_end: ''}  >
						<InputLabel id="sub_end-outlined-label"></InputLabel>
						<MuiPickersUtilsProvider utils={DateFnsUtils} >
						<KeyboardDatePicker //orientation="landscape" id="sub_end-box"
						autoOk
						minDate={new Date()}
						variant="inline"
						inputVariant="outlined"
						format="dd-MMM-yyyy"
						label="Allowed to"
						InputAdornmentProps={{ position: "end" }}
						value={this.state.sub_end}									
						name="sub_end" id="exampleDate" onChange={this.handleEndChange} 
						error={ errors.sub_end!="Initalize" ? errors.sub_end: ''} 
						/>
						</MuiPickersUtilsProvider>
						</FormControl>
					<Box>
					<Typography variant="subtitle2" align="left" color="error" >
					{ errors.sub_end!="Initalize" ? errors.sub_end	: ''}
					</Typography>
					</Box>
					</Grid>
					*/}
					
					<Grid item xs={12}>
					<Box display="flex" flexDirection="row-reverse" >
					
					{window.location.pathname!=="/subscription/add"?
					<Box marginLeft={1} >
					<Button variant="contained" size="large" color="secondary"  onClick={()=>{this.setState({deletePop:true})}}>
					Delete
					</Button>
					<DeletePopup />
					</Box>
					:''}
					
					<Box>
					<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} fullWidth disabled={this.state.loading}>
					  {this.state.loading && <CircularProgress size={24} />}
					  {!this.state.loading && <span>{window.location.pathname=="/subscription/add"?"Add Subscription Plan":"Update Plan"}</span> }
					</Button>
					</Box>
					
					</Box>
					</Grid>
				  
				  </Grid>	
				
			    </form>
				</CardContent>
              </Card>
	    );
  	}
}
export default () => {
	const location = useLocation();
	const history = useHistory();
    return (
        <SubscriptionAdd  location={location}  history={history} />
    )
}
  
  
  