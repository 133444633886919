import {
	Grid
} from '@material-ui/core';
import React, { Component } from 'react';

import Mission from './Mission';
import Vision from './Vision';

class Homepage extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
	
		return (
		    <Grid container spacing={0}  
			justifyContent="center" style={{backgroundColor:"white"}}>
			
			<Grid item xs={12}  >
			  <Mission />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Vision />
			</Grid>	

			</Grid>
		
		);
  	}
}
export default Homepage;
  
  