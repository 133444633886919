import { Box, Card, Grid } from '@material-ui/core';
import AlertBox from "../../functions/AlertBox";
import axios from 'axios';
import React, { Component, Suspense } from 'react';
import cookie from 'react-cookies';
import { isMobile } from 'react-device-detect';
import { chatStyle } from '../popup/tabStyle';
import './iframe.css';
const Tabs = React.lazy(() => import('../popup/Tabs'));
const WebTitle = React.lazy(() => import('./WebTitle'));
const Popup = React.lazy(() => import('../waiting/Popup'));

//let webinar_data=JSON.parse(localStorage.getItem("webinar_timer_de"))
let webinar_data=cookie.load('webinar_timer_de')
//const WebCenterScreen = React.lazy(() => import('./webinar/WebCenterScreen'));

let user_data=JSON.parse(localStorage.getItem("ac_login_user"))


class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
        
		email:this.isNull(user_data)==true ? user_data['email']:'',
		name:this.isNull(user_data)==true ? user_data['name']:'',
        
        
		isModerator:"none",msg:'',
		tabs:true,checked:false,
		isMobile:isMobile,
		video:null,
        msg:false,
        
        
        IST:webinar_data['a_IST'],
        GMT:webinar_data['a_GMT'],
        PST:webinar_data['a_PST'],
        webinar:webinar_data['a_webinar'],
        webinar_id:webinar_data['webinar_id'],
        title:webinar_data['webinar_title'],
	}
    }
    isNull(data)
    {
        let t=true
        if(data==null || data==undefined || data=='')
        {
            localStorage.clear()
            window.location="/webinars";
            t=false
        }  
        return t
    }
	
    componentDidMount()
	{
            cookie.save('participant_count',0, { path: '/' })

		//	alert(cookie.load('login_token'))
		
        this.saveWebUsers();

        this.if_login_with_admin()
        if(this.state.webinar_id!='None')
        {
            this.checkTimer();
        }
        else
        {
            window.location = '/og-webinars';
        }    
        this.get_user_specific_video_screen();
        this.get_data_read();    
    }
    
	checkTimer = () => {
		
		axios.post('/api/checkTimer')
			.then((response) => {
				
                localStorage.setItem('webinar_timer_de', JSON.stringify(response.data)) 
			
		});
	};

   saveWebUsers=()=>
	{
		let param={
	    'email':this.state.email,
	    'name':this.state.name,
	    'img':user_data['img'],
	    'webinar_id':this.state.webinar_id}
        
		axios.post('/api/saveWebUsers',param).then((response) => {});
	}
   
    get_data_read = () => {
		axios.post('/api/getRead',{'email':this.state.email,'platform':"webinar"})
		.then((response) => {

            cookie.save('chat_read', response.data['chat_read'], { path: '/' })
            cookie.save('q_read', response.data['qanda_read'], { path: '/' })    
            cookie.save('total_chat_read', response.data['chat_read'], { path: '/' })
            cookie.save('total_q_read', response.data['qanda_read'], { path: '/' })   
            //console.log(response.data['chat_read'])
        })
	}
    get_user_specific_video_screen = () => {
		axios.post('/api/getSettings')
		.then((response) => {
   		this.setState({value:response.data[0]['value']})
        if(this.state.value[1]==1)
        {
           this.setState({video:this.state.value[2]})
        } 
        else if (user_data['webinar_logged_as']=='moderator')  
        {
           this.setState({video:this.state.value[3]})
           this.setState({msg:"You've joined this webinar as Host."})
           setTimeout(() => 
			{
                this.setState({msg:false})
			}, 3000);
        } 
        else  
        {
           this.setState({video:this.state.value[4]})
        }    
        })
	}

    if_login_with_admin = () => {
	
		if(user_data['login_token']=='admin')
        {
            window.location='/schedule-webinar'
        }
	}
 


  	render()
  	{
		

		const classes = this.props.classes;	
			const user = this.props.user;

	
		
	
		const WebTab= () => {
		return (
				
			<Box display={(this.state.tabs==true &&  isMobile==false ) || isMobile==true ?'block':'none'} >
              <Suspense fallback={<div> </div>}>
              <Tabs />  
             </Suspense>                  
			</Box>	
		
		)}	

		const NewCard = () => {
		return (
		<>
        
           

		
			<Card elevation={0}>

		
			<Grid container 
			  spacing={0} xs={12} >
			
			
            <Grid container item xs={12} sm={12} lg={9}>
			
				<Grid  xs={12} item container  direction="row"
                alignItems="center">
                    <Suspense fallback={<div> </div>}>
                    <WebTitle title={this.state.title}/>
                    </Suspense>
				</Grid>
				{
					/*
					default url - /assets/newjs/html/test/mod.html
					moderator url - /webinar/webco/index.html
					member url - /webinar/web/index.html
					
					local host - '/assets/test/feb/21/host/index.html'
					local auidence - '/assets/test/feb/21/auidence/index.html'
					
					'https://opengrowthacademy.com/webinar/web/index.html'
					*/
				}
			    <Grid  xs={12}  style={{padding:'0px'}}   item>
                 {/*  
				 src={'/assets/test/mar/9/hu/index.html'}
				 '/assets/test/mar/9/host_test/index.html'
				 src={this.state.video}  '/assets/newjs/html/mod.html'    */}
                <iframe 
				src={this.state.video}
				  class="webframe"
				  width="100%" 
				  allow="camera; microphone" >
				</iframe> 
				</Grid>

		  </Grid>
		  

		  <Grid item xs={12} sm={12} lg={3} >
			<WebTab />
		  </Grid>
		  
		  </Grid>

		</Card>
		</>
		)}


		return(
		<>
         {
                            this.state.msg!=false 
                            ?<AlertBox show_status={true} 
                            msg={this.state.msg}
                            msg_type={"success"} />
                            : ''
                            
          }
		
            	<Popup/> 
			<NewCard />
		   </>
	    );
	
  	}
}
//export default withStyles(useStyles)(CourseBlock)
export default (props) => {
		//const user = useContext(UserContext);
		const classes = chatStyle();

    return (
        <CourseBlock 
		classes={classes}   />
    )
}