import { Box,Grid,ThemeProvider } from "@material-ui/core";
import React, { Component, Suspense } from 'react';
import { Academytheme, useStyles } from "../../functions/useStyles";



const setButtonText="Login";
const location = window.location.pathname;
let cls="";

const ForgotForm = React.lazy(() => import('./ForgotForm'));
const CarouselCard = React.lazy(() => import('../login/CarouselCard'));

class Login extends Component {

    constructor(props) {
    super(props);
  }


  
 
    render() {
	const classes = this.props.classes;

	return(
    <ThemeProvider theme={Academytheme}>
	
	<Grid container 
			  direction="row"
			  alignItems="center"
			  justify="center"
			  spacing={0}>
  		<Grid item xs={0} md={8}>
                <Suspense fallback={<div> </div>}>
						<CarouselCard /> 
			</Suspense>   
        </Grid>
		
		
		<Grid item xs={12} md={4}>
            <Suspense fallback={<div> </div>}>
						<ForgotForm /> 
			</Suspense>
        </Grid>

     </Grid>
    
	</ThemeProvider>  
	)
  
    }
}
export default () => {
    const classes = useStyles();
    return (
        <Login classes={classes} />
    )
}



