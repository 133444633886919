import React, { Suspense } from 'react';
import { Box,Card, CardContent, CardHeader,Grid } from '@material-ui/core';
import {CarouselSkeleton} from '../../Skeleton';
import ViewMentors from './ViewMentors';

const MentorsCategory = React.lazy(() => import('./MentorsCategory'));

const OurMentors=()=>{
	return ( 
		<Box>
		<Card >
		<CardHeader title={"Experts"} />
		<CardContent>		
		
		<ViewMentors action="page" />
			
		</CardContent>
		</Card>
	    </Box>
	);
}

const FolloweeTab = () => {
  return(
  <Grid container spacing={2}>
  <Grid item xs={12}>
	   <OurMentors />
  </Grid>
  {/*
  <Grid item xs={12}>
            <Suspense fallback={<div><CarouselSkeleton /></div>}>
              <MentorsCategory category="Expert" />
            </Suspense>
  </Grid>
  <Grid item xs={12}>
              <Suspense fallback={<div><CarouselSkeleton /></div>}>
              <MentorsCategory category="Leadership" />
            </Suspense>
  </Grid>
  */}
  </Grid>
  )
}
export default FolloweeTab;