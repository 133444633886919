import {
	Switch,
	FormGroup,
    FormControlLabel,
	FormHelperText ,Avatar, Box, Button,Card, 
	 CardContent, CardHeader, CircularProgress, Dialog, DialogActions, DialogContent,
	  DialogContentText, DialogTitle, FormControl, Grid, InputLabel, 
	  MenuItem, Select, TextField, Typography} from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../AlertBox";
import { deleteLocal } from "../../caching";
import { api, Category } from "../../Helper";
import { FaChevronRight,FaChevronLeft } from 'react-icons/fa';
import { useHistory, useLocation } from 'react-router-dom';
import { useStyles } from "../../useStyles";
import { styled } from '@material-ui/core/styles';

let creater_id=null;
if(["admin",null].includes(localStorage.getItem('login_token'))==false)
{
	creater_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}
let l='';
let community_list=["title","description","category"]


const IOSSwitch = styled(Switch)(({ theme }) => ({
  width: 42,
  height: 26,
  padding: 0,
  '& .MuiSwitch-switchBase': {
    padding: 0,
    margin: 2,
    transitionDuration: '300ms',
    '&.Mui-checked': {
      transform: 'translateX(16px)',
      color: '#fff',
      '& + .MuiSwitch-track': {
        backgroundColor: theme.palette.mode === 'dark' ? '#2ECA45' : '#65C466',
        opacity: 1,
        border: 0,
      },
      '&.Mui-disabled + .MuiSwitch-track': {
        opacity: 0.5,
      },
    },
    '&.Mui-focusVisible .MuiSwitch-thumb': {
      color: '#33cf4d',
      border: '6px solid #fff',
    },
    '&.Mui-disabled .MuiSwitch-thumb': {
      color:
        theme.palette.mode === 'light'
          ? theme.palette.grey[100]
          : theme.palette.grey[600],
    },
    '&.Mui-disabled + .MuiSwitch-track': {
      opacity: theme.palette.mode === 'light' ? 0.7 : 0.3,
    },
  },
  '& .MuiSwitch-thumb': {
    boxSizing: 'border-box',
    width: 22,
    height: 22,
  },
  '& .MuiSwitch-track': {
    borderRadius: 26 / 2,
    backgroundColor:
      theme.palette.mode === 'light' ? '#E9E9EA' : '#39393D',
    opacity: 1,
    transition: theme.transitions.create(['background-color'], {
      duration: 500,
    }),
  },
}));
			

class CommunityAdd extends Component {
  constructor(props) {
    super(props);

  const { location } = props;
  const isAddCommunity = location.pathname === "/manage-community/add";

  this.state = {
    access: isAddCommunity ? "" : location.state.access,
    title: isAddCommunity ? "" : location.state.title,
    community_id: isAddCommunity ? "" : location.state.id,
    description: isAddCommunity ? "" : location.state.description,
    category: isAddCommunity ? "" : location.state.category,
    loading: false,
    hosts_input: "",
    hosts: isAddCommunity ? [] : location.state.hosts_values.split(","),
    hosts_list: [],
    msg: false,
    msg_type: "success",
    deletePop: false,
    errors: {},
  };

  this.deleteLocal = deleteLocal.bind(this);
}

		componentDidMount() {
		  const { location } = this.props;

		  if (location.pathname === '/manage-community/add') {
			this.setState({
			  access: "",
			  title: "",
			  community_id: "",
			  description: "",
			  category: "",
			  hosts: []
			});
			this.forceUpdate();
		  }
		}

	  handleChange = (event) => {
		event.preventDefault();
		const { name, value } = event.target;
		let errors = { ...this.state.errors };
		 if (value === null || value === "") {
		  errors[name] = "Please fill out this field.";
		}
		this.setState({ errors, [name]: value });
	  };

	  checkError = (name, trimmedValue) => {
		//const trimmedValue = (typeof value === 'string') ? value.trim() : value;
		  let error = "";
		  /* if (name === "hosts" && trimmedValue.length==0) {
			error = `Please fill out this field`;
		  }
		  else */ if (trimmedValue === "") {
			error = "Please fill out this field.";
		  } else if (name === "title" && (trimmedValue.length < 5 || trimmedValue.length > 150)) {
			error = `Character count: ${trimmedValue.length} | Length must be between 5 and 150 characters.`;
		  } else if (name === "description" && (trimmedValue.length < 10 || trimmedValue.length > 5000)) {
			error = `Character count: ${trimmedValue.length} | Length must be between 10 and 5000 characters.`;
		  }
		  else if (name === "category" && trimmedValue.length === 0) {
			error = "Please fill out this field.";
		  }
		 

		  this.setState((prevState) => ({
			errors: {
			  ...prevState.errors,
			  [name]: error,
			},
		  }));

		  return error === "";
	};


  validForm = (list) => {
    let allFilled = false;
    this.setState({ addStatus: false });

    for (let i = 0; i < list.length; i++) {
      let value = list[i];
      allFilled = this.checkError(value, this.state[value]);

      if (allFilled === false) {
        break;
      }
    }

    return allFilled;
  };

  sendData(action) {
	  if(action=="next")
	  {
		  this.props.parentCallback(1);
	  }
	  else
	  {
		  this.props.history.goBack();		  
	  }
  }


  handleSubmit = (e) =>
   {
	   
		e.preventDefault();			
		let start_date=''
		let end_date=''
		let isValidate=this.validForm(community_list)
	
		let apiFun=''
		//alert(isValidate?1:2)
		if(isValidate==true)
		{	
			
				
			//var arr = JSON.parse(this.state.hosts);
			//var l = arr.map((e) => e.id).join(",");
			//var inst_values = [...new Set(l.split(","))].join(",");

			let payload = {
			  'access': this.state.access,
			  'title': this.state.title,
			  'description': this.state.description,
			  'category': this.state.category,
			  'creater_id': creater_id,
			  'hosts': creater_id
			};

			//console.log(arr);
			console.log(payload);


			let data=[]
			if(window.location.pathname!="/manage-community/add")
			{	
				payload.action='update';
				payload.id=this.state.community_id;
				payload.keys=""
			}
			else{
				
				payload.action='insert';
				payload.keys=(this.deleteKeys('add')).toString();


			}
			//console.log(payload.keys)
			this.setState({addStatus:'' });
			this.state.loading=true;
	
			axios.post(api+'/manage-community',payload)
			.then((response) => {
			//console.log("this is response");
			//console.log(response.data);
			this.state.loading=false;
			this.setState({msg:response.data['msg']})
			this.setState({msg_type:response.data['type']})
			if(response.data['type']=='success')
			{
					
					if(window.location.pathname=='/manage-community/add')
					{
						var community_id=response.data['community_id'];
						this.setState({community_id:community_id})
						var community =((this.state.title).trim()).replaceAll(" ", "-");
						localStorage.setItem("edit_community_id",community_id)
						setTimeout(() => {  this.sendData("next"); }, 1000);
					
                    }
					else
					{
						//  alert("check")
                     }
					
				
			}
			
			         setTimeout(() => { 
					   
					 this.setState({msg:false}, () => console.log(this.state.msg))
					   
					   
					   }, 5000);
			
			});
			
			
			
		}

	}
	handleClose = () => {
		  this.setState({ deletePop: false });
    }

	setList(val,table)
	{
		if(val.length>=3)
		{
			if(table=='hosts')
			{
				axios.post('/api/getProfessor',
				{'input':val})
				.then((response) => {
				this.setState({hosts_list:response.data})
				});
			}
			else
			{
				
			}
		}	
		else
		{
			this.setState({hosts_list:[]})
		}
	}

	handleDelete = () => {
		
		//let keys=this.deleteLocal(this.props.location.state['keys'])
		let keys="professor_community"
		let payload = {
		'id': this.state.community_id,
		'action':'delete',
		'keys':keys
		}
		//console.log(payload)
		axios.post(api+'/manage-community',payload)
		.then((response) => {
		this.setState({ deletePop: false});
		
		this.setState({msg:response.data['msg']})
		this.setState({msg_type:response.data['type']})		
		//console.log(response.data);
		
		setTimeout(() => {
			this.sendData("back")
		},3000);
	
		});
	}
	

		init_state=(arr)=>{
		
			if(window.location.pathname==="/manage-community/add")
			{
				return null;				
			}	
			else
			{
				let k=this.props.location.state[arr]
				return k;
			}
	}
	handleSwitchToggle = event => {
	  const newAccess = this.state.access =='private' ? 'public' : 'private';
		this.setState({
		  access: newAccess
		});
	}

	deleteKeys=(data)=>{
			
			return "";
			
	}
	componentDidMount()
	{
		//console.log(this.props.location.state);
	}
  	render()
  	{
		
		const classes = this.props.classes;
		const { errors } = this.state;
			const DeletePopup = () =>
			{

			return(
			
			 <Dialog
			open={this.state.deletePop}
			onClose={this.handleClose} 
			fullWidth 
			maxWidth="xs">
			
			<DialogTitle >
			<Typography variant="h6" color="textPrimary">Are you sure ?</Typography>
			</DialogTitle>
			
			<DialogContent >
			  <DialogContentText >
			  	<Typography  variant="subtitle1" color="textSecondary"  >
				<Box lineHeight={1.5}>
				Do you really want to delete these records? 
				This process cannot be undone.
			  </Box>
			  </Typography>
			  </DialogContentText>
			</DialogContent>
			
			
			<DialogActions >
			  <Button  onClick={ this.handleDelete}  color="primary"  size="large">Delete </Button>
			  <Button onClick={ this.handleClose}   color="primary" size="large">Cancel</Button>
			</DialogActions>
			</Dialog>
			
			
			)
			
		}
		
		const CustomLable=({title})=>{
			return(
			<Box display="flex">
			{title} 
			<Box marginLeft={2}>
			<Typography display="inline" color="error"> 
			* 
			</Typography>
			</Box>
			</Box>
			)
		}
		return (
				<Grid container spacing={2}>
  {this.state.msg && (
    <AlertBox
      show_status={true}
      msg={this.state.msg}
      msg_type={this.state.msg_type === "success" ? "success" : "warning"}
    />
  )}
  <Grid item xs={12}>
    <Card>
      <CardHeader title={"Add community details"} />
      <CardContent>
        <form role="form">
          <Grid container spacing={2}>
          
            <Grid item xs={12}>
              <TextField
                label={<CustomLable title="Community Name" />}
                color="primary"
                variant="outlined"
                name="title"
                type="text"
                onChange={this.handleChange}
                error={!!errors.title}
                helperText={errors.title}
                defaultValue={this.state.title}
                fullWidth
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                error={!!errors.description}
                helperText={errors.description}
                name="description"
                type="text"
                multiline
                rows={7}
                variant="outlined"
                label={<CustomLable title="Community Description" />}
                onChange={this.handleChange}
                defaultValue={this.state.description}
                fullWidth
              />
            </Grid>

            {/*
			<Grid item xs={12}>
              <FormControl variant="outlined" fullWidth>
				<Autocomplete
		  noOptionsText="Enter at least 3 characters to search matching admin"
		  multiple
		  id="tags-outlined"
		  limitTags={3}
		  options={this.state.hosts_list}
		  defaultValue={
			window.location.pathname === "/manage-community/add"
			  ? this.state.hosts
			  : JSON.parse(this.state.hosts)
		  }
		  getOptionLabel={(option) => option.name}
		  filterSelectedOptions
		  renderInput={(params) => (
			<TextField
			  {...params}
			  label={<CustomLable title="Admin" />}
			  placeholder="Admin"
			  variant="outlined"
			  error={!!errors.hosts}
			/>
		  )}
		  inputValue={this.state.hosts_input}
		  onInputChange={(event, newInputValue) => {
			this.setState({ hosts_input: newInputValue });
			this.setList(newInputValue, "hosts");
		  }}
		  onChange={(event, newValue) => {
			this.setState({ hosts: JSON.stringify(newValue) });
			errors.hosts = newValue == null ? "Please fill out this field." : "";
		  }}
		/>

                <FormHelperText>{errors.hosts}</FormHelperText>
              </FormControl>
            </Grid>
			*/}
			
            <Grid item xs={12} sm={6}>
              <FormControl
                variant="outlined"
                error={!!errors.category}
                fullWidth
              >
                <InputLabel>
                  <CustomLable title="Stream" />
                </InputLabel>

                <Select
                  label={<CustomLable title="Stream" />}
                  name="category"
                  onChange={this.handleChange}
                  value={this.state.category}
                >
                  {Category.map((e, index) => (
                    <MenuItem value={Category[index]}>
                      {Category[index]}
                    </MenuItem>
                  ))}
                </Select>
                <FormHelperText>{errors.category}</FormHelperText>
              </FormControl>
            </Grid>
			
			  
            <Grid item xs={12} sm={6}>
					<Box display="flex" justifyContent="end" >
					<Typography variant="subtitle1" color="textSecondary">
					{
						this.state.access === "public" 
						? 'This community is public and can be accessed by anyone.' 
						: 'This community is private and requires permission to access.'
					}
					</Typography>
					</Box>
				
					<Box marginTop={1} display="flex" justifyContent="end" >
					  <FormControlLabel
						style={{ marginRight: '0px' }}
						control={
						  <IOSSwitch 
							checked={this.state.access=="public"?true:false}
							onChange={this.handleSwitchToggle} />
						  
						}
					  />
					</Box>
            </Grid>
			


            <Grid item xs={12}>
              <Box display="flex" flexDirection="row-reverse">
            	{window.location.pathname!='/manage-community/add'?
						<>
						<Box marginLeft={1}>
						<Avatar
						  className={classes.reverse_primary}
						  onClick={() => {
							this.sendData("next");
						  }}
						>
						  <FaChevronRight fontSize="large" />
						</Avatar>
					  </Box>
					  
					  <Box marginLeft={1}
						display={creater_id!=null?"block":"none"}>
						<Button variant="contained" size="large" color="secondary" 
						onClick={()=>{this.setState({deletePop:true})}}>
						Delete
						</Button>
						<DeletePopup />
						</Box>
						
						</>
						:''}
						
						
							
						
					  
						<Box marginLeft={1} >
						<Button variant="contained" size="large"   color="primary"  onClick={this.handleSubmit} fullWidth disabled={this.state.loading}>
						  {this.state.loading && <CircularProgress size={24} />}
						  {!this.state.loading && <span>{window.location.pathname=='/manage-community/add'?"Add Community":"Update Community"}</span>}
						</Button>
						</Box>
						
						
						<Box marginLeft={1}>
						<Avatar
						  className={classes.reverse_primary}
						  onClick={() => {
							this.sendData("back");
						  }}
						>
						  <FaChevronLeft fontSize="large" />
						</Avatar>
					  </Box>
						
					</Box>
					</Grid>
					</Grid>
			      	</form>
					
					</CardContent>
					</Card>
					
					</Grid>
                  
                    
				</Grid>
                    
            
	    );
  	}
}
//export default withStyles(useStyles)(CommunityAdd)
export default (props) => {
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();
	return (
        <CommunityAdd classes={classes}
		history={history} 
		location={location} 
		parentCallback={props.parentCallback}  />
    )
}

  
  
  