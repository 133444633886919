import {
	Box,
	
	Grid, Slide, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';


const useStyles = theme => ({
image: {
			position:'relative',
			 backgroundColor:'rgb(0 0 0 / 75%)',
			 width:"100%",height:"140px"
		  },
		 
		  img: {
			margin: 'auto',
			display: 'block',
			maxWidth: '100%',
			maxHeight: '100%',
			width: 'inherit',opacity:'0.5',
			height: 'inherit',
		  },

		overlay: { 	position:'relative',
		backgroundColor:'rgb(0 0 0 / 75%)',
		width:" 100%",height:"100%"  },
		
		bgColor:{
			//backgroundColor:'rgb(228, 228, 228)' ,
		//'border-top-left-radius':'12px', 'border-top-right-radius':'12px'
		
		},	 
		shadow:{'box-shadow':'none',width:" 100%",height:"320px" }
});
	

class Benefits extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
			const classes = this.props.classes;	

		return (
		
		   <Grid container spacing={0}  justifyContent="center"  style={{'background-color': 'white',padding:'16px'}}>
			<Grid item xs="auto"  >
			 <Typography >
			  <Box  marginTop={4} fontWeight="600" lineHeight={1.25} fontSize="32px" 
			  color="text.primary">
				We will provide the benefits as mentioned below
			 </Box>
			  </Typography>
			</Grid>
			
			<Grid item xs={12} container spacing={2} justifyContent="center"  >

			<Grid item  sm={12} lg={4} >		
			  <Typography>
			  <Box  lineHeight={1.25} color="text.primary" fontSize="22px" marginTop={5} padding={4}>
				You will be featured in our community
				blog written by our editorial team.<br/><br/>
				Your profile will be highlighted on our
				website and social media such as
				LinkedIn, Twitter, Facebook, etc.<br/><br/>
				Get OpenGrowth Hub free for one month
				(schedule daily meetings, track projects, and
				collaborate with your employees).<br/><br/>
				A Special Feature in our Newsletter will
				be published.
			  </Box>
			  </Typography>
					
			</Grid>
	
			<Grid item  sm={12} lg={5} >		
				<Slide direction="left"  
					in={true} mountOnEnter unmountOnExit>
					<Box display="flex" 
					  justifyContent="center"
					  alignItems="center" 
					  padding={4}
					   height="100%" width="100%">
					  <img src={'/assets/images/homepage/images/events/tempsnip.svg'} height="inherit"  width="inherit" alt="benefits" style={{borderRadius:'12px',backgroundSize:'cover'}} />
					</Box>
					</Slide>
			</Grid>
			</Grid>
		  </Grid>
		  
		);
  	}
}
  
export default withStyles(useStyles)(Benefits)
