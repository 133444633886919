
import { Avatar, Box, Button, Card, CardHeader, CircularProgress, Grid, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import AlertBox from "../../AlertBox";
import { api,community_path,images } from "../../Helper";
import { deleteLocal } from "../../caching";
import { useStyles } from "../../useStyles";
import { useHistory, useLocation } from 'react-router-dom';
import convertSize from "convert-size";
import { FaChevronRight,FaChevronLeft } from 'react-icons/fa';
import AnyFileViewer from "../../chapters/stepper/AnyFileViewer";



class CourseBlock extends Component {

    constructor(props) {
    super(props);
    this.deleteLocal = deleteLocal.bind(this);

    this.state={
            loading:false,
			imgDetails:false,
            
            db_upload:false,
            folder_upload:false,
            isFileUpdated:false,
			
            size:false,
            type:false,
			            
            img_msg:false,img_msg_type:false,
            img:this.props.location.state==undefined?null:this.props.location.state.banner,
            community_id:this.props.location.state==undefined?localStorage.getItem('edit_community_id'):this.props.location.state.id
		
	}
    }
	
	clearValues = () => {
		this.setState({
			imgDetails: false,
			loading: false,
			img_msg: false,
			db_upload: false,
			folder_upload: false
		});
	}
	imageHandler = (e) => {
		  this.clearValues();
		  //console.log(e.target.files);
		  const file = e.target.files[0];
		  const { name, size, type } = file;
		  
		  if (name.match(/\s/g)) {
			this.setState({
			  img_msg: 'Filename cannot contain whitespaces.',
			  img_msg_type: 'warning',
			  imgDetails: false
			});
			return;
		  }
		  
		  if (name.length > 20) {
			this.setState({
			  img_msg: 'Filename length must be <=20 characters.',
			  img_msg_type: 'warning',
			  imgDetails: false
			});
			return;
		  }
		  
		  if (!name.match(/\.(png|jpg|jpeg|svg|gif|PNG)$/)) {
			this.setState({
			  img_msg: 'Please upload an image file.',
			  img_msg_type: 'warning',
			  imgDetails: false
			});
			return;
		  }
		  
		  const reader = new FileReader();
		  reader.onloadend = () => {
			this.setState({
			  img: reader.result,
			  isFileUpdated: true,
			  upload: false
			});
		  };
		  reader.readAsDataURL(file);
		  
		  this.setState({
			imgDetails: file,
			size: size,
			type: type.toString(),
		  }, () => {
			//console.log(this.state.size);
			//console.log(this.state.type);
		  });
		  
		  setTimeout(() => {
			this.setState({ img_msg: false });
		  }, 2000);
		};

		deleteKeys = () => {
		  const s_community_keys = this.deleteLocal('_community_dashboard_');
		  const keys = [s_community_keys];
		  //console.log(keys);
		  return keys;
		};

		sendData = (st) => {
		 if(st=="move")
		 {
			 this.props.history.push("/our-communities")
		 }	
		 else
		 {
			 this.props.parentCallback(0);
		 }
		};

	
	handleSubmit = async (e) => {
		e.preventDefault();
		
		let key = this.deleteKeys();
		let isupload = true;
		
		this.setState({
			db_upload: false,
			folder_upload: false,
			loading: true
		});
		
		if (!this.state.imgDetails) {
			this.setState({
				img_msg: 'Please upload valid media file.',
				img_msg_type: 'warning',
				loading: false
			});
			isupload = false;
		} else {
			let size = Math.round(convertSize(this.state.size, "MB"));
			let limit = 2;
			let t = true;
			
			if (this.state.type.includes("image") && size > 2 && this.state.choice !== "all") {
				t = false;
			}
			
			
			if (t === false) {
				this.setState({
					img_msg: `${this.state.type} file (${size} mb) must be less than ${limit} mb.`,
					img_msg_type: "warning",
					db_upload: `${this.state.type} file (${size} mb) must be less than ${limit} mb.`,
					loading: false
				});
				isupload = false;
			} else {
					this.setState({db_upload: 'Updating database ....'});
					let payload = {
						'banner': this.state.imgDetails['name'],
						'id': this.state.community_id,
						'keys': key.toString()
					};
					//console.log(payload);
					let response = await axios.post('/api/update_media_for_community', payload);
					if (response.data === "updated") 
					{
						this.setState({
							db_upload: 'Database updated successfully',
							img_msg: "File updated successfully",
							img_msg_type: "success"
						});
						
						if (this.state.imgDetails && response.data === "updated")
						{

							const header = {};
							let data = new FormData();
							header.Accept = 'application/json';
							data.append('file', this.state.imgDetails);
							data.append('keys', key);
							
							this.setState({folder_upload: 'Uploading starts ....'});
							let response = await axios.post(api + '/upload_media_for_community', data);
							
							//console.log(response.data);
							this.setState({
								isFileUpdated: true,
								loading: false,
								img_msg: response.data['msg'],
								folder_upload: response.data['msg']
							});
							
							/*let k = this.props.parent_data;
							k['banner'] = this.state.imgDetails['name'];
							*/
							//this.props.parent_data=k;
							//this.back();
						}
					} else {

						this.setState({
							db_upload: 'Database update operation failed ....',
							img_msg: "Try Again with non-existing record / Change the filename",
							loading: false
						});
					}
				
			}
		}
		
		setTimeout(() => {
			this.setState({
				img_msg: false,
				loading: false
			});
		}, 3000); 
		
		this.forceUpdate();
	}

	back=()=>{
		setTimeout(() => {
		 
			this.props.history.goBack();
		 
          }, 5000); 
	}
	componentDidMount()
	{
		
	}
	
	
  	render()
  	{
		

		const classes = this.props.classes;	
		const { errors } = this.state;
        let route=community_path
	const UploadImg = () => {
	  const styles = {
		large: {
		  height: '150px',
		  width: '150px',
		  border: '3px solid #e5e5e5',
		  borderRadius: '50%',
		},
	  };

	  const fileSrc = this.state.img && !this.state.isFileUpdated
		? route + this.state.img
		: this.state.img || false;


	  return (
		<Grid container direction="column" justifyContent="flex-start" alignItems="center" spacing={0}>
		  <Grid item xs={10}>
			<Box marginTop={3} display="flex" alignItems="center" justifyContent="center">
			  <Box position="relative" width="100%">
				<Box display="flex" alignItems="center" justifyContent="center" width="max-content" marginBottom={3}>
				  <Typography variant="subtitle1" style={{ lineHeight: 1.25 }} color="textSecondary">
					Click below to upload the media!
				  </Typography>
				</Box>

				<Box display="flex" alignItems="center" justifyContent="center">
				  <label htmlFor="photo-upload">
					<input accept="image/*" id="photo-upload" multiple type="file" onChange={this.imageHandler} hidden />

					{fileSrc ? (
					  <Avatar style={styles.large} src={fileSrc} />
					) : (
					  <Avatar
						style={styles.large}
						src={images['upload']}
					  />
					)}
				  </label>
				</Box>
			  </Box>
			</Box>
		  </Grid>

		  <Grid item xs={12} align="center" justify="center" alignItems="center">
			<Box marginBottom={3} marginTop={3}>
			  <Typography variant="subtitle2" color="textSecondary">
				Allowed *.jpeg, *.jpg, .*png, *.gif
			  </Typography>

			  <Typography variant="subtitle2" color="secondary">
				{this.state.imgDetails ? this.state.imgDetails.name : this.state.msg}
			  </Typography>
			</Box>
		  </Grid>
		</Grid>
	  );
	};

return (
  <Card>
    <CardHeader title="Update Community Banner" />

    <Grid
      container
      spacing={2}
      direction="row"
      alignItems="center"
      justify="center"
    >
      {this.state.img_msg ? (
        <AlertBox show_status msg={this.state.img_msg} msg_type="success" />
      ) : null}

      <Grid item xs={10} md={5} container>
        <Grid item xs={12}>
          <Box marginTop={3} marginBottom={3} textAlign="center">
            <Typography variant="subtitle2" color="secondary">
              {this.state.db_upload}
            </Typography>

            <Typography variant="subtitle2" color="secondary">
              {this.state.folder_upload}
            </Typography>
          </Box>
        </Grid>

        <Grid xs={12}>
          <Box padding={1}>
            <UploadImg />
          </Box>
        </Grid>

        <Grid item xs={12}>
          <Box display="flex" justifyContent="center" alignItems="center" marginBottom={1}>
            <Button variant="contained" size="large"   color="primary"
				onClick={this.handleSubmit} 
				disabled={this.state.loading} >
				{this.state.loading && <CircularProgress size={18} color="textSecondary"/>}
				 {!this.state.loading && <span> Upload file</span>}
				</Button>
				</Box>
		</Grid>
				
		</Grid>
		
		
		<Grid item xs={10} md={5} >
			<Box display="flex"  alignItems="center" justifyContent="center">
			<Box marginTop={3} marginBottom={3} width="90%">				
				<AnyFileViewer 
				src={this.state.img} info="community" />
				</Box>
			</Box>
		</Grid>
		
		
		<Grid item xs={12}>
						<Box display="flex"  marginTop={3} marginBottom={3} flexDirection="row-reverse" >
						
													
						<Box marginRight={2}  marginLeft={2}
						display="" >
						<Avatar  className={classes.reverse_primary} 
						onClick={()=>{this.sendData('move')}} >
						  <FaChevronRight fontSize="large"/>
						</Avatar>
						</Box>
						
						<Box marginRight={2}  marginLeft={2} 
						display={window.location.pathname=="/manage-community/add"?"none":"block"}>
						<Avatar className={classes.reverse_primary} onClick={()=>{this.sendData('back')}} >
						  <FaChevronLeft  fontSize="large"/>
						</Avatar>
						</Box>
							
							
						
					</Box>
		</Grid>
		</Grid>
        </Card>

            
            
	    );
	
  	}
}
export default (props) => {
    const classes = useStyles();

	const location = useLocation();
    const history = useHistory();
    return (
        <CourseBlock 
        classes={classes}
        location={location}  
        history={history}
		parentCallback={props.parentCallback}
		/>
    )
}

