import {
	Avatar, Badge, Box, Button, Card, CardContent, CardHeader, CircularProgress, Grid,
	List, ListItem, ListItemAvatar, ListItemText, makeStyles, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { useEffect, useState,useContext } from 'react';
import { FaUsers } from "react-icons/fa";
import InfiniteScroll from "react-infinite-scroll-component";
import { io } from "socket.io-client";
import { getCacheDetails } from "../caching";
import createActivityDetector from "activity-detector";
import { barColor, inactivity_duration, is_local, userProfile_path,socket_setup,images } from "../Helper";
import ConnectionStatus from "./ConnectionSatus";
import Messages from './Messages';
import { UserContext } from '../courses/UserContext';

const activityDetector = createActivityDetector({ timeToIdle: inactivity_duration });

let student_id=null;
let mentor_id=null;
let email=null;
let status=null;
if(["admin","webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	mentor_id=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_id']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}

const participntStyles = makeStyles((theme: Theme) => ({
  tab_root: {flexGrow: 1,width: '100%'},
  global:{textTransform: 'capitalize',color: 'rgb(99, 115, 129)','text-align': 'center',fontSize:'0.87rem',},
  m:{marginLeft:'auto' },
  width:{minWidth:'120px'},
  tab: {verticalAlign : 'middle',marginRight:'8px',display:'unset'},
  	 small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
	border:'3px solid #ebebeb'
      }, 
      card:{    padding: '16px 0px 16px 16px ',
      backgroundColor:barColor ,borderRadius:'0px',
          'border-bottom-left-radius': '12px',
        'border-bottom-right-radius': '12px'
    },
    avatar: {
	  marginTop:"0px",
    'min-width':'48px !important',
  }, 
 
  root:{ 'height':'calc(100vh - 227px)','overflow-y':'auto',
  padding: '0px 16px 0px 0px'},
  title:{fontWeight:'bold'},
}));


const StyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#44b700',
    color: '#44b700',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      //animation: '$ripple 1.2s infinite ease-in-out',
      //border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);
  
const InStyledBadge = withStyles((theme) => ({
  badge: {
    backgroundColor: '#f9bb02',
    color: '#f9bb02',
    boxShadow: `0 0 0 2px ${theme.palette.background.paper}`,
    '&::after': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      borderRadius: '50%',
      //animation: '$ripple 1.2s infinite ease-in-out',
      //border: '1px solid currentColor',
      content: '""',
    },
  },
  '@keyframes ripple': {
    '0%': {
      transform: 'scale(.8)',
      opacity: 1,
    },
    '100%': {
      transform: 'scale(2.4)',
      opacity: 0,
    },
  },
}))(Badge);



var sender='';
var sender_id='';
var sender_status='';
let k=0;
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	sender=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	sender_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	sender_status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}


let socket = null	

export default function App() {
	const classes = participntStyles();
	const [profile_list, set_profile_list] = useState([]);
	const [record_len, set_record_len ] = useState(0);
	const [start, set_start] = useState(0);
	const [end, set_end] = useState(10);
	const [hasMore, set_hasMore] = useState(true);	
	const [msg, setMsg] = useState(false)
	const [user, set_user] = useState(false)
	const [, forceUpdate] = useState(0);
    const [socketInstance, setSocketInstance] = useState("");
    const [loading, setLoading] = useState(true);
	const context_ref = useContext(UserContext);
	const instance_ref = React.useRef([]);

	useEffect(() => {
		
		//alert(user['email'])
		//console.log("------------- fetching mentors/mentee profile  --------------- ")
		if(mentor_id==null)
		{
			setLoading(false)
			set_hasMore(false)
			set_user([])
		}
		else{
			get_profile_list("initial",0)
		}
		//console.log(socket_setup)

		
				socket = io(socket_setup['end_point'], {
				transports: ["websocket"],
				autoConnect: true,
				reconnect: false,
				rejectUnauthorized:false,
				withCredentials: true,
				cors: {origin: socket_setup['origin'],methods: ["GET", "POST"]}
				});
			
			
		//redirect(site_url("resources/".$this->uri->segment(2)));
		/*reconnectionAttempts:3,reconnectionDelay:5000,reconnectionDelayMax:10000,timeout:10000*/
		
		//console.log("------------- socket connection log --------------- ")
		//console.log(socket);
		instance_ref.current=socket;
		context_ref.set_socket_info(socket);
		setSocketInstance(socket);
		//console.log(socketInstance);

		let k=0;
		let date=new Date()
		
			socket.on("connect", (data) => {
			k=k+1
			//console.log("connect function called times - "+k)
			//console.log("------------- "+date+" client socket is connected ------------------- ")
			//console.log("connect server listener returns object value")
			//console.log(data)
			});
		
		

		socket.on("disconnect", (data) => {
			//console.log("------------- client disconnected due to --------------- "+data)
			socket.removeAllListeners();
			//console.log(" sending connect request after disconnect ")
			//console.log(" calling callback function to connect ")
			handleCall();
			//socket.connect();

		});
			  
		socket.on("reconnect", (attempt) => {
			//console.log("------------- client reconnect is successfull  --------------- ")
			//console.log("reconnect server listener returns object value")
			//console.log(attempt);
		});
	
		socket.on("connect_error", (error) => {
			//console.log("------------- client connection raised error  --------------- ")
			//console.log("connect_error server listener returns object value")
			//console.log(error);
		});
		
		
		return function cleanup() {
			
			if(context_ref.socket_info!=null)
			{
				(context_ref.socket_info).removeAllListeners()
				(context_ref.socket_info).off("data", () => {
				console.log("data  event was removed");});
				//socket.off("active_user", () => {//console.log("log_active_user event was removed");});
				(context_ref.socket_info).disconnect();
				//console.log("Disconnecting socket on unmount");
			}
		};			
		//socket.on("log_active_user", (data) => {//console.log(data)});
		
	},[]);



		
    const fetchMoreData = () => {
    
		if(hasMore!=false)
		{
			let t=start+end
			set_start(t)
			get_profile_list("scroll",t)			
		}
	};	
	const get_profile_list=(state,start_is)=>
    {
		 let key=sender_id+"_my_mentors_"+start_is+"_to_"+end;
		 let arr=[sender_id,start,end]
		 if(status!='student')
		 {
			key=mentor_id+"_my_mentee_"+start_is+"_to_"+end;
			arr=[mentor_id,start,end]			
		 }
			getCacheDetails(key,"get_mentees_mentors",arr)
			.then(data => {	
			//console.log(data)
			let t=profile_list
			if(state=="initial")
			{
				t=data
			}
			else
			{
				t=[...profile_list,...data]
			}
			set_profile_list(t);
			if((data).length<end)
            {
				set_hasMore(false)
            }
			setLoading(false);
			set_record_len(profile_list.length)
			}); 
    }
  	
  	const getMessages=(ev)=>{
		k=0;
		setMsg(true)
		ev.chat_id=get_chat_id(ev)
		set_user(ev)
		////console.log(chats.current);

		//console.log(ev)
		
	}	
	const handleCall=()=>{
		//console.log("trying to connect after callback");
				
				socket = io(socket_setup['end_point'], {
				transports: ["websocket"],
				autoConnect: true,
				reconnect: false,
				rejectUnauthorized:false,
				withCredentials: true,
				cors: {origin: socket_setup['origin'],methods: ["GET", "POST"]}
				});
			
			
		//redirect(site_url("resources/".$this->uri->segment(2)));
		/*reconnectionAttempts:3,reconnectionDelay:5000,reconnectionDelayMax:10000,timeout:10000*/
		
		//console.log("------------- callback socket connection log --------------- ")
		//console.log(socket);
		context_ref.set_socket_info(socket);
		setSocketInstance(socket);
		//console.log(socketInstance);
		//forceUpdate(n=>!n);
		
	}
	const get_chat_id = (receiver_arr) =>
	{
		let d,str1,str2=''
		let recev_is=receiver_arr['user_is']
		let recev_id=receiver_arr['id']
		
		recev_is=status=='student'?'professor':'student'
		recev_id=status=='student'?receiver_arr['professor_id']:receiver_arr['id']
		
		if(parseInt(recev_id)<=parseInt(sender_id))
		{
			str1=((recev_is).toLowerCase()).charAt(0)+recev_id
			str2=(sender_status.toLowerCase()).charAt(0)+sender_id	
		}
		else
		{
			str2=((recev_is).toLowerCase()).charAt(0)+recev_id
			str1=(sender_status.toLowerCase()).charAt(0)+sender_id				
		}
		d=str1+"_"+str2;
		//console.log("chat_id - "+d+" , recev_is - "+recev_is+", recev_id - "+recev_id)
		//console.log("chat_id - "+d+", sender_status - "+sender_status+", sender_status - "+sender_id)
		return d;
	}
	
	
	const UserList=()=>
	{		
		let len=profile_list.length;		
		return (
		<Box  display={len==0?"none":"block"}>

			<Box >
			 <div id="scrollableDiv" style={{ height: "70vh", overflow: "auto" }}>
			<InfiniteScroll
			style={{overflow:'hidden'}}
			dataLength={len}
			hasMore={hasMore}
			loader={
				
					this.state.hasMore==true
					?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
			}
				 

			endMessage={
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
				<Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box> 
			}
			 scrollableTarget="scrollableDiv"

			>
			
			<List>
			{
				profile_list.map((e, index) => (
				<React.Fragment>
					
							<Button fullWidth onClick={()=>{getMessages(e)}}>
							<ListItem alignItems="flex-start" style={{paddingRight:"0px",paddingLeft:"0px"}}>
							<ListItemAvatar
							className={classes.avatar}>
							<StyledBadge
									overlap="circular"
									anchorOrigin={{
									  vertical: 'bottom',
									  horizontal: 'right',
									}}
									variant="dot"
								  >
								<Avatar 
								style={{height:'35px',width:'35px'}} src={userProfile_path+e['img']} />
								 </StyledBadge>
							</ListItemAvatar>
							<ListItemText
							  primary={
								<Box>
								 <Typography color="textPrimary" variant="caption">
								<Box lineHeight={1.1} className={classes.title} > {e['name']==''?e['email']:e['name']} </Box>
								<Box 
								
								lineHeight={1.6} fontSize="caption.fontSize" color="text.secondary"> {e['user_is']} </Box>
								 </Typography>
								</Box>
								}
							/>
						</ListItem>
					</Button>


				</React.Fragment>
				))}
			</List>
			</InfiniteScroll>
			</div>
			</Box>
				
		</Box>
		)
	}

	const StartMessaging=()=>{
		return(
			<Card elevation={3} >
						<CardContent className={classes.card} 
						style={{'height':'81vh',
						backgroundColor:"rgb(253 253 254)"}}>
					
						<Grid container  alignItems="center"
						justify="center" className={classes.root} >
							
							<Grid item xs={12} style={{marginBottom:'0px'}}>  
								<Box display="flex" justifyContent="center" alignItems="center" flexDirection="column">
									<img src={images['message']} alt="" height="370px" width="420px" />  
								</Box>
										
								<Box display="flex" justifyContent="center" alignItems="center" flexDirection="row" >
									<Typography variant="h5" color="textPrimary">
										Start Messaging ...!
									</Typography>
								</Box>
										
							</Grid>
				
						</Grid>
					</CardContent>
					</Card>
		)
	}
	const UsersCard=()=>{
			
			const root={    
			height: 'calc(100vh - 157px)',
			padding: '0px 16px 0px 0px',
			'overflow-y': 'hidden',
			'height':'calc(100vh - 216px)'}
			
		return(
		<Card elevation={3} >
		<CardHeader title={
			<Typography 
                className={classes.title}
                color="primary" variant="subtitle1">
			   <Box fontWeight="600">
				<FaUsers className={classes.tab} fontSize="large"  /> 
					{status=='student'?"Experts":"Mentees"}
				</Box>
                 </Typography>} />
		
		<CardContent className={classes.card} >
		<Box style={root}>	
		
		{
                profile_list.length==0 && loading==true
                ?
                    <Box marginTop={2} marginBottom={2} sx={{ alignItems: 'center', display: 'flex',flexDirection: 'column'}}>
                    <CircularProgress color="primary" size={24} />
                    </Box>
                :
					profile_list.length==0
					?
						<Box marginTop={1} marginBottom={1} sx={{ alignItems: 'center', display: 'flex',flexDirection: 'column'}}>
						<Typography 
						color="textPrimary" variant="subtitle1">
						{status=='student'?"Experts":"Mentees"} not found.
					   </Typography>
					   </Box>
					:
						<UserList/>
		}
		</Box>	
		</CardContent>
		</Card>
			)
		}
        
    return (
	<React.Fragment>
	
	<ConnectionStatus user={user} parentCallback={()=>handleCall}/>
	
	
	<Grid container alignItems="center" justify="center" spacing={2}
	style={{backgroundColor:"transparent"}}>
		
		<Grid item xs={12} sm={3} >	
		<UsersCard/>		
		</Grid>
		
		<Grid item xs={12} sm={9} style={{'height':"unset"}}>	
		{
			user!=false && msg==true
			? 
				<Messages
				parentCallback={handleCall}
				receiver_info={user} 
				/>
				
			:	
				<StartMessaging />

			
		}
		</Grid>


	</Grid>
	</React.Fragment>

  );
}
