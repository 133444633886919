
import {
	Box, Button, Card, CardContent, CardHeader, Chip,
	Drawer, Grid, IconButton, makeStyles, Typography
} from '@material-ui/core';
import AccessTimeIcon from '@material-ui/icons/AccessTime';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import CloseIcon from '@material-ui/icons/Close';
import EventIcon from '@material-ui/icons/Event';
import axios from 'axios';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { isMobile } from 'react-device-detect';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory } from 'react-router-dom';
import { deleteLocal } from "../../caching";
import { format_date, successColor, update_lc_mentor } from "../../Helper";
import AddSlots from './AddSlots';
import AlertBox from "../../AlertBox";







let mentor_id=null
let professor_id=null
let email=null
let status=null
if(["admin",null].includes(localStorage.getItem('login_token'))==false)
{
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	professor_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	if(status=='professor')
	{
		mentor_id=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_id']
	}	
	//alert(mentor_id)
}

 const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 4
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items:4
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 4
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

const useStyles = makeStyles(theme => ({
	icon:{fontSize:'0.85rem'},
	iconbtn:{padding:'2px'},
	shadow:{
		border:'0px solid black',
		backgroundColor:"#3453b01a",
		fontWeight:"bold",
		color:"#253B7C",
		'&:focus':{
			
		},
	},
	ic:{padding:'6px'},
	drawerPaper: {
    borderRadius: '0px ',
	},
	cardcontent:{padding:'8px 16px',cursor:'pointer'},
	bg:{backgroundColor:'#f2f2f240'},
}));


  

export default function GetSlots(props) {
	
		const classes = useStyles();
		const history = useHistory();
		
		const [drawerOpen, setDrawer] = useState(false);
		
		const [slots, setSlots] = useState([]);
		const [active_month_slots, setActiveMonthSlots] = useState([]);
		
		const [slot_id, setSlotID] = useState(null);
		const [loading, setLoading] = useState(false);
		const [msg, setMessage] = useState(false);
		const [msg_type, setMessageType] = useState("success");
		const [is_update, setUpdate] = useState(false);
		const [add_btn, addBtn] = useState(false);
		const [date, changeDate] = useState(new Date());
		const [time, changeTime] = useState(new Date());
		const [active_month, setActiveMonth] = useState((new Date()).getMonth()+1);
		
		const [action, setAction] = useState("add");
		const [slot_data, setSlotData] = useState({});
		
		const [, forceUpdate] = React.useState(0);
		
		
		const setDrawerOpen = (isDrawerOpen) => {
			setDrawer(isDrawerOpen);
		  }
		  
		const toggleDrawer = () => {
			//this.setState((pstate) => ({ drawerOpen: !pstate.drawerOpen }))
			setDrawer(!drawerOpen)
			forceUpdate(n => !n)
			
		  }
		const handleCallback = () => {
			toggleDrawer();
			setSlotData({})
			fetch_slots();
			setAction("add")
			forceUpdate(n => !n)
			console.clear()
		  }
		const deleteKeys=()=>{
			
				let keys_1=deleteLocal(professor_id+"_all_mentors_")
				let keys_2=deleteLocal(professor_id+"_mentors_")
				let keys_4=deleteLocal(professor_id+"_my_mentors_")
				let keys_3=deleteLocal(professor_id+"_upcoming_mentorship_")	
				let keys_5=deleteLocal(professor_id+"_all_mentors_0_to_")		
				let keys_6="user_"+professor_id+"_mentor_slot_"
				let keys_7="mentor"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6,keys_7]
				return keys
		}
	    const update_mentors_slots=(slot) => {
			
			setAction("update")

			let param={
				'date':slot['slot_date'],
				'slot_id':slot['id'],
				'duration':slot['duration']
			}
			setSlotData(param)
			
			toggleDrawer();
			//Sat Jul 30 2022 19:09:00 GMT+0530 (India Standard Time)
			//changeDate(date)
			//changeTime(date)
			//setSlotID(slot['id'])
	    }
	    
	   const get_recent_slot=(date) => {
		   //console.log(date)
		   let month=((new Date(date)).getMonth())+1
		   		 //  alert(month);

		   get_mentors_slot(month);
		}
	   const get_mentors_slot=(month) => {
		   
		   if(month!='')
		   {
			   setActiveMonth(month)
		   }
		   let key="user_"+professor_id+"_mentor_slot_"+mentor_id
			axios.post('/api/get_mentors_slot',
			{
				'mentor_id': mentor_id,
				'key':key,
				'active_month':month,
				'requested_slot':false,
				
			})
			.then((response) => 
			{
				if(month!='')
				{
					setActiveMonthSlots(response.data);
					////console.log(response.data)
				}
				else
				{
					setSlots(response.data);
					////console.log(response.data)

				}
				
						
			})
		}
		const get_req_msg=()=>{
		
			axios.post('/api/find_is_mentor',
			{'email':email})
			.then((response) => {
				let t=response.data

				update_lc_mentor(t,"verify")
				if(t['is_mentor']=="mentor")
				{
					fetch_slots();
					mentor_id=t['mentor_id']
				}
				else
				{
					
					history.push("/dashboard")
				}
				//alert(mentor_id)
			});
			
		}

						
	   const fetch_slots=() => {
			
			get_mentors_slot('');
			get_mentors_slot(active_month);

	   }				
	   const delete_mentors_slots=(id) => {
		   
		   	let key=(deleteKeys()).toString()
			axios.post('/api/remove_mentors_slot',
			{
				'mentor_id': mentor_id,
				'id': id,
				'key': key
			})
			.then((response) => 
			{
				let data=response.data;
				//console.log(data);
				if(data['msg']=="success")
				{
					setMessage("Slot removed successfully!");
					setMessageType("success");
				}	
				else
				{
					setMessage(data['info']);
					setMessageType("warning");

				}	
				fetch_slots();
				setTimeout(() => {
						setMessage(false)	
				}, 5000);
						
			})
		}

		
		const getDisabledHours = () => {
			var hours = [];
			for(var i =0; i < moment().hour(); i++){
				hours.push(i);
			}
			return hours;
		}

		const getDisabledMinutes = (selectedHour) => {
			var minutes= [];
			if (selectedHour === moment().hour()){
				for(var i =0; i < moment().minute(); i++){
					minutes.push(i);
				}
			}
			return minutes;
		}
		const addStr=(str, index, stringToAdd)=>
		{
		  return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
		}	
		
		const get_time=()=>
		{
			let tt=time.getHours()>11?time.getHours()-12:time.getHours()
			let t=tt.toString()
			t=t.length==2?t:("0"+t)
			t=t+ ":" 
			
			let m=time.getMinutes().toString()
			m=m.length==2?m:("0"+m)
			
			t=t+m+" "
			return t;
		}	
		
		useEffect(() => {
			get_req_msg();
		},[])

		const RightDrawer=({setDrawerOpen,open})=>{
		  return(
		  <div>
			<Drawer anchor="right" width={400} openSecondary={true} open={open}
			classes={{ paper: classes.drawerPaper }}>
			 <AddSlots 
			 parentCallback={handleCallback}  
			 action={action}
			 slot_data={slot_data}
			 />
			</Drawer>
		  </div>
		)
		}
		
		const RecentsSlot = () =>
	    { 
			return(	
				<Card elevation={1}>
				<CardHeader title="Recents Slots" />
				<CardContent>			
						
				{
			
				slots.length==0
				?
						<Box >
						<Typography variant="subtitle1" color="textSecondary">
						Slot not found
						</Typography>
						</Box>
				:
				
					<Box display="flex">
					{
						slots.map((e, index) => (
							<Box width="fit-content"
							display="flex"
							margin={1}
							onClick={()=>{get_recent_slot(e['to_date'])}}>
							<Card>
							<CardContent className={classes.cardcontent}>
									
								<Typography variant="caption"
								color="textSecondary" align="center">
								 <Box fontWeight="100">{(e['date_title']).split(",")[0]}</Box>
								 </Typography>
													
								<Typography variant="subtitle1" color="secondary" align="center">
								<Box 
								fontWeight="700"
								margin="4px 0px"
								lineHeight={1.25}
								>{format_date(e['date_title'],"short")}</Box>
								</Typography>
								<Typography variant="caption" align="center">
								<Box fontWeight="600" color="secondary">
								{(e['time_title']).slice(0,10)}</Box>
								</Typography>
													
							</CardContent>
							</Card>
							</Box>
										
						))
					}
					</Box>
				}
				</CardContent>
				</Card>
			)
		}
		
		
	
		const SlotsData = () =>
	    { 
			let d=(new Date()).getMonth();
			var months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
			
			var id=0;
			var ac=0;
			
			return(	
			<Card elevation={1}>
			<CardHeader title="Filter Slots" />
			<CardContent>	
			
			<Box marginBottom={2}>
			<Grid container spacing={1}>
			{months.map((e, index) => (
							
							<Grid item xs="auto">
							
							<Box display="none">
							{
								id=index+1,
								ac=parseInt(active_month)
								
							}
							</Box>
							
							
							{id==ac?
							<Chip
							label={e} 
							clickable onClick={()=>{get_mentors_slot(index+1)}}
							 color="primary"
							/>
							:<Chip
							label={e} 
							clickable onClick={()=>{get_mentors_slot(index+1)}}
							 variant="outlined" />
							}
							</Grid>
							
			))}
			</Grid>
			</Box>
			
			
					
			{
			
				active_month_slots.length==0
				?
						<Box >
						<Typography variant="subtitle1" color="textSecondary">
						Slot not found
						</Typography>
						</Box>
				:
				<Carousel responsive={responsive} >
				{
			
					active_month_slots.map((e, index) => (
						<Box marginRight={isMobile?0:2} marginBottom={0.5}>
						<Card className={e['expire_slot']==1?classes.bg:classes.bgno}>
						<CardContent>
						<Box marginBottom={2} display="flex" justifyContent='flex-start' >
							<Box flexGrow={1}>
							<Typography variant="h6" >
							<Box fontWeight="700">
							{e['month_title']}
							</Box>
							</Typography>
							<Typography variant="caption" >
							<Box fontWeight="700" color="textPrimary" display="flex">
							Slot ID : {e['id']} <Box marginLeft={1} color={successColor} > {e['slot_status']==1?"(Booked)":""}</Box>
							</Box>
							</Typography>
							</Box>
							
							<Box>
							<IconButton className={classes.iconbtn}>
							<CloseIcon  className={classes.icon}
							onClick={()=>{delete_mentors_slots(e['id'])}} />
							</IconButton>
							</Box>
							
						</Box>
						
						<Box 
						flexGrow={1} 
						display="flex" 
						padding={0} 
						 alignItems="center" >
						<Box>
						<IconButton  color="secondary" className={classes.ic}>
						  <AccessTimeIcon  fontSize="small"  />
						</IconButton>
						</Box>
						
						<Box marginLeft={2}>
						<Typography variant="subtitle2" color="textSecondary">
						{e['time_title']}
						</Typography>
						</Box>
						</Box>
						
						<Box flexGrow={1}
						display="flex" 
						alignItems="center"
						padding={0} >
						<Box>
						<IconButton  color="secondary" className={classes.ic} >
						  <EventIcon fontSize="small" />
						</IconButton>
						</Box>
						
						
						<Box marginLeft={2}>
						<Typography variant="subtitle2" color="textSecondary">
						{e['date_title']}
						</Typography>
						</Box>
						</Box>
						
						{/*
												disabled={e['expire_slot']==1?true:false}
	*/}
						<Box marginTop={2}>
							<Button fullWidth 
							className={classes.shadow}
							color="primary"
							onClick={()=>{update_mentors_slots(e)}} 
							disabled={e['expire_slot']==1 
							|| e['slot_status']==1  ?true:false}
							>
							Reschedule
							</Button>
						</Box>
						
						
					</CardContent>
					</Card>
					</Box>
								
					))}
					</Carousel>	
			}
			</CardContent>
			</Card>
			
			)
		}
		
		const ShowSlots =()=>{
		return (
			
			<Box display={status=='professor'?"block":"none"}>
			<Grid container  spacing={2}>	
			
			<Grid item xs={12} >
			<RecentsSlot />
			</Grid>
			
			<Grid item xs={12} >
			<SlotsData />
			</Grid>
			
			</Grid>
			</Box>
		
		)
		}
		return (
		
		
		 <div>
		 {
				
				msg!=false
				? <AlertBox 
				show_status={true} 
				msg={msg} 
				msg_type={msg_type} />
				:''
		}
		<React.Fragment>
					<Box marginBottom={2} display='flex' justifyContent='flex-end' margin="0px 8px 8px 8px">
					<Button
					size="large"  
					color="primary" 
					variant="contained"
					startIcon={<AddCircleIcon />}
					onClick={toggleDrawer}
					>
					Add Slots
					</Button>
					</Box>
					
					 <ShowSlots />

					
					
		</React.Fragment>
          <RightDrawer setDrawerOpen={setDrawerOpen} open={drawerOpen} />
        </div>

	    );
	
  	}



