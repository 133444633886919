import { Box, Button, Card, CardContent, Grid, InputAdornment, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState,useContext } from 'react';
import cookie from 'react-cookies';
import { successColor } from "../Helper";
import ConnectionStatus from "./ConnectionSatus";
import { useStyles } from './styles/chatStyle';
import { UserContext } from '../courses/UserContext';

var sender='';
var sender_status='';
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	sender=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	sender_status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}
export default function WebSocketCall({receiver_info,parentCallback}) {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  
  const [chat_input, set_chat_input] = useState("");
  const [sent_msg, set_sent_msg] = useState(false);
  const [msg, set_msg] = useState(false);
  const [loading, set_loading] = useState(false);
  const [msg_type, set_msg_type] = useState("success");
 	const context_ref = useContext(UserContext);

	const classes = useStyles();
 	const [chats, set_chats] = useState(["test 1"]);

		
  const handleText = (e) => {
    const inputMessage = e.target.value;
    set_chat_input(inputMessage);
	
  };

    const handleChatSubmit = (e) => {
		e.preventDefault();	
						console.clear();

		//console.log("------------- Handle message request   --------------- ")

		/*if (!chat_input) {
		  return;
		}*/
		
		if(chat_input==""  || chat_input==null)
		{
			//console.log("message not found")		
			set_sent_msg('Enter the message')
			//setTimeout(() => {set_sent_msg('')}, 2000);
		}
		else
		{
			set_sent_msg('')
			/*let build_response={'added_on': "18h",
			'chat': "hiiiii",
			'msg_id':8,
			'receiver': "anshu@opengrowth.com",
			'sender': "anshurk1@gmail.com",
			'status': "found"}*/
			let build_response={'added_on': "0 minutes",
			'chat': chat_input,
			'msg_id':'',
			'chat_id':receiver_info['chat_id'],
			'receiver': receiver_info['email'] ,
			'sender': sender,
			'status': "found"}
			//console.log((context_ref.socket_info))
			//console.log((context_ref.socket_info).id)
			
			if ((context_ref.socket_info).id!=false) 
			{
				(context_ref.socket_info).emit("data", build_response);				
				//(context_ref.socket_info).emit("data", build_response);				
				//console.log("socket data emited")
				save_messages(message)
			}
			else
			{
				set_sent_msg('Connection failed!')
				//console.log("client socket is not connected. so messages can't be sent.")
				parentCallback();
			}
		}
    };
    const save_messages = (res) =>
    {	
   
		//socket.emit("data", chat_input);

		var chat_id=receiver_info['chat_id']
		//e.preventDefault();			
		set_loading(true);
	
			if(chat_input!==null && chat_input!=='' )
			{
				
                let payload={
				'chat':chat_input,
				'chat_id':chat_id,
				'sender':sender,
				'receiver':receiver_info['email']}
				//console.log(payload)
                set_sent_msg('Processing...')
				save(payload)
                cookie.save('chat_read',parseInt(cookie.load('chat_read'))+1, { path: '/' })				
			}
			
			set_chat_input('');
	} 

	const save=(param)=>{  
				axios({
				method: 'post',
				url: '/api/ac_sendmsg',
				data:param,
				headers: {
				'Accept': 'application/json',
				'Content-Type': 'application/json'}
				})
				.then((response) => {
					set_loading(false);
					set_sent_msg('Message sent.')
					set_msg(response.data['msg'])
					set_msg_type(response.data['type'])
					//console.log("is socket connected after sending message - "+(context_ref.socket_info).connected)
					if((context_ref.socket_info).connected==false)
					{
						//console.log("sending callback request")
						parentCallback();
					}
					//console.log("message added to database "+response.data)
					set_sent_msg(false)
					//parentCallback();
				});
    }
 
  return (
   	<React.Fragment>
	
	<ConnectionStatus 
	user={receiver_info} 
	parentCallback={()=>{console.log("in form callback")}}
	/>
	
	
    <div>

	  		<Card elevation={0} style={{borderRadius:'0px'}}>
		<CardContent style={{backgroundColor:"rgb(253 253 254)",padding:'0px'}}>
		
				<Grid container spacing={2}  >

		
		<Grid item xs={12} >
				{sent_msg==false?<Box marginTop={2}></Box>:
						<Typography variant="caption" style={{color:successColor}}>
						{sent_msg}
				</Typography>
				}
			
				<Box marginBottom={2}>
				<form role="form" onSubmit={handleChatSubmit} id="submitForm">
				
						<TextField
						style={{backgroundColor:'#fff'}}
						  id="outlined-end-adornment"
						  name="chat_input"
                           size="large"
						  InputProps={{
                            classes: { adornedEnd: classes.endAdornment },
							endAdornment: 
							<InputAdornment position="end" >
                             <Button 
							 variant="contained" 
							 size="large" 
							 className={classes.btn }  
							 type="submit"
							 onClick={handleChatSubmit}  
							 color="primary"
							 disable={loading}>
                             Send 
                              </Button>
							</InputAdornment>,
						  }}
                          
						placeholder="Send messages..."
						 variant="outlined"
						fullWidth
						onChange={(e)=>{set_chat_input(e.target.value)}}
						value={chat_input}
						/>
						
					</form>
					</Box>
					</Grid>
				</Grid>
			
		</CardContent>
		</Card>
    </div>
    </React.Fragment>
  );
}
