import React from "react";
import styled from "styled-components";
import ForumIcon from "@material-ui/icons/Forum";
import { useHistory } from "react-router-dom"; // Import useHistory from react-router-dom

const CourseCartWrapper = styled.div`
  background: #25387c;
  color: #fff;
  font-size: 30px;
  width: 50px;
  height: 50px;
  border-radius: 50%;
  position: fixed;
  right: 40px;
  bottom: 20px;
  display: flex;
  align-items: center;
  justify-content: center;

  & p {
    margin-bottom: 0px;
  }

  &:hover {
    cursor: pointer;
    background: #f9bb02;
  }
`;

const CourseCart = ({ e }) => {
  const history = useHistory(); // Initialize useHistory

  const getChatbox = (data) => {
    let path = '/discussion-forum/' + data['slug'];

    const community = {
      id: data['id'],
      slug: data['slug']
    };

    history.push({
      pathname: path,
      state: community
    });
  };

  return (
    <CourseCartWrapper
      onClick={() => {
        getChatbox(e);
      }}
    >
        <ForumIcon fontSize="medium" color="white" />
    </CourseCartWrapper>
  );
};

export default CourseCart;
