import React, { useState, useEffect } from 'react';
import {
  makeStyles,
  Box,
  Avatar,
  Grid,
  Card,
  CircularProgress,
  Typography,
  CardContent,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import axios from 'axios';
import { Group } from '@material-ui/icons';
import { MdLogout } from 'react-icons/md';
import { useHistory } from 'react-router-dom';
import { AvatarGroup } from '@material-ui/lab';

import {userProfile_path, api, user_status_id, user_id, community_path } from '../Helper.js';
import Hosts from './Hosts';
import MembersCard from './MembersCard';
import AlertBox from '../AlertBox';
import ChatFloatButton from './groupchat/ChatFloatButton';

let user = user_status_id;
const member_useStyles = makeStyles(() => ({
  small: {
    minHeight: '350px',
    width: '100%',
    borderRadius: '12px',
    'object-fit': 'cover',
    minHeight: '350px',
  },
   smallme:{
		height:"45px !important" , 
		width:"45px !important" , 
		border: "2px solid #ebebeb",
    backgroundColor: "#ebebeb" ,
    borderRadius: '50%',
    'object-fit': 'cover',
	marginRight:"8px"
  },
  card: { height: '100%' },
  lh: { lineHeight: '1.5rem' }
}));

let name = '';
let email = '';

const login_token = localStorage.getItem('login_token');
if (!['admin', 'webinar', null].includes(login_token)) {
  const ac_login_user = JSON.parse(localStorage.getItem('ac_login_user'));
  email = ac_login_user.email;
  name = ac_login_user.fname;
}

const CommunityDetails = () => {
  const [community, setCommunity] = useState(null);
  const [loading, setLoading] = useState(false);
  const [isMember, setIsMember] = useState(false);
  const [msg, setMsg] = useState(false);
  const [msgType, setMsgType] = useState('success');
  const [notFoundDialogOpen, setNotFoundDialogOpen] = useState(false);
  const pathSegments = window.location.pathname.split('/');
  const slug = pathSegments[pathSegments.length - 1];
  const classes = member_useStyles();
  const history=useHistory();



  const getCommunity = async () => {
    let payload = {
      slug,
    };
    try {
      const response = await axios.post(api + '/get-community', payload);
      let res = response.data;
      console.log(res);
      if (res.length > 0) {
        let communityData = res[0];
        setCommunity(communityData);
        await getMemberStatus(communityData['id']);
      } else {
        setNotFoundDialogOpen(true);
      }
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false); // Set loading state to false when done
    }
  };

  useEffect(() => {
    getCommunity();
  }, []);

  
  const getMemberStatus = async (id) => {
  let payload = {
    community_id: id,
    user_id,
    user,
  };

  try {
    const response = await axios.post(api + '/get_community_members_status', payload);
    if (response.data === 'joined') {
      setIsMember(true);
    }
  } catch (error) {
    console.error(error);
  }
};


  const handleJoinLeave = () => {
    setLoading(true);
    const reqStatus = isMember ? 'leave' : community.access=='private'?'request': 'joined';
    const payload = {
      community_id: community.id,
      status: reqStatus,
      user: user,
      user_id: user_id,
      email,
      name,
      title: community.title,
	  creater_id:community.creater_id,
	  host_name:community.host_name,
	  host_email:community.host_email,
	}
    axios
      .post(api + '/manage_community_users_actions', payload)
      .then((response) => {
        const res = response.data;
        setMsg(res.msg);
       setMsgType(res.type);
        if (reqStatus === 'joined' && res.type === 'success') {
          setIsMember(true);
        }
        if (reqStatus === 'leave' && res.type === 'success') {
          setIsMember(false);
        }
        setTimeout(() => {
          //setMsg(false);
        }, 3000);
        setLoading(false);
      })
      .catch((error) => console.error(error));
  };

const CommunityCard = () => {
  const getDescriptionAsHTML = () => {
    return {__html: community.description};
  };


  return (
    <Card variant="outlined" className={classes.card}>
      <CardContent>
        <Box fontSize={24} color="#25387c" fontWeight={700}>
          {community.title}
        </Box>

        <Box marginBottom={2} marginTop={2}>
          <Avatar src={community_path + community.banner} alt={community.title} className={classes.small} />
        </Box>

        <Box display="flex" justifyContent="end" marginBottom={2} marginTop={2}>
          <Button
            variant="contained"
            color={isMember ? 'secondary' : 'primary'}
            disabled={loading}
            onClick={handleJoinLeave}
            startIcon={loading ? <CircularProgress color="inherit" size={20} /> : isMember ? <MdLogout /> : <Group />}
          >
		    {
			  loading
				? 'Loading...'
				: isMember
				  ? 'Leave Community'
				  : community.access === 'private'
					? 'Request to Join Community'
					: 'Join Community'
			}
          </Button>
        </Box>

        <Box>
          <Typography className={classes.lh} variant="subtitle1" color="textSecondary" align="left">
            <div dangerouslySetInnerHTML={getDescriptionAsHTML()} />
          </Typography>
        </Box>

		<Box display="flex">
       <Box>  
	   <Hosts community={community} />
		</Box>
		{/* community && community.total_members>0 
		&& 
		(<Box marginRight={2}>
		<Box color='#253B7C' marginTop={2} marginBottom={2} fontWeight={700} fontSize="16px">
		Members
		</Box>  
		<MembersSmallCard
		  members={community['members'] ? JSON.parse(community['members']) : []}
		  total_members={community.total_members}
		/>
		</Box>
		)*/}
		</Box>
		
      </CardContent>
    </Card>
  );
};

const MembersSmallCard = ({ members, total_members }) => {

   const avatarCards = members.length > 0 && members.map((member, index) => (
		member.img ? (
		  <Avatar
		    className={classes.smallme} 
			key={member.id}
			alt={member.name}
			src={userProfile_path + member.img}
		  />
		) : null
	  ));

  let remainingMembersCount = total_members - members.length;
  remainingMembersCount = remainingMembersCount > 10 ? 10 : remainingMembersCount;

  return (
    <Box display="flex" alignItems="center" marginBottom={2} marginTop={2}>
      {avatarCards}
      <Typography variant="subtitle1" color="textSecondary">
        {total_members > 0 
          ? remainingMembersCount ==0 
            ? ` Members` 
            : ` Members and ${remainingMembersCount} more` 
          : ''
        }
      </Typography>
    </Box>
  );
};


  const handleNotFoundDialogClose = () => {
    setNotFoundDialogOpen(false);
     history.goBack();
  };
  const parentAlertCallback=()=>{
	setMsg(false)
} 
console.log(community)
console.log(community ?"have data":" no data")
  return (
    <Box>
      {msg !== false ? (
        <AlertBox
		parentAlertCallback={parentAlertCallback}
		msg={msg} 
		show_status={msg} msg_type={msgType === 'success' ? 'success' : 'warning'} />
      ) : (
        ''
      )}
	  
	  {isMember && (<ChatFloatButton e={community} />)}
	  
      <Grid container spacing={2}>
        <Grid item xs={12} md={12}>
			{ community && ( <CommunityCard /> ) }
        </Grid>

        <Grid item xs={12} md={12}>
		{ community && ( <MembersCard community={community} isMember={isMember} /> )}
        </Grid>
      </Grid>

      <Dialog open={notFoundDialogOpen} onClose={handleNotFoundDialogClose}>
        <DialogContent>
		
		<Box padding={2}>
		<Box fontSize="20px"  display="flex" justifyContent="center"  fontWeight="bold" marginBottom={2} >
		Community Not Found
		</Box>
		
          <Typography variant="subtitle1" color="textSecondary" align="left">
            The requested community was not found.
          </Typography>
		  
		  <Box display="flex" justifyContent="center"  marginTop={2} >
		  <Button 
		  variant="contained" 
		  color="primary" 
		  onClick={handleNotFoundDialogClose} 
		  >
            Go Back
          </Button>
          </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

export default CommunityDetails;
