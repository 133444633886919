import {
	Box,Button,
	Grid, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import {speakers_path} from '../../Helper';
import { isMobile } from 'react-device-detect';

const useStyles = theme => ({
	
	border: {
		border:'2px solid #25387cf0',
		borderRadius:'25px',
		backgroundColor:'#9e9e9e0a',
		padding:"16px"
	  },
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"}
	
});
	

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		//let path="https://opengrowth.university/University/assets/images/";
		let subtitle='Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.';
		let path="/assets/images/homepage/images/offer/"

		let data=[
		{'img':speakers_path+'niraj.png',
		'title':"E-Book Name 1",
		'subtitle':subtitle,
		'href':""},	
		
		{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_brittany.png',
		'title':"E-Book Name 4",
		'subtitle':subtitle,
		'href':""}
		]
	
		
		const classes = this.props.classes;	

		return (

		  <Box marginBottom={8} marginTop={1}>
		  <Grid container 
		  spacing={2}
		  direction="row"
		  alignItems="center"
		  justifyContent="center">

	     	<Grid item xs={12}>
			<Box
			  display="flex"
			  justifyContent="center"
			  alignItems="center"
			  margin={3} 
			  marginTop={5}
			>
			<Typography variant="h4" >
			<Box fontWeight="600" fontSize="32px" >E Books
			<Box className={classes.outline}></Box>
			</Box>
			</Typography>
			</Box>
			</Grid>

			{
				data.map(({ img,href, title,subtitle})=> {
				return (	
					<Grid item sm={12} lg={6} container >
															
					<Grid item xs={12} container 
					className={classes.border}>
					
					<Grid item sm={6} lg={4}>
					<Box display="flex" 
					  justifyContent="center"
					  alignItems="center" 
					   height="100%" width="100%">
					  <img src={img} height="100%"  width="100%" alt="" style={{borderRadius:'12px',backgroundSize:'cover'}} />
					</Box>
					</Grid>
					
					<Grid item sm={6} lg={8}>
					<Box  display="block" textAlign="left" justifyContent="center" marginLeft={2}  marginTop={isMobile==true?2:0}  >
				
					<Box>
					<Typography  variant="h6" color="textPrimary" > 
					 <Box fontWeight="fontWeightBold" lineHeight={1.5} 
					 >{title}</Box>
					 </Typography> 
					 </Box>
					
					<Box marginBottom={3}>
					 <Typography  variant="subtitle1" color="textSecondary"> 
					 <Box marginTop={1} lineHeight={1.5} >
					 {subtitle}
					 </Box>
					 </Typography> 
					 </Box>
					
					<Box>
					<Button variant="contained" 
					size="medium" 
					color="primary" >
					Download
					</Button>
					</Box>
					
					</Box>				
					</Grid>  
					</Grid>  
					</Grid>  
				)})	
			}
			
		</Grid>
		
					
	     	<Grid item xs={12}>
			<Box
			  display="flex"
			  justifyContent="center"
			  alignItems="center"
			  margin={3} 
			  marginTop={5}
			>
			<Button variant="contained" size="large"   color="primary" >
			View All
			</Button>
			</Box>
			</Grid>
			
		</Box>
		);
  	}
}
  
export default withStyles(useStyles)(Innovative_Platform)
