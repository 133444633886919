import { Avatar, Box, Typography,makeStyles } from '@material-ui/core';
import AvatarGroup from '@material-ui/lab/AvatarGroup';
import React,{useEffect} from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { getString, userProfile_path , generateProfileUrl } from "../Helper";
import { useStyles } from "../useStyles";


const customUseStyles = makeStyles((theme) => ({
	auto:{ margin: "auto" , marginRight: "16px" },
	small:{
		height:"62px !important" , 
		width:"62px !important"  , 
		border: "2px solid #ebebeb",
    backgroundColor: "#ebebeb" }
}))
export default function HostsCard(props) {
	const customclasses = customUseStyles();
	const classes = useStyles();
	const history = useHistory();
	const location = useLocation();

 /* useEffect(() => {
	  console.log(props);
	}, [props]);
*/
	const getInstructorProfile = (id, mail, name, userStatus) => {
		let mail_url=generateProfileUrl(mail)
		name=name.toLowerCase()
		let profile_data= {
		profile_mail: mail,
		profile_type: userStatus,
		profile_id: id,
		}
		console.log(profile_data)
	//	alert(1)
		history.push({
		pathname: '/profile/' +mail_url ,
		state:profile_data,
		});
	};

	const AvatarCard = ({ idIs, mailIs, nameIs, imgIs,userIs }) => (
		<Box marginRight={1} marginLeft={1} display="block">
		<Box>
		<Avatar
		onClick={() => getInstructorProfile(idIs, mailIs, nameIs, userIs)}
		src={userProfile_path + imgIs}
		className={customclasses.small+" "+customclasses.auto}
		/>
		</Box>
		<Box textAlign="center" marginTop={2}>
		<Typography variant="subtitle2" color="textSecondary">
		{nameIs.replace('-', ' ')}
		</Typography>
		</Box>
		</Box>
	);

	const { hosts, host_email, host_name, host_image,host_user } = location.state === undefined ? props.community ?props.community:null : location.state;

	const idIsList = hosts !== null ? getString(hosts).split(',') : [];
	const mailIsList = host_email !== null ? getString(host_email).split(',') : [];
	const nameIsList = host_name !== null ? getString(host_name).split(',') : [];
	const imgIsList = host_image !== null ? getString(host_image).split(',') : [];
	const userList = host_user !== null ? getString(host_user).split(',') : [];

	const avatarCards = imgIsList
	.map((d, index) => ({
	idIs: idIsList[index]?.replaceAll('"', ''),
	mailIs: mailIsList[index]?.replaceAll('"', ''),
	nameIs: nameIsList[index]?.replaceAll('"', ''),
	imgIs: imgIsList[index]?.replaceAll('"', ''),
	userIs: userList[index]?.replaceAll('"', ''),
	}))
	.filter(card => card.imgIs.length > 3)
	.map((card, index) => (
	<AvatarCard
		 key={index}
		 idIs={card.idIs}
		 mailIs={card.mailIs}
		 nameIs={card.nameIs}
		 imgIs={card.imgIs}
		 userIs={card.userIs}
	   />
	));
	return (
	  location.state !== undefined && (
		<>
		  <Box color="#253B7C" marginTop={2} marginBottom={2} fontWeight={700} fontSize="16px">
			Admin
		  </Box>
		  <AvatarGroup max={15}>
			{avatarCards}
		  </AvatarGroup>
		</>
	  )
	);

}