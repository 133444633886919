import { Avatar, Box, Card,CardContent,CardActionArea,Container, Grid, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {speakers_path} from '../../Helper';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
    paritialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 40
  }
};


const styles = theme => ({
  border: {
	border:"8px solid #25387C",
	padding:"20px",
	margin:"56px 0px 56px",
	borderRadius:'25px'
  }, 
  text: {
	color:"#efe5e5",
	fontFamily: ['Public Sans','sans-serif',].join(',')
  },
  	large: {
		width:"130px",
		height:"130px",
		border: '3px solid #d8d8d8 !important'
	  },
	center:{
	top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'height .4s ease-in-out',
    padding: '0px 48px',
    position: 'absolute'
  },

	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

let subtitle='Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.';
let path="/assets/images/homepage/images/offer/"

let data=[
		{'img':speakers_path+'niraj.png',
		'title':"E-Book Name 1",
		'subtitle':'Director, Community Operations',
		'href':""},	
		{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':'Senior software Developer',
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':'Co-founder, Director - OpenGrowth University',
		'href':""},	
		{'img':speakers_path+'speaker_brittany.png',
		'title':"E-Book Name 4",
		'subtitle':'Senior software Developer',
		'href':""}
		]
	

function Footer(props) {
  const { classes } = props;
    const history = useHistory();

	const ProfileCard=({img,title,subtitle,href,index})=>{
		return (
		<Box margin={3}>
			<Card elevation={3}>
					<CardActionArea>
					<CardContent>
					<Box  display="block" textAlign="center" justifyContent="center" >
					
					<Box marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.large} 
					src={img} >
					</Avatar>
					</Box>
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box fontWeight="fontWeightBold" marginaTop={2}>
					 {title}
					 </Box>
					 </Typography> 
					
					 <Typography  variant="subtitle1" color="textSecondary" style={{minHeight:"56px"}}> 
					 {subtitle}
					 </Typography> 
					
					</Box>
					</CardContent>
					</CardActionArea>
			</Card>
			</Box>
		)
	}
  return (
		<Box marginBottom={3}>

	<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={0} 
					
					>
		
		<Grid item  xs={12} sm={8} >
			
			
			<Typography  >
					  <Box lineHeight={1.25} 
					  fontWeight="600"
					  fontSize="32px" 
					  marginTop={0}
					  textAlign="center" padding={2}>	
							Guest Speakers
							<Box className={classes.outline}></Box>
					  </Box>
			</Typography>			
		</Grid>
	
	
	  <Grid item  xs={10}>
	  <Container className={classes.pad}>
		<Carousel responsive={responsive}  >
			{
							
						data.map((e, index) => (
							
						<ProfileCard  
						img={e['img']}
						title={e['title']}
						subtitle={e['subtitle']}
						href={e['href']}
						index={index}
						/>
						
					))								
			}
			</Carousel>
			</Container>
	   </Grid>
	   </Grid>
	   </Box>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);