export const google_client_id="996189458260-5lopjeqjq6ub5hgm8hnuuru0o7netaf2.apps.googleusercontent.com"
export const google_client_sceret="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"
//created new - export const google_api_key="AIzaSyA1VpYcgLP4x3AveD5R0KkgXN6X8CoVr0c"
export const google_api_key="AIzaSyCply042KauQ3WG2yH9_yghW3DXB3Eh5Qg"
export const facebook_app_id="982126488987185"

let url=window.location.href

export let domain = (new URL(url)).hostname; 
////console.log(domain);
let redirect_uri_val='http://localhost:3000/linkedin'
if (domain!='localhost')
{
   redirect_uri_val=window.location.origin+'/linkedin'	
}
export let linkedin_credential={'client_id':'86cdibd3tcr29t',
'client_secret':'Z2aZ5iD16jlJnbIz',
'scope':'r_liteprofile r_emailaddress',
'grant_type':'authorization_code',
'redirect_uri': redirect_uri_val,
'auth_url':'https://www.linkedin.com/oauth/v2/authorization?',
'token_url':'https://www.linkedin.com/oauth/v2/accessToken?',
'client_profile':'https://api.linkedin.com/v2/me?',
'redirect_uri_param':'http%3A%2F%2Flocalhost%3A3000%2Flinkedin%2F%0A'
}
export let recapchta_credential={
'site_key':'6Lez5QgnAAAAAInKZCKrIAERVbhXFNVQF8_eAmdP',
'secret_key':'6Lez5QgnAAAAAL84caZVbGRDqg71cNPMA01FPRG2'
}