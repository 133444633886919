
import {
	Avatar, Box, Button, Card, CardContent, Chip, CircularProgress,
	Dialog, DialogActions, DialogContent,
	Grid, IconButton,makeStyles,
	Typography
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import React, { Component } from "react";
import { isMobile } from 'react-device-detect';
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
import ShowMoreText from "react-show-more-text";
//import { LocalizationProvider ,AdapterDateFns  } from "@material-ui/lab/LocalizationProvider";
import "react-sweet-progress/lib/style.css";
import AlertBox from "../../AlertBox";
import { deleteLocal, getCacheDetails } from "../../caching";
import { api, get_name_slug,generateProfileUrl, userProfile_path,images } from "../../Helper";
import { Action } from '../../Skeleton';
import { useStyles } from "../../useStyles";
import ProfilePopup from '../dashboard/ProfilePopup';
import ConnectButton from './buttons/ConnectButton';
import './css/mentors.css';



let status=null;
let student_id=null;

let token=localStorage.getItem('login_token')
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}
if(token=="admin")
{
	student_id="admin"
}

let active_mentor_id=null
const customUsestyles = makeStyles((theme) => ({
  card: {
    position: "relative",
    width: '100%',
    height: 'auto',
  },
  img: {
    width: '100%',
    height: '100px',
  },
  imgF: {
    width: '100%',
    height: '250px',
  },
  box: {
    height: 'auto',
    paddingRight: "12px",
  },
  small: {
    width: '64px',
    height: '64px',
    "&:hover": {
      transform: 'scale(1.02)',
      boxShadow: '0px 5px 3px -2px rgb(0 0 0 / 20%), 0px 5px 4px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',
    },
  },
  avatar1: {
    paddingRight: '1rem',
  },
  iconsbg: {
    backgroundColor: '#0000001f',
    padding: '8px',
    marginBottom: '8px',
  },
  name: {
    cursor: "pointer",
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 1,
    WebkitBoxOrient: "vertical",
    textAlign: 'left',
  },
  mentors_about: {
    overflow: "hidden",
    display: "-webkit-box",
    WebkitLineClamp: 2,
    WebkitBoxOrient: "vertical",
    textAlign: 'left',
  },
  expert: {
    color: '#25387c',
    border: '1px solid rgb(235, 235, 235)',
    fontWeight: 'bold',
    backgroundColor: '#c5d5e5',
  },
  chip: {
    color: '#25387c',
    border: '1px solid #25387c',
    fontWeight: 'bold',
    backgroundColor: 'white',
  },
  gridf: {
    height: "fit-content",
  },
  gridh: {
    height: "100%",
  },
  fw:{ fontWeight:"600" },
  pointer:{cursor:'pointer'},
  fontsize:{fontSize:"0.85rem"},
  borderR:{borderRight:"2px solid #F2F2F2"},
  avatar:{'object-fit':'cover',cursor:'pointer'},
  
  smroot: {
    flexDirection: 'row',
	 transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
	 marginBottom:'2px',
      transform: 'translateY(2px)',
    },
  },
    smname: {
    fontWeight: 700,
    cursor: 'pointer',
    color: '#25387c',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  smtags: {
    textAlign: 'left',
	overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
   smimageBox: {
    width: '100%',
    height: '85px',
//backgroundColor:"#f7f7f7"
  },
  smavatar: {
    width: '85px',
    height: '85px',
	borderRadius:0
  },
  
  
  
}));

class MentorCard extends Component {

    constructor(props) {
    super(props);
	this.get_name_slug = get_name_slug.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	course_list:[],mentors_list:[],
	progress_com:0,progress_left:9,loading:true,hasMore:true,
	start:0,end:7,
	msg:false,msg_type:"success",
	alert_msg:false,
	profile_popop:false,
	apply_popop:false,start_time:"",end_time:'',
	slots:null,date:null,
	search:'',search_with:false,
	btn_loading:false,
	hover:false,active_card:null,
	profile_details:[],mentor_id:null,
	
	mentorship_popop:false,
	dashboard_mentor_arr:[]
	}
	this.myDiv = React.createRef()
    }
	
		
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(student_id+"_all_mentors_")
		    let keys_2=this.deleteLocal(student_id+"_mentors_")
		    let keys_4=this.deleteLocal(student_id+"_my_mentors_")
			let keys_3=this.deleteLocal(student_id+"_upcoming_mentorship_")		
			let keys_5=this.deleteLocal(student_id+"_all_mentors_0_to_")		
				let keys_6="user_"+student_id+"_mentor_slot_"
				let keys_7="mentor"
				let keys=[keys_1,keys_2,keys_3,keys_4,keys_5,keys_6,keys_7]
				return keys
	}
	
	get_full_profile = (mentor) => {	
	let name=this.get_name_slug(mentor['name']);
	let mail_url=generateProfileUrl(mentor['email']);
	this.props.history.push({
	  pathname: '/profile/'+mail_url,
	  state: {
			profile_mail:mentor['email'],
			profile_type:'professor',
			profile_id:mentor['professor_id'],
			course_author_id:mentor['professor_id']
	  },
	})
	}
	
	
	/*
	get_full_profile = (mentor) => {	
	let x=status=='professor' && (window.location.pathname)!="/mentors-and-experts"
	let name=this.get_name_slug(mentor['name']);
	this.props.history.push({
	  pathname: '/profile/'+name,
	  state: {
			profile_mail:mentor['email'],
			profile_type:x?'student':'professor',
			profile_id:mentor['professor_id'],
			course_author_id:mentor['professor_id']
	  },
	})
	}*/
	
	setCustomState=(state,val)=>{
		this.setState({[state]:val},()=>{console.log(this.state[state])})
	}
	
	
	componentDidMount()
	{
		      //  //console.log(this.mycard.current.offsetWidth)

	}
	
	componentDidUpdate()
	{
		      //  //console.log(this.mycard.current.offsetWidth)
	}
	

	getInstructorProfile = (mentor) => {
		//alert(mentor['id'])
		this.setCustomState("profile_popop",true)
		this.setCustomState("profile_details",mentor)

	};
	
	getMentorsReqProfile = (mentor) => {
		//alert(mentor['id'])
		this.setCustomState("mentorship_popop",true)
		this.setCustomState("profile_details",mentor)

	};
	
	
	enable_mentorship = (user,val) => {
		let key=(this.deleteKeys()).toString()
		this.setCustomState("btn_loading",true)
		active_mentor_id=user['id']
		let pay={
			'mentor_id':user['id'],
			'mentor_name':user['name'],
			'mentor_email':user['email'],
			'action':'admin',
			'val':val,
			'key':key
		}
		this.setCustomState("mentors_list",[])	

		////console.log(pay)
		axios.post(api+'/enable_mentorship',
		pay)
		.then((response) => {
				this.setCustomState("btn_loading",false)
				this.setCustomState("start",0)
				this.setCustomState("alert_msg",response.data['msg'])
				setTimeout(() => {
					//alert(this.state.alert_msg)
					this.setCustomState("alert_msg",false)
					if(status=='professor')
					{
						this.props.history.push("/dashboard")
					}
					if(student_id=="admin")
					{
						this.props.history.push("/schedule-webinar")
					}
					this.props.parentCacheCallback();	
					
				}, 5000);
				this.forceUpdate();
				this.setCustomState("mentorship_popop",false)
			
		});
		
		
				
	};
		
	
	handleClose = () => {
		this.setCustomState("profile_popop",false)
		this.setCustomState("apply_popop",false)
		this.setCustomState("mentorship_popop",false)
	}
	
	handleCallback = (val) => {
		
		if(val.startsWith("search"))
		{
			//let nm=val.split(",")
			this.props.parentCacheCallback(val);
			//alert(nm[1])
		}
		if(val=="connect")
		{
			this.setCustomState("start",0);
			this.props.parentCacheCallback();	
		}
		if(val=="close")
		{
			this.handleClose()
		}
	}
	
	
	remove_key = () => {
		this.setCustomState("search_with",false);
		this.setCustomState("search",'');
		this.setCustomState("hasMore",true);
		this.setCustomState("start",0);
		this.getCachePlay(0,this.state.end,false)
	}
	
	handleHover = (id) => {
		
		
			let i=null
			this.setState({hover:!this.state.hover},
			()=>{console.log(this.state.hover)});
			if(this.state.hover==true)
			{
				i=id;
			}
			this.setState({active_card:i},()=>{console.log(this.state.active_card)});
	}	
	
	search_mentors = (tags,val) => {
		
		let string="search,"+tags
		if(window.location.pathname=="/mentors-and-experts")
		{
			this.props.parentCacheCallback(string)
		}
		else
		{
			this.props.history.replace({
			pathname: "/mentors-and-experts",
			  state: {
					industry:tags
			  } })
		}

	}
    render()
	{
	
		const classes = this.props.classes;
		const customclasses = this.props.customclasses;
		let i=0;

		const SmallRenderCard = ({ user }) => {
		
		  return (
			<Card className={customclasses.smroot}>
			  <Box 
			  onClick={()=>this.get_full_profile(user)}
			  className={customclasses.smimageBox}
			  display="flex" 
			  alignItem="center">
				<Avatar
				  src={user.img ? userProfile_path + user.img : ''}
				  alt={user.name}
				  className={customclasses.smavatar}
				  onClick={()=>this.get_full_profile(user)}
				/>
			  <Box 
			  width="100%"
			  height='85px'
			  display="flex" 
			  justifyContent="center" 
			  textAlign="left" 
			  alignItems="center">
				<Box padding="0px 16px">
				<Box 
				onClick={()=>this.get_full_profile(user)}>
				<Typography
				  variant="subtitle1"
				  component="subtitle1"
				  className={customclasses.smname}
				  align="center"
				>
				  {user.name}
				</Typography>
				</Box>
				<Typography variant="subtitle2" color="textSecondary" className={customclasses.smtags}>
				  {user.category ? (user.category).split(",").map(item => item.trim()).join(", "):''}
				</Typography>
			  </Box>
			  </Box>
			  </Box>
			</Card>
			)

		}
			
	
		const ChipCard= ({user}) => {	
		let category=[]
		if(![undefined].includes(user['category']))
		{
			category=(user['category']).split(",")	
		}
		let t=["/dashboard","/dashboard-mentorship"].includes(window.location.pathname) || isMobile
		let k="none"
		let view_page=window.location.pathname=="/recent-mentors"

		return (
		 <React.Fragment>
		  {
			category.length>0  && category.map((e, index) => (
			 <Grid item xs="auto">
			
			<Box display="none">
			 {k=t?index==0?"flex":"none":"flex"}
			 </Box>
			 
			 {
				k=="flex" && !view_page && index<2
				?<Box display={"flex"} marginRight={1} >
					<Chip onClick={() => { this.search_mentors(e,"tags")}}
					key={e} 
					clickable
					size="medium"
					 className={e=="Expert"? customclasses.expert: customclasses.chip}
					label={
					<Typography variant="caption" color="primary" ><Box fontWeight="600">	{e} </Box> </Typography>
					}
					/>	
				 </Box>
			 :''}
			 </Grid>
			 ))
		  }
				 
		</React.Fragment>
		)}
		
		const Loader= ({uid}) => {		
		return (
		<Box display={active_mentor_id==uid?"flex":"none"}
		marginLeft={2} marginRight={2} 
		justifyContent="center">
		 {this.state.btn_loading && <CircularProgress size={18} />}
		</Box>
		)}
		
		const AdminButtons= ({user}) => {		
		return (
				
				<Box 
				display={token=="admin"?"block":'none'}>
				<Box display="flex" flexDirection= 'row-reverse' >	
				{
					user['has_global_request']==1?
					
					<Button variant="contained" size="small"   color="secondary" 
					onClick={()=>this.getMentorsReqProfile(user)}>
						Manage Request
					</Button>
					
				:
				
						user['status']==1?
						
							<Button variant="contained" size="small"   color="primary" 
								onClick={()=>this.enable_mentorship(user,"deactivate")}>
								Deactivate Profile
							</Button>
						:
												
							<Button variant="contained" size="small"   color="secondary" 
								onClick={()=>this.enable_mentorship(user,"activate")}>
								Resume
							</Button>
				
				}
							  
				</Box>	
				</Box>	

		)}
		const MenteeButtons= ({user}) => {		
		return (
				<Box 
				display={token!="admin"?"flex":'none'} flexDirection= 'row-reverse' >				
				<Box display="none">
					<Button variant="outlined" size="small"   color="primary" 
					onClick={()=>this.get_full_profile(user)}>
					Profile
					</Button>
				</Box>
				
				
				<Box marginLeft={2} 
				display={status=='student' && ["/mentors-and-experts","/mentors-followed"].includes(window.location.pathname) || this.props.action=="tab"?"block":"none"}>
				{/*<Button 
					variant="contained" 
					size="small"
					color={"primary"}
					disabled={user['slot']==0?true:false}
					onClick={()=>this.apply_mentorship(user['id'],user['name'],user['email'],user['mentor_req'])}>
					Connect
				</Button>*/}
				<Box marginTop={this.props.action=="tab" ?"-16px":"0px"}>
				<ConnectButton handleCallback={this.handleCallback}  e={user} />
				</Box>		
				</Box>		
				
				<Box display="none">
					<Button variant="outlined" size="small"   color="primary" 
					onClick={()=>this.follow_mentor(user)}>
					Follow
					</Button>
				</Box>
								
				</Box>	
		)}

		
		const RenderCard = ({user}) => {
			let view_page=["/recent-mentors","/mentors-followed"].includes(window.location.pathname) && status!='student' || this.props.action=="tags" 
			let t=["/dashboard","/dashboard-mentorship"].includes(window.location.pathname) && this.props.action!="tab" ?false:true;
			let sty={
			dash:{height:'137px'},inner:{height:view_page?'171px':this.props.action=="tab"?"212px":'238px'}
			}

		return (
		<Card 
		elevation={1}
		className={classes.elevate} 
		 ref={this.myDiv}>
		<Grid container spacing={0}
		 style={isMobile?{}:t?sty.inner:sty.dash}>
			
			<Grid item xs={12} sm={4}>
			<img   
			height={t?view_page?'175px':'238px':'137px'}
			width='100%'
			 className={customclasses.avatar}
			src={userProfile_path+user['img']} 
			onError={(e) => { e.target.onerror = null; e.target.src = images['user-blank']; }}
			onClick={()=>this.get_full_profile(user)}/>
			</Grid>
	
		<Grid item xs={12} sm={8}>	
		<Card  className={customclasses.gridh} elevation={0}>
		<CardContent  className={customclasses.gridh}>
		  <Grid item xs={12} 
		  container
		  direction="row"
		  justifyContent="space-between"  className={this.props.action=="tab"? customclasses.gridf:customclasses.gridh}>
		 
		<Grid  item xs={12} >
		<Box>
		{
			user['has_global_request']==1
			?
				<Box fontSize="12px" fontWeight="600"
				color="green">
						Mentorship activation request is in procress!
				</Box>
			:''	
		}
		
				  <Box display={t && !view_page?"flex":'none'} justifyContent="flex-end" padding={1}  paddingBottom={0} >
					<Typography variant="caption" color="textPrimary"  
					className={customclasses.fw}>
					{user['slot']} slot(s) available
					</Typography >
					</Box>
		</Box>
		</Grid>
		
		<Grid  item xs={12}   className={customclasses.gridf}>
		<Typography color="primary" variant="subtitle1">
				  <Box fontWeight="600" 
				   className={customclasses.pointer}
				  lineHeight={1.25} onClick={()=>this.get_full_profile(user)}> 
					{user['name']} 
				  </Box>
				  </Typography>
				  <Typography variant="subtitle2" color="text.secondary">
				  <Box lineHeight={1.25} >{user['experience']}</Box>
				  </Typography>
				  
				    <Box lineHeight={1}
					marginBottom={1.5} 
					marginTop={1.5} 
					height={t?"60px":'30px'}>

					{t?<Typography variant="subtitle2" color="textSecondary">
					<Box lineHeight={1.5}  className={ customclasses.mentors_about}>
					{user['about']}
					</Box>
					<Box onClick={()=>{this.getInstructorProfile(user)}}>
					<Action title={"Know more"} />
					</Box>
					</Typography>:''}
					</Box>
		</Grid>
		
		
		<Grid  item xs={12} container  alignItems="center"  >
				{this.props.action !== "tags" &&
				token!="admin" && (
				  <ChipCard user={user} />
				)}

				
				<Grid item xs={12}   >
				{!(view_page && this.props.action!="tab") && (
				<Box display={"flex"}  flexDirection= 'row' justifyContent="right" marginTop={1} >
				<Loader uid={user['id']} />
				<MenteeButtons user={user} />
				<AdminButtons user={user} />
				</Box>)}
				</Grid>
				
				</Grid>

		
		
		
		</Grid>
		</CardContent>
		</Card>
		</Grid>
		</Grid>
		</Card>
		)}
	
 const ProfileDialog = () =>
	{ 
			
			return(
			
			<>
			{
				this.state.profile_popop
				?<ProfilePopup profile_details={this.state.profile_details} 
				handleCallback={this.handleCallback} />
				:''
			}
			</>
			
			)
	}					
			
		
    const MentorshipDialog = () =>
	{ 
			
			return(
			<Dialog
			open={this.state.mentorship_popop}
			onClose={this.handleClose} 
			fullWidth  className={ customclasses.border}
			maxWidth="md">
			<DialogActions >
				<IconButton 
				onClick={this.handleClose}
				 className={ customclasses.iconsbg}>
							<CloseIcon color="grey"  
							 className={customclasses.fontsize} />
				</IconButton>
			</DialogActions>
			<DialogContent >
			
			<Box display="flex"  marginBottom={2} >
			
			<Box display="block" paddingRight={3}  className={customclasses.borderR}>
			 <Avatar className={classes.large} src={userProfile_path+this.state.profile_details['img']} onClick={()=>{this.get_full_profile(this.state.profile_details)}}>
			 </Avatar>
			
			<Box display="flex" justifyContent="center">
			<Typography variant="h6" color="primary" >
				<Box lineHeight={1.5} marginTop={2}
				fontWeight="600" 
				textAlign="center"> 
				   {this.state.profile_details['name']}
				</Box>
			</Typography>
			</Box>
			</Box>
			
			

			
			<Box 
			display="block" 
			paddingLeft={3} 
			width="100%"
			height="100%"
			justifyContent="space-between"
			>
			
			<Typography variant="h6" color="primary" >
				<Box lineHeight={1.5} fontWeight="600"
				marginBottom={2}> 
				   Hi, I would like to be a expert at OpenGrowth.
				</Box>
			</Typography>
			
			<Box minHeight="90px">
			<Typography variant="subtitle1" color="textSecondary" component="p">
				<Box lineHeight={1.5} > 
				{this.state.profile_details['request_message']}
				</Box>
			</Typography>
			</Box>
			
						
					<Box display="flex" flexDirection= 'row-reverse' >			
						
			
					<Box margin={1}>
						<Button variant="contained" size="medium"   color="secondary" 
						onClick={()=>this.enable_mentorship(this.state.profile_details,"activate")}>
						Activate
						</Button>
					</Box>
					<Box margin={1}>
						<Button variant="outlined" size="medium"   color="primary" 
						onClick={()=>this.enable_mentorship(this.state.profile_details,"decline")}>
						Decline
						</Button>
					</Box>
					<Box margin={1}>
					<Loader uid={this.state.profile_details['id']} />
					</Box>
					</Box>
			
			
			
			</Box>
			

			
			</Box>
			</DialogContent>			
			
			</Dialog>
			
			)
	}			
	
	
	return ( 
	
	<React.Fragment>
	{			
		this.state.alert_msg!=false
		? 
			<AlertBox show_status={true} msg={this.state.alert_msg} msg_type={this.state.msg_type} />
		:''
	}
	<ProfileDialog />
	<MentorshipDialog />
	{
		["recent","followed"].includes(this.props.action)
		?<SmallRenderCard user={this.props.user} />
		:<RenderCard user={this.props.user} />
	}
	</React.Fragment>
			
	);
  }
}


export default (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const customclasses = customUsestyles();
	return (
        <MentorCard  
		classes={classes} 
		customclasses={customclasses} 
		location={location}  
		history={history}
		user={props.user}
		action={props.action}
		parentCacheCallback={props.parentCacheCallback}
		/>
    )
}
