import {
	Avatar, Box,Button, Card, Chip, CardContent, CardHeader, CircularProgress,
	
	Grid, Typography} from '@material-ui/core';


import CancelIcon from '@material-ui/icons/Cancel';
import SearchBar from "material-ui-search-bar";
import React, { Component } from "react";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import { getCacheDetails , deleteLocal} from "../caching";
import { findCourse, translateText,api } from "../Helper";
import axios from 'axios';
import { useStyles } from "../useStyles";
import AlertBox from "../AlertBox";
import InfiniteScroll from "react-infinite-scroll-component";
import ArticleDialog from './ArticleDialog';
import Vertical_Cards from './Vertical_Cards';
import { Vertical_Blogs_Skeleton } from "../Skeleton";

import './blog.css'

const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 1
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };

let student_id=null;
let email=null;
if(["admin","webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
}
let og_pah="https://www.opengrowth.com/assets/uploads/images/co_brand_1/article/"
		
class Scroll extends Component {

    constructor(props) {
    super(props);
	this.findCourse = findCourse.bind(this);
	this.translateText = translateText.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	course_list:[],mentors_list:[],
	progress_com:0,progress_left:9,loading:true,hasMore:true,
	start:0,end:10,msg:false,alert_msg:false,
	profile_popop:false,search:'',search_with:false,
	article:[],
	profile_details:[]}
    }
	
	componentDidMount()
	{
		//this.getCachePlay(this.state.start,this.state.end,false)	
		setTimeout(() => {
		//this.setState({ mentors_list: k })
				this.getCachePlay(this.state.start,this.state.end,false)	
		}, 1500);
		
	}
	getCachePlay = (start,end) => {
		
		//alert(end)
		//alert(fetch)
		let url="https://www.opengrowth.com/article-feed?&search="+this.state.search+"&id=&start="+start+"&end="+end
		axios.get(url)
		.then((response) => {
				//console.log(url)
				let data=response.data
				this.manage_data(data)

		});	
	}
	
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		setTimeout(() => {
			
			this.state.start=this.state.start+this.state.end
			if(this.state.search!='')
			{
				this.get_search_record(this.state.start,true,this.state.search)
			}
			else 
			{
				this.getCachePlay(this.state.start,this.state.end)
			}
			
		}, 1500);
	}}
	

	
	get_og_blogs = (param) => {
		
		let url="https://www.opengrowth.com/article-feed?&search=popular&id="+param.key+"&start="+param.start+"&end="+param.end
		if(["recent","today","week","month","liked","pouplar"].includes(param.key))
		{
			 url="https://www.opengrowth.com/article-feed?&search="+param.key+"&id=&start="+param.start+"&end="+param.end
		}
		//console.log(url)

   		fetch(url)
		.then(res => res.json())
		.then((data) => {
			
			this.manage_data(data)
					
		    let k="Below are matching courses to your search"
			if(data.length==0)
			{
				k="You have seen it all!"
			}
			else
			{
				this.post_blogs(data)
			}
			this.setState({alert_msg:k},()=>{console.log(this.state.alert_msg)})
			setTimeout(() => 
			{
				this.setState({ alert_msg: false});
			}, 1500);
						
		})
		.catch(console.log)
    };
	
	get_search_record = (start,fetch,search) => {

		let payload={
			'start':start,
			'end':this.state.end,
			'key':search,
		}
		axios.post(api+'/search_blog',
		payload)
		.then((response) => {
				
				//console.log(payload)
				let data=response.data
				//console.log(data)
				if(data.length==0)
				{
					
						this.get_og_blogs(payload)
				}
				else 
				{
					this.manage_data(data)
					
				}
				
		});	
		
		
	}
	
	post_blogs = (data) => {
		let payload={
			'blogs':data
		}
		axios.post(api+'/post_blogs',
		payload)
		.then((response) => {
			//console.log(payload);
			let data=response.data;
			//console.log(data)
		});	
	}
		
	manage_data = (data) => {
					this.setState({loading:false},()=>{console.log(this.state.loading)})
					if(data.length<this.state.end ){this.setState({hasMore:false})}
					if((this.state.mentors_list).length==0)
					{
						//console.log("new")
						this.setState({ mentors_list: data })
					}	
					else
					{//console.log("append")
						if (data.length>0)
						{
							this.setState({
							 mentors_list: [...this.state.mentors_list,...data]
							})
						}
						else
						{
							this.setState({ msg: "You have seen it all!" })
							setTimeout(() => {
							this.setState({msg:false})	
							}, 1500);
						}

			}
			//console.log(this.state.mentors_list)
	}
	
	handleClose = () => {
		this.setState({ profile_popop: false },()=>{console.log(this.state.profile_popop)});
	}
		
	getBlog = (article) => {
		this.setState({ profile_popop: true },()=>{console.log(this.state.profile_popop)});	
		this.setState({ article: article },()=>{console.log(this.state.article)});
		
	//	alert(this.state.article.image)
	}
	
	
	remove_key = () => {
		this.setState({ search: '' },()=>{console.log(this.state.search)});
		this.setState({ hasMore: true },()=>{console.log(this.state.hasMore)});
		this.setState({start:0},()=>{console.log(this.state.start)})
		this.setState({mentors_list:[]},()=>{console.log(this.state.mentors_list)})
		this.getCachePlay(0,this.state.end,false)	

	}
	
	
	manage_search = (tag) => {
		
		this.setState({search:tag},()=>{console.log(this.state.search)})
		this.setState({start:0},()=>{console.log(this.state.start)})
		this.setState({hasMore:true},()=>{console.log(this.state.hasMore)})
		this.setState({mentors_list:[]},()=>{console.log(this.state.mentors_list)})
		//alert((this.state.mentors_list).length)
		this.get_search_record(0,false,tag)
	}

    render()
	{
		const styles ={
	
		card: {position:"relative",width: '100%',height: 'auto',},
		
		img:{width: '100%',height: '100px'},
		imgF:{width: '100%',height: '250px'},
		
		box:{height:'auto',paddingRight:"12px"},
		
				
		};
		const classes = this.props.classes;
		let i=0;

		
		
	const BlogsList = () => {
		let arr=this.state.mentors_list;
		arr=window.location.pathname=="/mentors-and-experts"?arr.slice(0,2):arr
		
		return (
			<InfiniteScroll style={{overflow:'hidden'}}
			dataLength={this.state.mentors_list.length}
			hasMore={this.state.hasMore}
			loader={ 
			
							this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
			
			}
			endMessage={
				<>
				{window.location.pathname=="/mentors-and-experts"?'':
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>}
				</>
			}>
				<Box >
				<Grid container spacing={2}>
				{
		
				arr.map((e, index) => (
				<Grid item xs={12} sm={3} >
				{/*<List className={classes.root}>
				<BlogsCard article={e} />
				</List>*/}
				<Vertical_Cards blogs={e} />
				</Grid>
							
			))}
			</Grid>
			</Box>
		</InfiniteScroll>		
		)
	}
	
	const BlogsCard = ({article}) => {
			
			let title=article['title']
			let meta_description=article['meta_description']
			if([null,undefined].includes(title)==false)
			{
				title=(article['title']).replaceAll(/[\\\/"]/g, '')
				meta_description=(article['meta_description']).replaceAll(/[\\\/"]/g, '')
			}
	
		return (
					
					
					<Box display="flex" marginBottom={0.5} marginTop={0.5} >
					
					<Box>
					<Avatar src={og_pah+article['blog_image']} className={classes.small} />
					</Box>
					
					<Box marginLeft={2} display="block">
						<Box onClick={()=>this.getBlog(article)} sx={{cursor:'pointer'}}>
							<Typography variant="subtitle1" color='primary'>
							<Box  fontWeight="600" style={styles.name}>
							{title}
							</Box>
							</Typography>
						</Box>
						
						<Box>
						<Typography variant="body2" color='textSecondary'  style={styles.details}>
						{meta_description}
						</Typography >
						</Box>
					</Box>
					</Box>
		)}
	
	const CategoriesCard = () =>
	{ 
			let category_card=[{"tag":"recent","title":"Recent Blogs"},{"tag":"today","title":"Published Today"},{"tag":"week","title":"Published this Week"},{"tag":"month","title":"Published this Month"},{"tag":"popular","title":"Popular Blogs"},{"tag":"liked","title":"Most liked"}];
			return(	
			<Box padding={0}>
					  <Typography variant="subtitle1" color="textPrimary" >
						<Box fontWeight="600" marginBottom={2}>Popular Searches</Box>
					 </Typography>
					 
					   <Box display="flex" marginBottom={2}>
					   <Grid container spacing={2}>
					   {category_card.map((e, index) => (
							
							<Grid item xs="auto" key={index}>
							
							<Chip label={<Box fontWeight="bold" fontSize="6">{e['title']}</Box>} 
							 variant={e==this.state.search?"contained":"outlined"}
							 color={e==this.state.search?"primary":""}
							clickable onClick={() => {this.manage_search(e['tag'])}}
							/>
							</Grid>
							
						))}
						</Grid>
						</Box>

			</Box>
			)
	}
	return ( 
		
	
		<Box>
		
			{this.state.alert_msg!=false
			? <AlertBox show_status={true} msg={this.state.alert_msg} msg_type="success" />
			: 	''}
			
		{
				this.state.profile_popop==true?
				<ArticleDialog
				article={this.state.article}
				parentCallback={this.handleClose}
				/>
				:''
		}
		<Card>
			<CardHeader 
			title={"Recent Blogs"} />		
			<CardContent>		
			
			
			<Grid container  spacing={3}>
			
			
			<Grid item xs={12}>
				<CategoriesCard />
			</Grid>
			
			
			<Grid item xs={12} sm={7}>
			<Box marginBottom={4} marginTop={"-8px"}>
			<SearchBar
			value={this.state.search}  
			onChange={(newValue) => {this.setState({ search: newValue })}}
			onRequestSearch={() => {this.manage_search(this.state.search)}}
			style={ {
				margin: "0 auto",
				width: '100%',
				'box-shadow': '0px 0px 0px 1px rgb(0 0 0 / 20%)',
				'border-radius': '4px',
				'fontFamily':"Be Vietnam, sans-serif"
			}} />
			
			<Box margin={1} display={this.state.search==''?"none":"block"}>
			<Typography variant="subtitle1" >
			<Box fontWeight="bold">Searched keys</Box>
			</Typography> 
			<Box display="flex" direction="row" className={classes.chip}   borderColor="grey.300"  
			style={{backgroundColor:'#fbfbfb'}}
			border={1}  margin={1} padding={2}  borderRadius={12} > 
			{
				<Chip label={this.state.search} 
				onDelete={()=>{this.remove_key()}} 
				variant="outlined"
				deleteIcon={<CancelIcon style={{color:"#25387c"}} />}/>
			}
			</Box>	
			</Box>
			</Box>
			
			

			</Grid>
			
			</Grid>
			{
				
				this.state.loading==true
				?
					<Vertical_Blogs_Skeleton/>
				:	
					<BlogsList />
			}
			</CardContent>
			</Card>
		</Box>
				
	);
}}


export default () => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
	return (
        <Scroll  
		classes={classes} 
		location={location}  
		history={history} />
    )
}
