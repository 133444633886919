import React from 'react';
import { Grid, Container, Typography, Link, Box, IconButton, makeStyles } from '@material-ui/core';
import { FaGoogle, FaFacebookF, FaTwitter, FaInstagram,FaLinkedinIn } from 'react-icons/fa';

const useStyles = makeStyles((theme) => ({
  footer: {
    width: '100%',
    backgroundColor: 'hsl(216, 25%, 95.1%)',
    borderTop: '2px solid #e0e0e0',
 //   borderColor: theme.palette.primary.main,
	padding: theme.spacing(4, 0),
    textAlign: "center",
	[theme.breakpoints.up('md')]: {
	   textAlign: "unset",
   }
  },
  logo: {
    marginRight: theme.spacing(2),
  }, 
  link: {
	fontSize: '16px',
	fontFamily: 'system-ui',
    color: '#757575',
	fontWeight: 400,
	"&:hover": {
      color: "black",
      "text-decoration": "none",
    },
  },
  end: {
	  display:'flex',
	  justifyContent:'center',
		[theme.breakpoints.up('md')]: {
	  justifyContent:'end',
	   }
  },
  start: {
	  display:'flex',
	  justifyContent:'center',
		[theme.breakpoints.up('md')]: {
	  justifyContent:'start',
	   }
  },
  btn: {
    border: '2px solid #332d2d',
    color: '#332d2d',
	margin: '6px'
  },
}));

const Footer = () => {
  const classes = useStyles();

  const companyLinks = [
    { label: 'About Us', href: 'https://www.opengrowth.com/about-us' },
    { label: 'Contact Us', href: 'https://www.opengrowth.com/contact-us' },
    { label: 'Help & Support', href: 'https://www.opengrowth.com/contact-us' },
  ];

  const productLinks = [
    { label: 'Growth Expert', href: 'https://www.opengrowth.com/mentorship' },
    { label: 'Startup Services', href: 'https://www.opengrowth.com/services' },
    { label: 'Startup Academy', href: 'https://www.opengrowth.com/opengrowth-hub' },
  ];

  const resourceLinks = [
    { label: 'Blogs', href: 'https://www.opengrowth.com/sitemap.xml' },
    { label: 'Terms & Conditions', href: 'https://www.opengrowth.com/terms-and-conditions' },
    { label: 'Privacy & Cookies Policy', href: 'https://www.opengrowth.com/privacy-policy' },
  ];

  const footerLinks = [
    { label: 'Terms of Service', href: 'https://www.opengrowth.com/terms-and-conditions' },
    { label: 'Privacy Policy', href: 'https://www.opengrowth.com/privacy-policy' },
    { label: 'Sitemap', href: 'https://www.opengrowth.com/sitemap.xml' },
  ];

  const socialMediaLinks = [
    { icon: <FaFacebookF size={12} />, href: 'https://www.facebook.com/opengrowthforentrepreneurs' },
    { icon: <FaTwitter size={12}  />, href: 'https://twitter.com/opengrowth' },
    { icon: <FaLinkedinIn size={12}  />, href: 'https://www.linkedin.com/company/opengrowth' },
    { icon: <FaInstagram size={12}  />, href: 'https://www.instagram.com/opengrowth_/' },
  ];
	const TitleCard = ({text}) => {
		return (
		  <Box fontSize="20px" fontWeight="500" marginTop={2}  marginBottom={3} fontFamily="system-ui">
			  {text}
		  </Box>
		);
	  };
	const DataCard = ({label,href,index}) => {
		return (
               <Box marginBottom={1} marginTop={1}>
			   <Link href={href} className={classes.link}  key={index}>
                  {label}
                </Link>
                </Box>
		);
	  };
  return (
    <footer className={classes.footer}>
      <Container>
        <Grid container spacing={2}  justifyContent="center">
          <Grid container item xs={10} md={10}  justifyContent="space-between">
          <Grid item xs={12} md={3}>
		    <Box  marginTop={2}>
            <Link href="https://www.opengrowth.com/">
              <img
                src="https://www.opengrowth.com/assets/og/images/opengrowth-logo.png"
                className={classes.logo}
                height="52"
                alt="OpenGrowth Logo"
              />
            </Link>
            </Box>
          </Grid>

          <Grid item xs={12} md={"auto"}>
			<TitleCard text="Company" />
            {companyLinks.map((link, index) => (
             <DataCard label={link.label} href={link.href}  key={index} />
            ))}
          </Grid>

          <Grid item xs={12} md={"auto"}>
			<TitleCard text="Products" />
            {productLinks.map((link, index) => (
             <DataCard label={link.label} href={link.href}  key={index} />
            ))}
          </Grid>

          <Grid item xs={12} md={"auto"}>
            <TitleCard text="Resources" />
            {resourceLinks.map((link, index) => (
             <DataCard label={link.label} href={link.href}  key={index} />
            ))}
          </Grid>
        </Grid>

       <Grid  item xs={10}>
	   <Box marginBottom={2.5} marginTop={2.5}>
		    <hr />
        </Box>
		</Grid>

        <Grid   item xs={10} container justifyContent="space-between">
          

		<Grid item xs={12} md={8} container spacing={2}  className={classes.start}>
		  {footerLinks.map((link, index) => (
			<Grid item xs="auto" key={index}>
			  <DataCard label={link.label} href={link.href} />
			</Grid>
		  ))}
		</Grid>

          <Grid item xs={12} md={4} className={classes.end}>
            {socialMediaLinks.map((link, index) => (
              <Box key={index} display="inline">
                <IconButton href={link.href} 
				className={classes.btn} role="button">
                  {link.icon}
                </IconButton>
              </Box>
            ))}
          </Grid>
        </Grid>
        </Grid>

 
      </Container>
    </footer>
  );
};

export default Footer;
