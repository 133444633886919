import React, { useState } from "react";
import { Snackbar, makeStyles } from "@material-ui/core";
import { Alert } from "@material-ui/lab";

const useStyles = makeStyles((theme) => ({
  top: {
    marginTop: "6%",
  },
  wd: {
   // width: "65ch",
    width: "29vw",
  },
}));

const AlertBox = ({ show_status, msg, msg_type,parentAlertCallback }) => {
  const [open, setOpen] = useState(show_status);
  const classes = useStyles();

  const handleClose = () => {
    
	if(parentAlertCallback!=undefined)
	{
		parentAlertCallback()
	}
	else
	{
		setOpen(false);
	}
  };

  return (
    <Snackbar
      open={open}
      autoHideDuration={6000}
      onClose={handleClose}
      anchorOrigin={{ vertical: "top", horizontal: "center" }}
      className={classes.top}
    >
      <Alert
        className={classes.wd}
        elevation={6}
        variant="filled"
        onClose={handleClose}
        severity={msg_type}
      >
        {msg}
      </Alert>
    </Snackbar>
  );
};

export default AlertBox;
