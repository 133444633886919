import React, { useEffect,useState, Suspense } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Grid,Typography, Card, CardHeader, CardContent, Box, AppBar, Tabs, Tab } from '@material-ui/core';
import { CalendarToday,Forum , People } from '@material-ui/icons';
import { secondaryColor , successColor  } from '../../Helper';
import { useHistory,useLocation} from 'react-router-dom';

import FormCard from './FormCard';
import MessageCard from './MessageCard';
import Participants from './Participants';

const useStyles = makeStyles({
  fs:{textTransform:"capitalize"},
  card: {
    display: 'flex',
    flexDirection: 'column',
  }, 
  content: {
    display: 'flex',
    paddingBottom: '0px',
  },
  header: {
    position: 'sticky',
    top: 0,
    zIndex: 1,
    backgroundColor: 'white',
  },
  messageContainer: {
    flexGrow: 1,
    overflowY: 'auto',
    padding: '10px'
  },
  formCard: {
    backgroundColor: '#fff',
    border: '0px solid #ccc',
    padding: '0px 0px 8px',
  },
    root: {flexGrow: 1,width: '100%'},
  global:{textTransform: 'capitalize',color: 'rgb(99, 115, 129)','text-align': 'center',fontSize:'0.87rem',},
  m:{marginLeft:'auto' },
  width:{minWidth:'120px'},
  tab: {verticalAlign : 'middle',marginRight:'8px',display:'unset'},
	  upcoming: {
		  color: successColor
	  },
	 pending: {
		 color: secondaryColor
	  },
	  transparent:{backgroundColor:'transparent'}
});


function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}
const DiscussionForum = () => {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [keyProp, setKey] = useState(null);
  const [cardView, setCardView] = useState('chat');

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleUser = () => {
	  setCardView('chat');
  };
  const handleCallback = (row) => {
	  console.log(row)
    setKey(row);
	//alert(1)
  };
	useEffect(() => {
		console.log(keyProp)
		//alert(cardView)
	}, [cardView]);

	
  const Header = () => {
    return (
      <AppBar position="static" color="inherit" elevation={0} className={classes.transparent}>
        <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
          <Tab
            className={classes.width}
            label={
              <Box fontWeight="600">
                <Forum  className={classes.tab + ' ' + classes.upcoming} fontSize="small" />
                Discussion Forum
              </Box>
            }
            className={classes.m + ' ' + classes.global}
            {...a11yProps(0)}
          />
          <Tab
            className={classes.width}
            label={
              <Box fontWeight="600" className={classes.fs}>
                <People className={classes.tab + ' ' + classes.pending} fontSize="small" />
                Participants
              </Box>
            }
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
    );
  };

  const InitialCard = ({ image, title, subtitle }) => {
	  return (
		<Box
		  display="flex"
		  flexDirection="column"
		  alignItems="center"
		  justifyContent="center"
		>
		  <img width='auto' height='350px'  src={image} alt="Group" style={{borderRadius: '50%' }} />
		  <Typography variant="h6" component="div" mt={2} >
		  <strong>	{title} </strong>
		  </Typography>
		  <Typography variant="subtitle1" color="textSecondary" component="div" mt={1}>
			{subtitle}
		  </Typography>
		</Box>
	  );
	};
	  
  return (
	<Card >
        <Grid container spacing={3} alignItems="center" >
			
			<Grid item xs={12} sm={12}  md={3}>
			<Suspense fallback={<div></div>}>
				<Participants  chat_id={"forum"} userCallback={handleUser} />
			</Suspense>
			</Grid>

		<Grid item xs={12} sm={12} md={9} style={{height:'485px'}}>
				{
					cardView=="initial"
					?
						<InitialCard
						image="https://i.pinimg.com/originals/16/77/7a/16777a8077a451babb2b902efc1d8725.gif"
						title="ChatConnect"
						subtitle="Connecting people for great conversation"
					  />
	  
					:<Suspense fallback={<div> </div>}>
					<Box className={classes.messageContainer}>
					  <MessageCard 
					  keyProp={keyProp} 
					  parentCallback={handleCallback} 
					  chat_id={"forum"} />
					</Box>
						<div className={classes.formCard}>
						  <FormCard parentCallback={handleCallback}  chat_id={"forum"} />
						</div>
					</Suspense>
				}
			</Grid>

			
		
		</Grid>
		
	</Card>
  );
};

export default DiscussionForum;
