import { useState, useEffect, useCallback } from 'react';
import {
  Box, Grid, CardMedia ,Button, Card, Link,
  CircularProgress, Avatar,
  makeStyles, Typography, ListItem, ListItemAvatar, ListItemText,Chip
} from '@material-ui/core';
import { api, get_name_slug,generateProfileUrl, userProfile_path,user_id,user_status_id } from '../Helper';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from "react-infinite-scroll-component";
import ProfilePopup from '../mentors/dashboard/ProfilePopup';

const useStyles = makeStyles((theme) => ({
  root: {
    flexDirection: 'row',
	 transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    '&:hover': {
	 marginBottom:'2px',
      transform: 'translateY(2px)',
    },
  },
  cover: {
    width: '100%',
    height: 200,
    [theme.breakpoints.up('sm')]: {
      width: 200,
      height: 'auto',
    },
  },

  name: {
    fontWeight: 700,
    cursor: 'pointer',
    color: '#25387c',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
    lineHeight: '1.25',
    textAlign: 'left',
  },
  industry: {
    color: '#25cb37',
  },
  about: {
	  
    'line-height': '1.4',
    marginTop: '8px',
    color: '#5a6978',
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
  },
  tags: {
    textAlign: 'left',
	overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
  },
  iconWrapper: {
    display: 'flex',
    justifyContent: 'end',
    alignItems: 'center',
    marginTop: "16px",
	height:'34px',
  },
  imageBox: {
    width: '100%',
    height: '85px',
//backgroundColor:"#f7f7f7"
  },
  avatar: {
    width: '85px',
    height: '85px',
	borderRadius:0
  },

}));
const FolloweeTab = (props) => {
  const classes = useStyles();

  const [mentorsList, setMentorsList] = useState([]);
  const [profilePopup, setProfilePopup] = useState(false);
  const [profileDetails, setProfileDetails] = useState([]);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [hasMore, setHasMore] = useState(true);
  const [loading, setLoading] = useState(true);
  //const action="follower"
  const action=props.action
  const history=useHistory();

  const getFullNameSlug = get_name_slug.bind(this);
 const getFullProfile = (mentor) => {
	let mail_url=generateProfileUrl(mentor['email'])
    const name = getFullNameSlug(mentor['name']);
	let profile_data={
        profile_mail: mentor['email'],
        profile_type: mentor['follower_status'],
        profile_id: mentor['follower_id'],
        course_author_id: mentor['follower_status']=='professor'?mentor['follower_status']:null
      }
	 if(props.action=="following")
	 {
		profile_data={
			profile_mail: mentor['email'],
			profile_type: mentor['profile_status'],
			profile_id: mentor['profile_id'],
			course_author_id: mentor['profile_status']=='professor'?mentor['profile_status']:null
		  }
	 }
	//console.log(mentor)
	//console.log(profile_data)
	//alert(profile_data)
    history.push({
      pathname: '/profile/' + mail_url,
      state: profile_data,
    })
  };

  const handleCallback = (childData) => {
    setProfilePopup(false);
  };

  const getInstructorProfile = (data) => {
    setProfilePopup(true);
    setProfileDetails(data);
  }; 

  const getMentors = useCallback(
    (start, fetch) => {
		setLoading(true)
      let payload = {
        start: start,
        end: end,
        key: user_id+"_"+user_status_id+"_"+action+"_"+ start + "_" + end,
        profile_id:user_id,
        profile_status:user_status_id,
        action
      };
		if(action=="following")
		{
			payload = {
				start: start,
				end: end,
				key: user_id+"_"+user_status_id+"_"+action+"_"+ start + "_" + end,
				follower_id:user_id,
				follower_status:user_status_id,
				action
			  }
		}
      //console.log(payload);

      axios.post(api + "/get_profile_connections", payload).then((response) => {
        let res = response.data;
        //console.log(res);

        if (res.length < end) {
          setHasMore(false);
        }

        if (fetch === false) {
          setMentorsList(res);
        } else {
          if (res.length > 0) {
            setMentorsList((prevMentorsList) => [...prevMentorsList, ...res]);
            //console.log(mentorsList);
          } else {
            setHasMore(false);
          }
        }
		setLoading(false)
      });
    },
    [end, setHasMore, setMentorsList]
  );

  const fetchMoreData = useCallback(() => {
    if (hasMore) {
      let t = start + end;
      setStart(t);
      getMentors(t, true);
    }
  }, [end, getMentors, hasMore, start]);

  useEffect(() => {
	  setMentorsList([]);
	  setStart(0);
	  setHasMore(true);
	  //console.log("clear log")
      getMentors(0, false);
  }, [props.action]);

  const ProfileDialog = () => {
    return (
      <>
        {profilePopup ? (
          <ProfilePopup
            profile_details={profileDetails}
            handleCallback={handleCallback}
          />
        ) : null}
      </>
    );
  };



	const NewCard = ({ mentor }) => {
	  const handleMentorClick = () => {
		getFullProfile(mentor);
	  };

	  const handleInstructorClick = () => {
		getInstructorProfile(mentor);
	  };
	//  const arr = mentor.industry.split(",");
	  let interest = (mentor.interest).split(",").map(item => item.trim()).join(", ");
	

  return (
    <Card className={classes.root}>
      <Box onClick={handleMentorClick} 
	  className={classes.imageBox}
	  display="flex" 
	  alignItem="center">
        <Avatar
          src={mentor.img ? userProfile_path + mentor.img : ''}
          alt={mentor.name}
          className={classes.avatar}
        />
      <Box 
	  width="100%"
	  height='85px'
	  display="flex" 
	  justifyContent="start" 
	  textAlign="left" 
	  alignItems="center">
        <Box padding="0px 16px">
		<Box>
        <Typography
          variant="subtitle1"
          onClick={handleMentorClick}
          component="subtitle1"
          className={classes.name}
		  align="center"
        >
          {mentor.name}
        </Typography>
        </Box>
        <Typography variant="subtitle2" color="textSecondary" className={classes.tags}>
          {mentor.industry && (mentor.industry?" | ": "") && interest}
        </Typography>
      </Box>
      </Box>
      </Box>
    </Card>
	)

	}
	
	
return (
<>
<ProfileDialog/>
  <InfiniteScroll
    style={{ overflow: "hidden" }}
    dataLength={mentorsList.length}
    hasMore={hasMore}
    loader={
      				   hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={loading}
									onClick={fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
    }
    endMessage={
      <Box
        sx={{
          alignItems: "center",
          display: "flex",
          flexDirection: "column",
        }}
        marginTop={2}
        marginBottom={2}
      >
        {mentorsList.length === 0 ? (
          <Typography variant="subtitle1" color="textSecondary">
          {
			props.action === "follower"
			? "You don't have any followers yet."
			: "You don't have any followees yet."
		  }
          </Typography>
        ) : (
          window.location.pathname !== "/our-mentors" && (
            <Typography variant="subtitle2" color="textPrimary">
              You have seen it all
            </Typography>
          )
        )}
      </Box>
    }
  >
    <Box>
      <Grid container spacing={2}>
     
        {mentorsList.map((mentor) => (
			<Grid item xs={12} md={4} lg={3} >
		   <NewCard
            key={mentor.id}
            mentor={mentor}
          />
		   </Grid>
        ))}
     
      </Grid>
    </Box>
  </InfiniteScroll>
  </>
);

};

export default FolloweeTab;
