import { Box, Button, Card, CardContent, CardHeader, CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles } from '@material-ui/core';
//import ForwardToInboxIcon from '@material-ui/icons/ForwardToInbox';
import { alpha } from '@material-ui/core/styles';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { translateText } from "../../functions/Helper";








const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
     search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
	     backgroundColor: 'transparent',

    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	color:'primary'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize:'14px',
	padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },

}));

class Logs extends Component {

    constructor(props) {
	super(props);
	this.translateText = translateText.bind(this);
	this.state={
		    msg:false,msg_type:false,
            user_show:[],user_count:0,start:0,end:10,
			hasMore: true,record_len:0,log_data:[],webinar_id:null,loading:'ends',
			search_for:'All',search:null,data:'logs'}
    }
	
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
			
                this.getLogs('fetch')
                
			}, 1500);
		}
	};
	
	getLogs=(loc)=>
    {
			axios.post('/api/getAdminLog',{start:this.state.start,end:this.state.end})
            .then((response) => {
			//console.log(response.data)
            if(loc=='initial')
            {
                this.setState({user_show:response.data})
            }
			else
            {
				this.setState({ user_show: [...this.state.user_show,...response.data]})
            }
            
            if(response.data.length<this.state.end)
            {this.setState({hasMore:false})}	
            //alert(this.state.log_data[0]['title'])
			}); 
    }
   
	
   
	
	componentDidMount()
	{
        this.getLogs('initial')
	}
  	render()
  	{
	
		const classes = this.props.classes;
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
			
			  <StyledTableCell  >{e['email']}</StyledTableCell>
			  <StyledTableCell  >{e['ip']}</StyledTableCell>						 
			  <StyledTableCell  >{e['added_on']}</StyledTableCell>						 
              
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				{"Admin logs("+this.state.user_show.length+")"}
				</Typography>
				</Box>
				
		   </Box>			
			}/>
        
		<CardContent>
		
        
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.user_show.length}
				  hasMore={this.state.hasMore}
				  loader={									
				   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					
					}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					   <TableRow>
                       <StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >IP</StyledTableCell>
						<StyledTableCell >Added on</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
				 
				
				
    
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}