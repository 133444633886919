import React, { Component } from 'react';
import { getUserDetails, login_for_opengrowth } from '../../Helper';
import { Backdrop, CircularProgress } from '@material-ui/core';

class LCard extends Component {
  constructor(props) {
    super(props);
    this.getUserDetails = getUserDetails.bind(this);
    this.state = {
      showBackdrop: false,
    };
  }

  componentWillMount() {
	  
	const uid = new URLSearchParams(window.location.search).get('uid');
	if(![undefined,null].includes(uid))
	{  
		const decrypted_email = login_for_opengrowth();
		if (decrypted_email) {
		  this.setState({ showBackdrop: true });
		  this.getUserDetails(decrypted_email);
		}
		else
		{
			alert('Invalid UserID! User does not exists.')
			  if(window.location.pathname != '/')
			  {
				  window.location = '/';
			  }
		}
	}
  }

  render() {
    return (
      <>
        {this.state.showBackdrop && (
          <Backdrop open={this.state.showBackdrop} style={{ zIndex: 9999 }}>
            <CircularProgress color="inherit" />
          </Backdrop>
        )}
      </>
    );
  }
}

export default LCard;
