import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, Grid, InputLabel, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { KeyboardDatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from 'axios';
import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { api, translateText } from "../../functions/Helper";


const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

class Attendees extends Component {

    constructor(props) {
	super(props);
	    this.translateText = translateText.bind(this);
        this.state={
		      msg:false,msg_type:'success',
            attendees_list:[],
            total_user:0,
            start:null,end:null,
            start_num:0,end_num:10,hasMore: true,
            errors: {start:"Initalize",end:"Initalize"}
        }
    }
	
	handleDateChangeStart = (dob) => {
		this.setState({ start: new Date(dob).toLocaleString('en-US', {timeZone: 'Asia/Calcutta'}) });
		if(this.state.start!=null){this.state.errors.start='';}
	}
	handleDateChangeEnd = (dob) => {
		this.setState({ end: new Date(dob).toLocaleString('en-US', {timeZone: 'Asia/Calcutta'}) });
		if(this.state.end!=null){this.state.errors.end='';}
	}
    checkError=(name,value)=>
    {
   		this.state.errors[name]=value==null||value==''?" All fields are required":"Initalize";
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
	}
	validForm=()=>
	{
		const registerList = ['start', 'end'];
		let allFilled=false;
		this.setState({reg:false});
		for (let i = 0; i < registerList.length; i++)
		{
			let value=registerList[i];
			allFilled=this.checkError(value,this.state[value]);
		    if (allFilled==false)
			{
				break;
		    }
		}
		return allFilled
	}

    handleChange = event => {
    //event.preventDefault();
	let errors = this.state.errors;
    const { name, value } = event.target;
    this.state[name]=value;
    this.setState({changeFound:true}); 
     errors[name]=value==null?"All fields are required":"";
	 this.setState({ errors, [name]: value });
    };

    convertDate=(val)=>
	{
		var d = new Date(val);
		var date_format_str = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString());
		return date_format_str
	}
    fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start_num':this.state.start_num+this.state.end_num})
			this.getAllAttendees("scroll")			
			}, 1500);
		}
	};	
    

    getAllAttendees=(state)=>
    {
			axios.post('/api/getAllAttendees', {
				'start':this.state.start_num,
				'end':this.state.end_num
			})
            .then((response) => {
			//console.log(response.data)
			if(state=="initial")
			{
				this.setState({attendees_list:response.data})

			}
			else
			{
				this.setState({ attendees_list: [...this.state.attendees_list,...response.data]})

			}
			
			if(response.data.length<this.state.end)
            {
				this.state.hasMore=false;
				this.setState({hasMore:false})
            }	
			
			}); 
    }
	handleSubmit = (e) =>
	{
		e.preventDefault();
		
        let isValidate=this.validForm()
		//alert(this.convertDate(this.state.start))
		if(new Date(this.state.start)>new Date(this.state.end))
		{
			isValidate=false;
			this.setState({msg: "End date must be greater than start date."});
			setTimeout(() => {this.setState({msg:false})}, 2000);
		}
		
		if(isValidate==true)
		{
			//alert(this.convertDate(this.state.start))
			//alert(this.convertDate(this.state.end))
            this.getBwAttendees();
								
		}
	}
	
	
	getBwAttendees=()=>
    {
        axios.post(api+'/getBwAttendees',
        {
            'start':this.convertDate(this.state.start),
			'end':this.convertDate(this.state.end),
        })
			.then((response) => {
			this.setState({attendees_list:response.data})
            if(this.state.attendees_list.length>0 )
            {
                //console.log(this.state.attendees_list.length)
                if(response.data[0]['status']=='found')
                {
                    this.setState({msg:'Below are the search result!'})
                }
                else
                {
                    this.setState({msg:'No result found!'})
                } 
            }
            else
                {
                    this.setState({msg:'No result found!'})
                }
           this.state.hasMore=false;
			setTimeout(() => {this.setState({msg:false})}, 2000);
            //console.log(response.data)
		});
    }
	componentDidMount()
	{
		
		this.getAllAttendees('initial')	
	}
  	render()
  	{
	
		const classes = this.props.classes;
        const { errors } = this.state;


		const Tables = () => {
		return (
		<>		
		{
            
         this.state.attendees_list.map((e, index) => (
		<StyledTableRow>
             <StyledTableCell  >{e['join_id']}</StyledTableCell>
			  <StyledTableCell  >{e['webinar_id']}</StyledTableCell>
			  <StyledTableCell  >{e['email']}</StyledTableCell>
			  <StyledTableCell  >{e['join_time']}</StyledTableCell>
			  <StyledTableCell  >{e['logout']}</StyledTableCell>
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={ 
        
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				Attendees ({this.state.attendees_list.length})
				</Typography>
				</Box>
				
				
				<Box >
                <Button  
				variant="outlined" 
				color="primary"  
				onClick={()=>{
                    this.state.hasMore=true;
                    this.state.start_num=0;
                    this.getAllAttendees("initial")
                    }}>
				Recent Attendees
				</Button>
				</Box>
 
				
		   </Box>			
			}/>
            
		<CardContent>
		<Grid container spacing={2}>
				
                            <Grid item xs={4}>
									<FormControl variant="outlined" fullWidth
										error={ errors.start!="Initalize" ? errors.start: ''}  >
										
										<InputLabel id="dob-outlined-label"></InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils} >
										  <KeyboardDatePicker  //orientation="landscape" id="dob-box"
											variant="inline"
											inputVariant="outlined"
											label="Start On"
											InputAdornmentProps={{ position: "end" }}
											value={this.state.start}

											name="start" id="exampleDate" 
											onChange={this.handleDateChangeStart} 
											error={ errors.start!="Initalize" ? errors.start: ''} 
										  />
										</MuiPickersUtilsProvider>
									  </FormControl>  
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.start!="Initalize" ? errors.start: ''}
									</Typography>
									</Box>
								</Grid>
								
                            <Grid item xs={4}>
									<FormControl variant="outlined" fullWidth
										error={ errors.end!="Initalize" ? errors.end: ''}  >
										<InputLabel id="dob-outlined-label"></InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils} >
										  <KeyboardDatePicker //orientation="landscape" id="dob-box"
											variant="inline"
											inputVariant="outlined"
											label="Ends on"
											InputAdornmentProps={{ position: "end" }}
											value={this.state.end}                     
									
											name="end" id="exampleDate" 
											onChange={this.handleDateChangeEnd} 
											error={ errors.end!="Initalize" ? errors.end: ''} 
										  />
										</MuiPickersUtilsProvider>
									  </FormControl>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.end!="Initalize" ? errors.end: ''}
									</Typography>
									</Box>
                        </Grid>
                
                           <Grid item xs={2}>
								<Box display="flex" flexDirection="row-reverse" >
								
								<Button variant="contained" size="medium" 
								style={{padding:'11px'}}
								color="primary"  onClick={this.handleSubmit} 
								fullWidth
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>
                                     Search</span>}
								</Button>
								
								</Box>
                        </Grid>
            <Grid item xs={12}>
          
           <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename="attendees_list"
                    sheet="tablexls"
                    buttonText="Export attendees list"/>
            
		  <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.attendees_list.length}
				  next={this.fetchMoreData} 
				  hasMore={this.state.hasMore}
				  loader={
					  
									   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
				}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper} >
				  <Table className={classes.table} aria-label="customized table"  id="table-to-xls">
					<TableHead>
					 <TableRow>
						<StyledTableCell >Joinning ID</StyledTableCell>
						<StyledTableCell >Webinar ID</StyledTableCell>
						<StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Joinning Time</StyledTableCell>
						<StyledTableCell >Logout On</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
					
		</Grid>
        </Grid>
	
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Attendees classes={classes} />
    )
}