import React, { useEffect, useState } from 'react';
import axios from 'axios';
import {Typography,Box,Button,Grid,Container,Card,CardContent} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { deleteLocal } from "../../caching";

// Parse the JSON data from sessionStorage
var meetingData = JSON.parse(sessionStorage.getItem('active_pay'));
var userData = ''; // Define userData as an empty string

// Check if login_token is not one of ['admin', 'webinar', null], and if not, parse ac_login_user
if (!['admin', 'webinar', null].includes(localStorage.getItem('login_token'))) {
  userData = JSON.parse(localStorage.getItem('ac_login_user'));
}

const MentorsPayment = () => {
  const urlSearchParams = new URLSearchParams(window.location.search);
  const [meetData, setMeetData] = useState(meetingData);
  const paymentId = urlSearchParams.get('payment_id');
  const [msg, setMsg] = useState(''); 
  const [, forceUpdate] = useState(null); 
  const history = useHistory();


  useEffect(() => {
	  console.log(urlSearchParams)
	  console.log(paymentId)
	  console.log(meetData) 
	  /*
	   meetData -------
	   {
		"meet_id": 113,
		"idea": "just testing on the server",
		"date_on": "2023-12-15",
		"start_time": "14:44:00",
		"req_status": 1,
		"date_title": "Friday, December 15, 2023",
		"mentee_id": "204",
		"mentor_id": 266,
		"mentor_uid": "266",
		"mentor_email": "anshu@opengrowth.com",
		"mentor_profile_url": "anshu-opengrowth",
		"mentor_name": "Anshu kr ",
		"mentor_img": "anshu.jpg",
		"mentee_email": "anshurk1@gmail.com",
		"mentee_profile_url": "anshurk1@gmail.com",
		"mentee_name": "Rakhi Kumari ",
		"mentee_img": "anshurk1.jpg",
		"time_slot_id": 313,
		"query": "no",
		"reject_reason": null,
		"google_meet_link": "https://meet.google.com/zgc-wktu-nbg",
		"google_event_id": "i1ch9c2qvrkp3qpia0q5lib3f0",
		"time_title": "02:44 PM  - 03:14 PM",
		"month_title": " December 15",
		"expire_slot": 0,
		"slot_date": "2023-12-15 14:44:00",
		"slot_end_date": "2023-12-15 15:14:00",
		"time_12": "02:44 PM",
		"time_24": "02:44 PM",
		"time_12_after": "03:14 PM",
		"willStartOn": "Fri, 15 Dec 2023 14:45:17 GMT",
		"willEndOn": "Fri, 15 Dec 2023 16:01:00 GMT",
		"exceded": false,
		"started": false,
		"start_timestamp": 1702631717,
		"end_timestamp": 1702636260
	}*/
					
		const deleteKeys=()=>{
			
				let keys_1=deleteLocal(meetData.mentee_id+"_upcoming_mentorship_")
				let keys=[keys_1]
				return keys
		}
    const fetchData = async () => {
      if (meetData) {
        try {
          const result = await axios.get(`https://api.razorpay.com/v1/payments/${paymentId}/status?callback`);
          const parsedRes = result.data;
          const statusCode = parsedRes.http_status_code;
          let razorpayOrderId = '';
          let razorpaySignature = '';

          if (statusCode === 200) {
            razorpayOrderId = parsedRes.razorpay_order_id;
            razorpaySignature = parsedRes.razorpay_signature;
          }
		  let key=(deleteKeys()).toString()
          const param = {
            payment_id: paymentId,
            razorpay_order_id: razorpayOrderId,
            razorpay_signature: razorpaySignature,
            payment_response: parsedRes,
		    keys:key,
          };
		 let res_eg=
		  {
				"payment_id": "pay_N7zzZo970bmdMb",
				"razorpay_order_id": "",
				"razorpay_signature": "",
				"payment_response": "/**/Razorpay.jsonp_callback({\"razorpay_payment_id\":\"pay_N7zzZo970bmdMb\",\"razorpay_order_id\":\"order_N7zyd10DuK4kM6\",\"razorpay_signature\":\"764c6255107bd15fb9058fdeab86c9fa352ec77de42fee67ccd3fff9734b0f10\",\"http_status_code\":200});"
			}
			
			console.log(param)
			const mergedParam = { ...meetData, ...param };
			console.log(mergedParam)

          try {
            const response = await axios.post('/api/manage_mentors_payment', mergedParam);
            const res = response.data;
            console.log(res);
            setMsg(res.msg);
            forceUpdate(n=>!n);
          } catch (error) {
            console.log(error);
          }
        } catch (error) {
          console.log(error);
        }
      }
    };

    fetchData();
  }, [meetData, paymentId]);


const Title = () => (
  <Box display="flex" flexDirection="column" alignItems="center" marginTop={1} marginBottom={1}>
    <img src="/assets/images/web/pay.png" alt="" height="240px" width="420px" />
    <Typography variant="h6" color="textPrimary" >
     <strong> Hi {userData.fname} </strong>
    </Typography>
     <Box marginBottom={2}>
   <Typography variant="subtitle1" color="primary">
  {msg.toLowerCase() === 'paid' || msg.toLowerCase() === 'already paid' ? 'Your payment is confirmed.' : msg}
    </Typography>
    </Box>
    <Box marginBottom={2}>
    <Typography align="center" variant="subtitle1" color="textSecondary">
      Have a question? For any concerns or queries related to our services, reach out to{' '}
      <a href="/contact-us">us</a> or write us at{' '}
      <a href="mailto:contact@opengrowth.com" target="_blank">
        contact@opengrowth.com
      </a>.
    </Typography>
    </Box>
    <Button variant="contained" size="large" color="primary" onClick={() => history.goBack()}>
      Go Back
    </Button>
  </Box>
);

return  (
  <Card elevation={2}>
    <CardContent style={{ height: '95vh' }}>
      <Grid container alignItems="center" justify="center">
        <Grid xs={8}>
          <Title />
        </Grid>
      </Grid>
    </CardContent>
  </Card>
)};

export default MentorsPayment; 
