
import React from 'react';
import { gapi } from "gapi-script";
import { google_client_id , google_api_key } from  "../functions/users/login/Credential";

//import Drag3 from './Drag3';
//import Socket from './socket/socket_test';
//import Ck from './ch/ck';

function App() {

  /* 
    Update with your own Client Id and Api key 
  */


	 let CLIENT_ID=google_client_id
	 let  API_KEY=google_api_key;
	
	
	
  var DISCOVERY_DOCS = ["https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest"]
  var SCOPES = "https://www.googleapis.com/auth/calendar.events"

  const handleClick = () => {
	  alert("clicked")
	  console.clear();
    gapi.load('client:auth2', () => {
      //console.log('loaded client')

      gapi.client.init({
        apiKey: API_KEY,
        clientId: CLIENT_ID,
        discoveryDocs: DISCOVERY_DOCS,
        scope: SCOPES,
      })

      gapi.client.load('calendar', 'v3', () => console.log('initiated!'))

      gapi.auth2.getAuthInstance().signIn()
      .then(() => {
        
        var event = {
			
		  conferenceDataVersion:1,
		   "conferenceData": {
			"createRequest": {
				"conferenceSolutionKey": {
					"type": "hangoutsMeet"
				},
				"requestId": "JksKJJSK1KJSK"
			}
		 },
          'summary': 'Delete Event',
          'location': 'Google Meet',
          'description': 'Really great refreshments',
          'start': {
            'dateTime': '2023-09-30T09:00:00-07:00',
            'timeZone': 'America/Los_Angeles'
          },
          'end': {
            'dateTime': '2023-09-30T17:00:00-07:00',
            'timeZone': 'America/Los_Angeles'
          },
          'recurrence': [
            'RRULE:FREQ=DAILY;COUNT=2'
          ],
          'attendees': [
            {'email': 'lpage@example.com'},
            {'email': 'anshurk1@gmail.com'}
          ],
          'reminders': {
            'useDefault': false,
            'overrides': [
              {'method': 'email', 'minutes': 24 * 60},
              {'method': 'popup', 'minutes': 10}
            ]
          }
        }
   var del_request = gapi.client.calendar.events.delete({
          'calendarId': 'primary',
          'eventId': '4bb9f2f78l4srlqe9tmujm4eno',
        })

        del_request.execute(event => {
          //console.log(event)
        })
		
        var request = gapi.client.calendar.events.insert({
          'calendarId': 'primary',
		  'conferenceDataVersion': 1,
          'resource': event,
        })

        request.execute(event => {
          //console.log(event)
          window.open(event.htmlLink)
        })
		
		// "https://www.google.com/calendar/event?eid=MHRmOTIxc3I0bThvaXFsaTA5MzFjczRnNjhfMjAyMjA5MjhUMTYwMDAwWiBhbnNodXJrMUBt"
        // event id - MHRmOTIxc3I0bThvaXFsaTA5MzFjczRnNjhfMjAyMjA5MjhUMTYwMDAwWiBhbnNodXJrMUBt

        /*
            Uncomment the following block to get events
        */
        /*
        // get events
		
		*/
        gapi.client.calendar.events.get({
          'calendarId': 'primary',
          'eventId':"v6evl6uh5reoq9g9kj0dbv3gt8",
        }).then(response => {
          const events = response.result.items
          //console.log('EVENTS: ', events)
        })
        
    /*
        gapi.client.calendar.events.list({
          'calendarId': 'primary',
          'timeMin': (new Date()).toISOString(),
          'showDeleted': false,
          'singleEvents': true,
          'maxResults': 10,
          'orderBy': 'startTime'
        }).then(response => {
          const events = response.result.items
          //console.log('EVENTS: ', events)
        })
        
		*/
    
		
		
     

      })
    })
  }


  return (
   <>
		
		
		
		{/*<Homepage /> 		<Drag3 />
*/}
        <button style={{width: 100, height: 50}} onClick={handleClick}>Add Calendar Event</button>

 
   </>
  );
}

export default App;