import {
	Box, Step, StepLabel, Stepper,
	Typography
} from '@material-ui/core';
import React, { Component } from "react";
import CommunityAdd from "./CommunityAdd";
import CommunityMedia from "./CommunityMedia";



// Step titles
let labels = ["Basic Details","Upload Banner"];


 export default class StepForm extends Component {


 constructor(props) {
    super(props);

    this.state={
		activeStep:0,
		course_data:[]
		}
    }

	handleNext = (step) =>
	{
		this.setState({activeStep:step});
	}
  	
	handleSteps = (step) => {
    switch (step) {
      case 0:
        return (
          	<div className="d-flex ">
				<CommunityAdd parentCallback = {this.handleNext} />
			</div>
        );
      case 1:
        return (
          	<div className="d-flex ">
				<CommunityMedia parentCallback = {this.handleNext} />
			</div>
        );
        default:
        break;
    }
  }
   render()
  	{
		
		return (
		<>
				<Box style={{ marginTop: "20px" }}>
					<Typography variant="h6" align="center">
					 <strong> Community : {labels[this.state.activeStep]}</strong>
					</Typography>
				</Box>
				  <Stepper
					activeStep={this.state.activeStep}
					style={{ marginTop: "15px" , marginBottom: "15px" ,
					backgroundColor: '#fbfbfb'}}
					alternativeLabel
				  >
					{labels.map((label) => (
					  <Step key={label}>
						<StepLabel>{label}</StepLabel>
					  </Step>
					))}
				  </Stepper>
				  {this.handleSteps(this.state.activeStep)}
		</>
	)}
}

