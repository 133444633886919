// src/components/AllExperts.jsx

import React, { useState, useMemo } from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  Button,
  Chip,
  TextField,
  InputAdornment,
  IconButton,
  CircularProgress,
  useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupWidget, PopupButton } from 'react-calendly';
import ExpertCard from './ExpertCard';
import SearchIcon from '@material-ui/icons/Search';
import ClearIcon from '@material-ui/icons/Clear';
import { manualExperts } from './ManualExperts';
import GrowthServiceCard from './GrowthServiceCard';
import GrowthBenefitsCard from './GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp'; // Ensure this path is correct
import TrendingBlogs from './TrendingBlogs';
import FAQSection from './FaqSection';
import Slider from 'react-slick'; // Import Slider from react-slick

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Banner from './Banner';
const blogData = [
  {
      image: "https://picsum.photos/id/1/800/600",
      title: "The ultimate guide on business process optimization",
      author: "Surbhi Bapna",
      authorImage: "https://picsum.photos/id/1000/100/100",
      category: "Startup Basics",
  },
  {
      image: "https://picsum.photos/id/20/800/600",
      title: "Balancing commitment and flexibility with on-demand expertise",
      author: "OpenGrowth Content Team",
      authorImage: "https://picsum.photos/id/1001/100/100",
      category: "Industry Insights with Experts",
  },
  {
      image: "https://picsum.photos/id/48/800/600",
      title: "Optimize your remote workspace with essential tech tips",
      author: "OpenGrowth Content Team",
      authorImage: "https://picsum.photos/id/1002/100/100",
      category: "Remote Work",
  },
  {
      image: "https://picsum.photos/id/60/800/600",
      title: "Clear your IT backlog to boost business growth",
      author: "OpenGrowth Content Team",
      authorImage: "https://picsum.photos/id/1003/100/100",
      category: "Future Tech",
  },
  {
      image: "https://picsum.photos/id/180/800/600",
      title: "Leveraging AI for enhanced customer experience",
      author: "Tech Innovator",
      authorImage: "https://picsum.photos/id/1004/100/100",
      category: "Artificial Intelligence",
  },
  {
      image: "https://picsum.photos/id/239/800/600",
      title: "The rise of sustainable startups: A green revolution",
      author: "Eco Entrepreneur",
      authorImage: "https://picsum.photos/id/1005/100/100",
      category: "Sustainability",
  },
  {
      image: "https://picsum.photos/id/287/800/600",
      title: "Mastering the art of digital marketing for small businesses",
      author: "Marketing Guru",
      authorImage: "https://picsum.photos/id/1006/100/100",
      category: "Digital Marketing",
  },
  {
      image: "https://picsum.photos/id/366/800/600",
      title: "Blockchain technology: Revolutionizing supply chain management",
      author: "Blockchain Expert",
      authorImage: "https://picsum.photos/id/1007/100/100",
      category: "Blockchain",
  },
  {
      image: "https://picsum.photos/id/403/800/600",
      title: "The psychology of pricing: Strategies for startups",
      author: "Pricing Strategist",
      authorImage: "https://picsum.photos/id/1008/100/100",
      category: "Business Strategy",
  },
  {
      image: "https://picsum.photos/id/450/800/600",
      title: "Building a resilient team culture in the age of remote work",
      author: "HR Specialist",
      authorImage: "https://picsum.photos/id/1009/100/100",
      category: "Team Management",
  },
];
// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  h6font: {
    color: '#25387c',
  },
  h5font: {
    marginTop: '40px',
  },

  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
    paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',          // Enable flex layout
    flexWrap: 'wrap',         // Allow chips to wrap on desktop
    gap: theme.spacing(1),    // Space between chips
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',       // Allow wrapping on small screens
      justifyContent: 'center', // Align chips to the start
      
    },
  },
  filterChip: {
    // Existing chip styles
    // Add responsive width for small screens
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',           // Each chip takes up 50% of the container's width
      boxSizing: 'border-box',   // Include padding and border in the width
      marginBottom: theme.spacing(1), // Space between rows
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
      fontSize: '0.8rem',
    },
  },
  h6font: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  // Styles for Load More button and loading indicator
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  // Styles for the promotional card
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '400px',
    height: '100%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
      fontSize: '0.9rem'
    },
  },
}));

// AllExperts Component
const AllExperts = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendluUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10')

  // Initialize experts with manual experts data
  const [experts, setExperts] = useState(manualExperts);

  const [selectedExpert, setSelectedExpert] = useState(null);

  // Manage selected category state
  const [selectedCategory, setSelectedCategory] = useState('All');

  // Manage search query state
  const [searchQuery, setSearchQuery] = useState('');

  // Manage visible count for "Load More" functionality
  const [visibleCount, setVisibleCount] = useState(8);

  // Manage loading state when loading more experts
  const [loadingMore, setLoadingMore] = useState(false);

  // Define chip labels with corresponding filter criteria
  const chipLabels = [
    { label: 'All', filter: 'All' },
    { label: 'Growth experts', filter: 'Growth' },
    { label: 'AI experts', filter: 'AI' },
    { label: 'Fractional experts', filter: 'Fractional' },
  ];

  // Handle expert click
  const handleExpertClick = (expert) => {
    setSelectedExpert(expert);
    // Optionally, navigate to expert's detail page
    // history.push(`/expert/${expert.id}`, { expert });
  };

  // Handle chip click
  const handleChipClick = (label) => {
    setSelectedCategory(label);
    setVisibleCount(8); // Reset visibleCount when changing filters
  };

  // Handle search input change
  const handleSearchChange = (event) => {
    setSearchQuery(event.target.value);
    setVisibleCount(8); // Reset visibleCount when changing search query
  };

  // Handle clearing search
  const handleClearSearch = () => {
    setSearchQuery('');
    setVisibleCount(8); // Reset visibleCount when clearing search
  };

  // Filter experts based on selected category and search query
  const filteredExperts = useMemo(() => {
    let filtered = [];

    if (selectedCategory === 'All') {
      filtered = experts;
    } else if (selectedCategory === 'Growth experts') {
      filtered = experts.filter((expert) =>
        expert.industry.toLowerCase().includes('growth')
      );
    } else if (selectedCategory === 'AI experts') {
      filtered = experts.filter((expert) =>
        expert.industry.toLowerCase().includes('ai')
      );
    } else if (selectedCategory === 'Fractional experts') {
      filtered = experts.filter((expert) =>
        expert.category.toLowerCase().startsWith('fractional')
      );
    }

    // Apply search filter
    if (searchQuery.trim() !== '') {
      const query = searchQuery.toLowerCase();
      filtered = filtered.filter(
        (expert) =>
          expert.name.toLowerCase().includes(query) ||
          expert.about.toLowerCase().includes(query) ||
          expert.industry.toLowerCase().includes(query) ||
          expert.category.toLowerCase().includes(query)
      );
    }

    return filtered;
  }, [experts, selectedCategory, searchQuery]);

  // Determine if "Load More" button should be shown
  const canLoadMore = visibleCount < filteredExperts.length;

  // Handle "Load More" button click
  const handleLoadMore = () => {
    setLoadingMore(true);
    // Simulate API call delay; replace with actual data fetching if necessary
    setTimeout(() => {
      setVisibleCount((prev) => prev + 8);
      setLoadingMore(false);
    }, 1000);
  };

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <Banner />

      {/* Main Content Card */}
      <Card className={classes.mainCard}>
        {/* New Headings */}
        <Typography variant="h4" className={styleClasses.h4font}>
          Level up the passion in your team and boost your metrics with an experienced Growth Expert
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.h6font}`}>
          Connect with our growth experts to receive personalized advice and accelerate your business growth.
        </Typography>
        {/* End of New Headings */}

        <Typography variant="h5" className={`${styleClasses.h5heading} ${classes.h5font}`}>
          Our Growth Experts
        </Typography>

        {/* Chips and Search Bar */}
        <Box className={classes.chipsearchbox}>
          {/* Chips */}
          <Box className={`${styleClasses.filterChipsContainer} ${classes.filterChips}`}>
            {chipLabels.map((chip, index) => (
              <Chip
                key={index}
                label={chip.label}
                variant={selectedCategory === chip.label ? 'default' : 'outlined'}
                color={selectedCategory === chip.label ? 'primary' : 'default'}
                onClick={() => handleChipClick(chip.label)}
                className={`${styleClasses.filterChip} ${classes.filterChip}`} // Merge global and local chip classes
              />
            ))}
          </Box>

          {/* Search Bar */}
          <Box className={classes.searchBarBox}>
            <TextField
              variant="outlined"
              placeholder="Find an expert in a specific domain"
              value={searchQuery}
              onChange={handleSearchChange}
              className={classes.searchBar}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon color="action" />
                  </InputAdornment>
                ),
                endAdornment:
                  searchQuery && (
                    <InputAdornment position="end">
                      <IconButton
                        aria-label="clear search"
                        onClick={handleClearSearch}
                        edge="end"
                        size="small"
                      >
                        <ClearIcon />
                      </IconButton>
                    </InputAdornment>
                  ),
              }}
            />
            {/* <Button
              variant="contained"
              onClick={() => {
              }}
              className={classes.searchButton}
            >
              Search
            </Button> */}
          </Box>
        </Box>

        {/* Displaying Experts inside the same card */}
        {filteredExperts.length > 0 ? (
          <>
            <Grid
              container
              spacing={2}
              style={{
                justifyContent: 'center',
                padding: '8px 8px',
              }}
            >
              {filteredExperts.slice(0, visibleCount).map((expert, index) => (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  lg={3}
                  key={expert.id || index}
                  style={{ padding: '8px' }}
                >
                  <ExpertCard
                    expert={expert}
                    handleExpertClick={handleExpertClick}
                    context="allExperts"
                    loading={false}
                    role={null} // Adjust as necessary
                  />
                </Grid>
              ))}
            </Grid>

            {/* Load More Button */}
            {canLoadMore && (
              <Box className={classes.loadMoreButtonContainer}>
                <Button
                  variant="outlined"
                  onClick={handleLoadMore}
                  className={classes.loadMoreButton}
                  disabled={loadingMore}
                >
                  {loadingMore ? <CircularProgress size={24} color="primary" /> : 'Load More'}
                </Button>
              </Box>
            )}
          </>
        ) : (
          <Typography
            variant="h6"
            color="textSecondary"
            style={{ textAlign: 'center', padding: '32px 0' }}
          >
            No experts found matching your criteria.
          </Typography>
        )}
      </Card>

      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography
              variant="h4"
              className={`${styleClasses.h4font}`}
              style={{ color: 'white' }}
            >
              Looking for a Growth Associate?
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6font}`}>
              Let us help you drive user acquisition, boost engagement, and scale your
              business—all at a fraction of the cost of a full-time hire.
            </Typography>
            <PopupButton
                url={calendlyUrl}
                rootElement={document.getElementById('root') || undefined}
                text="Book a discovery call"
                className={`${styleClasses.individualButton} ${classes.promoButton}`}
                variant="contained"
              />
            
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
        />
      </Box>
      {/* End of Promotional Card */}

      {/* Additional Components */}
      <GrowthBenefitsCard context = 'allExpert'/>
      <TrendingBlogs blogs={blogData} />
      <FAQSection context='allexpert' />

      {/* Footer Component (if any) */}
      {/* <Footer /> */}
    </Box>
  );
};

export default AllExperts;