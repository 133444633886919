import React, { useState, Suspense } from 'react';
import { Card, CardHeader, CardContent, Grid, Tab, Tabs, Typography, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { FaUsers, FaUserFriends } from 'react-icons/fa';
import FolloweeTab from './FolloweeTab';
//import FollowerTab from './FollowerTab';
import { ViewMentors_Skeleton } from '../Skeleton';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: '100%',
  },
  global: {
    textTransform: 'capitalize',
    color: 'rgb(99, 115, 129)',
    textAlign: 'center',
    fontSize: '0.87rem',
  },
  m: {
    marginLeft: 'auto',
  },
}));

export default function CourseViewBot() {
  const classes = useStyles();
  const [value, setValue] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  const handleChange = (_event, newValue) => {
    setValue(newValue);
  };

  const handleLoading = (loading) => {
    setIsLoading(loading);
  };

  const TabPanel = ({ value, index, children }) => {
    const hidden = value !== index;
    return (
      <div role="tabpanel" hidden={hidden}>
        {!hidden && (
          <Suspense fallback={<div>Loading...</div>}>
            <div>{children}</div>
          </Suspense>
        )}
      </div>
    );
  };

  return (
    <Card>
      <CardHeader title="My Network" />
      <CardContent style={{ padding: 0 }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <Tabs value={value} onChange={handleChange} indicatorColor="primary" textColor="primary">
              <Tab
                label={
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" display="flex" alignItems="center">
                      <FaUsers fontSize="large" style={{ marginRight: '8px' }} />
                      Following
                    </Box>
                  </Typography>
                }
                className={classes.global}
              />
              <Tab
                label={
                  <Typography component="div">
                    <Box fontWeight="fontWeightBold" display="flex" alignItems="center">
                      <FaUserFriends fontSize="large" style={{ marginRight: '8px' }} />
                      My Followers
                    </Box>
                  </Typography>
                }
                className={classes.global}
              />
            </Tabs>
            <Card elevation={0}>
              <CardContent>
                <TabPanel value={value} index={0}>
                  <Suspense fallback={<ViewMentors_Skeleton card="small" />}>
					{/* <FollowerTab action="following" handleLoading={handleLoading} /> */}
					<FolloweeTab action="following" handleLoading={handleLoading} />
                  </Suspense>
                </TabPanel>

                <TabPanel value={value} index={1}>
                  <Suspense fallback={<ViewMentors_Skeleton card="small" />}>
                    <FolloweeTab action="follower" handleLoading={handleLoading} />
                  </Suspense>
                </TabPanel>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  );
}
