import {
	Box,
	Grid, 
	
	ThemeProvider, Typography
} from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	

		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center">

				
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 Refund Policy 
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" color="textSecondary"> 
					 <Box  marginTop={1.5} lineHeight={1.5}> Once enrolled for any course or taken up a mentorship program or a subscription, no refund shall be made of the candidate, student, individual or a company if they fail to avail the services on time, within a specified time, or lapses their subscription period, or any other reason. </Box>
					 </Typography> 
					
					</Box>			
		</Grid>	
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
