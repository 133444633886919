import { Avatar, Box, Button, Card, CardActionArea, CardContent, CircularProgress, Grid, TextField, ThemeProvider, Typography } from "@material-ui/core";
import { deepPurple } from '@material-ui/core/colors';
import {
    createTheme, makeStyles
} from '@material-ui/core/styles';
import DoneIcon from "@material-ui/icons/Done";
import axios from 'axios';
import React, { Component } from 'react';
import { FaCalendarAlt, FaCalendarWeek, FaRegCalendarAlt } from "react-icons/fa";
import { useHistory } from 'react-router-dom';
import Cookies from 'universal-cookie';
import { genPassword, successColor } from "../../../functions/Helper";
import { Academytheme } from "../../../functions/useStyles";
import { getCacheDetails } from "../../caching";

import Appbar from "../../../functions/homepage/wrapper/Appbar";
import Footer from "../../../functions/homepage/wrapper/Footer";

import LearnTheArt from "./LearnTheArt";
import WhyToAttend from "./WhyToAttend";




const cookies = new Cookies();

const useStyles = makeStyles((theme) => ({
  box:{position:'absolute',bottom:'15%',left:'7%'},
  root: {
    flexGrow: 1,
	minHeight:"100%",
	'background-color':'white'
	
  },
  paper: {
  'border-radius':"12px",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: 
  {
	'border-radius':"12px !important",
	position:'relative',
	backgroundColor:'rgb(0 0 0 / 75%)',
	width:"100%",height:"400px"
 },
  img: {	
	'border-radius':'12px',
	margin: 'auto',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	width: 'inherit',
	height: 'inherit',
  },
  card: {
 
   padding: '16px',
   margin: '16px 24px 16px 16px',
  
  }, 
  card1: {
 
  // padding: '16px',
   margin: '16px 0px 16px 0px',
  
  },  
   avatar: {
 
   height: '170px !important',
   width: '170px !important',
   border:'2px solid #eee !important',
	'background-color': '#254180 !important'
  
  },
   avatarog: {
 
   height: '130px !important',
   width: '130px !important',
   border:'2px solid #eee !important',
	'background-color': '#FCCD05 !important'
  
  },
  
  avatarInner: {
 
   height: '130px !important',
   width: '130px !important',
      border:'3px solid #eee !important',
  
  },
   footer:{backgroundColor:'#4B74E7  !important'},
    
	large: {
		width: theme.spacing (8),
		height: theme.spacing (8),
	    'background-color': '#FCCD05 !important'
	  },
}));

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
	MuiDialog:{paperWidthXs: {
    'border-radius': '12px'}}
  },
});

let webinar_id='';
let seconds='';

let status=null;
if([null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}
class AccordionBlock extends Component {
    constructor(props) {
    super(props);

	this.state={
        email:null,
        name:null,
        loading:false,
        msg:null,
        ip_address:null,
        speaker_list:[],
        is_upcoming:true,
        is_webinar_found:'no',
        
        speaker_data:[],
        webinar_data:[],
        
        loading :false,
    }}
    
  
	componentDidMount()
	{
       

        var webinar_path=window.location.pathname
        // how-to-engage-an-online-community?
        
      



        var webinar_id=webinar_path.substr(21)
        if (webinar_id!='')
        {
            this.setState({is_webinar_found:'yes'})
            
            let webinar_nm = webinar_id.toLowerCase();
            webinar_nm = webinar_nm.replaceAll(" ", "-");
           
            
            this.getWebinarID(webinar_nm)
            

        }
        else
        {
            this.setState({is_webinar_found:'yes'})
            this.getWebSpeaker()
            this.checkTimer()

        }
        //alert(webinar_id)
        if(localStorage.getItem('login_token')=='admin')
        {
            window.location='/schedule-webinar'
        }
        this.getIpAddress()
        //alert(genPassword())
        

	}
    	
    getWebSpeaker = () => {
        
        
        let data={
            'key':"webinar_speakers",
            'user':"speakers",
        }
        
        getCacheDetails("getWebinarSpeaker",data)
		.then(data =>  {
         this.setState({speaker_data:data})
		 //console.log(data)
		})
        
				
	};
	checkTimer = () => {
		
		axios.post('/api/checkTimer').then((response) => {
			this.setState({
                 webinar_id:response.data['webinar_id'],
                 webinar_data:response.data,
            })
			//console.log(response.data)
		    this.check_text_status(response.data)
		});
	};
	 addStr(str, index, stringToAdd){
	  return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
	}
    check_text_status = (t) => {
		
			//if(Date.parse(t['willStartOn'])>Date.parse(t['timeNow']))
			let d=(new Date()).toString()
			d= d.substr(0, d.indexOf('+'));
			d=this.addStr(d,3,',')
			//console.log(d)
			//console.log(t['willStartOn'])
				
			if(Date.parse(t['willStartOn'])>Date.parse(d))
			{
				this.setState({is_upcoming:false},()=>{console.log(this.state.is_upcoming)})
			}
			else
			{
			}
			
	}
    getWebinarID = (webinar_nm) => {
		axios.post('/api/getWebinarID',
        {webinar_nm:webinar_nm})
		.then((response) => {
            this.getWebDetails(response.data)

		});		
	};
    
        getWebDetails = (webinar_id) => {

        let data={
            'key':"webinar_detail_"+webinar_id,
            'webinar_id':webinar_id,
            'user':"speakers",
        }
        
        getCacheDetails("getWebinarDetails",data)
		.then(data =>  {
         if(data['isValidID']=='yes')
         {
            this.setState({
                speaker_data:data['speaker_data'],
                webinar_data:data['webinar_data'],
                webinar_id:webinar_id,
            })
			this.check_text_status(data['webinar_data'])
         }
         else
         {
             this.setState({is_webinar_found:'no'})
         }
		 //console.log(data)
		})
        
	};

  
	validateForm = () => {
		if(this.state.email!=null && this.state.name!=null )
        {
            return true;
        }
        else
        {
            return false;
        }
	};
	
	getIpAddress = () => {
		fetch('https://ipapi.co/json/').then(res => res.json()).then((data) => {
		 this.setState({ ip_address: data['ip'] })
		 this.state.ip_address=data['ip']
		 //alert(this.state.ip_address)
		}).catch(console.log)
	}
	
	
	verifyUser = () => {
        this.setState({loading:true})
		let isValid=this.validateForm();
        if(isValid==false)
        {
            this.setState({msg:'All the fields are required.' })
            this.setState({loading:false})

        }   
        else
        {
            let k=(this.state.name).split(" ");
            axios.post('/api/saveWebinarUser',
            {
                'name':this.state.name,
                'fname':k[0],
                'email':this.state.email,
                'webinar_id':this.state.webinar_id,
                'page':window.location.pathname,
                'ip':this.state.ip_address,
                'password':genPassword(),
                'user':'speakers',
                'loc':'landing',
            })
			.then((response) => {
                //console.log(response.data)
                this.setState({loading:false})
                if(response.data=='not found')
                {
                    //this.setState({msg:"Email doesn't exists!"})
                    //cookies.set('visitor_email', this.state.email, { path: '/' });
                    //cookies.set('visitor_name', this.state.name, { path: '/' });
                    ////console.log(cookies.get('visitor_email')+' '+cookies.get('visitor_name')); // Pacman

                    sessionStorage.setItem("visitor_email",this.state.email);
                    sessionStorage.setItem("visitor_name",this.state.name);
                    //console.log(sessionStorage.getItem("visitor_email")+' '+sessionStorage.getItem("visitor_name"))

                    setTimeout(() => { window.location = '/webinar-register/'+this.state.webinar_id;}, 1000);
                    
                }
                else 
                {
                    // this.setState({msg:"Already registered!"})
                     setTimeout(() => { 
                     this.props.history.push({
                          pathname:'/webinar-user',
                          state: {
                                webinar_id:this.state.webinar_id,
                          },
                        })
                     }, 1000);
                }                    
            });
        }    
        setTimeout(() => {this.setState({msg:null})}, 2000);
	};
	
	
    
	render(){
	const classes = this.props.classes;
    const history = this.props.history;
    const cookies = this.props.cookies;

	const reasons = [
	 {label: "Learn how to get more people to join a community?",icon: DoneIcon},
	 {label: "Get insights on how to engage a thriving online community",icon: DoneIcon},
	 {label: "Find how to keep members active over time",icon: DoneIcon},
	 {label: "Perks of winning super exciting Freebies",icon: DoneIcon},
	 {label: "A chance to interact with leading experts",icon: DoneIcon}
	 ];
	  
      let data=[
        {time:this.state.webinar_data['a_IST'], loc:"(Mumbai)",icon:FaRegCalendarAlt},
        {time:this.state.webinar_data['a_PST'],loc:"(Los Angeles)",icon:FaCalendarWeek},
        {time:this.state.webinar_data['a_GMT'], loc:"(London)",icon:FaCalendarAlt},
		]
      
	const styles={
		image: {
			position:'relative',
			 width:"100%",height:"100%",
			 borderRadius:'25%'
		  },
		 
		  img: {
			margin: 'auto',
			display: 'block',
			maxWidth: '100%',
			maxHeight: '100%',
			width: 'inherit',
			height: 'inherit',
		  },
	}	
	
    	
        const TimeCard = ({loc,time,icon:Icon}) => {
        return (
  
           
					<Grid item xs={12} sm={4}>
					<Card elevation={1} style={{height:"100%"}}>
					
					<CardActionArea style={{height:"100%"}}>
					<CardContent >
					
					 
					<Box  display="block" textAlign="center" >
					
					<Box marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.large}  >
                    <Icon fontSize="30" />
                    </Avatar>
					</Box>
					
					<Typography  variant="subtitle1" 
                    color="textPrimary" > 
					 <Box marginTop={1.8}
					  fontWeight="600" lineHeight={1.5} >
					 {time}
					 </Box>
					 </Typography>
					 
					 
					 <Typography  variant="subtitle1" color="textSecondary" > 
					 <Box  lineHeight={1.5}>
					 {loc}
					 </Box>
					 </Typography>
					 
					 
					</Box>
					</CardContent>
					</CardActionArea>
					</Card>
					</Grid> 
    )}	
	const TextD = ({text}) => {
	return (
            <Box lineHeight={1.3} marginRight={1}  marginLeft={1} >
              <Typography
				className={"MuiTypography--subheading"}
				variant="subtitle1"
				color="textSecondary">
                {text}
               </Typography>
              </Box>
    )}
    
	const WebinarTitle = () => {
	return (
	<Box marginTop={8} >
	{
		this.state.is_upcoming==true?
		<Box justifyContent= 'center' textAlign="center" display="flex"  marginBottom={1}>
			<Typography
				className={"MuiTypography--subheading"}
				variant="subtitle1"
				color="textSecondary">
				{("Subscribe to watch our webinars") }
			</Typography>

        </Box>
		:''
	}
		<Box  paddingRight={8}  paddingLeft={8}
		justifyContent= 'center' textAlign="center" display="flex" marginTop={0} marginBottom={1}>
			<Typography variant="h4" color="primary" >
			{this.state.webinar_data['webinar_title']}
			</Typography>
        </Box>
	</Box>
    )}
	const WebinarSchedule = () => {
	return (
	
			<Box marginBottom={5}  
            display="flex"  justifyContent= 'center' textAlign="center" 
                flexDirection="row">
			  
                <TextD text={this.state.webinar_data['a_landing_new']} />
                <TextD text={this.state.webinar_data['a_IST']} />
                <TextD text={"|"} />
                <TextD  text={this.state.webinar_data['a_GMT']} />

			</Box>
    )}

	const IntroCard = ({img,name,designation,desc}) => {
        
       desc=(String(desc)).split("&#013;");

	return (
	
			
            <Box  display="block" textAlign="center" justifyContent="center"  padding={4}>
					
					<Box marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.avatar}>
					<Avatar  className={classes.avatarInner}
					src={'/assets/images/speakers/'+img} />
					</Avatar>				
					</Box>
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={2} lineHeight={1.5}>{name}</Box>
					 </Typography> 
					
					 <Typography  variant="subtitle1" color="textSecondary" gutterBottom > 
					 <Box lineHeight={1.5} > 
                         {designation}
                     </Box>
					 </Typography> 
					
					 <Typography  variant="subtitle1" color="textSecondary" gutterBottom > 
				 
                     <Box lineHeight={1.5} marginTop={2}> 
                        {
                          desc.map((e,index) => (
                            
							<Typography  variant="subtitle1" color="textSecondary" style={{lineHeight:'1.5rem'}}> 
                            <Box marginBottom={2}> 
							{e}
							 </Box>
                            </Typography> 
							
                        ))}
                     </Box>
					 </Typography> 
					
					</Box>
    )}
	const CalenderCard = () => {
	return (
	<>
	     <Box marginBottom={1}  marginTop={2} display="flex" textAlign="center" justifyContent="center" >
            {/*  <Avatar  className={classes.avatarog} >
              <FaRegCalendarAlt fontSize="80"/>
            </Avatar>		*/}		
              </Box>
                           
		  <Card elevation={0}  >
		  <CardContent style={{padding:'0px 64px 8px 64px'}}>
		  <Grid container spacing={3}>

			{
				data.map(({time,loc,icon})=> {
				return (	

					 <TimeCard time={time} loc={loc} icon={icon} />
                     
				)})	
			}
			
		</Grid>
		</CardContent>
		</Card>
    </>   
    )}	
   
   
    const Error = () => {
	return (
   <Grid item xs={12} sm={8}>
            <Box  display="block" textAlign="center" justifyContent="center" 
            marginTop={8} fontWeight="700">
            <Typography  variant="h2" color="primary"> 
            Not Found!
			</Typography>
			</Box>   
            <Box  display="block" textAlign="center" justifyContent="center" 
            marginBottom={8} fontWeight="700">
            <Typography  variant="h6" color="textSecondary"> 
            Invalid Webinar URL!
			</Typography>
			</Box>
     </Grid>
    )}
    
    const WebinarDesc = () => {
	return (
     <>  
             {
		
                this.state.speaker_data.map((e, index) => ( 
                <Grid item xs={12} sm={9}>       		
                <IntroCard 
                img={e['speaker_img']}
                name={e['speaker_name']}
                designation={e['speaker_desc']}
                desc={e['speaker_desc_para']}
                />	    
                </Grid>	  
               ))

               }
               
            
              <Grid item xs={12} sm={8}>
                    <CalenderCard />
              </Grid>
            
              <Grid item xs={10} sm={8}>
                    <WhyToAttend attend_content={this.state.webinar_data['attendC']} 
                    webinar_id={this.state.webinar_data['webinar_id']} />
              </Grid>
            
              <Grid item xs={10} sm={8} >
                    <LearnTheArt 
                     learn_title={this.state.webinar_data['learnT']}
                     learn_content={this.state.webinar_data['learnC']}
                     webinar_id={this.state.webinar_id}
                     />
              </Grid>
            </>
    )}
	
       return(
         <ThemeProvider theme={Academytheme}>
        <div className={classes.root}  id="register">
                
        <Grid container 
		direction="row"
			  alignItems="center"
			  justify="center" spacing={0}>
            
	 
             {
                this.state.is_webinar_found=='no'
                ?<Error/>
                :
                <>
                <Grid item xs={10}  md={12} >
                    <WebinarTitle/>
                    <WebinarSchedule />
               </Grid>	
	
	
			{
				status!=null 
				?''
				:
				<React.Fragment>
				<Grid item xs={10}  md={12} 
				container direction="row"
			  alignItems="center"
			  justify="center">
                <Grid item xs={8} md={3} >
                        <Box display={this.state.webinar_data['exceded']==false?"flex":"none"} justifyContent="center" alignItems="center"
                        margin={1}>
                        <TextField  label="Name" name="name" type="text" color="primary" variant="outlined" type="text"  onChange={event=>{this.setState({name: event.target.value })}}
                        defaultValue={this.state.name} fullWidth />
                        </Box>
                 </Grid>	
                 
                <Grid item xs={8} md={3} >
                        <Box display={this.state.webinar_data['exceded']==false?"flex":"none"} justifyContent="center" alignItems="center"
                         margin={1}>
                        <TextField  label="Email Address" name="email" type="email" color="primary" variant="outlined" type="text"  onChange={event=>{this.setState({email: event.target.value })}}
                        defaultValue={this.state.email} fullWidth />
                        </Box>
                 </Grid>       

                 </Grid>
                                  
                 <Grid item xs={10}  md={12} 
					container direction="row"
                    alignItems="center"
                    justify="center">
                   <Grid item xs={12} md={3} >       
                        <Box display={this.state.webinar_data['exceded']==false?"flex":"none"}  justifyContent="center" alignItems="center"
                        marginBottom={2} marginTop={1}>
                        <Button variant="contained" size="large"    color="secondary" style={{'text-transform': 'initial'}} disabled={this.state.loading}
                        onClick={()=>{this.verifyUser()} }>
                          {this.state.loading && <CircularProgress size={24} />}
                          {!this.state.loading && <Typography variant="subtitle1" style={{color:'white',fontWeight:'700'}} >
                            I'm interested
                            </Typography> }
                        </Button>
                        
                        </Box>
                        <Box display="flex" justifyContent="center" alignItems="center">
                        <Typography variant="subtitle1" style={{color:successColor}} >
                            {this.state.msg}
                            </Typography>
                        </Box>
                 </Grid>
                 </Grid>
                 </React.Fragment>
			  }
                 <WebinarDesc/>
       	
                 
                
                </>
                
             }
        
		</Grid>
           
		
        
		</div>
        		</ThemeProvider >

        );
}
}

export default () => {
    const classes = useStyles();
    const history = useHistory();
   
    return (
        <AccordionBlock classes={classes} history={history}  />
    )
}

  