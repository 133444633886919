import {
	Box, Card, CardContent, CardHeader, Grid, makeStyles, ThemeProvider, Typography
} from '@material-ui/core';
import React, { Component, Suspense, useContext } from 'react';
import { getCacheDetails } from '../../caching';
import { UserContext } from '../../courses/UserContext';
import {
	Account_Skeleton,AttendSkeleton
} from '../../Skeleton';
import { Academytheme } from "../../useStyles";
import axios from 'axios';
import {api } from "../../Helper";

const PopularConnects = React.lazy(() => import('../dashboard/PopularConnects'));
const FractionalExperts = React.lazy(() => import('../dashboard/FractionalExperts'));
const TimelineMentors = React.lazy(() => import('../dashboard/TimelineMentors'));
const MyMentors = React.lazy(() => import('../pages/MyMentors'));
const UpcomingWebinar = React.lazy(() => import('./webinars'));
const UpcomingWebinarDash = React.lazy(() => import('./webinars/UpcomingWebinar'));
const ArticleRead = React.lazy(() => import('../../blogs/PopularBlogs'));
const PopularCourses = React.lazy(() => import('../../dashboard/PopularCourses'));
const ConnectCard = React.lazy(() => import('./ConnectCard'));
const Scroll = React.lazy(() => import('./Scroll'));
const Mentorship = React.lazy(() => import('../dashboard/mentorship'));
const MentorsForDashboard = React.lazy(() => import('./MentorsForDashboard'));
const ViewedRecently = React.lazy(() => import('../../courses/ViewedRecently'));
// MyMentors = React.lazy(() => import('../mentors/pages/MyMentors'));

let student_id=null
let status=null
let is_mentor=null;
let mentor_req=null;
if(["admin",null,"webinar"].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_req']
	is_mentor=JSON.parse(localStorage.getItem('ac_login_user'))['is_mentor']
}

let is_mentor_count=1;
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
class StudentDashboard extends Component {
	
	constructor(props) {
    super(props);
	this.state={msg:false}
	this.getCacheDetails = getCacheDetails.bind(this);
	}
	
	getCacheProgress=()=>{
		let k=0;
		let completed=0;
		getCacheDetails(
		student_id+"_video_progress_dashboard",
		"getVideoProgress",
		[student_id,0,20])
		.then(data => {
			k=data['progress'];
			this.props.user.set_user_info((user) => ({
				progress_count:k
			}));
		
		})
	}
	updateActiveSlotCount=()=>{
		let payload={
				'mentor_id':student_id,
			}
			axios.post(api+'/update_mentors_slot_count',payload)
			.then((response) => {
			
				console.log(response)
				
			});
	}
	
	getCacheBookmark=()=>{
				
		let i=0;
		getCacheDetails(student_id+"_users_bookmarked_courses_0_to_100","getBookmarkedCourses",[0,100,student_id])
		.then(data => {	

				i=data.length;
				this.props.user.set_user_info((user) => ({
					...user,
					bookmark_count:i
				}));				
		});
	}
	
  	componentDidMount()
	{
		this.updateActiveSlotCount();
		this.getCacheProgress();
		this.getCacheBookmark();	
		if(status=='professor' && is_mentor=="mentee" && mentor_req==1)
		{
			this.setState({msg:"You experts profile activation request is in process. You will be allowed to provide mentorship services once the permission will be granted by the admin."},()=>{console.log(this.state.msg)})
		}	
			setTimeout(() => {
					this.setState({msg:false})
			}, 3000);
		
	}
	
render()
{   
	const classes = this.props.classes;
	return (
   <ThemeProvider theme={Academytheme}>
 
	<div className={classes.root}>
	
	 
	 <Box display={"none"}>
	 <Typography variant="h6" color="primary">
	 <Box fontWeight="600"> {this.state.msg}</Box>
	 </Typography>
	 </Box>
	
    <Grid container direction="row" spacing={3} >
	<Grid  xs={12} sm={12} md={status=='professor'?12:8} lg={status=='professor'?12:8}   item>
    <Grid container direction="column" spacing={3}>
    
		
		{/*status=='student' ?
			<Grid item  xs={12}>
			<Suspense fallback={<div> </div>}>
			<MyMentors  />
			</Suspense>
			</Grid>
		:""
		*/}

			<Grid item xs={12}>	
			<PopularConnects action="popular-connects" />
			</Grid>	
		
		{
			status=='professor' && is_mentor=="mentee"
			?""
			:<Grid item xs={12}>
			<Mentorship />
			</Grid>		
		}	
		{
			status=='student' && (
			<Grid item xs={12}>	
			<FractionalExperts action="" />
			</Grid>	
			)
		}
		
		{/*
			status!="professor" 
			&&
			(
			<Grid item xs={12}>	
			<ConnectCard />
			</Grid>
			)	
		
		*/}

		

		
			<Grid item xs={12}>	
			<TimelineMentors />
			</Grid>

		

			
    </Grid>
  </Grid>
  {status=='student' && (
  <Grid  xs={12} sm={12} md={4}  lg={4}    className="block"  item>
    <Grid container direction="column" spacing={3}>
     
			
			<Grid item  xs={12}>
			<Suspense fallback={<div> <Account_Skeleton/></div>}>
			<MentorsForDashboard action="connect" />
			</Suspense>
			</Grid>
			
			
			<Grid item  xs={12}>
			<Suspense fallback={<div></div>}>
			<ViewedRecently />
			</Suspense>
			</Grid>
			
			<Grid item  xs={12}>
			<Suspense fallback={<div></div>}>
			<ArticleRead />
			</Suspense>
			</Grid>
			
    </Grid>
  </Grid>)}
  	
			
</Grid>

		
	
 
    </div>
	</ThemeProvider>

  );
}}

export default (props) => {
		const user = useContext(UserContext);
	    const classes = useStyles();

    return (
        <StudentDashboard classes={classes}   user={user}   />
    )
}

  