import React,{useEffect} from 'react';
import { community_path } from '../../Helper';
import { Avatar, Card, CardContent , Grid, Typography, Box, makeStyles } from "@material-ui/core";
import { useHistory, useLocation } from "react-router-dom";
import RequestList from "./RequestList";

const useStyles = makeStyles((theme) => ({
  card: {
    elevation: 1,
    // Add any other card styles you need here
  },
  small: {
    width: "100% !important",
    height: 'auto',
    borderRadius: '12px !important',
  },
  title: {
    // Add any title text styles you need here
  }, 
  description: {
    cursor: 'pointer', 
	color: 'blue' ,
	fontSize: '14px' 
  }, 
  desc: {
	  lineHeight:'1.5rem',
	  overflow: 'hidden',
	  'text-overflow': 'ellipsis',
	  'display': '-webkit-box',
	  '-webkit-line-clamp': 3,
	  '-webkit-box-orient': 'vertical'
 }

}));

const CommunityRender = ({ communityData }) => {
  const classes = useStyles();
  const history = useHistory();


  const findCommunity = () => {
	  //alert(communityData['slug'])
    history.push({
		
      pathname: `/community/${communityData['slug']}`,
      state: communityData,
    });
  };
  const descriptionLines = communityData.description.split('\n').slice(0, 3).join('\n');

  return (
    <Card className={classes.card}>
    <CardContent>
     <Typography
        component="div"
        color="primary"
        variant="subtitle1"
        onClick={findCommunity}
      >
        <Box
          lineHeight={1.25}
          fontWeight="600"
          marginBottom={2}
          className={classes.title}
          fontSize={"20px"}
        >
          {communityData.title}
        </Box>
      </Typography>
	  
	  <Avatar
        src={`${community_path}${communityData.banner}`}
        alt={communityData.title}
        className={classes.small}
      />
	  
	  
	  <Box marginTop={2}>
		<Typography color="textSecondary" variant="subtitle1">
		  <div className={classes.desc}>
			{descriptionLines} {communityData.description.length > descriptionLines.length && '...'}
		  </div>
		  <span
			onClick={() => {
			  findCommunity(communityData, "view");
			}}
			className={classes.description}
		  >
			Know More
		  </span>
		</Typography>
      </Box>
	  

    </CardContent>
    </Card>
  );
};

const StepForm = () => {
  const location = useLocation();
  const history = useHistory();
  const communityData = location.state;
  
  useEffect(() => {
    if (!communityData) {
     
			if (history.length > 1) {
			  history.goBack();
			} else {
			  history.push('/dashboard');
			}
	}
  }, [history]);
  return (
    <Grid container spacing={2}>
      <Grid item xs={12} md={7}>
        <RequestList  community={communityData} />
      </Grid>
      <Grid item xs={12} md={5}>
        <CommunityRender communityData={communityData} />
      </Grid>
    </Grid>
  );
};

export default StepForm;
