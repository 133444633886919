import axios from 'axios'
let data=0;
export let api="/api/";

let onlyDataCall=['getWebinarSpeaker','getWebinarDetails','getReasonsList','getWebinarHost','getReplies','getWebinarList']
export function getDataOnly(url,param)
{

		return axios.post(api+url,param)
		.then(response => {
		
        
        const value = {value:response.data, ttl: ((new Date()).getTime()/1000)+2*60}  // 2 minute}
        localStorage.setItem(param.key,JSON.stringify(value));
        
        return response.data
		})
	
}
export function callFunction(funName,param)
{
		
		if (onlyDataCall.includes(funName))
		{
			return getDataOnly(funName,param).then(data => {return data});		
		}	
		
}
export function fromStorage(key)
{
	return JSON.parse(localStorage.getItem(key));

}


export function getCacheDetails(funName,param){
		if (window.localStorage)
		{
			if(localStorage.getItem(param.key)!==null) 
			{	
				/*//alert(funName+' - from localstorage')
				return Promise.resolve('key').then(function(value) {
				return JSON.parse(localStorage.getItem(param.key));
				})*/
                return callFunction(funName,param)


			}
			else
			{  
				//alert(funName+' -  local storage, coming from db')
				return callFunction(funName,param)
				  
			}

		}
		else
		{ 
			//alert(funName+'  - no local storage, coming from db')
			return callFunction(funName,param)

		}
}

export function deleteLocal(matchKeys)
{
		//alert("match key ---------- "+matchKeys)
		let localStorage_Keys=(Object.keys(localStorage)).filter(res => res.includes(matchKeys))
		//let localStorage_Keys=(['13_enrolled_courses_0_to_8','13_enrolled_courses_0_to_20','13_enrolled_courses_0_to_4','13_xyz'].filter(res => res.includes('13_')))
		//alert(localStorage_Keys)
		for(let j = 0; j < localStorage_Keys.length; j++)
		{ 
			//alert(localStorage_Keys[j]);
			localStorage.removeItem(localStorage_Keys[j]); 
		}
		return localStorage_Keys
}