import React, { Suspense, useState, useContext } from 'react';
import { Box, Grid, makeStyles, ThemeProvider } from '@material-ui/core';
import { Academytheme } from '../useStyles';

const ListCommunity = React.lazy(() => import('./ListCommunity'));
const ButtonSection = React.lazy(() => import('./ButtonSection'));
const RecommendedCommunityCard = React.lazy(() => import('./RecommendedCommunityCard'));

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
}));

const StudentDashboard = () => {
  const [msg, setMsg] = useState(false);
  const classes = useStyles();

  return (
    <ThemeProvider theme={Academytheme}>
      <div className={classes.root}>
        <Grid container direction="row" spacing={3}>
          <Grid item xs={12} sm={12} md={8} lg={8}>
            <Grid container direction="column" spacing={3}>
              <Grid
                item
                xs={12}
                container
                justifyContent="center"
                alignItems="center"
              >
                <Suspense fallback={<></>}>
                  <ButtonSection />
                </Suspense>
              </Grid>
              <Grid item xs={12}>
                <Suspense fallback={<></>}>
                  <ListCommunity />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={12} md={4} lg={4} className="block">
            <Grid container direction="column" spacing={3}>
              <Grid item xs={12}>
                <Suspense fallback={<div></div>}>
                  <RecommendedCommunityCard />
                </Suspense>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
    </ThemeProvider>
  );
};

export default StudentDashboard;
