// src/components/AboutUs.jsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';

// Import your images here
import aboutUsImage from './assets/aboutus1.jpg'; // Replace with your actual image
import missionImage from './assets/mission.png'; // Replace with your actual image
import visionImage from './assets/vision.png'; // Replace with your actual image
import useGlobalStyles from './styles/CourseStyles';

const useStyles = makeStyles((theme) => ({
  section: {
    padding: theme.spacing(4, 2),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(4, 1),
    },
  },
  cardContainer: {
    marginBottom: theme.spacing(4),
  },
  card: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(4),
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
    },
  },
  image: {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginBottom: theme.spacing(2),
    },
  },
  content: {
    width: '50%',
    paddingLeft: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
    },
  },
  smallCard: {
    marginTop: '24px',
    padding: theme.spacing(2),
    borderRadius: '8px',
    textAlign: 'center',
    height: '100%',
  },
  missionContent: {
    width: '50%',
    paddingRight: theme.spacing(4),  
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  visionContent: {
    width: '50%',  
    paddingLeft: theme.spacing(4),    
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingRight: 0,
      textAlign: 'center',
    },
  },
  missionVisionImage: {
    width: '50%',
    height: 'auto',
    borderRadius: '8px',
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      marginTop: theme.spacing(2),
    },
  },
}));

const AboutUs = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box>
      {/* First Card: About Us */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <img src={aboutUsImage} alt="About Us" className={classes.image} />
          <Box className={classes.content}>
            <Typography variant={isSmall ? 'h4' : 'h4'} className={styleClasses.h4font} gutterBottom>
              About Us
            </Typography>
            <Typography variant="h6" className={styleClasses.description}>
            At OpenGrowth, we are on a mission to empower entrepreneurs and transform their dreams into reality. We understand the challenges and aspirations that come with starting and growing a business, and our goal is to provide the necessary support and resources to help entrepreneurs thrive.
            </Typography>
            <br />
            <Typography variant="h6" className={styleClasses.description}>
            With a deep passion for fostering AI innovation and collaboration, OpenGrowth serves as a vibrant community where entrepreneurs can connect, learn, and grow together. Our platform offers a comprehensive suite of services, including expert guidance, educational resources, startup services, and a virtual collaboration platform called OpenGrowth Hub.
            </Typography>
            <br />
            <Typography variant="h6" className={styleClasses.description}>
            We believe in the power of entrepreneurship to drive positive change and create financial independence. Through our tailored solutions, we aim to equip entrepreneurs with the knowledge, skills, and connections they need to succeed. Whether you're just starting out or looking to scale your business, OpenGrowth is here to support you every step of the way.
            </Typography>
            <br />
            <Typography variant="h6" className={styleClasses.description}>
            Join us on this exciting journey and be part of a dynamic community of entrepreneurs. Together, we can unleash your full potential and shape a future of innovation, growth, and success. Welcome to OpenGrowth, where entrepreneurial dreams become a reality.
            </Typography>
          </Box>
        </Card>
      </Box>

      {/* Second Card: Our Services */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Typography variant="h4" align="center" className={styleClasses.h4font} gutterBottom>
        Create true business impact
        </Typography>
        <Typography variant="h6" align="center" className={styleClasses.description}>
        As an enthusiastic community that helps and supports people at every phase of their entrepreneurial journey. We hope to fuel the entrepreneurial spirit and help entrepreneurs achieve their goals by harnessing the power of AI.
        </Typography>
        <Grid container spacing={4}>
          <Grid item xs={12} sm={4}>
            <Card className={classes.smallCard} elevation={2}>
              
              <Box mt={2}>
                <Typography variant="h5" gutterBottom className={styleClasses.h5heading}>
                Build strategic goals
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className={styleClasses.subtitlefont}>
                Our expert panel of business leaders and mentors share their expertise to help entrepreneurs acquire a comprehensive perspective on their strategic goals and business models.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.smallCard} elevation={2}>
              
              <Box mt={2}>
                <Typography variant="h6" gutterBottom className={styleClasses.h5heading}>
                Scale your venture
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className={styleClasses.subtitlefont}>
                We support innovators and risk-takers to walk that extra mile and scale their ventures. Our mentors guide entrepreneurs develop a growth mindset so that they are empowered to make the most of every opportunity and strive towards growth.
                </Typography>
              </Box>
            </Card>
          </Grid>
          <Grid item xs={12} sm={4}>
            <Card className={classes.smallCard} elevation={2}>
              
              <Box mt={2}>
                <Typography variant="h6" gutterBottom className={styleClasses.h5heading}>
                Create a happy work environment
                </Typography>
                <Typography variant="subtitle1" color="textSecondary" className={styleClasses.subtitlefont}>
                Our mantra is “Do Good. Do Well.” We understand a venture’s goals and guide entrepreneurs to establish personal accountability while creating a successful team.
                </Typography>
              </Box>
            </Card>
          </Grid>
        </Grid>
      </Box>

      {/* Mission Card */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <Box className={classes.missionContent}>
            <Typography variant={isSmall ? 'h4' : 'h4'} gutterBottom className={styleClasses.h4font}>
              Our Mission
            </Typography>
            <Typography variant="h6"  className={styleClasses.description}>
            At OpenGrowth, we aim to empower AI startups to scale and thrive by connecting them with a dynamic network of growth experts and fractional executives. We are committed to shaping the future of work and innovation through curated resources and courses that enhance entrepreneurial skills and drive success.
            </Typography>
            <br />
            <Typography variant="h6"  className={styleClasses.description}>
            We foster a collaborative environment where startups gain the tools, insights, and support they need to navigate the challenges of AI-driven growth. Through the OpenGrowth Hub, we streamline teamwork and boost productivity with cutting-edge technology and expert-led services, all tailored to the unique needs of emerging AI businesses.
            </Typography>
            <br />
            <Typography variant="h6"  className={styleClasses.description}>
            Join us in shaping the leaders of tomorrow, today, and unlocking the full potential of AI-driven innovation.
            </Typography>
          </Box>
          <img src={missionImage} alt="Our Mission" className={classes.missionVisionImage} />
        </Card>
      </Box>

      {/* Vision Card */}
      <Box className={`${classes.section} ${classes.cardContainer}`}>
        <Card className={classes.card} elevation={3}>
          <img src={visionImage} alt="Our Vision" className={classes.missionVisionImage} />
          <Box className={classes.visionContent}>
            <Typography variant={isSmall ? 'h4' : 'h4'} gutterBottom className={styleClasses.h4font}>
              Our Vision
            </Typography>
            <Typography variant="h6"  className={styleClasses.description}>
            OpenGrowth envisions a future where on-demand expertise and fractional hiring redefine the modern workplace, becoming essential drivers of business innovation and growth. We aim to create a vibrant, interconnected ecosystem where every aspiring startup founder can access the knowledge and insights of experienced professionals, making success more attainable in today’s dynamic economy.
            <br /> <br /> 
            By championing collaboration, empowerment, and excellence, OpenGrowth is setting a new standard for building, innovating, and leading in the AI-powered future of work. We are committed to reshaping how businesses scale, ensuring that flexibility and expertise are at the heart of the next generation of entrepreneurial success.
            </Typography>
          </Box>
        </Card>
      </Box>
    </Box>
  );
};

export default AboutUs;
