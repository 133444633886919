import React, {  } from 'react'
import { Avatar,
		Box,
		Card,CardContent,CardActionArea,CardMedia,Chip,
		IconButton,
		
		Grid,
		Link,makeStyles,Typography 
} from "@material-ui/core";
import { useHistory } from 'react-router-dom';

import "react-multi-carousel/lib/styles.css";
import VisibilityOutlinedIcon from '@material-ui/icons/VisibilityOutlined';
import ShareOutlinedIcon from '@material-ui/icons/ShareOutlined';
import QuestionAnswerOutlinedIcon from '@material-ui/icons/QuestionAnswerOutlined';
import { og_path } from "../Helper";




const useStyles = makeStyles(() => ({
  details: { 	   overflow: "hidden",display: "-webkit-box",
					WebkitLineClamp: 3,WebkitBoxOrient: "vertical",height:'60px', },
	 cd :{
		 "&:hover": {
			transform: 'scale(1.02)',
		//'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
		'box-shadow': '0px 5px 3px -2px rgb(0 0 0 / 20%), 0px 5px 4px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

	 }},
	 		media: {
			height: 0,
			paddingTop: '64.25%', // 16:9
		  },  
		   card: {position:"relative"},
		   overlay: {
			  position:"absolute",  borderRadius:'12px', fontFamily:"Be Vietnam, sans-serif",backgroundColor: 'rgba(0, 0, 0, 0.5)',color: 'white',width: '100%',height: '100%',top:'0%',padding:'8px'
		   },
		  
		 	inner: { 	position:'relative',width: '100%',height: '100%',top:'0%'},
		 	center: { 	position:'absolute',top: "33%",left:"27%"  },
			bot1: { 	position:'absolute',bottom: "20%",left:"0%"  },
			bot2: { 	position:'absolute',bottom: "0px",left:"0%" },
			right: { 	position:'absolute',bottom: "0px",right:"0%" },
			top: { 	position:'absolute'},
			details: { 	   overflow: "hidden",display: "-webkit-box",
					WebkitLineClamp: 3,WebkitBoxOrient: "vertical" },
			small: {
				width: '52px !important',
				height:  '52px !important',	
				border: '2px solid #d8d8d8'
				},
				
}));

let og_media="https://www.opengrowth.com/assets/uploads/images/co_brand_1/article/"


function Vertical_Cards(props) {
  const [, forceUpdate] = React.useState(0);
  const [blogs, setBlogs] = React.useState(props.blogs);
  const history = useHistory();

  const classes = useStyles();

  
 	const get_blogs = (blog_tag) => {
	
		 var tag=(blog_tag.trim()).replaceAll('"',""); 
		 history.push({
		  pathname:'/channels',
		  state: {
				category:tag,
		  },
		})
		 
	}
	 
	const view_blogs = (blog) => {
		history.push({
		pathname: "/blogs",
		  state: {
				article:blog
		} })	
	}
  const RenderCard=({article})=>{
			
	  		let blog_content=null
			let cover_image=null
			let category=null
			let published_on=null
			let title=null
			let slug=null
			let description=null
			let total_shares=null
			let total_comments=null
			let author_img=null
			let author_name=null
			let total_viewed=null
			if(article!=undefined)
			{
				blog_content=article['content']
				author_name=article['first_name']+" "+article['last_name']
				author_img=og_path+'assets/uploads/images/co_brand_1/users/small/'+article['photo_name']
				title=(article['title']).replaceAll(/[\\\/"]/g, '')
				slug=article['slug']
				cover_image=article['cover_image']
				category=article['category']
				published_on=article['article_date']
				description= (article['meta_description']).replaceAll(/[\\\/"]/g, '')
				total_shares=article['total_shares']
				total_comments=article['total_comments']
				total_viewed=article['total_viewed']
				
			}
			let old_text="src=\"\/assets";
			let new_text='src=\"\https:www.opengrowth.com/assets';
			if(blog_content!=undefined)
			{
				blog_content=blog_content.replaceAll(old_text,new_text)
				blog_content=blog_content.replaceAll("<p>Let us know your opinion in the comment section.<\/p>\r\n\r\n<p>&nbsp;<\/p>\r\n\r\n<p>We, at&nbsp;<a href=\"https:\/\/www.opengrowth.com\/\" target=\"_blank\">OpenGrowth<\/a>, are continually looking for trending startups in the ecosystem.&nbsp;<\/p>\r\n\r\n<p>If you want to know any further information about the startup ecosystem or have any mind-boggling ideas, do refer to the other blogs at&nbsp;OpenGrowth. If you have any suggestions, do let us know in the comment section below.<\/p>\r\n",'')
			
				////console.log(blog_content)
			}
  return (
				
			<Box margin={0.5}>
			<Card elevation={3}  className={classes.cd} 
			style={{height:"100%"}}>
			
			<CardActionArea >
				<CardMedia
				className={classes.media}
				image={og_media+cover_image} />
					
						<Box  className={classes.overlay} padding={1} >
						<Box  className={classes.inner} padding={1} >


							<Typography component="div" variant="subtitle1">
								<Box display="inline"  fontWeight="fontWeightBold"  className={classes.top} lineHeight={1.2}    textAlign="center" alignItem="center" >
								{title}
								</Box>
								</Typography>
										

								
								<Chip 
								 onClick={()=>{get_blogs(category)}}
								color="secondary" className={classes.chip}
								size="small"   label={category} 
								clickable  className={classes.bot2}  />
								
								
								</Box>
								</Box>
						  
					</CardActionArea>
					
				<CardContent  className={classes.card} style={{height:'100%'}}>
				<Grid container spacing={0} direction="row"
                 alignItems="center" justify="center">
				
					<Grid
					 item xs={12} 
					  container
					  direction="column"
					  justifyContent="space-between"
					>
					<Grid item xs={12}>
					<Box display="flex"
					alignItems="center"
					marginBottom={2}>
							
							<Avatar 
							src={author_img}
							 className={classes.small} />
							
							<Box marginLeft={2}>
								<Typography color="primary" variant="subtitle1">
								<Box  lineHeight={1.25} fontWeight="600">
								{author_name}
								</Box></Typography>
								
								<Typography color="textSecondary" variant="subtitle2">
								{published_on}
								</Typography>
							</Box>
							
					</Box>
					</Grid>
					 					
					<Grid item xs={12}>
					    <Typography color="textSecondary" variant="body2" className={classes.details}>
						 {description}
						 </Typography> 
						 <Link
						 style={{cursor:'pointer'}}
						 onClick={()=>view_blogs(article)} >
						 <Typography variant="body2" >
						 Read More 
						 </Typography>
						 </Link>
					 </Grid>
					 
					 <Grid item xs={12}>
					 <Box
					 marginTop={2}
					  style={{
					  display: 'flex',
					  justifyContent: 'space-between',
					 }}>
					
					<IconButton > 
					<Box marginRight={0.5} ><ShareOutlinedIcon  /></Box>
					<Typography variant="subtitle2" color="textSecondary">
					{total_shares}
					</Typography>
					</IconButton>
				
				
					<IconButton aria-label="share">
					<Box marginRight={0.5} ><QuestionAnswerOutlinedIcon   /></Box>
					<Typography variant="subtitle2"  color="textSecondary">
					{total_comments}
					</Typography>
					</IconButton>
					
					<IconButton aria-label="share">
					<Box marginRight={0.5} ><VisibilityOutlinedIcon  /></Box>
					<Typography variant="subtitle2"  color="textSecondary">
					{total_viewed}
					</Typography>
					</IconButton>
					
				
					
					</Box>
					</Grid>
					
				</Grid>
				
				
			
				</Grid>
				</CardContent>
				</Card>
				</Box>
						
					
  )} 


 
  
    return (
		<RenderCard article={blogs} />
		
  );
}

export default Vertical_Cards;