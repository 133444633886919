import React, { Component, Suspense } from 'react';
import SubscriptionAdd from './SubscriptionAdd';
import SubscriptionList from './SubscriptionList';



const location= window.location.pathname;

class Subscription extends Component {

    constructor(props) {
    super(props);
	}
	

	
  	render()
  	{
	

	return (
	    < >

		{
			window.location.pathname=='/subscription/add' 
				?<Suspense fallback={<div> </div>}><SubscriptionAdd />  </Suspense>
				:
					window.location.pathname=='/subscription/view'
					?<Suspense fallback={<div> </div>}><SubscriptionList /></Suspense>
					:<Suspense fallback={<div> </div>}><SubscriptionAdd /></Suspense>
		}
		</  >  
		

	    );
  	}
}
export default () => {
    return (
        <Subscription />
    )
}

  