// src/components/GrowthStudioHome.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
  InputAdornment,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert_banner2.png';

import bannerImage from '../assets/Contentmarketing.png';
import growthstudiovideo from '../assets/Contentmarketing.mp4';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import BenefitsCard from './BenefitsCard';
import FAQSection from '../Experts/FaqSection';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  // Updated banner styles
  bannerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: 'none',
    paddingLeft: '0rem',
    borderRadius: "4px",
    alignItems: 'flex-start',
    overflow: "hidden",
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: "auto",
      flexDirection: 'column', // Ensure vertical stacking on small screens
      alignItems: 'left',
    },
  },
  imageBox: {
    width: '100%',
    height: '500px', // Adjust the height as needed
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      height: '900px' // Adjust height for smaller screens
    },
    position: 'relative',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  contentOverlay: {
    position: 'absolute',
    top: 0,
    left: 0,
    height: '100%',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(37, 56, 124, 0.8)', // Optional: Add a slight overlay for better text visibility
    [theme.breakpoints.down("sm")]: {
      backgroundColor: 'rgba(37, 56, 124, 0.8)',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: theme.spacing(0, 2),
    },
  },
  contentBox: {
    backgroundColor: 'transparent',
    color: "white", // Adjust text color based on image
    marginTop: 32,
    maxWidth: '60%', // Adjust as needed

    paddingRight: theme.spacing(4),
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100% !important',
      width: '100%',
      textAlign: 'left',
      marginTop: theme.spacing(0),
      paddingRight: 0,
    },
  },
  formBox: {
    backgroundColor: 'rgba(255, 255, 255, 0.9)',
    borderRadius: '8px',
    padding: theme.spacing(2), // Reduced padding
    maxWidth: '25%', // Reduced width from 30% to 25%
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
      marginTop: theme.spacing(2),
    },
    boxShadow: '0 4px 8px rgba(0,0,0,0.2)',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center', // Center the form elements
  },
  bannerButton: {
    marginTop: theme.spacing(1), // Reduced margin-top
    borderRadius: "50px",
    backgroundColor: "#f9bb02",
    color: "black",
    fontWeight: 600,
    fontSize: '0.9rem', // Slightly smaller font
    padding: '6px 16px', // Reduced padding
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#f9bb02",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      padding: "6px 12px",
    },
  },
  // New styles for smaller TextFields
  textField: {
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.5em', // Reduced height
      padding: '0 12px', // Reduced horizontal padding
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.8rem', // Smaller font size
    },
  },
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-4), // Adjust to overlay on the image
    marginBottom: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4, // Higher z-index to appear above image and content
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: theme.spacing(-2),
    },
  },
  h3font: {
    fontWeight: "500",
    color: '#f9bb02',
    border: 'none'
  },
  h6fontheading: {
    color: '#fff',
  },
  h4font: {
    padding: '8px 12px',
    fontWeight: "600",
    fontSize: '49px',
    border: 'none'
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  filterChip: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',
      boxSizing: 'border-box',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
    },
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '300px',
    height: '50%',
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // New styles for the video section
  videoContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
    },
  },
  video: {
    width: '100%',
    maxWidth: '100%',
    height: 'auto',
    borderRadius: '8px',
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
    },
  },
}));

// BannerSlide Component
const BannerSlide = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

  // State for subscribe modal
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
  const [isLoading, setIsLoading] = useState(false); // Loading state

  // States for Contact Us form
  const [fullName, setFullName] = useState('');
  const [businessEmail, setBusinessEmail] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [organization, setOrganization] = useState(''); // New State for Organization
  const [message, setMessage] = useState('');
  const [formStatus, setFormStatus] = useState(null); // null, 'success', 'error'
  const [isFormLoading, setIsFormLoading] = useState(false);

  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    setSubscriptionStatus(null);
    setEmail('');
    setIsLoading(false);
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      // TODO: Replace with your actual subscription logic (e.g., API call)
      // Example using fetch:
      /*
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error('Subscription failed');
      }
      */

      // Simulating successful subscription
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate network delay
      setSubscriptionStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  // Handle Contact Us form submission
  const handleContactSubmit = async (e) => {
    e.preventDefault();
    setIsFormLoading(true);
    try {
      // TODO: Replace with your actual contact form submission logic (e.g., API call)
      // Example using fetch:
      /*
      const response = await fetch('/api/contact', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ fullName, businessEmail, phoneNumber, organization, message }),
      });
      if (!response.ok) {
        throw new Error('Form submission failed');
      }
      */

      // Simulating successful form submission
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate network delay
      setFormStatus('success');
      setFullName('');
      setBusinessEmail('');
      setPhoneNumber('');
      setOrganization('');
      setMessage('');
    } catch (error) {
      console.error('Form submission error:', error);
      setFormStatus('error');
    } finally {
      setIsFormLoading(false);
    }
  };

  return (
    <>
      <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
        {/* Banner Image */}
        <Box className={classes.imageBox}>
          <img
            src={bannerImage}
            alt="Growth Studio Banner"
            className={classes.bannerImage}
          />

          {/* Content Overlay */}
          <Box className={classes.contentOverlay}>
            <Box className={classes.contentBox}>
              <Typography
                variant={isSmall ? "h4" : "h1"}
                gutterBottom
                className={`${styleClasses.h3font} ${classes.h3font}`}
              >
                Startup Services to Build, Engage, and Elevate
              </Typography>
              <Typography
                variant={isSmall ? "h6" : "h6"}
                gutterBottom
                className={`${styleClasses.h6banner} ${classes.h6fontheading}`}
              >
                From Content Writing for AI and Digital Marketing to Brand Building and Expert Community, our services ensure your brand shines online, keeping your audience informed and
                Engaged.

              </Typography>
            </Box>

            {/* Contact Us Form */}
            <Box className={classes.formBox}>
              <Typography variant="h6" gutterBottom className={styleClasses.h6font} align='center'>
                Contact Us
              </Typography>
              <form onSubmit={handleContactSubmit} style={{ width: '100%' }}>
                <Grid container spacing={1}> {/* Reduced spacing from 2 to 1 */}
                  <Grid item xs={12}>
                    <TextField
                      label="Full Name"
                      variant="outlined"
                      fullWidth
                      required
                      value={fullName}
                      onChange={(e) => setFullName(e.target.value)}
                      aria-label="Full Name"
                      size="small" // Added size
                      className={classes.textField} // Applied custom styles
                      margin="dense" // Reduced margin
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Business Email"
                      type="email"
                      variant="outlined"
                      fullWidth
                      required
                      value={businessEmail}
                      onChange={(e) => setBusinessEmail(e.target.value)}
                      aria-label="Business Email"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Phone Number"
                      variant="outlined"
                      fullWidth
                      required
                      value={phoneNumber}
                      onChange={(e) => setPhoneNumber(e.target.value)}
                      aria-label="Phone Number"
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            +1 {/* You can integrate a country code selector if needed */}
                          </InputAdornment>
                        ),
                      }}
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  {/* Organization Field Placed Before Message */}
                  <Grid item xs={12}>
                    <TextField
                      label="Organization"
                      variant="outlined"
                      fullWidth
                      required
                      value={organization}
                      onChange={(e) => setOrganization(e.target.value)}
                      aria-label="Organization"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      label="Message"
                      variant="outlined"
                      fullWidth
                      required
                      multiline
                      rows={2} // Increased number of rows for taller text box
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      aria-label="Message"
                      size="small"
                      className={classes.textField}
                      margin="dense"
                    />
                  </Grid>
                  {isFormLoading && (
                    <Grid item xs={12}>
                      <Box display="flex" justifyContent="center">
                        <CircularProgress size={24} /> {/* Reduced size */}
                      </Box>
                    </Grid>
                  )}
                  {formStatus === 'success' && (
                    <Grid item xs={12}>
                      <Typography color="primary" align="center">
                        Your message has been sent successfully!
                      </Typography>
                    </Grid>
                  )}
                  {formStatus === 'error' && (
                    <Grid item xs={12}>
                      <Typography color="error" align="center">
                        Failed to send your message. Please try again.
                      </Typography>
                    </Grid>
                  )}
                  <Grid item xs={12}>
                    <Button
                      type="submit"
                      variant="contained"
                      color="primary"
                      fullWidth
                      className={classes.bannerButton}
                      disabled={
                        !fullName || !businessEmail || !phoneNumber || !organization || !message || isFormLoading
                      }
                    >
                      Get Started
                    </Button>
                  </Grid>
                </Grid>
              </form>
            </Box>
          </Box>
        </Box>

        {/* Subscribe Modal */}
        <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
          <DialogTitle>Subscribe to Our Newsletter</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Email Address"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
                size="small"
                className={classes.textField}
                margin="dense"
              />
            </FormControl>
            {isLoading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress size={24} /> {/* Reduced size */}
              </Box>
            )}
            {subscriptionStatus === 'success' && (
              <Typography color="primary" align="center" style={{ marginTop: theme.spacing(2) }}>
                Successfully subscribed!
              </Typography>
            )}
            {subscriptionStatus === 'error' && (
              <Typography color="error" align="center" style={{ marginTop: theme.spacing(2) }}>
                Subscription failed. Please try again.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscribe} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubscribe}
              color="primary"
              variant="contained"
              disabled={!email || isLoading}
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

// ContentMarketingHome Component
const ContentMarketingHome = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <BannerSlide />

      <BenefitsCard />

      {/* New Video Section */}
      <Box className={classes.videoContainer}>
        <video
          src={growthstudiovideo}
          controls
          className={classes.video}
        >
          Your browser does not support the video tag.
        </video>
      </Box>

      <FAQSection context="contentMarketing" />

      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography
              variant="h4"
              className={`${styleClasses.h4font}`}
              style={{ color: 'white' }}
            >
              Scale Smarter, Not Harder—Leverage Fractional
              Experts Today!
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
              Drive growth with flexible, high-impact experts that adapts to your business needs.
            </Typography>
            <Button
              variant="contained"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              onClick={() => {
                window.location.href =
                  'https://calendly.com/vedika-5m0y/30min?month=2024-10'; // Replace with your desired URL
              }}
            >
              Book a Discovery Call
            </Button>
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
        />
      </Box>
    </Box>
  );
};

export default ContentMarketingHome;
