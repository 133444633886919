import {
	Grid
} from '@material-ui/core';
import React, { Component } from 'react';

import Event from './Event';
import Session from './Session';
import Upcoming_Webinars from './Upcoming_Webinars';
import Previous_Webinars from './Previous_Webinars';
import EBook from './EBook';
import Speakers from './Speakers';

export const IMAGES = [
  {
    id: 1,
    src: "/assets/images/courses/11.png",
    alt: "Placeholder image",
  },
  {
    id: 2,
    src: "/assets/images/courses/12.png",
    alt: "Placeholder image",
  },
  {
    id: 3,
    src: "/assets/images/courses/13.png",
    alt: "Placeholder image",
  },
  {
    id: 4,
    src: "/assets/images/courses/14.png",
    alt: "Placeholder image",
  },
  {
    id: 5,
    src: "/assets/images/courses/15.png",
    alt: "Placeholder image",
  },
  {
    id: 6,
    src: "/assets/images/courses/2.png",
    alt: "Placeholder image",
  },
];
class Homepage extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
	
		return (
		    <Grid container spacing={0}  
			justifyContent="center" 
			style={{backgroundColor:"white"}}>
	
			<Grid item xs={12}  >
			  <Event />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Session />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Upcoming_Webinars />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Previous_Webinars />
			</Grid>	
			
			<Grid item xs={10}  >
			  <EBook />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Speakers />
			</Grid>	
			
			{/*
			<Grid item xs={12}  >
			  <Event />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Session />
			</Grid>	

			<Grid item xs={12}  >
			  <Upcoming_Webinars />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Previous_Webinars />
			</Grid>	

			<Grid item xs={10}  >
			  <EBook />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Speakers />
			</Grid>	
			*/}
			</Grid>
		
		);
  	}
}
export default Homepage;
  
  