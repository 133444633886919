// src/components/BookCallForm.js

import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Button,
    Typography,
    Avatar,
    makeStyles,
    useTheme,
    useMediaQuery,
    Grid,
    Box,
} from "@material-ui/core";

import useGlobalStyles from "../styles/CourseStyles";
import useExpertStyles from "../styles/ExpertStyles";

import { openPopupWidget } from "react-calendly"; // Import openPopupWidget

const useStyles = makeStyles((theme) => ({
    dialogContent: {
        // Remove or minimize gaps between form fields
        paddingTop: theme.spacing(2),
        paddingBottom: theme.spacing(2),
    },
    dialogActions: {
        display: "flex",
        justifyContent: "flex-end",
        padding: theme.spacing(2),
    },
    submitButton: {
        backgroundColor: "#f9bb02",
        color: "black",
        fontWeight: 600,
        textTransform: "none",
        "&:hover": {
            backgroundColor: "#f9bb02",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
        },
    },
    // Custom styles for TextFields
    textField: {
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: theme.palette.grey[400],
            },
            "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
            },
            "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
            },
            // Preserve error styles
            "&.Mui-error fieldset": {
                borderColor: theme.palette.error.main,
            },
        },
        // Customize helperText for errors
        "& .MuiFormHelperText-root.Mui-error": {
            color: theme.palette.error.main,
        },
    },
    summarySection: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        marginBottom: theme.spacing(2),
    },
    expertAvatar: {
        width: theme.spacing(10),
        height: theme.spacing(10),
        marginBottom: theme.spacing(1),
    },
    expertName: {
        fontWeight: 600,
        textAlign: "center",
    },
    expertIndustry: {
        color: theme.palette.text.secondary,
        textAlign: "center",
    },
}));

const BookCallForm = ({ open, onClose, expertAvatar, expertName, expertIndustry }) => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const expertClasses = useExpertStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    // State to manage form fields
    const [formData, setFormData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
    });

    // State to manage form validation errors
    const [formErrors, setFormErrors] = useState({});

    // Load Calendly's embed script
    useEffect(() => {
        // Check if Calendly script is already loaded
        if (!document.getElementById("calendly-script")) {
            const script = document.createElement("script");
            script.id = "calendly-script";
            script.src = "https://assets.calendly.com/assets/external/widget.js";
            script.async = true;
            document.body.appendChild(script);
        }
    }, []);

    // Validate a single field and return error message if invalid
    const validateField = (name, value) => {
        let error = "";
        if (name === "firstName") {
            if (!value.trim()) {
                error = "First name is required";
            }
        } else if (name === "lastName") {
            if (!value.trim()) {
                error = "Last name is required";
            }
        } else if (name === "email") {
            if (!value.trim()) {
                error = "Email is required";
            } else if (!/\S+@\S+\.\S+/.test(value)) {
                error = "Email is invalid";
            }
        } else if (name === "phone") {
            if (value.trim() && !/^\d{10}$/.test(value)) {
                error = "Phone number must be 10 digits";
            }
        }
        return error;
    };

    // Handle input changes with real-time validation
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData((prevState) => ({
            ...prevState,
            [name]: value,
        }));

        // If there's an error for this field, re-validate it
        if (formErrors[name]) {
            const error = validateField(name, value);
            setFormErrors((prevErrors) => ({
                ...prevErrors,
                [name]: error,
            }));
        }
    };

    // Validate all fields and return true if valid
    const validateForm = () => {
        let errors = {};
        Object.keys(formData).forEach((field) => {
            const error = validateField(field, formData[field]);
            if (error) {
                errors[field] = error;
            }
        });
        setFormErrors(errors);
        return Object.keys(errors).length === 0;
    };

    // Function to open Calendly popup with prefilled data
    const openCalendlyPopup = () => {
        if (window.Calendly) {
            window.Calendly.initPopupWidget({
                url: 'https://calendly.com/vedika-5m0y/30min',
                prefill: {
                    name: `${formData.firstName} ${formData.lastName}`,
                    email: formData.email,
                },
                utm: {
                    expert_name: expertName, // Pass expert's name
                    expert_industry: expertIndustry, // Pass expert's industry
                },
            });
        } else {
            console.error("Calendly script not loaded");
        }
    };

    // Handle form submission
    const handleFormSubmit = (e) => {
        e.preventDefault();
        if (validateForm()) {
            // Here, you can handle the form data as needed (e.g., send to backend)
            console.log("Form Data:", formData);

            // Optionally, reset the form
            setFormData({
                firstName: "",
                lastName: "",
                email: "",
                phone: "",
            });
            setFormErrors({});

            // Close the form dialog
            onClose();

            // Open Calendly popup
            openCalendlyPopup();
        }
    };

    return (
        <Dialog
            open={open}
            onClose={onClose}
            aria-labelledby="form-dialog-title"
            fullWidth
            maxWidth="sm"
        >
            <DialogTitle id="form-dialog-title">Book a Discovery Call</DialogTitle>
            <DialogContent className={classes.dialogContent}>
                {/* Summary Section */}
                <Box className={classes.summarySection}>
                    <Avatar
                        alt={expertName}
                        src={expertAvatar}
                        className={classes.expertAvatar}
                    />
                    <Typography variant="h6" className={styleClasses.h6font}>
                        {expertName}
                    </Typography>
                    <Typography variant="subtitle1" className={styleClasses.subtitlefont}>
                        {expertIndustry}
                    </Typography>
                </Box>

                {/* Form Fields */}
                <form onSubmit={handleFormSubmit}>
                    <Grid container spacing={2}>
                        {/* First Name */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={formData.firstName}
                                onChange={handleInputChange}
                                error={!!formErrors.firstName}
                                helperText={formErrors.firstName}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        {/* Last Name */}
                        <Grid item xs={12} sm={6}>
                            <TextField
                                label="Last Name"
                                name="lastName"
                                type="text"
                                fullWidth
                                variant="outlined"
                                value={formData.lastName}
                                onChange={handleInputChange}
                                error={!!formErrors.lastName}
                                helperText={formErrors.lastName}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        {/* Email */}
                        <Grid item xs={12}>
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                fullWidth
                                variant="outlined"
                                value={formData.email}
                                onChange={handleInputChange}
                                error={!!formErrors.email}
                                helperText={formErrors.email}
                                required
                                className={classes.textField}
                            />
                        </Grid>
                        {/* Phone Number */}
                        <Grid item xs={12}>
                            <TextField
                                label="Phone Number"
                                name="phone"
                                type="tel"
                                fullWidth
                                variant="outlined"
                                value={formData.phone}
                                onChange={handleInputChange}
                                error={!!formErrors.phone}
                                helperText={formErrors.phone}
                                // Phone is optional, so no 'required' prop
                                className={classes.textField}
                            />
                        </Grid>
                    </Grid>
                </form>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Button onClick={onClose} className={`${styleClasses.businessButton}`} >
                    Cancel
                </Button>
                <Button
                    onClick={handleFormSubmit}
                    className={`${styleClasses.individualButton} ${expertClasses.popupButton} ${classes.submitButton}`}
                    variant="contained"
                >
                    Next
                </Button>
            </DialogActions>
        </Dialog>
    );
};

// PropTypes for type checking
BookCallForm.propTypes = {
    open: PropTypes.bool.isRequired,
    onClose: PropTypes.func.isRequired,
    expertAvatar: PropTypes.string.isRequired,
    expertName: PropTypes.string.isRequired,
    expertIndustry: PropTypes.string.isRequired,
};

export default BookCallForm;
