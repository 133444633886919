import React, { useEffect, useState } from 'react';
import {
  Avatar,
  Box,
  Button,
  Chip,
  Card,
  CardContent,
  CardHeader,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  makeStyles,
  Tooltip,
  Typography,
  Link,Grid,CircularProgress
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import CheckIcon from '@material-ui/icons/Check';
import axios from 'axios';
import InfiniteScroll from 'react-infinite-scroll-component';
import {CategoriesView_Skeleton} from '../../Skeleton';
import { useHistory } from 'react-router-dom';

import AlertBox from '../../AlertBox';
import RejectRequest from './RejectRequest';
import { user_id,get_name_slug,generateProfileUrl,user_status_id,api,userProfile_path } from '../../Helper';

const useStyles = makeStyles((theme) => ({
  small: {
    width: '64px',
    height: '64px',
  },
  name:{fontWeight:'bold'},
  mauto:{margin:'auto'},
  btn:{minWidth: '0px',width: '30px',height: '30px',borderRadius:'50%'}
}));


function RequestList({community}) {
  const [renderList, setRenderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [, forceUpdate] = useState(null);
  const [msg, setMsg] = useState('');
  const [selectedReq, setSelectedReq] = useState(null);
  const [req, setReq] = useState(null);
  const [msgType, setMsgType] = useState('success');
  const classes = useStyles();
  const history=useHistory();

  useEffect(() => {
    getRequestList(start, false);
  }, []);

  const fetchMoreData = () => {
    if (hasMore !== false) {
      setLoading(true);
      setTimeout(() => {
        setStart((start) => start + end);
        getRequestList(start + end, true);
      }, 200);
    }
  };

  const getRequestList = (start, fetch) => {
    const cacheKey = `all_join_req_community_${start}_to_${end}`;
    let payload = {
      community_id: community.id,
      creater_id: community.creater_id,
      start,
      end,
      key: cacheKey,
    };
	  console.log(community)
	  console.log(payload)
	
    axios.post(api + '/list_join_request', payload).then((response) => {
      setLoading(false);
      const res = response.data; 
      const data = res.rows; 
	  console.log(res)
	  console.log(data)
	  //alert(1)
      if (data.length < end) setHasMore(false);
      else {
        setHasMore(true);
      }
      if (fetch === false) {
        setRenderList(data);
      } else {
        if (data.length > 0) {
          setRenderList((prevList) => [...prevList, ...data]);
        } else {
          setMsg('You have seen it all!');
        }
      }
      setLoading(false);
      setTimeout(() => {
        setMsg('');
      }, 2000);
    });
  };

  const handleCallback = () => {
    //alert(3)
	forceUpdate(n=>!n)
	setSelectedReq(null);
    setReq(null);
	setStart(0);
	getRequestList(0, false);
  }
  const handleReq = (e, action) => {
    setReq(action);
    
    let payload = {
      status: action,
      email: e['user_info'].emails,
      name: e['user_info'].names,
      user: e.user,
      user_id: e.user_id,
      community_id: e.community_id,
      title: community.title,
	  host_email:community.host_email,
	  host_name:community.host_name,
    };
	setSelectedReq(payload);
	console.log(payload)
    if (action === 'reject') {
		forceUpdate(n=>!n)
      return;
    }          
    axios.post(api + '/manage_join_request', payload).then((response) => {
      const res = response.data;
      console.log(res);
      setLoading(false);
      setMsg(res.msg);
	  if(res.type=='success') 
	  {
		setTimeout(() => {
			handleCallback()
		}, 3000);
	  }

    });
  };

  const ActionButton = ({ e }) => {
    return (
      <Box display="flex" alignItems="center" justifyContent="end" width="100%">

		<Tooltip title="Accept Request">
          <Button size="medium" color="primary"  variant="outlined" className={classes.btn}
		  onClick={() => handleReq(e, 'joined')}>
            <CheckIcon fontSize="small" />
          </Button>
        </Tooltip>
        
          <Box marginLeft={2}>
           <Tooltip title="Reject Request">
		   <Button  size="medium"  color="secondary" variant="outlined" 
		   className={classes.btn} onClick={() => handleReq(e, 'reject')}>
              <CloseIcon  fontSize="small"  />
            </Button>
			 </Tooltip>
          </Box>
       
      </Box>
    );
  };

const RenderRequest = ({ e }) => {
		
	  const res = e.user_info;
	  	  console.log(e);

  let images = res.images;
  let name = res.names;
  let interest = res.interest;
  let industry = res.industry;

  if (name !== null && name !== undefined) {
    name = name.replaceAll(/[\\\/"]/g, '');
    interest = interest.replaceAll(/[\\\/"]/g, '');
  }
  

	const redirect_user = () => {
	const slug = get_name_slug(res['names']);
	let mail_url=generateProfileUrl(res['emails'])
	const profile_data = {
		profile_mail: res['emails'],
		profile_type:res['user'],
		profile_id: e['user_id'],
		course_author_id: res['user'] === 'professor' ? res['user'] : null,
	  };

	  history.push({
		pathname: '/profile/' + mail_url,
		state: profile_data,
	  });
	};
return (
  <Box margin={0.5} marginBottom={2} >
    <Card elevation={3}>
      <CardContent>
        <Grid container spacing={2}>
          <Grid item xs={12} md={9}>
            <Box display="flex" alignItems="center">
              <Avatar
                src={images ? userProfile_path + images : ''}
                alt={name}
                className={classes.small}
              />
              <Box marginLeft={2} marginTop={1} marginBottom={1}>
                <Box
                  onClick={redirect_user}
                  sx={{ cursor: 'pointer' }}
                  display="flex"
                  justifyContent="space-between"
                  alignItems="center"
                >
                  <Typography variant="subtitle1" color="primary" className={classes.name}>
                    {name}
                  </Typography>
               </Box>
				
					<Typography variant="subtitle2" color="textSecondary" >
					  {e.interest && e.industry
						? `${interest} | ${industry}`
						: interest || industry}
					</Typography>
				  

                    
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={3} className={classes.mauto}>
            <ActionButton e={e} />
          </Grid>
        </Grid>
      </CardContent>
    </Card>
  </Box>
);

};

  const RenderCard = () => {
    return (
      <InfiniteScroll
        style={{ overflow: 'hidden' }}
        dataLength={renderList}
        hasMore={hasMore}
        loader={
          hasMore === true ? (
            <Box
              marginTop={2}
              marginBottom={2}
              alignItems="center"
              display="flex"
              flexDirection="column"
            >
              {loading ? (
                <CircularProgress disableShrink />
              ) : (
                <Button
                  variant="outlined"
                  size="medium"
                  color="primary"
                  disabled={loading}
                  onClick={fetchMoreData}
                >
                  Load More
                </Button>
              )}
            </Box>
          ) : (
            <CircularProgress disableShrink />
          )
        }
        endMessage={
          <Box
            sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}
            marginTop={2}
            marginBottom={2}
          >
            <Typography variant="subtitle2" color="textPrimary">
              You have seen it all
            </Typography>
          </Box>
        }
      >
        <Grid container spacing={0}>
          { renderList.map((e, index) => (
            <Grid item xs={12} key={index}>
              <RenderRequest e={e} />
            </Grid>
          ))}
        </Grid>
      </InfiniteScroll>
    );
  };

  return (
    <Box>
      {msg !== '' ? (
        <AlertBox show_status={true} msg={msg} msg_type={msgType} />
      ) : (
        ''
      )}
	  {req=="reject" && (<RejectRequest selectedReq={selectedReq} handleCallback={handleCallback} />)}
      <Grid container spacing={3}>
        <Grid item xs={12}>
		
            <Box marginBottom={2} fontWeight={700}>
            <Typography variant="h6" color="textPrimary" className={classes.name} >
			Join Request
			</Typography>
			</Box>
			

              {renderList.length === 0 ? (
                loading === true ? (
                  <CategoriesView_Skeleton />
                ) : (
                  <Box padding={2} >
                  <Typography variant="subtitle1" color="textSecondary" align="left">
                    No join request made for the community.
                  </Typography>
                  </Box>
                )
              ) : 
                renderList!=undefined && Array.isArray(renderList) && renderList.length>0 && ( <RenderCard /> )
              }

        </Grid>
      </Grid>
    </Box>
  );
}

export default RequestList;
