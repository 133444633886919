import React, { Component, Suspense } from 'react';
import { ProfessorDashboard, StudentDashboard } from '../Routes';
import { api } from "../Helper";
import axios from 'axios';
import cookie from 'react-cookies';

let status=null
let email=null
let session_id=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	 	session_id=JSON.parse(localStorage.getItem('ac_login_user'))['session_id']

}	

class Dashboard extends Component {

    constructor(props) {
    super(props);
	}
	
	logout_academy=()=>{
		
		axios.post(api+'/logout_academy',
			{'storage_id':localStorage.getItem('login_token'),
			'session_id':session_id,
			'email':email,
			'status':status
			})
			.then((response) => {
			//console.log(response.data);
			//console.log("logout academy with session")
			localStorage.clear();
			})
	}
	checkSession=()=>{
		axios.get(api+'/get_session_id')
		.then((response) => {
			//console.log(response.data);
			// alert(response.data);
			
			if(localStorage.getItem('login_token')!=response.data)
			{
				
				//console.log(localStorage.getItem('login_token')+" --------different session------- "+response.data)
				this.logout_academy()
			}
			else{
				//console.log(localStorage.getItem('login_token')+" ------common session--------- "+response.data)
			}
		})
	}
	
	checkTimer = () => {
		axios.post('/api/checkTimer')
        .then((response) => {
		localStorage.setItem('webinar_timer_de', JSON.stringify(response.data)) 
		})
	}
	getActiveWebinarId=()=>
	{
		axios.post('/api/getActiveWebinarId')
			.then((response) => {
            this.setState({'webinar_id':response.data})
            localStorage.setItem('webinar_id',response.data)
		});
	}
	componentDidMount()
	{
		
		//this.checkSession()
		let config_res=cookie.load('config')
		if(config_res && (config_res.filter(item => item.component === "webinar").map(item => item.enable)[0]))
		{
			this.checkTimer()
			this.getActiveWebinarId()
		}
	}
    render()
    {
    
	return (
	
	<Suspense fallback={<div> </div>}>
    {
		status=='professor'
        ? <ProfessorDashboard />
        : <StudentDashboard />
	}  
	</Suspense>
   
	  );
    }
}
export default Dashboard;

  