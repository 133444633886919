import {
	Box, Card, CardContent, CardHeader, Grid, makeStyles, ThemeProvider, Typography
} from '@material-ui/core';
import React, { Suspense, useEffect, useState, useContext } from 'react';
import { getCacheDetails } from '../caching';
import { UserContext } from '../courses/UserContext';
import {
	User_Course_Skeleton,
	AttendSkeleton, CommunitySkeleton,
	ArticleSkeleton} from '../Skeleton';
//import config from '../../../translation/config.json';
import { Academytheme } from "../useStyles";
import { config } from "../Helper";

//const Drag = React.lazy(() => import('../blogs/drag.js'));
const TabCard = React.lazy(() => import('./tab'));
const PromotionCard = React.lazy(() => import('./PromotionCard'));
const ButtonSection = React.lazy(() => import('./ButtonSection'));
const CommunityCard = React.lazy(() => import('../community/DashboardCommunity'));
const UpcomingWebinarDash = React.lazy(() => import('../mentors/dashboard/webinars/UpcomingWebinar'));
const ArticleRead = React.lazy(() => import('../blogs/PopularBlogs'));
const ConnectedExperts = React.lazy(() => import('../mentors/dashboard/ConnectedExperts'));
const MentorsForDashboard = React.lazy(() => import('../mentors/dashboard/MentorsForDashboard'));
const ViewedRecently = React.lazy(() => import('../courses/ViewedRecently'));
const SuggestedCourse = React.lazy(() => import('../courses/SuggestedCourse'));
const TimelineMentors = React.lazy(() => import('../mentors/dashboard/TimelineMentors'));
const Mentorship = React.lazy(() => import('../mentors/dashboard/mentorship'));



let student_id=null
let fname=null
let status=null
let is_mentor=null;
let mentor_req=null;
if(["admin",null,"webinar"].includes(localStorage.getItem('login_token'))==false)
{
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	mentor_req=JSON.parse(localStorage.getItem('ac_login_user'))['mentor_req']
	is_mentor=JSON.parse(localStorage.getItem('ac_login_user'))['is_mentor']
}
const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));
  
const StudentDashboard = () => {

 // const { user, setUser } = useContext(UserContext);
 // const { user, setUser } = useState(null);
  const user = useContext(UserContext);
  const [msg, setMsg] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    const getCacheProgress = async () => {
      try {
        const data = await getCacheDetails(
          student_id + "_video_progress_dashboard",
          "getVideoProgress",
          [student_id, 0, 20]
        );
        const k = data["progress"];
        user.set_user_info((user) => ({
          ...user,
          progress_count: k,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    const getCacheBookmark = async () => {
      try {
        const data = await getCacheDetails(
          student_id + "_users_bookmarked_courses_0_to_100",
          "getBookmarkedCourses",
          [0, 100, student_id]
        );
        const i = data.length;
        user.set_user_info((user) => ({
          ...user,
          bookmark_count: i,
        }));
      } catch (error) {
        console.error(error);
      }
    };

    getCacheProgress();
    getCacheBookmark();

    if (status === 'professor' && is_mentor === "mentee" && mentor_req === 1) {
      setMsg(
        "You experts profile activation request is in process. You will be allowed to provide mentorship services once the permission will be granted by the admin."
      );
      setTimeout(() => {
        setMsg(false);
      }, 3000);
    }
  }, []);

	const BannerCard=()=>{
	  const boxStyle = {
		  position: 'relative',
		  minHeight: '150px',
		  border: '1px solid rgb(235, 235, 235)',
		  backgroundImage: 'url("./assets/images/web/how-we-do-it.png")',
		  backgroundImage: 'url("./assets/images/web/banner3.png")',
		  backgroundSize: 'cover',
		  backgroundPosition: 'center',
		  borderRadius: '12px',
		  padding: 2,
		  margin: 'auto',
		};

		const overlayStyle = {
		  position: 'absolute',
		  top: 0,
		  left: 0,
		  width: '100%',
		  height: '100%',
		  backgroundColor: 'rgb(71 90 109 / 80%)',
		  borderRadius: '12px',
		};

		const textStyle = {
		  position: 'relative',
		  zIndex: 1, 
		  fontSize: '18px',
		  color: '#f2f2f2',
		  textAlign: 'center',
		  marginTop: '40px',
		};

		return(
	
		<Box style={boxStyle}>
		  <div style={overlayStyle}></div>
		  <div style={textStyle}>
			Welcome, {fname}. <br /><br />
			Your expertise is the driving force on OpenGrowth - let's continue shaping success together
			<br />
		  </div>
		</Box>

		)
	}
  return (
  
<ThemeProvider theme={Academytheme}>
  <div className={classes.root}>
    <Box display="none">
      <Typography variant="h6" color="primary" fontWeight={600}>
        {msg}
      </Typography>
    </Box>
    <Grid container direction="row" spacing={3}>
      <Grid item xs={12} >
		<BannerCard />
	  </Grid>
      <Grid item xs={12} sm={12} md={8} lg={8}>
        <Grid container direction="column" spacing={3}>
          {/*<Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <Drag />
            </Suspense>
          </Grid>

	
		  */}
		   <Grid item xs={12} 
			container 
			justifyContent="center"
			alignItems="center">
            <Suspense fallback={<></>}>
              <ButtonSection />
            </Suspense>
          </Grid>
		  
		   <Grid item xs={12} 
			container 
			justifyContent="center"
			alignItems="center">
            <Suspense fallback={<div></div>}>
              <PromotionCard />
            </Suspense>
          </Grid>

		{
			status=='professor' 
			?
				is_mentor=="mentor"
				?
					<Grid item xs={12}>
					<Mentorship />
					</Grid>
				:''
			:
				<Grid item xs={12}>
				<ConnectedExperts />
				</Grid>			
		}
		
		{/*
          <Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <TabCard />
            </Suspense>
          </Grid>


		
		{
			status=='professor' && is_mentor=="mentee"
			?""	
			:<Grid item xs={12}>	
			<TimelineMentors />
			</Grid>
		*/}
		
          {/*<Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <NewCourses />
            </Suspense>
          </Grid>
          {status === 'student' && (
            <Grid item xs={12}>
              <Suspense fallback={<div> <Graph_Skeleton/></div>}>
                <Box marginBottom={2}>
                  <ApexGraphCard />
                </Box>
              </Suspense>
            </Grid>
          )}
          {status === 'student' && (
            <Grid item xs={12}>
              <Suspense fallback={<div> </div>}>
                <MyMentors />
              </Suspense>
            </Grid>
          )}
          {status !== 'professor' || is_mentor !== "mentee" ? (
            <Grid item xs={12}>
              <Mentorship />
            </Grid>
          ) : null}
          {config.webinar.value === 1 && (
            <Grid item xs={12}>
              <UpcomingWebinar />
            </Grid>
          )}
          <Grid item xs={12}>
            <Suspense fallback={<div> <Horizontal_Course_Skeleton/></div>}>
              <CourseBlock />
            </Suspense>
          </Grid>
          <Grid item xs={12}>
            <Suspense fallback={<div> </div>}>
              <Scroll />
            </Suspense>
          </Grid>*/}
		  
		
		   <Grid item xs={12}>
            <Suspense fallback={ <CommunitySkeleton action="vertical" />}>
              <CommunityCard />
            </Suspense>
          </Grid>			  
		  
        </Grid>
      </Grid>
      <Grid item xs={12} sm={12} md={4} lg={4} className="block">
        <Grid container direction="column" spacing={3}>
          {/*status === 'student' && (
            <Grid item xs={12}>
              <Suspense fallback={<div> <User_Course_Skeleton/></div>}>
                <SuggestedCourse />
              </Suspense>
            </Grid>
          )*/}
          {config.mentorship.value === 1 && (
          <Grid item xs={12}>
            <Suspense fallback={<div> <ArticleSkeleton/></div>}>
              <MentorsForDashboard action="connect" />
            </Suspense>
          </Grid>
          )}		  
          {config.webinar.value === 1 && (
            <Grid item xs={12}>
              <Suspense fallback={<div><AttendSkeleton /></div>}>
                <Card elevation={1}>
                  <CardHeader title="Webinars you can attend" />
                  <CardContent style={{ padding: "0px" }}>
                    <UpcomingWebinarDash parent="right" />
                  </CardContent>
                </Card>
              </Suspense>
            </Grid>
          )}
          <Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <ArticleRead />
            </Suspense>
          </Grid>
          {/*config.mentorship.value === 1 && (
          <Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <MentorsForDashboard action="recent" />
            </Suspense>
          </Grid>
	       )*/}	  
          <Grid item xs={12}>
            <Suspense fallback={<div></div>}>
              <ViewedRecently />
			</Suspense>
			</Grid>
		</Grid>
		</Grid>		
	</Grid>
    </div>
	</ThemeProvider>

  
  
  )
}

export default StudentDashboard;