import React, { Component, useEffect ,useRef} from 'react';
import Razorpay from 'react-razorpay';
import { razorpay_credential } from '../../Helper';


export default function PayButton({e}) {

  useEffect(() => {
	console.log(e)
    sessionStorage.setItem('active_pay', JSON.stringify(e));
    const rzpPaymentForm = document.getElementById("rzp_payment_form");
 
    if (!rzpPaymentForm.hasChildNodes()) {

      const script = document.createElement("script");
      script.src = "https://checkout.razorpay.com/v1/payment-button.js";
      script.async = true;
      script.dataset.payment_button_id = razorpay_credential.payment_button_id
      rzpPaymentForm.appendChild(script);
	  
		/*const rzpPaymentSpanList = document.getElementsByClassName("razorpay-payment-button");
		//console.log(rzpPaymentSpanList)
//		if(![null,undefined,''].includes(rzpPaymentSpanList))
	////console.log(rzpPaymentSpanList.length[0])
	//console.log(rzpPaymentSpanList.item(0))
		if(![null,undefined,'',0].includes(rzpPaymentSpanList.item(0)))
		{
			//if(!'length' in rzpPaymentSpanList)
			//{	
				var rzpPaymentSpan = rzpPaymentSpanList.item(0); //retrieve the first node in the stack
				//console.log(rzpPaymentSpan)
				rzpPaymentSpan.click() 
			//}
			
		}*/
	}
	
  });



    
  return (
    <div>
      <form id="rzp_payment_form" ></form>
    </div>
  );
}

