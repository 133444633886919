import {
	Box,
	Grid, 
	
	ThemeProvider, Typography
} from '@material-ui/core';


import { withStyles } from '@material-ui/core/styles';
import React, { Component } from 'react';
import { Academytheme } from "../home/index/useStyles";
import CommonCard from './CommonCard';

const styles = theme => ({
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

class Innovative_Platform extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
		
		const classes = this.props.classes;	
		let points=[
		{'text':"OpenGrowth Academy may use your personal information for marketing purposes, reference purposes, and improve our services and business intelligence for research and statistical or intelligent data."},{'text':"All employees working with OpenGrowth Academy associated with collecting, processing will have access to your data. They are obliged to respect the confidentiality of the data."}, 
		{'text':"When you log in, our servers automatically record the information that your provide us or your browser sends us, whenever you visit out website."}]
		return (
		  <ThemeProvider theme={Academytheme}>
	
		
		  <Grid container
		            alignItems="center"
                    justify="center"
		  >

					
	
		<Grid item  xs={12} >		
		<Box  display="block" textAlign="left" 
		justifyContent="center" >
				
				
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5}>
					 Confidentiality, Report and Analysis 
					 </Box>
					 </Typography> 
					

					<CommonCard points={points} />

					
		</Box>			
		</Grid>
		
		</Grid>

		</ThemeProvider>
		);
  	}
}
  
export default withStyles(styles)(Innovative_Platform)
