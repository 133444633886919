// src/components/WhyHireGrowthAssociate.jsx

import React from 'react';
import {
    Box,
    Typography,
    Card,
    Grid,
    CardContent,
    CardMedia,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct
import featureImage1 from '../assets/associate1.webp'; // Replace with your actual image paths
import featureImage2 from '../assets/associate2.webp'; // Replace with your actual image paths

const useStyles = makeStyles((theme) => ({
    // Styles for WhyHireGrowthAssociate
    container: {
        borderRadius: '8px',
        marginTop: theme.spacing(8),
        padding: theme.spacing(4),
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    heading: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(4),
        color: '#25387c', // Dark blue color
        textAlign: 'center',
    },
    card: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        padding: theme.spacing(2),
        backgroundColor: '#ffffff',
        border: 'none',
        borderRadius: '8px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        height: '100%', // Make the card fill the height of its container
    },
    cardMedia: {
        height: 200,
        width: '100%',
        borderRadius: '4px',
        objectFit: 'cover',
        marginBottom: theme.spacing(2),
        marginTop: theme.spacing(4),
    },
    cardTitle: {
        fontWeight: '600',
        color: '#25387c',
        marginBottom: theme.spacing(1),
        textAlign: 'center',
    },
    cardDescription: {
        color: '#555',
        textAlign: 'center',
    },
    h5: {
        flexGrow: "1 !important",
        marginBottom: '16px !important',
    }
}));

const WhyHireGrowthAssociate = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();

    return (
        <Box className={classes.container}>
            {/* Section Heading */}
            <Typography
                variant="h4"
                className={styleClasses.h4font}
                gutterBottom
                align='center'
            >
                Why Hire a Growth Associate
            </Typography>

            {/* Two-Column Layout */}
            <Grid container spacing={4}>
                {/* First Card - 50% */}
                <Grid item xs={12} md={6}>
                    <Card className={classes.card}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${styleClasses.h5}`} gutterBottom>
                            Cost-Effective Growth Solutions
                        </Typography>
                        <CardMedia
                            className={classes.cardMedia}
                            image={featureImage1} // Replace with your desired image
                            title="Lead Generation"
                        />
                        <CardContent>
                            <Typography
                                variant="subtitle1"
                                className={styleClasses.subtitlefont}
                            >
                                Achieve growth without the expense of a full-
                                time executive. A Growth Associate drives
                                acquisition, engagement, and scaling through
                                data-driven strategies—at a fraction of the cost.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>

                {/* Second Card - 50% */}
                <Grid item xs={12} md={6}>
                    <Card className={classes.card}>
                        <Typography variant="h5" className={styleClasses.h5font} gutterBottom>
                            Seamless Collaboration for Business
                            Expansion
                        </Typography>
                        <CardMedia
                            className={classes.cardMedia}
                            image={featureImage2} // Replace with your desired image
                            title="Data Insights"
                        />
                        <CardContent>
                            <Typography
                                variant="subtitle1"
                                className={styleClasses.subtitlefont}
                            >
                                A Growth Associate integrates with marketing,
                                product, and sales to implement targeted
                                strategies, expanding your customer base and
                                boosting revenue through seamless
                                collaboration.
                            </Typography>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default WhyHireGrowthAssociate;
