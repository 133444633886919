import {Box,Grid} from '@material-ui/core';
import React, { Component, Suspense } from 'react';
import { CourseViewTop, ProfessorCourseBlock, Quiz, QuizProgress, QuizStart } from '../Routes';
import { Course_Top_Skeleton, Horizontal_Course_Skeleton, Quiz_Skeleton } from '../Skeleton';



let status=null
if([null,"admin","webinar"].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}	

class Quizizz extends Component {

       constructor(props) {
    super(props);
   
    }

	
    render()
    {

	const QuizProfessor=()=>{
		return (
			
			<Grid container spacing={2}>
			 
				
					<Grid item xs={12} >
					<Suspense fallback={<div><Course_Top_Skeleton/> </div>}><CourseViewTop />  </Suspense>
					</Grid>
			
				
					<Grid item xs={12} >
					<Suspense fallback={<div> <Quiz_Skeleton/></div>}><Quiz /></Suspense>
					</Grid>
				
			
			</Grid>
	)
	}
		
	const QuizUser=()=>{
		return (
		   	<Grid container spacing={2}>
			{ 
				status=='professor'
				?
					<Grid item xs={12} >
					<Suspense fallback={<div><Horizontal_Course_Skeleton/> </div>}>
					<ProfessorCourseBlock />
					</Suspense>
					</Grid>
			
				:
					<Grid item xs={12} >
					<Suspense fallback={<div><Quiz_Skeleton/> </div>}>
					<Quiz />
					</Suspense>
					</Grid>
				
			}
			</Grid>
	)
	}
		
	
    return (
        
		<>
		{
		  window.location.pathname=="/quiz/start" ? <QuizStart />
          : window.location.pathname=="/quiz/progress"  ? <QuizProgress />
          : window.location.pathname=="/quiz/view"  ? <QuizProfessor />
          : <QuizUser />
		}
		</>

      );
    }
}

export default Quizizz;
  
  
  
		
  