import { Box, Button, ThemeProvider, Typography } from "@material-ui/core";
import React, { Component } from 'react';
import Cookies from 'universal-cookie';
import { Academytheme } from "../../../functions/useStyles";
import { Markup } from 'interweave';



const cookies = new Cookies();

let status=null;
if([null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}
export default class AccordionBlock extends Component {
    constructor(props) {
    super(props);}

	
    
	render(){
	const classes = this.props.classes;
    const history = this.props.history;
    const cookies = this.props.cookies;

	  

    const LearnTheArt = () => {
		    
        let para=String(this.props.learn_content)
        para=(para).split("<br>");
        
	return (
	<Box padding={4} paddingTop={2}>
			
            <Box display="flex" justifyContent="center" alignItems="center"
            marginBottom={2}>
			 <Typography  variant="h5" color="primary"> 
             {this.props.learn_title}
			</Typography>
                
			</Box>
            
			<Box   display="block" justifyContent="center"  alignItems="center">
			<Typography  variant="subtitle1" color="textSecondary" style={{lineHeight:'1.5rem'}}>
			{para.map((e,index) => (
                <Markup 
				content={e} />    
            ))}</Typography>                
			</Box>

            
            <Box display={status==null?"flex":"none"} justifyContent="center" alignItems="center"
            marginBottom={2} marginTop={2}>
			<Button variant="contained" size="large"    color="secondary" style={{'text-transform': 'initial'}}
			href="#register">
            
            <Typography variant="subtitle1" style={{color:'white',fontWeight:'700'}} >
				Register Now
				</Typography>
			</Button>
			</Box>
   </Box>
    )}


  
	
       return(
        <ThemeProvider theme={Academytheme}>
        <LearnTheArt />
        </ThemeProvider >

        );
}
}
