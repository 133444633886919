import React, { useState } from 'react';
import axios from 'axios';
import {
  Button,
  CircularProgress,
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  IconButton,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { makeStyles } from '@material-ui/core/styles'; // Import makeStyles

import AlertBox from '../../AlertBox';
import { api } from '../../Helper';

const useStyles = makeStyles((theme) => ({
  close: {
    fontSize: '0.85rem',
  }, 
  border: {
    borderRadius: '8px',
  },
  iconsbg: {
    backgroundColor: '#0000001f',
    padding: '8px',
    marginBottom: '8px',
  },
}));

function RejectionDialog(props) {
  const classes = useStyles();
  const [loading, setLoading] = useState(false);
  const [rejectionReason, setRejectionReason] = useState('');
  const [msg, setMsg] = useState('');
  const [msgType, setMsgType] = useState('warning');

  const handleClose = () => {
    setRejectionReason('');
    props.handleCallback();
  };

  const handleReject = () => {
  
	   if (rejectionReason.trim() === '') {
		setMsg('Kindly specify a remark as well.');
		return;
	  }
	  if (rejectionReason.length > 1000) {
		setMsg('Rejection reason should not exceed 1000 characters.');
		return;
	  }
	  setLoading(true);

    const payload = props.selectedReq;
    payload.remark = rejectionReason.trim();

    console.log(payload);

    axios.post(api + '/manage_join_request', payload).then((response) => {
      const res = response.data;
      console.log(res);
      setLoading(false);
      setMsg(res.msg);
      setMsgType(res.type);
      if (res.type === 'success') {
        
		setTimeout(() => {
			handleClose()
		}, 2000);
      }
    });
  };

  return (
    <div>
      {msg !== '' && <AlertBox show_status={true} msg={msg} msg_type={msgType} />}
      <Dialog
        open={true}
        onClose={handleClose}
        PaperProps={{className: classes.border }}
        maxWidth="md"
        fullWidth
      >
        <DialogActions>
          <IconButton onClick={handleClose} className={classes.iconsbg }>
            <CloseIcon color="disabled" className={classes.close} />
          </IconButton>
        </DialogActions>
        <DialogContent>
          <Box padding={3}>
         
		 
		 <Box 
		 marginBottom={2}
		  fontSize="18px"
		  fontWeight="bold"
		>
		  Please provide a reason for rejection:
		</Box>

		  
		  
		  <TextField
		  autoFocus
		  fullWidth
		  name="about"  
		  type="text"  	
		  label="Rejection Reason"
		  multiline 
		  minRows={7}
		  variant="outlined" 
		  value={rejectionReason}
		  onChange={(e) => setRejectionReason(e.target.value)}
		  />
			
          <Box marginTop={2} justifyContent="center" display="flex" flexDirection="row-reverse">
            <Button
              variant="contained"
              size="large"
              color="primary"
              onClick={handleReject}
              disabled={loading}
            >
              {loading && <CircularProgress size={24} />}
              {!loading && <span>Reject User Request</span>}
            </Button>
          </Box>
          </Box>
        </DialogContent>
      </Dialog>
    </div>
  );
}

export default RejectionDialog;
