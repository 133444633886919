
import {
	Avatar, Box,Button, Card, CardContent, CardHeader, CircularProgress, makeStyles, Paper, Table, TableBody, TableCell, TableContainer,
	TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { api, translateText, userProfile_path } from "../../functions/Helper";
  



const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});

class Logs extends Component {

    constructor(props) {
	super(props);
	    this.translateText = translateText.bind(this);
	this.state={
		course_show:[],start:0,end:10,hasMore: true,record_len:0}
    }
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
				axios.post(api+'/getLogs',
				{'start':this.state.start,
				'end':this.state.end,
				})
				.then((response) => {
				if(response.data.length<this.state.end){this.state.hasMore=false;}	
				this.setState({ course_show: [...this.state.course_show,...response.data]})
			});			
			}, 1500);
		}
	};
	componentDidMount()
	{
		
			axios.post(api+'/getLogs',
			{'start':this.state.start,
			'end':this.state.end,
			})
			.then((response) => {
			this.setState({course_show:response.data})
			if(response.data.length<this.state.end){this.state.hasMore=false;}	
			//console.log(response.data)
			});
		

		
	}
  	render()
  	{
	
		const classes = this.props.classes;
		const Tables = () => {
		return (
		<>		
		{this.state.course_show.map((e, index) => (
		<StyledTableRow>
			<StyledTableCell  >
			 <Box display="flex" flexDirection="row" p={1} m={1}>
				<Box p={1} >
				 <Avatar src={userProfile_path+e['img']}  />
				</Box>
				<Box p={1} >
				  {e['email']}
				</Box>
			  </Box> 
			</StyledTableCell>
             <StyledTableCell  >{e['status']}</StyledTableCell>
			  <StyledTableCell  >{e['logged_as']}</StyledTableCell>
			  <StyledTableCell  >{e['reasons']}</StyledTableCell>
				<StyledTableCell  >{e['ip']}</StyledTableCell>
				<StyledTableCell  >{e['time']}</StyledTableCell>
				<StyledTableCell  >{e['session']}</StyledTableCell>
				<StyledTableCell  >{e['logout_at']}</StyledTableCell>
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Card >
		
		<CardHeader title={this.translateText('LOG_TITLE')}/>
	  
		<CardContent>
		
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.course_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
							this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
				}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Status</StyledTableCell>
						<StyledTableCell >Logged As </StyledTableCell>
						<StyledTableCell >Reason</StyledTableCell>
						<StyledTableCell >IP</StyledTableCell>
						<StyledTableCell >Time</StyledTableCell>
						<StyledTableCell >Session</StyledTableCell>
						<StyledTableCell >Logout On</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
				 
				
				
				
			
		</CardContent>
        </Card>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}