

import {
	AppBar , Avatar, Box, Button, Card, Chip,  CardContent, CircularProgress, Grid, Link, makeStyles, TableCell, TableRow,
	Typography, withStyles , Tab, Tabs
} from '@material-ui/core';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import HomeIcon from '@material-ui/icons/Home';
import {
	Rating,
	Skeleton
} from '@material-ui/lab';
import React, { useEffect } from "react";
import { useHistory, useLocation } from 'react-router-dom';
import { getCacheDetails } from "../../caching";
import { api, successColor, secondaryColor, images,pinkColor,lightBlueColor,generateCourseUrl } from "../../Helper";
import Editor from 'ckeditor5-custom-build/build/ckeditor';
import { CKEditor } from '@ckeditor/ckeditor5-react'
import moment from "moment";
import { FaChevronLeft, FaChevronRight} from 'react-icons/fa';
import { MdQuiz} from 'react-icons/md';
import { RiFileEditFill } from 'react-icons/ri';
import { BsFileEarmarkRuledFill} from 'react-icons/bs';

import AlertBox from "../../AlertBox";

import axios from 'axios';
import Sticky from 'react-sticky-el';

import '../../courses/units/index.css';
import AssignmentUpload from './AssignmentUpload';
import QandA from '../../courses/units/QandA';


let student_id=null
let status=null
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
}


const StyledTableCell = withStyles((theme) => ({
	head: {
	//  backgroundColor: theme.palette.common.black,
   backgroundColor:'#497ba7',   
	 color: theme.palette.common.white,
	 		textAlign:'center',

	},
	body: {
	  fontSize: 14,
	  textAlign:'center'
	},
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
	root: {
		textAlign:'center',
	  '&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	  },
	},
  }))(TableRow);
  
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
  card:{minHeight:'100vh',marginBottom:'24px'},
  	avatar2:{
		cursor:'pointer',
		backgroundColor:"#f9bb021f",
		color:secondaryColor,
		'&:hover':{
			backgroundColor:secondaryColor,
			color:"white",			
		},
	},
	link:{cursor:'pointer',fontWeight:'bold'},
	 cardrender :{
		 "&:hover": {
			transform: 'scale(1)',
		//'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)',
		'box-shadow': '0px 5px 3px -2px rgb(0 0 0 / 20%), 0px 5px 4px 0px rgb(0 0 0 / 14%), 0px 1px 10px 0px rgb(0 0 0 / 12%)',

		},
	 },
	chipdata:{
		whiteSpace: 'nowrap',
		fontSize: '0.75rem',
		lineHeight: '1',
		fontWeight: '700'
	},
	chipboxp:{	
	backgroundColor: 'white',
		color: secondaryColor,
		border:"1px solid "+secondaryColor
	},
	chipboxs:{	
	backgroundColor: 'white',
		color: successColor,
		border:"1px solid "+successColor
	},
	
	 root: {flexGrow: 1,width: '100%'},
	  global:{textTransform: 'capitalize',color: 'rgb(99, 115, 129)','text-align': 'center',fontSize:'0.87rem',},
	  m:{marginLeft:'auto' },
	  width:{minWidth:'120px'},
	  tab: {verticalAlign : 'middle',marginRight:'8px',display:'unset'},
	  quiz: {color: pinkColor},
	  cumlative: {color: lightBlueColor},
	  inclass: {color: secondaryColor},
		
	   details: { 	cursor:'pointer',
	   overflow: "hidden",display: "-webkit-box",
					WebkitLineClamp: 3,WebkitBoxOrient: "vertical" },
					iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px'}
		  
});



type TabPanelProps = {
  children?: React.ReactNode;
  index: any;
  value: any;
};
function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`scrollable-prevent-tabpanel-${index}`}
      aria-labelledby={`scrollable-prevent-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={3}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}
function a11yProps(index) {
  return {
    id: `scrollable-prevent-tab-${index}`,
    'aria-controls': `scrollable-prevent-tabpanel-${index}`,
  };
}

export default function AssignmentScreen(props) {
  const classes = useStyles();
  const [msg, setErr] = React.useState(false);
  const [view, setView] = React.useState("list");
   
	const location = useLocation();
	const history = useHistory(); 
  const [, forceUpdate] = React.useState(null);
  const [loading, setLoader] = React.useState(true);
  const [assignment_list, setAssignmentList] = React.useState([]);
  const [requested_assignment, setRequested_assignment] = React.useState([]);
  const [upload_view, setUpload] = React.useState(false);
  const [tab_req, setTab] = React.useState(![undefined].includes(location.state['tab_request'])?location.state['tab_request']:"module");
  const [value, setValue] = React.useState(tab_req=="module"?0:tab_req=="chapter"?1:2);

	let module_level_id=location.state['module_level_id']	
	let module_name=location.state['module_name']	
	const quizCallback = async () =>
	{
	}
	const open_assignment=(e)=>{
		
		let k=[]
		let ac="add"
		if((e['children']).length>0)
		{
			k=(e['children'])[0]
			ac="update"
		}
		
		
		k['assignment_title']=e['assignment_title']
		k['assignment_id']=e['assignment_id']
		k['request']=ac
		
	    // alert("1")
		 get_assignment_doc(k)

	}
	const verify_unit_completion=(e)=>
	{
		let payload={
		'chapter_id':e['chapter_id'],
		'module_level_id':e['module_level_id'],
		'course_id':location.state['course_searched'],
		'student_id':student_id,
		'test':'data'}
		//console.log(e)
		if(tab_req=="chapter")
		{	
			axios.post(api+'/get_if_marked_completed',payload)
			.then((response) => {	
                
				//alert("bh")
				//console.log(payload)
				//console.log(response.data)
				if(parseInt(response.data['is_completed'])==100)
				{
					open_assignment(e)
				}
				else
				{
					setErr("You must complete modules unit to access in-class activities.")
					forceUpdate(n=>!n);
					setTimeout(() => {setErr(false)}, 2000);			
				}
			})	
		}
		else
		{
				open_assignment(e)
		}
	}
	const open_module = async (path) =>
	{
		let parent_level_id=location.state['parent_level_id']
		parent_level_id=parent_level_id.split("_")
		path=path.split('-')
		path.shift()
		let current_module=path[1]
		let courseurl=generateCourseUrl(location.state['course_searched_name']);
		let param={
						course_searched_name:location.state['course_searched_name'],
						course_searched:location.state['course_searched'],
						module:path,
						module_id:[parseInt(parent_level_id[1])]
				  }
		//console.log(param)
    	//alert(param['module'])
		history.push({
				  pathname:'/courses/'+courseurl,
				  state: param
		})	
			
	}
	const handleChange = (event, newValue) => {
		setAssignmentList([])
		let d=newValue==1?"chapter":newValue==0?"module":"quiz"
		setTab(d);
		setValue(newValue);
		if(d!="quiz")
		{
			get_default_assignment(d)
		}
		
		//console.log(newValue)
	//alert("test")
	}; 	
	const getCourse = () => {	
	
		var id=location.state['course_searched']
		var course=location.state['course_searched_name']
	    let courseurl=generateCourseUrl(course);
			history.push({
			  pathname:'/courses/'+courseurl,
			  state: {
					course_searched_name:course,
					course_searched:id,
			  },
			})	

		
	}
	const get_default_assignment=(tab_request)=>
	{
		
		//alert(tab_request)
		let key="assignment_"+tab_request+"_"+module_level_id+"_grade_added_"+0+"_to_"+40+"_"+status+"_"+student_id
		let param=["module_level_id",module_level_id,"grade",0,40,tab_request]
		//console.log(param)
		getCacheDetails(key,"get_asssigment_added",param)
		.then(data => {
			//alert("jh")
			////console.log(param)
			//localStorage.removeItem(key);
			
			//console.log(data)
			setTimeout(() => { setLoader(false) }, 1500);

	
					if(data.length>0)
					{
						setAssignmentList(data)
						setLoader(false) 
					}
					else
					{
						setErr("Activities are not added here.")
						setTimeout(() => {setErr(false)}, 2000);						
					}
				
		})	
	}
	
	const addDefaultSrc=(ev)=>{
	  ev.target.src = images['sm_img']
	}
//setRequested_assignment
	const render_upload_card=async()=>{
		let payload={
				'student_id':student_id,
				'assignment_id':view['assignment_id'],
				'key':student_id+"_"+view['assignment_id']+"_assignment",
				'action':"submitted_id"
		}
		//console.log(payload)

		/*	let res = await axios.post(api+'/get_submitted_assignment',payload);
		    let data  = res.data;
			//console.log(data)
			if(data.length>0)
			{
				data.request="update"
				setRequested_assignment(data)
			}
			else
			{
				setRequested_assignment({request:"add"})
			}
			*/
					//console.log(requested_assignment)

			if(requested_assignment==[])
			{
				setRequested_assignment({request:"add"})
			}
			else
			{
				//console.log(requested_assignment)
				requested_assignment.request="update"
				setRequested_assignment(requested_assignment)
			}
			
			//console.log(requested_assignment)
			forceUpdate(n=>n+1)
		    setUpload(true)
	}
	
	const view_assignments=()=>{
		get_default_assignment(tab_req);
		setView("list")
	}
	const upload_callback=(action)=>{
		setUpload(false)
		if(action=="list")
		{
			view_assignments();
		}
	}
	
	const get_assignment_doc = async (response) =>
	{
		//alert("2")
			let payload={
				'assignment_id':response['assignment_id']
			}
						//console.log(payload)

			let res = await axios.post(api+'/get_assignment_doc',payload);
		    let data  = res.data;
			//console.log(data)

			setView(response)
			setRequested_assignment(data)
			
			//console.log(response)
			//console.log(view)
			forceUpdate(n=>n+1)

	}
	
	

  useEffect(() => {
	if(tab_req!="quiz")
	{
		get_default_assignment(tab_req)
	}
	
	 //console.log(location.state)
  },[]);
  
    const RenderChip = ({e}) =>
	{
		return(
		<Box marginLeft={2}>
		<Chip 
			key={e['parent']=="module"?"Cumulative Activity":"In-Class Activity"} 
			className={e['parent']=="module"?classes.chipboxs:classes.chipboxp} 
			size="medium"
			label={
			<Typography className={classes.chipdata}
			variant="subtitle2" 
			style={e['parent']=="module"?{color:successColor}:{color:secondaryColor}}>
			{e['parent']=="module"?"Cumulative Activity":"In-Class Activity"}
			</Typography>
			} 
			clickable />
		</Box>
		)
	}

	const CommonTop =()=>{
	  let arr=location.state['path'].split('-')
	  arr.shift();
	  return(
		<Box display="flex"  >
		<Grid container>
		<Grid item xs={12} md={8}>
			<Box  >
			<Link  onClick={()=>{open_module(location.state['path'])}} style={{cursor:'pointer'}} >
				<Typography variant="h6" color="textPrimary">
				   <Box fontWeight="600" marginBottom={1} fontSize="18px" lineHeight={1.2}>
				   {location.state['course_searched_name']}
				   </Box>
				</Typography>
			</Link>
				
				<Link 
				 onClick={()=>{open_module(location.state['path'])}}
				 style={{cursor:'pointer'}} >
				<Typography variant="h6" color="primary">
				   <Box fontWeight="600" marginBottom={1} fontSize="22px" lineHeight={1.2}>
				   {module_name} 
				   </Box>
				</Typography>
				</Link>
				
				<Box display="flex" alignItems="center" marginTop={0} color="rgb(90, 105, 120)">
				<HomeIcon fontSize="small" style={{marginTop:"-2px",color:'rgb(90, 105, 120)',marginRight:'4px'}} /> {location.state['course_searched_name']} / {module_name} / {view!='list'?view['assignment_title']:"All Activities "}
				</Box>
		</Box>
		</Grid>
		
		<Grid item xs={12}md={4}>
		<Box 
		flexGrow={1}
		display="flex"
		justifyContent="flex-end"
		height="fit-content">
				
				<ButtonsSection />
		</Box>
		</Grid>
		</Grid>
		</Box>
		
				
	  )
	}  
   	const ButtonsSection= () =>{
		return(
		<Box>
			{
				view!="list"
				?
				    <Box marginBottom={2} display="flex">
				    <Box flexGrow={1} paddingRight={2}>
						<Avatar onClick={()=>{view_assignments()}}
						className={classes.avatar2}>
						<FaChevronLeft />
						</Avatar>
					</Box>
					 <Box display={view['msg']!='error' && !upload_view ?"flex":"none"}>
						<Button variant="contained" size="large"   color="primary" 
						onClick={()=>{render_upload_card()}} 
						disabled={[undefined,'',null].includes(requested_assignment['document'])?true:false}>
						Upload Assignment Now
						</Button>
					</Box>
					</Box>
				:""
			}
			
			</Box>
		)
	}
  
	const Tables = () => {
		return (
		<>		
		{assignment_list.length>0 && assignment_list.map((e, index) => (
		<StyledTableRow>
		
		<StyledTableCell  >
		<Link className={classes.link} onClick={()=>{open_assignment(e)}}>
			{e['assignment_title']}	
		</Link>
		</StyledTableCell>
        
			 	     
		<StyledTableCell  >
		{(e['children']).length>0?(e['children'])[0]['file']:"File not found."}	
		</StyledTableCell>
         	 	     
		<StyledTableCell  >
		{
			(e['children']).length>0
			?
				<>
				{(e['children'])[0]['status']}
				<br/>
				{moment((e['children'])[0]['added_on']).format("lll")}
				</>
			:
				"Pending"
		}	
		</StyledTableCell>
             
		 	     
		<StyledTableCell  >
		{(e['children']).length>0?(e['children'])[0]['grade']:"0"}	
		</StyledTableCell>
             	     
		<StyledTableCell  >
		{(e['children']).length>0?(e['children'])[0]['comments']:"-"}	
		</StyledTableCell>
             
		
            </StyledTableRow>
		))}
		</>
		)
		}
		
	const TablesSkeleton = () => {
		return (
		<>
		{[0,1,2,3,4].map((e, index) => (
		<StyledTableRow>
		{[0,1,2,3,4].map((e, index) => (
		<StyledTableCell  >
		<Skeleton height={50} width={"100%"} />
		</StyledTableCell>
		))}
        </StyledTableRow>
		))}
		</>
		)
	}		
	const MiddleSection = ({e}) => {
		let param=[
		{'key':"File Submission",'data':(e['children']).length>0?(e['children'])[0]['file']:"File not found."},
		{'key':"Status",'data':(e['children']).length>0?(e['children'])[0]['status']:"Pending"},
		{'key':"Grade",'data':(e['children']).length>0?(e['children'])[0]['grade']:"0"}]
		return (
		<Box padding={2}>
			
			<Grid xs={12} container>		
			{param.map((e, index) => (
			<Grid item xs={12} sm={4}>	
			<Box display="flex" justifyContent="center" textAlign="center">
			<Box marginTop={2}>
			<Box fontSize="16px" fontWeight="700" color="#2b333f"> {e['key']}</Box>
			<Box fontSize="14px" color="primary">
			{
				e['key']=="Status"
				? e['data']=="Pending"
					?e['data']
					:<Typography style={{color:"green"}}  variant="subtitle2">{e['data']}</Typography>
				:
					e['key']=="Grade"
					?<Rating size="small" 
					readOnly
					name="customized-10"
					defaultValue={e['data']} max={10} 
					/>
					:e['data']
			}
			</Box>
			</Box>
			</Box>
			</Grid>
			))}
			</Grid>
			
			<Grid item xs={12}>
			<Box marginTop={3} display="flex" alignItems="center">
			
			<Typography variant="subtitle1" color="textPrimary">
			<Link className={classes.link} onClick={()=>{open_assignment(e)}}>
			{e['assignment_title']}	
			</Link>
			</Typography>
			
			<RenderChip e={e} />
			</Box>
			
			<Box onClick={()=>{open_assignment(e)}}>
			<Typography variant="subtitle2" color="textSecondary" className={classes.details}>
			{[undefined,null,''].includes(e['assignment_desc'])?"":(e['assignment_desc']).replaceAll("&nbsp;","")}...
			</Typography>
			</Box>
			
			{(e['children']).length>0?
			![undefined,null,''].includes((e['children'])[0]['comments'])
			?<Box marginTop={1}>
			<Typography variant="subtitle2" style={{color:'green',display:'flex'}}>
			<Box fontWeight="bold" >Author Comments : </Box>
			<Typography color="textSecondary" variant="subtitle2">&nbsp; {" "+(e['children'])[0]['comments']}	</Typography>		
			</Typography>
			</Box>:""
			:""}
			
			</Grid>
			
		</Box>
		)
	}	
	const CardRender = () => {
		return (
		<>
		{assignment_list.length>0 && assignment_list.map((e, index) => (
		<Box marginTop={2}>
		<Card className={classes.cardrender}>
		<Grid container  
		  direction="row"
		  justifyContent="space-between"
		  alignItems="center">
		
			<Grid item xs={12} sm={"auto"}>
				<Box 
				width="fit-content"
				style={{backgroundColor:'rgba(37, 56, 124, 0.04)'}} 
				margin={2}
				padding={2}
				borderRadius={"12px"}>
				<DescriptionOutlinedIcon  
				color="primary" 
				style={{fontSize:'120px'}} />
				</Box>
			</Grid>
		
			<Grid item xs={12} sm={9} >	
				<MiddleSection e={e} />
			</Grid>
			
			<Grid item
			xs={12} 
			sm={"auto"} 
			style={{backgroundColor:'rgba(37, 56, 124, 0.04)',height:'221px'}} >
				<Box 
				display="flex"
				height="100%"
				alignItems="center"
				>
				<Box padding={2} style={{cursor:'pointer'}}
				onClick={()=>{open_assignment(e)}}>
				<FaChevronRight  
				color="primary" 
				style={{fontSize:'16px'}} />
				</Box>
				</Box>
			</Grid>
		
			
		
		</Grid>
		
		</Card>
		</Box>
		

		))}
	</>)
	}
		
 	const AssignmentList= () =>{
				let colors=["#4caf50","#2196f3","#ffb74d",'#f44336']

		return(
		<Box  padding={4} >
			{/*<TableContainer component={Paper}>
						  <Table className={classes.table} aria-label="customized table">
							<TableHead>
							  <TableRow>
								<StyledTableCell >Assignments</StyledTableCell>
								<StyledTableCell >Uploaded Doc</StyledTableCell>
								<StyledTableCell >Status <br/>(Pending/Submitted/Evaluated)</StyledTableCell>
								<StyledTableCell >Grade after Evaluation <br/>(Between 1 to 10)</StyledTableCell>
								<StyledTableCell >Comments</StyledTableCell>
							  </TableRow>
							</TableHead>
							<TableBody>
				{
					loading==false && 
					![undefined,null,[],''].includes(assignment_list) 
					?assignment_list.length>0?<Tables />:<TablesSkeleton />
					:<TablesSkeleton />
			  
				}
				</TableBody>
							 </Table>
							</TableContainer>
			*/}	
					<CardRender/>
				</Box>
	)};
		
 		
 	const OpenView= () =>{
		var content=requested_assignment['content'];
		return(
		<Card >
		<CardContent>			
		<CommonTop />
		

			{
				view['msg']!='error'   &&
				![undefined,'',null].includes(requested_assignment['document'])
				?	<Box marginTop={2} padding={2} className="ck-custom">
						<CKEditor
										className="ckeditor-render"
									  editor={Editor}
									  data={content}
									  disabled={true}
									  config={{
										toolbar: null
									  }}
									/>
					{/*<div class="assignment_content" dangerouslySetInnerHTML={{ __html: content }} />
					*/}
					</Box>
				: <Box display="flex" justifyContent="center"  marginTop={2}>
							<Typography  variant="subtitle1" color="textSecondary">
							Activity content not found.
							</Typography>
				</Box>
			}
			
		</CardContent>	
		</Card>
		
		)
	}		
 	
		
 	const ManageQuiz = () =>{
		return(	
		<>
		{
			loading==undefined
					?		
						<Box display="flex" justifyContent="center" padding={2}>
						<CircularProgress color="primary" />
						</Box>
					:
						
							<Box >
							<Box 
							justifyContent="center" 
							display={assignment_list.length==0 && loading==false?"block":"none"}>
							<Typography  variant="subtitle1" color="textSecondary">
							No activity have been added in {module_name}
							</Typography>
							</Box>
							
							<AssignmentList />
							</Box>
		}
		</>
		)
	}
		
 	const ManageActivity = () =>{
		return(	
		<>
		{
			loading==undefined
					?		
						<Box display="flex" justifyContent="center" padding={2}>
						<CircularProgress color="primary" />
						</Box>
					:
						
							<Box >
							<Box 
							justifyContent="center" 
							display={assignment_list.length==0 && loading==false?"block":"none"}>
							<Typography  variant="subtitle1" color="textSecondary">
							No activity have been added in here.
							</Typography>
							</Box>
							
							<AssignmentList />
							</Box>
		}
		</>
		)
	}
 	const ListData= () =>{
		return(
		<Card>
		<CardContent className={classes.card}>
		
		<CommonTop /> 	
		
			<div className={classes.root}>
			<Grid container spacing={3} >
				
				<Grid item xs={12} sm={12}  >
					
				<AppBar position="static" color="inherit" elevation={0}  
				style={{backgroundColor:'transparent'}}>
				<Tabs value={value} onChange={handleChange} 
				indicatorColor="primary" textColor="primary" >
										 
				<Tab  className={classes.width}  label= {<Box fontWeight="600" >
				<BsFileEarmarkRuledFill className={classes.tab +" "+classes.cumlative} fontSize="18px"  /> 
				Cumulative Activites</Box>}
				className={classes.m+' '+classes.global}  {...a11yProps("module")} />
							  
				<Tab  className={classes.width} label= {<Box fontWeight="600">
				<RiFileEditFill className={classes.tab+" "+classes.inclass} fontSize="medium"  /> 
				In-Class Activites</Box>}
				className={classes.global}  {...a11yProps("chapter")} />
				
				<Tab  className={classes.width} label= {<Box fontWeight="600">
				<MdQuiz className={classes.tab+" "+classes.quiz} fontSize="medium"  /> 
				Quizzes</Box>}
				className={classes.global}  {...a11yProps("quiz")} />
				
				</Tabs>
				</AppBar>
									
			</Grid>
			
			<Grid item  xs={12} sm={12} >
			{
				tab_req=="quiz"
				?
					<QandA 
					handleQuiz={quizCallback}
					chapter_data={location.state} />
				:
					<ManageActivity />
		    }
		   </Grid> 
		   
		</Grid>
		</div>

		
		</CardContent>	
		</Card>
		)
	}


  return (
  	<Box>
	{
		msg!=false 
		?<AlertBox 
		show_status={true} 
	    msg={msg}
	    msg_type={"warning"} />
		: ''
	}
	
		
		{/*<Box marginBottom={2}>
				<Link onClick={()=>{getCourse()}} style={{cursor:'pointer'}} >
				<Typography variant="subtitle1" color="primary">
				   <Box fontWeight="600" >{location.state['course_searched_name']} </Box>
				</Typography>
				</Link>
				</Box>*/}
	
	
	{
		view=="list"
		?
			<ListData />				
		
		:
			<Grid container spacing={2} justifyContent="center">
			
				<Grid item xs={12} sm={upload_view==true?7:12} >
				<OpenView />
			    </Grid>
				
				{
					upload_view==true?
					<Grid item xs={12} sm={upload_view==true?5:12} >
					<Sticky boundaryElement=".assignment_content" hideOnBoundaryHit={false} >
					<AssignmentUpload
					user_project={view} 
					requested_assignment={requested_assignment} 
					parentCallback={upload_callback} />
			        </Sticky>
					</Grid>
					:""
				}
			
			</Grid>
				
	}
					
	</Box>
  );
}