import { Avatar, Box, Grid, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import {
  createTheme, makeStyles
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import { useLocation } from 'react-router-dom';
import { getCacheDetails } from "../../caching";
import SocialIconsGrid from "./SocialIconsGrid";

import Appbar from "../../../functions/homepage/wrapper/Appbar";
import Footer from "../../../functions/homepage/wrapper/Footer";


const useStyles = makeStyles((theme) => ({
  box:{position:'absolute',bottom:'15%',left:'7%'},
  root: {
    flexGrow: 1,
	minHeight:"100%",
	'background-color':'white'
	
  },
  paper: {
  'border-radius':"12px",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: 
  {
	'border-radius':"12px !important",
	position:'relative',
	backgroundColor:'rgb(0 0 0 / 75%)',
	width:"100%",height:"400px"
 },
  img: {	
	'border-radius':'12px',
	margin: 'auto',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	width: 'inherit',
	height: 'inherit',
  },
  card: {
 
   padding: '16px',
   margin: '16px 24px 16px 16px',
  
  }, 
  card1: {
 
  // padding: '16px',
   margin: '16px 0px 16px 0px',
  
  },  
   avatar: {
 
   height: '170px !important',
   width: '170px !important',
   border:'2px solid #eee !important',
	'background-color': '#254180 !important'
  
  },avatarInner: {
 
   height: '130px !important',
   width: '130px !important',
      border:'3px solid #eee !important',
  
  },
 footer:{backgroundColor:'#4B74E7  !important'},
 fb:{backgroundColor:'#38539E  !important'},
 tw:{backgroundColor:'#3AA9F0  !important'},
 ln:{backgroundColor:'#287AB8  !important'},
 insta:{backgroundColor:'#fff  !important',color:'pink  !important'},  link:{textDecoration: "none !important"}

}));

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
	MuiDialog:{paperWidthXs: {
    'border-radius': '12px'}}
  },
});

let webinar_id='';
let seconds='';

class AccordionBlock extends Component {
    constructor(props) {
    super(props);

	this.state={speaker_list:[]}}
    
  	componentDidMount()
	{
        if(this.props.location.state===undefined)
        {
            this.getWebSpeaker()
            //console.log("is undefined "+this.props.location.state)
        }  
        else
        {
            //console.log(this.props.location.state['webinar_id'])
            this.getWebDetails(this.props.location.state['webinar_id'])
        }

        if(localStorage.getItem('login_token')=='admin')
        {
            window.location='/schedule-webinar'
        }
         //this.getWebSpeaker()
	}
    getWebDetails = (webinar_id) => {
        
        let data={
            'key':"webinar_detail_"+webinar_id,
            'webinar_id':webinar_id,
            'user':'speakers'
        }
        
        getCacheDetails("getWebinarDetails",data)
		.then(data =>  {
         if(data['isValidID']=='yes')
         {
            this.setState({
                speaker_list:data['speaker_data'],
                webinar_id:webinar_id,
            })
         }
        
		})
        
	};

    getWebSpeaker = () => {
        
        
        let data={
            'key':"webinar_speakers",
        }
        
        getCacheDetails("getWebinarSpeaker",data)
		.then(data =>  {
		this.setState({speaker_list:data})
		// //console.log(data)
		})
        
				
	};	
     
	
	
	
	render(){
	const classes = this.props.classes;
	const location = this.props.location;
	const styles={
		image: {
			position:'relative',
			 width:"100%",height:"100%",
			 borderRadius:'25%'
		  },
		 
		  img: {
			margin: 'auto',
			display: 'block',
			maxWidth: '100%',
			maxHeight: '100%',
			width: 'inherit',
			height: 'inherit',
		  },
	}	

	const NewCard = () => {
	return ( 
 
		<div className={classes.root}>
        
        
        <Grid container 
		direction="row"
			  alignItems="center"
			  justify="center" spacing={0}>
	
		<Grid item xs={12} >
		<Box justifyContent= 'center' textAlign="center" display="flex" marginTop={8}>
			<Typography variant="h4" style={{color:'#25387C'}} >
			Thank you for signing up for our webinar!
			</Typography>
        </Box>
        
       <Box justifyContent= 'center' textAlign="center" display="flex" marginTop={0} marginBottom={4}>
        <Typography variant="h6" color="textSecondary" >
			We will send you a confirmation email shortly.
        </Typography>
        </Box>
       </Grid>	
	
       		
		{
		
        this.state.speaker_list.map((e, index) => (
	
        <Grid item xs={12} >
	    
            <Box  display="block" textAlign="center" justifyContent="center"  marginBottom={4}>
					
					<Box marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.avatar}>
					<Avatar  className={classes.avatarInner}
					src={'assets/images/speakers/'+e['speaker_img']} />
					</Avatar>				
					</Box>
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box  marginTop={2} lineHeight={1.5}> {e['speaker_name']}</Box>
					 </Typography> 
					
					 <Typography  variant="subtitle1" color="textSecondary" gutterBottom > 
					 <Box lineHeight={1.5} > {e['speaker_desc']}</Box>
					 </Typography> 
					
					</Box>
        </Grid>	  
        ))	
		}
			
		<Grid item xs={12} >
	    
            <Box  display="block" textAlign="center" justifyContent="center" >
					
					<Typography  variant="subtitle1" color="textSecondary" > 
					 <Box  marginTop={4}  marginBottom={2} lineHeight={1.5}>Connect with us:</Box>
					 </Typography> 
                     
                    <SocialIconsGrid />
										
			</Box>
        </Grid>	       		
		</Grid>
        
		
       

		</div>
				
		)}

       return(
         <NewCard/>
	   );
}
}
export default () => {
    const classes = useStyles();
     const location = useLocation();

    return (
        <AccordionBlock classes={classes} location={location} />
    )
}

  