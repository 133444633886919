// src/components/FAQSection.jsx

import React from 'react';
import {
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  makeStyles,
  useTheme,
  useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import useGlobalStyles from '../styles/CourseStyles';
import { defaultFaqData, contextFaqData } from './FaqData'; // Import FAQ data

const useStyles = makeStyles((theme) => ({
  faqContainer: {
    padding: theme.spacing(4),
    paddingTop: theme.spacing(8),
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: theme.spacing(4),
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 8,
      paddingRight: 8,
    },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    padding: theme.spacing(2),
    textAlign: 'center',
    backgroundColor: '#f9bb02',
    borderRadius: '4px',
  },
  subtitle: {
    marginBottom: theme.spacing(4),
    color: '#555',
    textAlign: 'center',
  },
  accordion: {
    marginBottom: theme.spacing(2),
    alignItems: 'center',
    boxShadow: 'none',
    '&:before': {
      display: 'none',
    },
  },
  accordionSummary: {
    backgroundColor: '#f5f5f5',
    textAlign: 'center',
    borderRadius: 8,
    minHeight: 56,
    '& .MuiAccordionSummary-content': {
      margin: 0,
    },
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px',
    },
  },
  accordionDetails: {
    padding: theme.spacing(2),
    backgroundColor: '#fafafa',
    borderRadius: '0 0 8px 8px',
  },
  question: {
    color: '#000',
    fontFamily: 'MyCustomFont, sans-serif',
  },
  answer: {
    fontSize: '1.2rem',
  },
}));

const FAQSection = ({ context }) => { // Accept context as a prop
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  // Determine which FAQ data to use based on context
  const faqData = React.useMemo(() => {
    if (context && contextFaqData[context]) {
      return contextFaqData[context];
    }
    return defaultFaqData;
  }, [context]);

  return (
    <Box className={classes.faqContainer}>
      {/* Title and Subtitle */}
      <Typography variant="h4" className={`${styleClasses.h4font} ${classes.title}`}>
        Frequently Asked Questions
      </Typography>

      {/* Optional Subtitle (You can modify or remove this as needed) */}
      {/* <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitle}`}>
        Here are some common questions we receive
      </Typography> */}

      {/* FAQ Accordions */}
      {faqData.map((faq, index) => (
        <Accordion key={index} className={classes.accordion}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            className={classes.accordionSummary}
          >
            <Typography variant='h6' className={styleClasses.h6font}>{faq.question}</Typography>
          </AccordionSummary>
          <AccordionDetails className={classes.accordionDetails}>
            <Typography variant='subtitle1' className={styleClasses.subtitlefont}>{faq.answer}</Typography>
          </AccordionDetails>
        </Accordion>
      ))}
    </Box>
  );
};

export default FAQSection;
