import {
  alpha,
  Box, Button,
  Card, CardContent, CardHeader,
  FormControl,
  InputLabel,IconButton,
  MenuItem,makeStyles,
  Paper,
  Select,
  Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import React, { Component } from 'react';
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
import AlertBox from "../../functions/AlertBox";
import { api, translateText } from "../../functions/Helper";







const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
     search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
	     backgroundColor: 'transparent',

    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	color:'primary'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize:'14px',
	padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },

}));

class Logs extends Component {

    constructor(props) {
	super(props);
	this.translateText = translateText.bind(this);
	this.state={
		    msg:false,msg_type:false,
            user_show:[],user_count:0,start:0,end:10,
			hasMore: true,record_len:0,webinar_list:[],webinar_id:null,
			search_for:'All',search:null,}
    }
	
    deleteUser = (email) => {
    
        axios.post(api+'/deleteUser',{
			'email':email,
		})
		.then((response) => {
		this.setState({msg:response.data['msg']})
		this.state.msg_type=response.data['type']	
		//console.log(response.data);
        
		this.getUsers('all')
        	setTimeout(() => {
			this.setState({msg:false})
			}, 3000);
        } )
	};	
	getWebinars=()=>
    {
			axios.post('/api/scheduleWebinar',
            {
                search:'select',
                start:0,
                end:100,
            })
            .then((response) => {
			//console.log(response.data)
			this.setState({webinar_list:response.data})
            //alert(this.state.webinar_list[0]['title'])
			}); 
    }
   
	getUsers=(id)=>
    {
			axios.post('/api/getWebUser',
            {'webinar_id':id})
            .then((response) => {
			//console.log(response.data)
			this.setState({user_show:response.data})
            this.setState({user_count:response.data.length})	
            if(response.data.length>0)
            {
                this.setState({msg:'Below are the search result!'})
            } 
            else
            {
                this.setState({msg:'No result found!'})
            }    
			});
            setTimeout(() => {this.setState({msg:false})}, 2000);

    }
    handleSubmit=()=>
    {
         if(this.state.webinar_id==null)
         {
             this.setState({msg:'Please choose the webinar title.'})
             this.setState({msg_type:'warning'})
         }
         else
         {
           this.getUsers(this.state.webinar_id)  
         }
         setTimeout(() => {this.setState({msg:false})}, 2000);

    }
   
	
	componentDidMount()
	{
		this.getUsers('all')
        this.getWebinars()
	}
  	render()
  	{
	
		const classes = this.props.classes;
       
		const Tables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
			
             <StyledTableCell  >{e['webinar_id']}</StyledTableCell>
             <StyledTableCell  >{e['name']}</StyledTableCell>
			  <StyledTableCell  >{e['email']}</StyledTableCell>
			  <StyledTableCell  >{e['user_status']}</StyledTableCell>
			  <StyledTableCell  >{e['added_on']}</StyledTableCell>	
              	 
                 {/*<StyledTableCell  >
            {email_list.includes(e['email'])==true
                ?
                    <IconButton  onClick={()=>{this.deleteUser(e['email'])}}>
                            <CloseIcon color="grey"   />
                    </IconButton>
             :''}
        </StyledTableCell> */}
        
        <StyledTableCell  >
            <IconButton  onClick={()=>{this.deleteUser(e['email'])}}>
                            <CloseIcon color="grey"   />
           </IconButton>
        </StyledTableCell>
			 
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				{"Webinar registered users ("+this.state.user_count+")"}
				</Typography>
				</Box>
				
				
				<Box display="flex" >
                <Box marginRight={1} minWidth="200px">
                <FormControl variant="outlined" fullWidth>
					<InputLabel>Webinars</InputLabel>
					<Select name="webinar_id"
					onChange={event=>this.setState({webinar_id:event.target.value})}
					label="Webinar title"
					value={this.state.webinar_id}
                    >
					{this.state.webinar_list.map((e, index) => (
					<MenuItem value={e['webinar_id']}>{e['title']}</MenuItem>
					 ))}
					</Select>
				</FormControl>
				 </Box>
				
                <Box>
                <Button  
				variant="contained" 
				color="primary" 
                size="large"
				onClick={this.handleSubmit}>
				Get users
				</Button>
				</Box>
				</Box>
 
				
		   </Box>			
			}/>
        
		<CardContent>
		
        
           <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="download-table-xls-button"
                    table="table-to-xls"
                    filename={"webinar_registration_list_"+this.state.webinar_id}
                    sheet="tablexls"
                    buttonText="Export registered user"/>
            
        <TableContainer component={Paper} >
				  <Table className={classes.table} aria-label="customized table"  id="table-to-xls">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Webinar ID</StyledTableCell>
						<StyledTableCell >Name</StyledTableCell>
						<StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >User status</StyledTableCell>
                        <StyledTableCell >Added on</StyledTableCell>
						<StyledTableCell > </StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
            </Table>
		  </TableContainer>	
				
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}