import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Autolink from 'react-native-autolink';
import { makeStyles,Avatar, Box, CircularProgress, List, ListItem, ListItemAvatar, ListItemText, Typography } from '@material-ui/core';
import { api, userProfile_path } from '../../Helper';
import AlertBox from "../../AlertBox";

const useStyles = makeStyles((theme) => ({
 card: {
    border: '1px solid #ebebeb',
    borderRadius: '12px',
    padding: '8px',
    backgroundColor: '#fff9f0'
  },
  msg:{
	   lineHeight:'1rem',
	   color:'black'
   }
}));

    
const ReplyCard = ({ msg_id, chat_id,handleReplyCallback }) => {
	const classes = useStyles();
	const [btnLoading, setBtnLoading] = useState(false);
	const [dataList, setDataList] = useState([]);
	const [start, setStart] = useState(0);
	const [hasMore, setHasMore] = useState(true);
	const end = 8;
	const fetchData = (newStart) => {
	  const payload = { msg_id, chat_id, length: dataList.length, start: newStart, end };
	  return axios.post(api + '/get_message_replies', payload)
		.then(response => {
		  if (response.data.length === 1 && response.data[0].status === "No result found") {
			return [];
		  } else {
			return response.data.reverse();
		  }
		});
	};

	const fetchPrevMsg = () => {
	  setBtnLoading(true);
	  fetchData(start + end)
		.then(res => {
		  const updatedDataList = [...res, ...dataList];
		  setHasMore(res.length >= end);
		  setDataList(updatedDataList);
		  setStart(start + end);
		})
		.finally(() => setBtnLoading(false));
	};

	useEffect(() => {
	  setStart(0);
	  fetchData(0)
		.then(res => {
		  if (res.length > 0) {
			setDataList(res);
			setHasMore(res.length >= end);
		  }
		});
	}, [msg_id, chat_id]);



  const Message = ({ text }) => (
    <Autolink 
	className={classes.msg}
	text={text} email hashtag="instagram" mention="twitter" phone="sms" url />
  );

  const FetchPrevMsgButton = () => (
    <Box display="flex" justifyContent="end" width="100%" marginTop={2} marginBottom={2}>
      {hasMore && (
        <Box
          fontSize="13px"
          fontWeight={700}
          color="#253B7C"
          style={{ cursor: 'pointer' }}
          disabled={btnLoading}
          onClick={fetchPrevMsg}
        >
          {btnLoading ? <CircularProgress /> : 'Fetch Previous Replies'}
        </Box>
      )}
      {!hasMore && (
        <Box marginBottom={2}>
          <Typography variant="subtitle2" color="textSecondary">
            There are no new replies discovered!
          </Typography>
        </Box>
      )}
    </Box>
  );

  return (
    <Box>
    {
		btnLoading 
		?	  
			dataList.length === 0 
			?		
				<Box marginTop={2} marginBottom={2} sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column' }}>
				  <CircularProgress color="primary" size={24} />
				</Box>
			:
				""
		: 
			dataList.length === 0 
			?
				<Box marginBottom={2}>
				  <Typography variant="subtitle2" color="textSecondary">
					There are no replies discovered!
				  </Typography>
				</Box>
			:''
			
    }

      {dataList.length > 0 && (
        <React.Fragment>
          {dataList.length > 7 && <FetchPrevMsgButton />}
          <List>
            {dataList.map((chat, index) =>
              chat !== undefined ? (
                <ListItem
                  key={chat.id}
                  alignItems={'flex-start'}
                  style={{ padding: '0px' }}
                >
                  <ListItemAvatar>
                    <Avatar
                      alt={chat.name}
                      src={userProfile_path + chat.img}
                    />
                  </ListItemAvatar>
                  <ListItemText
                    primary={
                      <Box width="100%" padding="8px 0px">
                        <Box minWidth="100%" width="fit-content" className={classes.card}>
                          <Box>
                            <Typography color="textPrimary" variant="caption">
                              <Box display="flex" justifyContent="space-between">
                                <Box fontWeight="bold">
                                  {chat.name}
                                </Box>
                                <Box marginLeft={1} color="text.secondary">
                                  {chat.added_on}
                                </Box>
                              </Box>
                              <Box marginTop={1} color="text.secondary">
                                <Message text={chat.chat} />
                              </Box>
                            </Typography>
                          </Box>
                        </Box>
                      </Box>
                    }
                  />
                </ListItem>
              ) : null
            )}
          </List>
        </React.Fragment>
      )}
    </Box>
  );
};

export default ReplyCard;
