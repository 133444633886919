import { Box, Button, Card, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import axios from 'axios';
import { UserContext } from '../../functions/courses/UserContext';
import React, { Component, useContext } from 'react';
 import cookie from 'react-cookies';
import { useHistory } from 'react-router-dom';

import CountdownTimer from "react-component-countdown-timer";
import AlertBox from "../../functions/AlertBox";
import { successColor,api } from "../../functions/Helper";
import './timer.css';


const useStyles = makeStyles((theme) => ({
  card1: {
   margin: '16px 0px 16px 0px',
  },  
  timer:{fontFamily:'Public Sans,sans-serif !important'},
  success:{
      'box-shadow': 'rgb(229 249 229) 0px 8px 16px 0px',
      color:'#fff',backgroundColor:'#4caf50',
      "&:hover":{backgroundColor:'#2e7d32'}
   }

}));
//let webinar_data=JSON.parse(localStorage.getItem("webinar_timer_de"))
let webinar_data=cookie.load('webinar_timer_de')
let user_data=JSON.parse(localStorage.getItem("ac_login_user"))
if([undefined,null,''].includes(webinar_data))
{
	axios.post('/api/checkTimer')
                .then((response) => {	
		cookie.save('webinar_timer_de',
		response.data, { path: '/' })
		webinar_data=response.data
	})
	
}	
let msg='Webinar will start soon. Please stand by!';
let arr=[];
class AccordionBlock extends Component {
constructor(props) {
    super(props);

	this.state={webinar_list:[],speaker_list:[],loading:false,
                 
                 seconds:null,
                 exceded:null,
                 started:null,
                 IST:null,
                 GMT:null,
                 PST:null,
                 webinar:null,
                 webinar_id:null,
                 img:null,
				 willStartOn:null,
				 willEndOn:null,
				 is_webinar_started:null,
				 is_webinar_ended:null,
                 
    session_status:'',msg:msg,msg_admin:false,msg_type:"success",
    email:null,closed_at:null,
	isModerator:false,
    

        
    }}

	UNSAFE_componentWillMount()
	{
		//alert(cookie.load('webinar_timer_de'))
        if(user_data==null )
        {
          // window.location='/webinars'
           window.location='/webinars'
        }
		else if(webinar_data==null)
		{
			
			axios.post('/api/checkTimer')
                .then((response) => {
					
                //localStorage.setItem('webinar_timer_de', JSON.stringify(response.data)) 
				
				cookie.save('webinar_timer_de',response.data, { path: '/' })
				webinar_data=response.data
				this.set_web_data(response.data);
			})
			
		}
        else 
        {
			this.set_web_data(webinar_data);
        }    
		
	}
	set_web_data=(web_data)=>{
		            this.setState({
                 willStartOn:web_data['willStartOn'],
                 willEndOn:web_data['willEndOn'],
                 seconds:web_data['seconds'],
                 exceded:web_data['exceded'],
                 started:web_data['started'],
                 IST:web_data['a_IST'],
                 GMT:web_data['a_GMT'],
                 PST:web_data['a_PST'],
                 webinar:web_data['a_webinar'],
                 webinar_id:web_data['webinar_id'],
                 img:web_data['img'],
                 email:user_data['email']
        })
		
	}	
	
	addStr(str, index, stringToAdd){
	  return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
	}
	componentDidMount()
	{		
		
       // this.temp_call("mount");
	   
	   setTimeout(() => {
					
				
			if([null,undefined,'null'].includes(this.props.user.date_info['current_date']))
			{
				//console.log(this.props.user.date_info['current_date'])
			}
			//console.log(this.props.user.date_info)		
			this.state.loading=true;
			if (this.state.webinar_id!='None')
			{
			  this.checkSeconds(); 
			  
			}
			this.masterUser();
			
						
		}, 4000);
	}
	
	
	
	
	checkSeconds=()=>
	{
		if( this.props.user.date_info['is_started']==true)
		{
			if(this.props.user.date_info['is_ended']==false) 
			{
				this.goToWeb()
			}
			else 
			{
				this.setState({msg:"This Webinar has Ended"},()=>{console.log(this.state.msg)})
			}	
		}
			
	}
	goToWeb=()=>
	{
		this.setState({msg:'You can join now.'}); 
		window.location = '/og-webinars/'+this.state.webinar_id;
	}

	masterUser=()=>
	{
		axios.post('/api/getAdminList')
	   .then((response) => {
             
            for(var i=0;i<response.data.length;i++)
            {
                arr.push(response.data[i]['email'])
                //alert(arr)
            }
        });
        return arr;
	}
	
	addStr(str, index, stringToAdd){
	  return str.substring(0, index) + stringToAdd + str.substring(index, str.length);
	}
    
	validationBeforeWeb=()=>
	{
		
			if( this.props.user.date_info['is_started']==true && 
			this.props.user.date_info['is_ended']==false) 
			{
				window.location = '/og-webinars/'+this.state.webinar_id;
			}	
			else
			{
				//alert(arr)
				
				if(arr.includes(this.state.email))
				{
					this.setState({msg_admin:"You are entering as a Admin"})
					setTimeout(() => {
						
						this.setState({msg_admin:false})
						window.location = '/og-webinars/'+this.state.webinar_id;
					   }, 1000);
				}
				
			}
		
        
	}
	
	render(){
	const classes = this.props.classes;
	let sec=this.state.seconds

	var settings = {
     // count: parseInt(this.state.seconds),
	  count:parseInt(this.props.user.date_info['second_left']),
      border: true,
      showTitle: true,
      noPoints: true,
	   direction:"left",
	  size:16, 
	  labelSize:16,
	 color:"black",

    };
	const TimeSchedule = () => {
    return ( 
    <Box lineHeight={1.3} >
      {this.state.webinar+' | '+this.state.IST+" / "+this.state.PST}
    </Box>
    )}

    return(
      		
					<Card elevation={0} className={classes.card1}>
                    
                      {
						  		
						  
                            this.state.msg_admin!=false 
                            ?<AlertBox show_status={true} 
                            msg={this.state.msg_admin}
                            msg_type={this.state.msg_type=="success"?"success":"warning"} />
                            : ''
                      }
			
                    <Box justifyContent= 'center' textAlign="center" display="flex" marginTop={3}>
                        
                        <Box display={this.state.webinar_id=='None'?'none':'block'}>
                        
                        <Box>
                        
                            <Typography variant="h6" color="textPrimary" >
                            <Box lineHeight={1.5} fontWeight="600">
                            {webinar_data['webinar_title']}
                            </Box>
                            </Typography>
                            
                            {/*<Typography variant="h6">h6</Typography>
                            <Typography variant="subtitle1">subtitle1</Typography>
                            <Typography variant="subtitle2">subtitle2</Typography>
                            <Typography variant="body1">body1</Typography>
                            <Typography variant="body2">body2</Typography>
                            <Typography variant="caption">caption</Typography> */}
                            
                        </Box>
                        
                             
                        <Box marginBottom={1.5}>
                        
                            <Typography variant="body2" color="textSecondary" >
                            <Box lineHeight={1.5}><TimeSchedule /></Box>
                            </Typography>
                            
                        </Box>
                        
                        
                    <Box marginTop={2} marginBottom={2}>
                    <Button variant="contained" size="large" 
                    className={classes.success}
                    color="success" style={{'text-transform': 'initial'}}
                    onClick={()=>{this.validationBeforeWeb()} } >
                        {this.state.msg}
                    </Button>
                    </Box>
                    
                    <Box marginTop={1.5}>
                    {parseInt(this.props.user.date_info['second_left'])>0
                    ?<Box marginTop={2} style={{width:'fit-content',margin:'auto'}}>
                    <CountdownTimer {...settings} 
                    className={classes.timer}
                    onEnd={()=>{this.goToWeb()} } />
                    </Box>
                    :''}
                    </Box>
                    
                    </Box>
                    
                    <Box display={this.state.webinar_id=='None'?'block':'none'}>
                    <Typography variant="h6"  style={{color:successColor}}>
                    Sorry, We couldn't find any webinar
                    </Typography>
                    </Box>
		
                </Box>	
                </Card>
						
	);}
}
export default (props) => {
    const classes = useStyles();
	const history = useHistory();
	const user = useContext(UserContext);

    return (
        <AccordionBlock 
		user={user} 
		classes={classes}  
		history={history} />
    )
}