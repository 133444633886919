import {
	Box, Card, CardContent
	
	} from '@material-ui/core';


import React, { Component } from "react";

import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
import "react-sweet-progress/lib/style.css";
import { getCacheDetails , deleteLocal} from "../caching";
import { findCourse, translateText } from "../Helper";
import { useStyles } from "../useStyles";
import AlertBox from "../AlertBox";
import ArticleDialog from './ArticleDialog';

import './blog.css'




class Scroll extends Component {

    constructor(props) {
    super(props);
	this.findCourse = findCourse.bind(this);
	this.translateText = translateText.bind(this);
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.state={ 
	course_list:[],mentors_list:[],
	progress_com:0,progress_left:9,loading:true,hasMore:true,
	start:0,end:10,msg:false,alert_msg:false,
	profile_popop:false,search:'',search_with:false,
	article:[],
	profile_details:[]}
    }
	componentDidMount()
	{
		if(this.props.location.state==undefined)
		{
			this.props.history.push("/view-blogs")
		}
		//console.log(this.props.location.state['article'])
		
	}


    render()
	{

	

	return ( 
		
	
		<Box>
		
			{this.state.alert_msg!=false
			? <AlertBox show_status={true} msg={this.state.alert_msg} msg_type="success" />
			: 	''}
			
		
			<Card>		
			<CardContent>		
			<ArticleDialog
				article={this.props.location.state['article']}
				render="main"
			/>
			</CardContent>
			</Card>
	 </Box>

	);
  }
}


export default () => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
	return (
        <Scroll  
		classes={classes} 
		location={location}  
		history={history} />
    )
}
