// src/components/Homepage.js

import React, { useCallback, useEffect, useState, Suspense, lazy } from "react";
import axios from "axios";
import {
    Box,
    CircularProgress,
    Typography,
    Button,
    useMediaQuery
} from "@material-ui/core"; // MUI v4 imports
import { makeStyles, useTheme } from "@material-ui/core/styles"; // MUI v4 styling
// import Slider from "react-slick"; // Removed since not needed
import bannerVideo from '../assets/Homepagevideo.mp4';
import { PopupWidget } from 'react-calendly';
import { PopupButton } from 'react-calendly';
import useGlobalStyles from "../styles/CourseStyles";
import { Link } from "react-router-dom";
import courses from '../Courses/CourseData';


// Lazy loaded components
const ExpertCarousel = lazy(() => import("./ExpertCarousel"));
const TrendingBlogs = lazy(() => import("../Experts/TrendingBlogs"));
const Courses = lazy(() => import("./Courses"));
const Hub = lazy(() => import("./Hub"));
const Testimonials = lazy(() => import("./Testimonials"));
const GrowthStudio = lazy(() => import("./GrowthStudio"));
const ContentMarketingPage = lazy(() => import("./ContentMarketingPage"));
const OpengrowthAI = lazy(() => import("./OpengrowthAI"));
const CardCarousel = lazy(() => import("./CardCarousel"));

// Sample Data

const blogData = [
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730120763.png",
        title: "Artificial Intelligence and Academic Integrity: Striking A Balance",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Startup Basics",
        link: "https://www.blogs.opengrowth.com/artificial-intelligence-and-academic-integrity-striking-a-balance"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1731079565.png",
        title: "What is the Game Playing Algorithm In AI?",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Industry Insights with Experts",
        link: "https://www.blogs.opengrowth.com/what-is-the-game-playing-algorithm-in-ai"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730914470.png",
        title: "Tools and Middleware Program for PlayStation®5 and PlayStation®4 Systems",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Remote Work",
        link: "https://www.blogs.opengrowth.com/tools-and-middleware-program-for-playstation-systems"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730914165.png",
        title: "Integrating AI Bots in QA: A Turning Point in Video Game Development",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Future Tech",
        link: "https://www.blogs.opengrowth.com/integrating-ai-bots-in-qa-a-turning-point-in-video-game-development"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/business-tech-1730890166.png",
        title: "Understanding How to Utilize Technology Within Your Business",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Artificial Intelligence",
        link: "https://www.blogs.opengrowth.com/understanding-how-to-utilize-technology-within-your-business"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/em-1730289670.png",
        title: "How to Boost Ecommerce Sales with Expert Email Marketing Strategies",
        author: "Mallika Khandelwal",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1709736699-img-20240226-194733701-1-jpg.jpg",
        category: "Sustainability",
        link: "https://www.blogs.opengrowth.com/how-to-boost-ecommerce-sales-with-expert-email-marketing-strategies"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730120172.png",
        title: "Adopting Enterprise in the AI Era",
        author: "OpenGrowth Content Team",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Digital Marketing",
        link: "https://www.blogs.opengrowth.com/adopting-enterprise-in-the-ai-era"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-image-5-1730119595.png",
        title: "AI in Gaming: How AI Creates Intelligent Game Characters and Opponents",
        author: "Nishi Chawla",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1722947421-profile-photo-jpeg.jpeg",
        category: "Blockchain",
        link: "https://www.blogs.opengrowth.com/ai-in-gaming-how-ai-creates-intelligent-game-characters-and-opponents"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/blog-1730119552.png",
        title: "How to Find and Address the Grey Areas of AI Ethics",
        author: "Pricing Strategist",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1721211340-opengrowth-logo-png.png",
        category: "Business Strategy",
        link: "https://www.blogs.opengrowth.com/how-to-find-and-address-the-grey-areas-of-ai-ethics"
    },
    {
        image: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/article/2024/cross-selling-customer-1729857279.png",
        title: "Seamless Cross-Selling: Driving Revenue Through Member-Centric Offers",
        author: "Mallika Khandelwal",
        authorImage: "https://www.blogs.opengrowth.com/assets/uploads/images/co_brand_1/users/small/1709736699-img-20240226-194733701-1-jpg.jpg",
        category: "Team Management",
        link: "https://www.blogs.opengrowth.com/seamless-cross-selling-driving-revenue-through-member-centric-offers"
    },
];


// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
    
    homepageContainer: {
        overflowX: "hidden",
        
    },
    bannerContainer: {
        position: "relative",
        // width: "100%",
        display: "flex",
        border: 'none',
        borderRadius: "4px",
        overflow: "hidden",
        backgroundColor: "#051949", // Fallback color
        [theme.breakpoints.up("md")]: {
            height: "100%",
        },
        [theme.breakpoints.down("sm")]: {
            height: "100%",
        },
    },
    h1font: {
        fontWeight: "500", 
        color: '#f9bb02', 
        border: 'none',
        [theme.breakpoints.down("sm")]: {
            textAlign: 'left',
        },
    },
    subtitle2: {
        [theme.breakpoints.down("sm")]: {
            width: '90%',
        },
    },
    videoBox: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
        overflow: "hidden",
        zIndex: 1,
        "&::after": {
            content: '""',
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            background: "rgba(5,25,73,0.5)", // Semi-transparent overlay for readability
            pointerEvents: "none",
            zIndex: 2,
        },
    },
    video: {
        width: "100%",
        height: "100%",
        objectFit: "cover",
        zIndex: 1,
    },
    contentBox: {
        position: "relative",
        zIndex: 3,
        width: "59%", // Adjust as needed
        color: "white",
        textAlign: 'left',
        paddingTop: 80,
        paddingBottom: 88,
        [theme.breakpoints.down("sm")]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
            maxWidth: "100%",
            width: "100%",
        },
        [theme.breakpoints.between("sm", "md")]: {
            paddingLeft: theme.spacing(4),
            
        },
    },
    bannerText: {
        marginBottom: theme.spacing(1),
        border: 'none',
        [theme.breakpoints.down("sm")]: {
        
        },
        "&::after": {
            border: 'none',
        },
    },
    bannerButton: {
        marginTop: theme.spacing(2),
        borderRadius: "50px",
        backgroundColor: "#f9bb02",
        color: "black",
        fontWeight: 600,
        fontSize: '1rem',
        padding: '6px 16px',
        textTransform: "none",
        cursor: 'pointer',
        "&:hover": {
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
            backgroundColor: "#f9bb02",
        },
        [theme.breakpoints.down("sm")]: {
            fontSize: "0.8rem",
            padding: "6px 12px",
        },
    },
    button1: {
        padding: '8px 12px'
    },
    buttonContainer: {
        display: 'flex',
        gap: 24,
    }
}));

// BannerSlide Component with Video as Background
const BannerSlide = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));

    // State to control the visibility of the Calendly popup
    const [calendlyOpen, setCalendlyOpen] = useState(false);

    // Function to open Calendly modal
    const openCalendlyModal = () => {
        setCalendlyOpen(true);
    };

    return (
        <>
            <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
                {/* Video Background */}
                <Box className={classes.videoBox}>
                    <video
                        className={classes.video}
                        src={bannerVideo}
                        autoPlay
                        muted
                        loop
                        playsInline
                        preload="auto"
                    >
                        Your browser does not support the video tag.
                    </video>
                </Box>

                {/* Overlay Content */}
                <Box className={classes.contentBox}>
                    <Typography
                        variant={isSmall ? "h1" : "h1"}
                        gutterBottom
                        className={`${styleClasses.h1heading} ${classes.h1font}`}
                    >
                        Strategize, Scale, and Succeed with Our Growth Experts
                    </Typography>
                    <Typography
                        variant={ isSmall ? "subtitle1" : "h6"}
                        paragraph
                        className={`${styleClasses.h6banner} ${styleClasses.h6HeadingDesc}`}
                    >
                        Struggling with expertise gaps? Our Growth Experts are ready to help AI startups like yours bridge scaling gaps and achieve success.
                    </Typography>
                    <Box className={classes.buttonContainer}>
                    <PopupButton
                        url="https://calendly.com/vedika-5m0y/30min?month=2024-10"
                        rootElement={document.getElementById('root')}
                        text="Book a Discovery Call"
                        className={`${styleClasses.individualButton} ${classes.button1}`}
                    />
                    <Button className={styleClasses.individualButton}
                    component={Link} // Use Link component from react-router-dom
                    to="/register/user">
                        Join as an Expert
                    </Button>
                    </Box>
                    
                </Box>
            </Box>

            {/* Calendly PopupWidget */}
            {calendlyOpen && (
                <PopupWidget
                    url="https://calendly.com/vedika-5m0y/30min?month=2024-10"
                    rootElement={document.getElementById('root')}
                    onModalClose={() => setCalendlyOpen(false)} // Close the modal when user closes it
                />
            )}
        </>
    );
};


function OgHomepage() {
    const classes = useStyles();
    const [experts, setExperts] = useState([]);
    const [loading, setLoading] = useState(false);

    const fetchExperts = useCallback(async (category = null) => {
        setLoading(true);
        try {
            const userEmail = "your-email@example.com"; // Replace with actual user email
            const token = "your-api-token"; // Replace with your actual token

            let response;
            if (category) {
                response = await axios.post(
                    "https://academy.opengrowth.com/api/search_mentor",
                    {
                        email: userEmail,
                        start: 0,
                        end: 16,
                        key: `0_popular_tags_${category}`,
                        search: category,
                        search_with: "tags",
                        action: "",
                        token: token,
                    }
                );
            } else {
                response = await axios.post(
                    "https://academy.opengrowth.com/api/get_all_mentors",
                    {
                        id: userEmail,
                        start: 0,
                        end: 16,
                        key: "0_all_mentors_0_to_10",
                    }
                );
            }
            setExperts(response.data);
        } catch (error) {
            console.error("Error fetching experts:", error);
        } finally {
            setLoading(false);
        }
    }, []);

    useEffect(() => {
        fetchExperts();
    }, [fetchExperts]);

    return (
        <Box className={classes.homepageContainer}>
            {/* <Header /> */}
            <BannerSlide /> {/* Video Background with Left-Aligned Content */}
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                    <CircularProgress />
                </Box>
            ) : (
                <Suspense fallback={
                    <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
                        <CircularProgress />
                    </Box>
                }>
                    <ExpertCarousel experts={experts} />
                    <CardCarousel />
                    <OpengrowthAI />
                    <GrowthStudio />
                    <Courses course={courses} />
                    <ContentMarketingPage />
                    <TrendingBlogs blogs={blogData} />
                    {/* <Hub course={communities} /> */}
                    <Testimonials />
                </Suspense>
            )}
            {/* <Footer /> */}
        </Box>
    );
}

export default OgHomepage;