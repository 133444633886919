import axios from 'axios';
import React, { useState } from 'react';
import SendIcon from '@material-ui/icons/Send';
import { makeStyles } from '@material-ui/core/styles';
import {FormControl,OutlinedInput,Box} from '@material-ui/core';
import { useHistory,useLocation} from 'react-router-dom';

import {format_date} from '../../Helper';
import AlertBox from "../../AlertBox";

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center', 
  },
  input: {
    flexGrow: 1,
	width:'98%',
	margin:'auto',
	borderRadius:'12px',
	padding:'4px',
	'box-shadow': '0 0 15px rgba(0,0,0,.1)'

  },
  sendButton: {
    cursor: 'pointer',
    color: theme.palette.primary.main,
    marginLeft: theme.spacing(1),
  },
}));
var senderEmail='';
var senderName='';
var senderImg='';
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	senderEmail=JSON.parse(localStorage.getItem('ac_login_user'))['email'];
	senderName=JSON.parse(localStorage.getItem('ac_login_user'))['fname'];
	senderImg=JSON.parse(localStorage.getItem('ac_login_user'))['img'];
}
const InputBox = ({parentCallback,chat_id}) => {
  const classes = useStyles();
  const location = useLocation();
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const [, forceUpdate] = useState(null);

  const [msg, setMsg] = useState(false);
  const [msg_type, setMsgType] = useState("success"); 
  
  const handleSendMessage = () => {
    if (message.trim() !== '') {
      setLoading(true);
	  onSendMessage(message);
      setMessage('');
    }
  };

  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      handleSendMessage();
    }
  };
  
  const onSendMessage= (message) =>{
	  	let sender=JSON.parse(localStorage.getItem('ac_login_user'))['email']
		let param={
		'chat':message,
	    'chat_id':chat_id,
		'sender':sender,
	    'receiver':chat_id
	  }
	  if(location.state)
		{
			param['community_id']=location.state.id
		}
	  let headers={
				'Accept': 'application/json',
				'Content-Type': 'application/json'}
	axios({
		method: 'post',
		url: '/api/ac_sendmsg',
		data:param,
		headers: headers
	})
	.then((response) => {
		console.log(response.data)
			setMsg((response.data).msg);
			setMsgType((response.data).type);
			setLoading(false);
		param['sender']=senderEmail
		param['name']=senderName
		param['img']=senderImg
		param['msg_id']=(response.data).id
		param['added_on']=format_date(new Date(),"short")
		parentCallback(param);

	});
  }
  return (
    <Box marginTop={0}>
	   {
			/*msg!=false
			? <AlertBox 
			show_status={true} 
			msg={msg} 
			msg_type={msg_type}
			parentAlertCallback={()=>{setMsg(false)}}
			/>
			:''*/
		}
		
    <FormControl fullWidth className={classes.root}>
      <OutlinedInput
        className={classes.input}
        placeholder="Please enter text"
        value={message}
        onChange={(event) => setMessage(event.target.value)}
        onKeyPress={handleKeyPress}
       readOnly={loading}
	   endAdornment={
          <Box padding={"12px 24px"}>
		  <SendIcon size="small" color="secondary" className={classes.sendButton} onClick={handleSendMessage} />
		  </Box>
        }
      />
    </FormControl>
    </Box>
  );
};

export default InputBox;
