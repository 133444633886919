import { Box, Button, Card,CardContent,CardActionArea,Container, Grid, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import {speakers_path} from '../../Helper';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 40
  }
};


const styles = theme => ({
  card:{
	  borderRadius:'25px',
	 /* background:'url(' + prev + ')',
		'background-repeat':'no-repeat',
		'background-size':'100%',
		'background-position':'bottom',*/
	//backgroundColor:'#efe9e9',
	//backgroundColor:'#efe9e91c',
	},
	color:{color:"white"},
	btn:{margin:"auto",fontWeight:"500", padding:"5px 48px"},
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

let subtitle='Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.';
let path="/assets/images/homepage/images/offer/"

let data=[
		{'img':speakers_path+'niraj.png',
		'title':"E-Book Name 1",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_brittany.png',
		'title':"E-Book Name 4",
		'subtitle':subtitle,
		'href':""},
			{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
			{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		]
	

function Footer(props) {
  const { classes } = props;
    const history = useHistory();

	const ProfileCard=({img,title,subtitle,href})=>{
		return (
					<Box margin={2}>
					<Card elevation={3} style={{height:"100%"}}>
					
					<CardActionArea  style={{height:"100%"}}>
					<CardContent >
					
					
					<Box  display="block" textAlign="center" justifyContent="center" >
					
					<Typography  variant="h6" color="textPrimary" > 
					 <Box fontWeight="fontWeightBold" 
					 lineHeight={1.5}>{title}</Box>
					 </Typography> 
					 
					 					
					<Box marginBottom={2}  marginTop={2} display="flex" textAlign="center" justifyContent="center" margin="auto" width="70%" height="135px">
						<img src={img} height="100%"  width="100%" alt="" style={{borderRadius:'12px',backgroundSize:'cover'}} />

					</Box>
					
					 <Typography  variant="subtitle1" color="textSecondary" gutterBottom> 
					 <Box marginTop={2} lineHeight={1.5} minHeight={"100px"} >{subtitle}</Box>
					 </Typography> 
					
					
					<Box marginTop={2}>
					<Button variant="contained" 
					size="medium" 
					color="secondary" className={classes.btn}>
					Learn More
					</Button>
					</Box>
						
					<Box marginTop={2} marginBottom={1}>
					<Button fullwidth variant="contained" 
					size="medium" 
					color="primary" className={classes.btn}>
					Enroll Now
					</Button>
					</Box>
					
					</Box>
					

					
					</CardContent>
					</CardActionArea>
					</Card>
					</Box>
		)
	}
  return (
  <Box marginTop={4} marginBottom={1} 
  style={{backgroundColor:"white"}}>
	<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={0} 
					>
		
	  <Grid item  xs={12} sm={10} style={{marginTop:"-16px"}}>
	  <Typography >
					  <Box lineHeight={1.25} 
					  fontWeight="600"
					  fontSize="32px" marginBottom={3}
					  textAlign="center" padding={2}>	
							Upcoming Webinars
						<Box className={classes.outline}></Box>
					  </Box>
			</Typography>
	  </Grid>
	  
	  <Grid item  xs={10}  >
	  <Container>
		<Carousel responsive={responsive}  >
			{
							
						data.map((e, index) => (
							
						<ProfileCard  
						img={e['img']}
						title={e['title']}
						subtitle={e['subtitle']}
						href={e['href']}/>
						
					))								
			}
			</Carousel>
			</Container>
	   </Grid>
	   </Grid>
    </Box>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);