
import { Box, Button, Card, CardContent, CardHeader, Checkbox, CircularProgress, FormControlLabel, Grid, IconButton, InputAdornment, TextField, Tooltip, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import axios from 'axios';
import convertSize from "convert-size";
import React, { Component } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { deleteLocal } from "../../caching";
import { api, is_local,format_date } from "../../Helper";
import { useStyles } from "../../useStyles";
//import FileUploadIcon from '@material-ui/icons/FileUpload';
import CloudUploadIcon from '@material-ui/icons/CloudUpload';
import { FaFileAlt } from 'react-icons/fa';

let student_id=null;
let name=null;
let fname=null;
let gender="Male";
let student_email=null;
if(["admin","webinar",null].includes(localStorage.getItem('login_token'))==false)
{
	name=JSON.parse(localStorage.getItem('ac_login_user'))['name']	
	gender=JSON.parse(localStorage.getItem('ac_login_user'))['gender']	
	fname=JSON.parse(localStorage.getItem('ac_login_user'))['fname']	
	student_email=JSON.parse(localStorage.getItem('ac_login_user'))['email']	
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']	
}
class AssignmentUpload extends Component {
    constructor(props) {
    super(props);
	this.deleteLocal = deleteLocal.bind(this);

	this.state={
		title:this.init_state("title"),
		description:this.init_state("description"),
		submitted_id:this.init_state("submitted_id"),
		submitted_file:this.init_sub_state("file"),
		accept:this.set_accept(),
		
		fileDetails:null,type:null,size:0,img_msg:false,
		file_changes:false,upload:false,
		if_file_exists:false,
	
		loading:false,
		checkedA: true,
		msg:false,msg_type:'success',
		errors: {title:"Initalize",submitted_file:"Initalize",description:"Initalize"}
		}
    }
	componentDidMount()
	{
		//console.log(this.props.user_project)
		//console.log(this.props.requested_assignment)
		//console.log(format_date(new Date(),"full"))
		//alert(this.props.user_project['request'])
		if(this.props.user_project['request']==="update")
		{
			this.open_file_to_newtab("test")
		}
	}
	
	
	init_sub_state=(variable)=>{
		let t=this.props.user_project[variable]
		if(t==undefined && this.props.requested_assignment['restrict_upload_media']!="Link")
		{
			t="Click on icon to upload File"
		}
		return t;
	}
	init_state=(variable)=>{
	//	//console.log(this.props.user_project)
				return this.props.user_project[variable]

		/*if(this.props.user_project['request']==="add")
		//if(this.props.user_project==undefined)
        {
			return null;
        
        }
		else 
		{
			let k=this.props.user_project[variable]
			//console.log(k);
			return k;
		}*/
	}
	
	deleteKeys=()=>{
		
			let a=this.deleteLocal("assignment")
			let b=this.deleteLocal("module")		
			let c=this.deleteLocal("structure")		
			let keys=[a,b,c]
			keys=keys.toString()
			return keys
			
	}
	checkError=(name,value)=>
    {
		
		if(this.state.errors[name]=="Initalize")
		{
			this.state.errors[name]=[false,'false',null,'','None',undefined,"Click on icon to upload File"].includes(value)?"Please fill out this field.":"Initalize";
		}
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
		
	}
	validForm=(list)=>
	{
		let allFilled=false;
		for (let i = 0; i < list.length; i++)
		{
				
			let value=list[i];
			allFilled=this.checkError(value,this.state[value]);
			if (allFilled===false)
			{
				//alert("breaked")
				break;
			}
			else{
				//alert("not")
			}
		}
		return allFilled
	}
	
	handleChange = event => {
	
	this.setState({file_changes:true})
	event.preventDefault();
	let errors = this.state.errors;
	const { name, value } = event.target;
	errors[name]=value==null|| value==''?"Please fill out this field.":"Initalize";
	this.state[name]=value;
	let min=5
	let max=100
	if(name=="title")
	{
		min=5
		max=50
	}
	if(name=="description")
	{
		min=5
		max=200
	}
	if(["title"].includes(name))
	{
		errors[name]= value.length<min || value.length>max ?" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters.":"Initalize"	
	}
	if(["description"].includes(name))
	{
		errors[name]= value.length>max ?" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters.":"Initalize"	
	}
	this.setState({ errors, [name]: value });
	};
	
	

    open_file_to_newtab = (action) =>
    {
		if(![undefined,null,'',false,'false'].includes(this.state.submitted_file))
		{
			var url=window.location.origin
			let filepath="/assets/images/assignment/learners/"+this.state.submitted_file
		  			url=url+filepath

		  fetch(filepath,{headers: 
               {'Content-Type': 'application/json','Accept': 'application/json'}
           }).then((res) => {
			  //console.log(res)
			  if (res.ok) {
				  
				if(action=="view")
				{
					window.open(url, '_blank');
				}
				this.setState({if_file_exists:true})
				
				  
			  } else {
				 
				 	if(action=="view")
					{
						this.setState({msg:" File does not exists on the server."});
					}
					setTimeout(() => {
						this.setState({msg:false});
					}, 4000)
			  }
			});
			
		}
	}
    check_validation_criteria = () =>
    {
		let  assignment_list=['title','description','submitted_file']
		let isValidate=this.validForm(assignment_list)
		
		if(this.props.requested_assignment['restrict_upload_media']!="Link")
		{
			let t=true;
			let limit=1
			let size=Math.round(convertSize(this.state.size, "MB"));
			if(size>limit)
			{
				t=false;
				isValidate=false;
			}
			
			if(t==false) 
			{
				this.setState({img_msg: this.state.type+" file ("+size+" mb) must be less than "+limit+" mb."});
				this.setState({loading: false});
				setTimeout(() => {
					this.setState({img_msg:false});
				}, 2000); 
			}
		}
		
		if(isValidate==true)
		{
			if(this.state.checkedA==false)
			{
				this.setState({msg:"Please mark the declaration. "})
				isValidate=false
			}
		}
		
		return isValidate;
	}
	
    set_accept = () =>
    {
		let d=this.props.requested_assignment['restrict_upload_media']
		let ac="image/*"
		switch(d) {
		  case "PDF":
			ac=".pdf"
			break;
		  case "Excel File":
			//ac="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet/vnd.ms-excel"
			ac=".xls,.xlsx";
			break;
		  case "PPT's":
			ac=".ppt,.pptx"
			break;
		  case "Doc File":
			ac=".doc,.docx"
			break;
		  default:
			ac=ac
		}
		return ac;
	}
    handleSubmit = (e) =>
    {
		e.preventDefault();
		//console.log(this.props.user_project)

		let isValidate=this.check_validation_criteria()
		//let learner_msg="Congratulations! Your assignment titled '"+this.props.user_project['assignment_title']+"' has been successfully submitted. Thank you for your hard work and dedication.";
		let date_on=format_date(new Date(),"full");
		let course_name=this.props.location.state['course_searched_name'];
		
		let v=gender=="Male"?"his":"her"
		let instructor_msg="Your learner, "+fname+" has submitted "+v+" assignment '"+this.props.user_project['assignment_title']+"' for '"+course_name+"' on "+date_on+"."
	 
	 
		let learner_msg="Congratulations! Your assignment '"+this.props.user_project['assignment_title']+"' has been successfully submitted on "+date_on+". Keep up the hard work and dedication."
			//alert(instructor_msg)
		this.forceUpdate();
		if(isValidate==true)
		{	
			let filename=this.state.submitted_file
			if(this.props.requested_assignment['restrict_upload_media']!="Link")
			{
				var ext = filename.substr(filename.lastIndexOf('.') + 1);
				filename=(new Date().getUTCMilliseconds())+"."+ext;
			}
			let payload=
			{
				'assignment_id':this.props.user_project['assignment_id'],
				'assignment_title':this.props.user_project['assignment_title'],
				'course_id':this.props.requested_assignment['course_id'],
				'learner_msg':learner_msg,
				'instructor_msg':instructor_msg,
                'instructor_email':'',
                'learner_email':student_email,
                'learner_template':'assignment_to_learner_submitted.html',
                'instructor_template':'assignment_to_instructor_submitted.html',
                'student_id':student_id,
				'title':this.state.title,
                'description':this.state.description,
                'restrict_upload_media':this.props.requested_assignment['restrict_upload_media'],
                'file':filename,
                'keys':this.deleteKeys(),
            }

			//console.log(payload)
			
			if(this.props.user_project['request']==="add")
			{	
				payload.action='add';
				payload.status='Submitted';
			}
			else
			{
				payload.status='Re-submitted';
				payload.action='update';
				payload.user='learner';
				payload.submitted_id=this.state.submitted_id;
			}
			this.setState({addStatus:'' });
			this.state.loading=true;
	
			axios.post(api+'/manage_learner_assignment',payload)
			.then((response) => {
			//console.log(response.data);
			this.state.loading=false
			this.setState({msg:response.data['msg']})
			this.setState({msg_type:response.data['type']})
			this.setState({img_msg:response.data['msg']})
			
			if(response.data['type']=="success" && 
			this.props.requested_assignment['restrict_upload_media']!="Link")
			{
				this.upload_file(payload)
			}
			
			});
			
		}

	}
    upload_file = (param) =>
    {
	
		this.forceUpdate();
		if(this.state.upload==true)
		{	

			 const header = {}; //headers
			 let data = new FormData();
			 header.Accept = 'application/json';
             data.append( 'file',this.state.fileDetails);
             data.append( 'filename',param['file']);
             data.append( 'keys',param['keys']);
             data.append( 'msg',param['msg']);
             data.append( 'fname',fname);
             data.append( 'action','upload');
             data.append( 'course_id',this.props.requested_assignment['course_id']);
             data.append( 'assignment_title',this.props.user_project['assignment_title']);

			//console.log(data)
			this.state.loading=true;
	
			axios.post(api+'/upload_learner_assignment',data)
			.then((response) => {
			//console.log(response.data);
			this.state.loading=false
			this.setState({msg:response.data['msg']})
			this.setState({msg_type:response.data['type']})
			this.setState({img_msg:response.data['msg']})
			
				if(response.data['type']=="success")
				{
					if(['add','update'].includes(param['action']))
					{
						setTimeout(() => {  this.go_back("list") }, 1000);
					}
				}
				else
				{
					 this.setState({msg: "Upload operation failed!"});
				}
            }, (error) => 
            {
				//console.log(error.data);
				this.setState({loading:false})
				this.setState({msg:'Upload failed ....'})
                                
            });
			
		}

	}
	clearValues = () => {
		this.setState({
			fileDetails: null,
			submitted_file: null,
			loading: false,
			img_msg: false,
			msg: false
		});
		console.clear();
	}
	handleAlert = () => {
		//alert("no")
	}
	imageHandler = (e) => {
	this.clearValues();
	//console.log(e.target.files)
	const file = e.target.files[0];

	this.setState({fileDetails: e.target.files[0]});
	this.setState({size: e.target.files[0]['size']},()=>console.log(this.state.size));
	this.setState({type: (e.target.files[0]['type']).toString()},()=>console.log(this.state.type));
	
    let name=(e.target.files[0])['name']
	this.setState({submitted_file: name},()=>{console.log(this.state.submitted_file)});

	let reader = new FileReader();
	reader.onloadend = () => {
		this.setState({img: reader.result});	
	}
	this.setState({isFileUpdated:true})
	reader.readAsDataURL(e.target.files[0]);	
	this.setState({upload:false})
    
    if(name.match(/\s/g))
	{
		this.setState({
			img_msg:'Filename cannot contain whitespaces.',
			submitted_file: false,
		});
		this.state.errors["submitted_file"] = "Filename cannot contain whitespaces.";
	}
	else if(name.length>20)
	{
		this.setState({
			img_msg:'Filename length must be <=20 characters.',
			submitted_file: false,
		});
		this.state.errors["submitted_file"] = "Filename length must be <=20 characters.";
	}
	 else if (file.size > 100000) {
        this.setState({
            img_msg: "File size should be less than or equal to 100KB",
            submitted_file: false,
        });
		this.state.errors["submitted_file"] = "File size should be less than or equal to 100KB";

    }
	else
	{  
		let mismatch=false
		
		if (["PDF"].includes(this.props.requested_assignment['restrict_upload_media']))  
		{
			if(!name.match(/\.(pdf)$/))  
			{
				mismatch="pdf"
			}
		}	
		else if (["PPT's"].includes(this.props.requested_assignment['restrict_upload_media']))  
		{
			if (!name.match(/\.(ppt|pptx)$/)) 
			{
				mismatch="ppt"
			}
		}
		else if (['Doc File'].includes(this.props.requested_assignment['restrict_upload_media']))   
		{
			if (!name.match(/\.(doc|docx)$/)) 
			{
				mismatch="doc"
			}
		}		
		
		else if (['Excel File'].includes(this.props.requested_assignment['restrict_upload_media']))   
		{
			if (!name.match(/\.(xls|xlsx)$/)) 
			{
				mismatch="excel"
			}
		}		
		if (mismatch!=false) 
		{
			let m='Please upload a '+mismatch+' file.'
			if(mismatch=="excel")
			{
				m='Please upload an excel(.xls/.xlxs) file.'
			}
			this.setState({
				img_msg:m,
				msg:m,
				fileDetails:false,
				submitted_file: false,
			});
			this.state.errors["submitted_file"] = m;
		} 
		else
		{
			this.state.errors['submitted_file']="Initalize";
			this.setState({upload:true})
		}
	}
	//alert(name)
	//console.log(this.state.submitted_file)
	//console.log(this.state.fileDetails)
	this.forceUpdate();
	setTimeout(() => {this.setState({img_msg:false})}, 2000); 
}	

	go_back=(action)=>{
		this.props.parentCallback(action);
	}


  	render()
  	{
		
		const { errors } = this.state;
		const styles ={
		iconsbg:{backgroundColor:'#0000001f',padding:'8px',marginBottom:'8px',height:'30px'}
		};
		return (
			<Grid container spacing={2}>			
			<Grid item xs={12} >	
			<Card>
			<CardHeader 
			title={
				<Box width="100%" display="flex" flexDirection="row">
				<Box flexGrow={1} >
				<Typography variant="h6" color='textPrimary'>
					<Box  fontWeight="600" paddingRight={3}>
					{this.props.user_project['request']=="add"?"Submit Assignment":"Update Submitted Assignment"}
					</Box>
				</Typography>
				</Box>
				<IconButton 
				onClick={this.go_back}
				style={styles.iconsbg}>
							<CloseIcon color="grey"  
							style={{fontSize:"0.85rem"}} />
				</IconButton>
			</Box>
			}
			/>
		<CardContent style={{height:"100vh"}}>			
				
				<Box marginBottom={2}>
				<Typography variant="subtitle2" color='textSecondary'>
				Upload your assignment here, considering the mentioned guidelines.
				</Typography>
				</Box>
				
				<Box textAlign="center">
				{
							this.state.msg!=false 
							?
								<Box marginBottom={2}>
								<Typography variant="subtitle2" color='error'>
								{this.state.msg}
								</Typography>
								</Box>
							:""
				}
				</Box>
						
				
				<form role="form">
  
						<Grid container spacing={2}>
						<Grid item xs={12}>
						<TextField   label="Title" color="primary" variant="outlined"  name="title" type="text" onChange={this.handleChange} error={ errors.title!="Initalize" ? errors.title: ''} 
						defaultValue={this.state.title}
						fullWidth/>
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.title!="Initalize" ? errors.title: ''}
						</Typography>
						</Box>
						</Grid>
	
					
					
						<Grid item xs={12}>
						<TextField 
						error={ errors.description!="Initalize" ? errors.description:''} 
						name="description"  type="text"  label="Description" multiline  rows={3}
						variant="outlined" 
						onChange={this.handleChange} 
						defaultValue={this.state.description}
						fullWidth/>
						<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.description!="Initalize" ? errors.description: ''}
						</Typography>
						</Box>
						</Grid>

					
						<Grid item xs={12}>
					   
					    <Box marginBottom={2}>
						<Typography variant="subtitle2" color='textSecondary'>
						{
							this.props.requested_assignment['restrict_upload_media']=="Any File Format"
							?"Author will accept any file format for this activity "
							:"Author will accept only "+ (this.props.requested_assignment['restrict_upload_media']).toLowerCase() + " for this activity "
						}
						(Maximum upload file size : 100KB).
						</Typography>
						</Box>
				
				
				 {
					this.props.requested_assignment['restrict_upload_media']=="Link"
					?
						<TextField 
						error={ errors.submitted_file!="Initalize" ? errors.submitted_file:''} 
						name="submitted_file"  type="text"  label="Specify Assignment URL" 
						id="outlined-end-adornment"
                        size="large"
						onChange={this.handleChange}
						placeholder="Specify Assignment URL"
						variant="outlined"
						fullWidth
						defaultValue={this.state.submitted_file}
						/>
						
					:
						<Box display="flex" alignItems="center">
					<TextField
						error={errors.submitted_file !== "Initalize" ? errors.submitted_file : ''}
						label="Upload File"
						size="large"
						InputProps={{
							endAdornment:
							<InputAdornment position="end" >
								<Box display={this.state.if_file_exists?"none":"flex"}  alignItems="center" justifyContent="center">
									<label htmlFor="photo-upload">
										<input accept={this.state.accept} id="photo-upload" type="file" onChange={this.imageHandler} hidden/>
										<CloudUploadIcon color="primary" size="medium" />
									</label>
								</Box>
							</InputAdornment>,
							readOnly:true
						}}
						placeholder="Click on upload icon to upload file"
						variant="outlined"
						fullWidth
						value={this.state.submitted_file === false ? 'Invalid File' : this.state.submitted_file}
					/>

						<Box marginLeft={2}
						style={{cursor:'pointer'}}
						display={this.props.user_project['request']=="add"?"none":"block"}>
						<Tooltip  placement="right-start"  title={"View File"}>
						<IconButton
						color="primary"
						onClick={()=>{this.open_file_to_newtab("view")}}>
						<FaFileAlt fontSize="medium" />
						</IconButton>
						</Tooltip>
						</Box>
						</Box>
				}
				<Box>
						<Typography variant="subtitle2" align="left" color="error" >
						{ errors.submitted_file!="Initalize" ? errors.submitted_file: ''}
						</Typography>
				</Box>
				</Grid>
						
						<Grid item xs={12}>
						
						<Box  marginTop={2}  marginBottom={4} >
						<FormControlLabel
						style={{ alignItems: 'flex-start' }}
							control={
							<Box style={{marginTop:-7}}>
							<Checkbox
								checked={this.state.checkedA}
								onChange={(event) => {this.setState({ checkedA: event.target.checked }) }}
								name="checkedA"
								color="primary"
							  />
							</Box>
							}
							labelPlacement="end"
							label=
							{<Typography  variant="subtitle2" style={{color:'green'}}>
							<Box lineHeight={1.5}>
							{"I, "+name+", understand that submitting another’s work as my own can result in zero credit for this assignment. Repeated violations of the Opengrowth Academy Honor Code may result in removal from this course or deactivation of my Opengrowth Academy account. "}
							</Box>
							</Typography>}
						 />
						</Box>
						
						<Box display="flex" flexDirection="row-reverse" >						
						<Box marginLeft={1} >
						<Box display="none">{
							this.props.user_project['request']==="add"
							? "oo"
							:
								this.props.requested_assignment['restrict_upload_media']=="Link"
								?"cc"
								:
									this.state.if_file_exists
									?"nn"
									:"pp"
							
							}
							</Box>
							<Button 
						variant="contained" size="large"   color="primary"  
						onClick={this.handleSubmit} fullWidth 
						disabled={
							this.props.user_project['request']==="add"
							? this.state.loading
							:
								this.props.requested_assignment['restrict_upload_media']=="Link"
								?true
								:
									 this.state.if_file_exists
									?true
									:this.state.loading
							
							}
						>
						  {this.state.loading && <CircularProgress size={24} />}
						  {!this.state.loading && <span>
						  {this.props.user_project['request']==="add"?"Submit Assignment":"Update Assignment"}
						  </span>}
						</Button>
						</Box>
						
						</Box>
						</Grid>
						
						
						</Grid>
						
			    	
			      	</form>
					</CardContent>
					</Card>
					</Grid>
                    
                   
				</Grid>
		);
            
  	}
}
export default (props) => {
    const classes = useStyles();

	const location = useLocation();
    const history = useHistory();
    return (
        <AssignmentUpload 
        classes={classes}
        location={location}  
        history={history}
		user_project={props.user_project}
		requested_assignment={props.requested_assignment}
		parentCallback={props.parentCallback}
		/>
    )
}