import { Box,Typography , TextField, Button, FormControlLabel, Radio, RadioGroup, CircularProgress, Grid } from '@material-ui/core';
import AlertBox from "../../functions/AlertBox";
import {api} from "../../functions/Helper";
import React, { useState, useEffect } from 'react';
import axios from 'axios';
const OtpForm = ({ data }) => {
	let otp_data=data.otp
const [enable, setEnable] = useState(otp_data ? otp_data.enable : "false");
  const [loading, setLoading] = useState(false);
  const [operationStatus, setOperationStatus] = useState(null);
  const [msg, setMsg] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    setOperationStatus(null);
    setMsg('');
    	        console.clear();
    console.log(data);
        console.log(otp_data);

    const formData = {
      'config_id':data.id,
      enable: enable ? 1 : 0
    };
        console.log(formData);

    axios
      .post(api + '/manage_config_otp', formData)
      .then((response) => {
        console.log(formData);
        console.log(response.data);
        setOperationStatus(response.data['status']);
        setMsg(response.data['msg']);
      })
      .catch((error) => {
        console.error(error);
        setOperationStatus('error');
        setMsg('Save operation failed.');
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const TitleCard = ({ text }) => {
    return (
      <Box
        marginBottom={-0.5}
        fontWeight={700}
        color={"#1A2027"}
        fontSize={"1rem"}
      >
        {text}
      </Box>
    );
  };

  return (
    <form onSubmit={handleSubmit}>
	   {msg && 
		    <AlertBox  
			show_status={true} 
			msg={msg}
			msg_type={operationStatus==="success"?"success":"warning"} />
		}
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12}>
          <TitleCard text="Enable OTP Functionality" />
         <RadioGroup
		  row
		  aria-label="Enable"
		  name="enable"
		  value={enable ? 'true' : 'false'}
		  onChange={(e) => setEnable(e.target.value === 'true')}
		>
		  <FormControlLabel value="false" control={<Radio />} label="Disable" />
		  <FormControlLabel value="true" control={<Radio />} label="Enable" />
		</RadioGroup>
        </Grid>
       
        <Grid item xs={12}>
          <Box display="flex" justifyContent="end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
            >
              {loading ? <CircularProgress size={24} /> : 'Update Configuration'}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </form>
  );
};

export default OtpForm;
