import {
	Box , Grid
} from '@material-ui/core';
import React, { Component } from 'react';
import ReactPlayer from 'react-player';
import { isMobile } from 'react-device-detect';

import Speakers from './Speakers';
import CarouselCard from './CarouselCard';
import Benefits from './Benefits';
import Initiatives from './Initiatives';
import Wonder_Women from './Wonder_Women';
import Events from './Events';
import EventIntro from './EventIntro';
import CountDown from './CountDown';
import Whats_Happening from './Whats_Happening';

class Homepage extends Component {

  constructor(props) {
    super(props);
	
    }

	componentDidMount() {
		
	}
  	render()
  	{
	
		const Journey=()=>{
			return (
		
			<Box margin={2} display={isMobile==true?"none":"block"} >
					
            <Box display="flex"
					  justifyContent="center"
					  alignItems="center" 
					   height="82" width="100%">
			<img src='/assets/images/homepage/images/events/journey.PNG' height="100%" 
			width="100%" 
			alt="" 
			style={{borderRadius:'12px',backgroundSize:'cover'}} 
			/>
			</Box>
			</Box>
                    
		)
		}		
	
		const Banner=()=>{
			
			const center={
			position:'absolute',
			right:'0%',
			top:'0%',
			zIndex:"0"
			//transform:'translate(34%, -255%)'
			}
		//		let hg=(56.25*(window.innerWidth)/100).toString();

		let hg=(42.25*(window.innerWidth)/100).toString();
			hg=Math.floor(hg)+"px"
		//console.log(hg)
		return (
		<Box width="100%" height="100%"  position="relative">
			
			<Box >
			<Grid container spacing={0}  
				justifyContent="center" >
				<Grid item xs={12}  >
				<Box height={hg} width="97%">
				<ReactPlayer
				  className='react-player'
				   light=		"/assets/images/homepage/images/events/womens_thumb.png"
				  url="/assets/images/homepage/images/events/womens.mp4" 
				  width='100%'
				  height='inherit'
				  controls={true}
				  playing
				/>

				</Box>
				
				
				<Box position="absolute"  
				bottom="-5%"
				right="25%"
				zIndex="999">
				<CountDown />
				</Box>

				</Grid>
				
				</Grid>	
		   </Box>
		   
		  
		  {
			  isMobile==false
			  ?<Box style={center}>
			  <img src="/assets/images/homepage/images/events/women.svg" 
			  height="100%" width="100%" />
			  </Box>:''
		  }
	   
	    </Box>
		
		)
		}	
	
		return (
		    <Grid container spacing={0}  
			justifyContent="center" style={{backgroundColor:"white"}}>

							
			<Grid item xs={12}  >
			  <Banner />
			</Grid>	
			
			<Grid item xs={12}  >
			  <Whats_Happening />
			</Grid>
							
			<Grid item xs={12}  >
			  <EventIntro />
			</Grid>
				
			<Grid item xs={12}  >
			  <Events />
			</Grid>
				
				
			<Grid item xs={12}  >
			  <Wonder_Women />
			</Grid>
				
			
			<Grid item xs={12}  >
			  <Initiatives />
			</Grid>
				
			<Grid item xs={9}  >
			  <Journey />
			</Grid>
					
			<Grid item xs={12}  >
			  <Benefits />
			</Grid>
				
			<Grid item xs={12}  >
			  <CarouselCard />
			</Grid>
			
		
			
			<Grid item xs={12}  sm={10}>
			  <Speakers />
			</Grid>
			
		
			
			</Grid>
		
		);
  	}
}
export default Homepage;
  
  