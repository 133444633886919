import { Avatar, Box, Card,Container, Grid, Link, Typography } from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import React from 'react';
import { useHistory } from 'react-router-dom';
import PropTypes from 'prop-types';
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link as RouterLink } from 'react-router-dom';
import {speakers_path} from '../../Helper';
// import wave from './images/wave.svg';
import './css/style.css';

const responsive = {
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
    paritialVisibilityGutter: 60
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 1,
    paritialVisibilityGutter: 40
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
    paritialVisibilityGutter: 40
  }
};


const styles = theme => ({
  border: {
	border:"8px solid #25387C",
	padding:"20px",
	margin:"56px 0px 56px",
	borderRadius:'25px'
  }, 
  text: {
	color:"#efe5e5",
	fontFamily: ['Public Sans','sans-serif',].join(',')
  },
  	large: {
		width: theme.spacing(18),
		height: theme.spacing(18),
		border: '3px solid #d8d8d8 !important'
	  },
	
	 orange: {
		width: theme.spacing(18),
		height: theme.spacing(18),
		backgroundColor:'#f9bb02',
	 },
	
	center:{
	top: '20%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    transition: 'height .4s ease-in-out',
    padding: '0px 48px',
    position: 'absolute'
  },
  card:{
	  height:"100%",
	  padding:"62px 24px 24px 24px",
	 backgroundColor:"#25387C",
	  background:'url(' + + ')',
		'background-repeat':'no-repeat',
		'background-size':'100%',
		'background-position':'bottom',
	  position:'relative'},
	pad:{paddingTop:"32px",paddingBottom:"32px"},
	outline:{borderBottom:'6px solid #F9BB02',
	width:'100px',margin:"auto",marginTop:"16px"},
});

let subtitle='Let’s take a look at brilliant women entrepreneurs who have set out to ensure a successful self-funded business. To understand how the journey looks for a woman entrepreneur.';
let path="/assets/images/homepage/images/offer/"

let data=[
		{'img':speakers_path+'niraj.png',
		'title':"E-Book Name 1",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_akriti.png',
		'title':"E-Book Name 2",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'priya.jpg',
		'title':"E-Book Name 3",
		'subtitle':subtitle,
		'href':""},	
		{'img':speakers_path+'speaker_brittany.png',
		'title':"E-Book Name 4",
		'subtitle':subtitle,
		'href':""}
		]
	

function Footer(props) {
  const { classes } = props;
    const history = useHistory();

	const ProfileCard=({img,title,subtitle,href,index})=>{
		return (
		<>
		{
			0==0
			
			?
			<Box margin={2} marginTop={12}>
						

					<Card elevation={3} className={classes.card}>	
					<Box  display="block" textAlign="center" justifyContent="center" >
					
					<Typography  variant="h6"  > 
					 <Box  marginTop={1.5} fontWeight="fontWeightBold" lineHeight={1.5} style={{color:'white'}}>
						{title} 
						{/*" ------------ "+data.length+" "+index*/}
					 </Box>
					 </Typography> 
					

					
					<Typography  variant="subtitle1" > 
					 <Box  marginTop={1.5}  style={{color:'white'}}lineHeight={1.5}> {subtitle} 
					 
					 <Box display="inline" >
                      <Link style={{color:'white'}} component={RouterLink}  href={href}>{" Read More"}</Link>
					  </Box> </Box>
					 </Typography> 
					
					</Box>
					</Card>
					
					
					<Box className={classes.center}
					marginBottom={1}  marginTop={1} display="flex" textAlign="center" justifyContent="center" >
					<Avatar  className={classes.large} src={img} ></Avatar>
					</Box>
					
			

				</Box> 
		:""}
		</>
		)
	}
  return (

	<Grid container direction="row"
                    alignItems="center"
                    justify="center"
					spacing={0} 
					
					>
		
		<Grid item  xs={12} sm={8} >
			
			
			<Typography  >
					  <Box lineHeight={1.25} 
					  fontWeight="600"
					  fontSize="32px" 
					  marginTop={8}
					  textAlign="center" padding={2}>	
							Trending Sessions
							<Box className={classes.outline}></Box>
					  </Box>
			</Typography>			
		</Grid>
	
	
	  <Grid item  xs={12} id="section-testimonials" >
	  <Container className={classes.pad}>
		<Carousel responsive={responsive}  >
			{
							
						data.map((e, index) => (
							
						<ProfileCard  
						img={e['img']}
						title={e['title']}
						subtitle={e['subtitle']}
						href={e['href']}
						index={index}
						/>
						
					))								
			}
			</Carousel>
			</Container>
	   </Grid>
	   </Grid>
  );
}

Footer.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(Footer);