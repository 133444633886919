
import DateFnsUtils from '@date-io/date-fns';
import { Box, Button, Card, CardContent, CardHeader, CircularProgress, FormControl, FormControlLabel, Grid, IconButton, InputLabel, TextField, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import { createTheme, withStyles } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';
import CloseIcon from '@material-ui/icons/Close';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import axios from 'axios';
import React, { Component } from 'react';
import ImageUpload from "../../functions/ImageUpload";
import AlertBox from "../../functions/AlertBox";
import { api,webinar_img_path } from "../../functions/Helper";
import AttendReasons from './AttendReasons';
import WebinarList from './WebinarList';




const location = window.location.pathname;

const useStyles = theme => ({
 
  speakerButton: {
    backgroundColor: '#e0e0e0',
	color:'black',
	textTransform: 'capitalize',
	margin:'8px',
	'&:hover':{backgroundColor:'#d5d5d5'}
  },
  startButton: {
    backgroundColor: '#43A941',
	color:'white',
	textTransform: 'capitalize',
	margin:'8px',

	'&:hover':{backgroundColor:'rgb(57 140 55)'}
  },
  endButton: {
    backgroundColor: '#F44C3D',
	color:'white',
	textTransform: 'capitalize',
	margin:'8px',

	'&:hover':{backgroundColor:'#d8473a'}
  },
  small: {
    width: '40px !important',
    height: '36px !important',
	border: '2px solid #d8d8d8 !important'
	
  },root: {
    flexGrow: 1,
	padding:'0%',
	margin:'0%',
	},
  paper: {
  'border-radius':"12px",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: 
  {
	'border-radius':"12px",
	position:'relative',
	backgroundColor:'rgb(0 0 0 / 75%)',
	width:"100%",height:"520px",
	position:'relative'
  },
  webcam:{position:'absolute',
  top:'20px',right:'20px',borderRadius:'12px'},
  img: {	
	'border-radius':'12px',
	margin: 'auto',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	width: 'inherit',
	height: 'inherit',
  },
  media: {
	'border-radius':'12px'
  },
 
 
});
 const videoConstraints = {
      facingMode: "user"
};
const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
	MuiPaper:{rounded: {
    'border-radius': '12px'}}
  },
})



class CourseBlock extends Component {

    constructor(props) {
    super(props);
  
    this.state={
		start:"",end:"",
		startT:null,endT:null,title:null,webinar_id:null,
		reg:false,operation:'add',
		loading:false,randomStr:null,active:false,access_to:false,
		
		instructor_input:'',course_instructor_values:[],instructor_list:[{"id":1,"name":'rakhi'},{"id":1,"name":'rakhi'}],
        
		host_input:'',course_host_values:[],
        
        attendC:null,learnT:null,learnC:null,img:null,path:null,
		
		errors: {start:"Initalize",end:"Initalize",title:"Initalize",attendC:"Initalize",course_instructor_values:"Initalize",course_host_values:"Initalize"}
	}
    }
	handleSwitch = () => {
        let val=this.state.active==false?true:false;
        this.setState({active:val})
    }
	handleAccessSwitch = () => {
        let val=this.state.access_to==false?true:false;
        this.setState({access_to:val})
    }
	handleDateChangeStart = (dob) => {
		this.setState({ start: new Date(dob).toLocaleString('en-US', {timeZone: 'Asia/Calcutta'}) });
		if(this.state.start!=null){this.state.errors.start='';}
	}
	handleDateChangeEnd = (dob) => {
		this.setState({ end: new Date(dob).toLocaleString('en-US', {timeZone: 'Asia/Calcutta'}) });
		if(this.state.end!=null){this.state.errors.end='';}
	}
	
	getAllSpeakers=()=>
    {
        axios.get(api+'/getAllSpeakers')
			.then((response) => {
            //console.log(response.data.length)
			//console.log(response.data)
			let ar=[];
			for (let i=0;i<response.data.length;i++)
			{
				var t={"id":response.data[i]['speaker_id'],"name":response.data[i]['speaker_name']}
				ar.push(t)
			}
			this.setState({instructor_list:ar})
			});
    }
		
	componentDidMount()
	{
		
		if(localStorage.getItem('login_token')!='admin')
		{
			window.location='/webinars'
		}
		//this.getWebinarSchedule();
		this.buildRandomString();
		this.getAllSpeakers();
        

		
	}
	validForm=()=>
	{
		const registerList = ['title','start', 'end','course_instructor_values','course_host_values','attendC','learnT','learnC'];
		let allFilled=false;
		this.setState({reg:false});
		for (let i = 0; i < registerList.length; i++)
		{
			let value=registerList[i];
			allFilled=this.checkError(value,this.state[value]);
		    if (allFilled==false)
			{
				break;
		    }
		}
		return allFilled
	}
	convertDate=(val)=>
	{
		var d = new Date(val);
		var date_format_str = d.getFullYear().toString()+"-"+((d.getMonth()+1).toString().length==2?(d.getMonth()+1).toString():"0"+(d.getMonth()+1).toString())+"-"+(d.getDate().toString().length==2?d.getDate().toString():"0"+d.getDate().toString())+" "+(d.getHours().toString().length==2?d.getHours().toString():"0"+d.getHours().toString())+":"+((parseInt(d.getMinutes()/5)*5).toString().length==2?(parseInt(d.getMinutes()/5)*5).toString():"0"+(parseInt(d.getMinutes()/5)*5).toString())+":00";
		return date_format_str
	}
    
    handleChange = event => {
    //event.preventDefault();
	let errors = this.state.errors;
    const { name, value } = event.target;
    this.state[name]=value;
    this.setState({changeFound:true}); 
     errors[name]=value==null?"All fields are required":"";
	 this.setState({ errors, [name]: value });
    };

    getWebinarURL = (webinar_nm) => {
    webinar_nm=webinar_nm.toLowerCase()
    webinar_nm = webinar_nm.replaceAll(" ", "-");
    return webinar_nm
    };

    
	handleSubmit = (e) =>
	{
		e.preventDefault();
		
        let l='';
        var arr = this.state.course_instructor_values;
			arr.map((e, index) => (
			l= l+","+e.id
		));
		var speaker=l.substring(1);
        
        let h='';
        var arr1 = this.state.course_host_values;
			arr1.map((e, index) => (
			h= h+","+e.id
		));
        var host=h.substring(1);
		
		let isValidate=this.validForm()
		
		if(new Date(this.state.start)>new Date(this.state.end))
		{
			isValidate=false;
			this.setState({reg: "End date must be greater than start date."});
			setTimeout(() => {this.setState({reg:false})}, 2000);
		}
		
		if(isValidate==true)
		{
			
					
                    this.setState({loading: true});
					axios.post('/api/scheduleWebinar',
					{
						'title': this.state.title,
						'active': this.state.active,
						'speakers': speaker,
						'host': host,
						'webinar_id': this.state.webinar_id,
						'attendC': this.state.attendC,
						'learnT': this.state.learnT,
						'learnC': this.state.learnC,
						'start': this.convertDate(this.state.start),
						'end': this.convertDate(this.state.end),
						'path': this.getWebinarURL(this.state.title),
						'search': this.state.operation,
						'access_to': this.state.access_to
					})
					.then((response) => 
					{
						//console.log(response.data);
						//alert(response.data[0]['status']);
						if(response.data[0]['status']=="success")
						{							
							
							if(this.state.operation=="add")
							{
								this.setState({reg: "Webinar schedule added successfully"});
							}
							else 
							{
								this.setState({reg: "Webinar schedule updated successfully"});
							}
                          
						   caches.keys().then(function(names) {
							 //console.log(names)	

							for (let i = 0; i < names.length; i++) {
												//console.log(names[i])	
												caches.delete(names[i]);
							}
							})
						   //  window.location.reload();
							//this.getWebinarSchedule();
							//alert("Webinar schedule updated successfully")
							
						}
						else
						{
							this.state.reg="Try Again";

						}
					}, (error) => 
					{
						//console.log(error.data);
					});
					this.setState({loading: false});
					setTimeout(() => {this.setState({reg:false})}, 2000);
								
		}
        else
        {
            //alert("jkhj")
        }  
	}
	
	
	getWebinarDetails=(id)=>
	{
		//alert()
		axios.post('/api/scheduleWebinar',
		{'search': 'webinar',
		'id':id})
		.then((response) => 
		{
			this.setState({start:response.data[0]['startTime']})
			this.setState({end:response.data[0]['endTime']})
			this.setState({title:response.data[0]['title']})
			this.setState({active:response.data[0]['active']})
			this.setState({attendC:response.data[0]['attendC']})
			this.setState({learnT:response.data[0]['learnT']})
			this.setState({learnC:response.data[0]['learnC']})
			this.setState({img:response.data[0]['img']})
			this.setState({path:response.data[0]['path']})
			this.setState({access_to:response.data[0]['access_to']})
            let inst=response.data[0]['speakers']
            let host=response.data[0]['host']
            //alert(host)

			this.setState({course_instructor_values:inst})
			this.setState({course_host_values:host})
			//console.log(response.data)
		})
        this.forceUpdate();
	}
	
	checkError=(name,value)=>
    {
   		this.state.errors[name]=value==null||value==''?" All fields are required":"Initalize";
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
	}
	  
		
	
	getRandomString=(length)=>
	{
		var randomChars = 'abcdefghijklmnopqrstuvwxyz';
		var result = '';
		for ( var i = 0; i < length; i++ ) {
			result += randomChars.charAt(Math.floor(Math.random() * randomChars.length));
		}
		return result;
	}
			
	
	handleClose=()=>
	{
		window.location.reload();
	}
	handleParent=(id)=>
	{
		//alert(id)
		this.setState({webinar_id:id})
		this.setState({operation:'update'})
		this.getWebinarDetails(id)
	}
			
	
	buildRandomString=()=>
	{
		var randomStr =this.getRandomString(3)+"-"+this.getRandomString(3)+"-"+this.getRandomString(3)
		this.setState({webinar_id:randomStr})
		this.state.webinar_id=randomStr
		return randomStr;
	}
	
  	render()
  	{
		

		const classes = this.props.classes;	
		const { errors } = this.state;

	
	
		return(
		<>
				<Card style={{height:'fit-content'}} elevation={3}>
		
		{ this.state.reg!=false  
			? <AlertBox className={classes.dialog} show_status={true} 
			msg={this.state.reg}
			msg_type="success" />
		: ''}
		
		
		<CardHeader title={
			
		<Box display="flex">
				
				<Box flexGrow={1}>
				<Typography variant="h6"  color="textPrimary">
				{this.state.operation=='update'?this.state.title:"Schedule Webinar"}
				</Typography>
				</Box>
				
				<Box display={this.state.operation=="update"?"block":"none"}>
				 <IconButton  onClick={this.handleClose}>
						<CloseIcon color="grey"   />
				  </IconButton> 
				</Box>
		</Box>}
		/>
		<CardContent>
		<Grid container direction="row" spacing={2}>
				    
		<Grid  xs={12}>
			 <Box padding={2} >
			
				
     			<Box  flexGrow={1} marginBottom={2}  fontWeight="600">
				<Typography variant="subtitle1"  color="textPrimary">
				<Box  display={this.state.operation=='update'?'block':'none'} fontWeight="600">
				{this.state.path }
				</Box>
				</Typography>
				</Box>
				

				
				<form role="form" >
				<Grid container spacing={2}>
				
                                
								<Grid item xs={12} sm={6}>
									<TextField label="Webinar" color="primary" variant="outlined"  name="title" type="text"  onChange={this.handleChange} 
									error={ errors.title!="Initalize" ? errors.title: ''} 
									value={this.state.title  || ''} fullWidth/>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.title!="Initalize" ? errors.title: ''}
									</Typography>
									</Box>
								</Grid>
								
                				<Grid item xs={12} sm={6}>
								<Typography variant="subtitle1" align="left" color="primary" >
								{this.state.webinar_id}
								</Typography>
								</Grid>
                
								<Grid item xs={6} sm={6}>
									<FormControl variant="outlined" fullWidth
										error={ errors.start!="Initalize" ? errors.start: ''}  >
										
										<InputLabel id="dob-outlined-label"></InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils} >
										  <KeyboardDateTimePicker  //orientation="landscape" id="dob-box"
										    minDate={new Date()}
											variant="inline"
											inputVariant="outlined"
											label="Start On"
											InputAdornmentProps={{ position: "end" }}
											value={this.state.start || new Date()}									
											name="start" id="exampleDate" 
											onChange={this.handleDateChangeStart} 
											error={ errors.start!="Initalize" ? errors.start: ''} 
										  />
										</MuiPickersUtilsProvider>
									  </FormControl>  
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.start!="Initalize" ? errors.start: ''}
									</Typography>
									</Box>
								</Grid>
								
								<Grid item xs={6} sm={6}>
									<FormControl variant="outlined" fullWidth
										error={ errors.end!="Initalize" ? errors.end: ''}  >
										<InputLabel id="dob-outlined-label"></InputLabel>
										<MuiPickersUtilsProvider utils={DateFnsUtils} >
										  <KeyboardDateTimePicker //orientation="landscape" id="dob-box"
										    minDate={new Date()}
											variant="inline"
											inputVariant="outlined"
											label="Ends on"
											InputAdornmentProps={{ position: "end" }}
											value={this.state.end || new Date()}									
											name="end" id="exampleDate" 
											onChange={this.handleDateChangeEnd} 
											error={ errors.end!="Initalize" ? errors.end: ''} 
										  />
										</MuiPickersUtilsProvider>
									  </FormControl>
									<Box>
									<Typography variant="subtitle2" align="left" color="error" >
									{ errors.end!="Initalize" ? errors.end: ''}
									</Typography>
									</Box>
								</Grid>
					
								<Grid  xs={12} sm={6}>
								<Box margin={1}>
                                <FormControl variant="outlined" fullWidth>
								<Autocomplete 
									multiple id="tags-outlined" limitTags={3} 
									options={this.state.instructor_list}
                                    value={this.state.course_instructor_values}
									getOptionLabel={(option) => option.name}
									filterSelectedOptions
									renderInput={(params) => (
									 <TextField {...params} label="Instructors" placeholder="Instructors" variant="outlined" error={ errors.course_instructor_values!="Initalize" ? errors.course_instructor_values: ''}
									/>
									)}
							
							inputValue={this.state.instructor_input}
							onInputChange={(event, newInputValue) => {
							 this.setState({instructor_input:newInputValue});
							 // this.setList(newInputValue,'host');
							}}
							
							 onChange={(event, newValue) => {
							this.setState({course_instructor_values:newValue});
							  errors.course_instructor_values=newValue==null?"Please fill out this field.":"Initalize";
						  }}
						  />  
						</FormControl>
						<Box>
							<Typography variant="subtitle2" align="left" color="error" >
							{ errors.speakers_values!="Initalize" ? errors.speakers_values: ''}
							</Typography>
						</Box>
						</Box>
						</Grid>
								
									
								<Grid  xs={12} sm={6}>
								<Box margin={1}>
                                <FormControl variant="outlined" fullWidth>
								<Autocomplete 
									multiple id="tags-outlined" limitTags={3} 
									options={this.state.instructor_list}
                                    value={this.state.course_host_values}
									getOptionLabel={(option) => option.name}
									filterSelectedOptions
									renderInput={(params) => (
									 <TextField {...params} label="Hosts" placeholder="Hosts" variant="outlined" error={ errors.course_host_values!="Initalize" ? errors.course_host_values: ''}
									/>
									)}
							
							inputValue={this.state.host_input}
							onInputChange={(event, newInputValue) => {
							 this.setState({host_input:newInputValue});
							 // this.setList(newInputValue,'host');
							}}
							
							 onChange={(event, newValue) => {
							this.setState({course_host_values:newValue});
							  errors.course_host_values=newValue==null?"Please fill out this field.":"Initalize";
						  }}
						  />  
						</FormControl>
						<Box>
							<Typography variant="subtitle2" align="left" color="error" >
							{ errors.host_values!="Initalize" ? errors.host_values: ''}
							</Typography>
						</Box>
						</Box>
						</Grid>
									
								
								<Grid  xs={12} sm={11}>
									<Box margin={1}>
									<Typography variant="subtitle1" align="left" color="textSecondary" >
									Active (if it's an incoming webinar)
									</Typography>
									</Box>
								</Grid>
                                
								<Grid  xs={12} sm={1}>
									<FormControlLabel
										className="text-secondary"
										control={
										<Switch
											checked={this.state.active==0?false:true}
											onChange={this.handleSwitch}
											color="primary"
										/>
									}/>
								</Grid>
								
                                
								<Grid  xs={12} sm={11}>
									<Box margin={1}>
									<Typography variant="subtitle1" align="left" color="textSecondary" >
									{"Accessibility (private,if checked)"}
									</Typography>
									</Box>
								</Grid>
                                
								<Grid  xs={12} sm={1}>
									<FormControlLabel
										className="text-secondary"
										control={
										<Switch
											checked={this.state.access_to==0?false:true}
											onChange={this.handleAccessSwitch}
											color="primary"
										/>
									}/>
								</Grid>
								
                                
                                
                                <Grid item xs={12} >
                                <TextField error={ errors.attendC!="Initalize" ? errors.attendC:''} fullWidth
                                name="attendC"  type="text"  	
                                label="Why should I attend"
                                multiline  rows={7}
                                variant="outlined" fullWidth 
                                onChange={this.handleChange} 
                                value={this.state.attendC || '' }/>
                                <Box>
                                <Typography variant="subtitle2" align="left" color="error" >
                                { errors.attendC!="Initalize" ? errors.attendC: ''}
                                </Typography>
                                </Box>
                                </Grid>
                                
                                
                                
 
								<Grid item xs={12} >
									<TextField label="Learn title" color="primary" variant="outlined"  name="learnT" type="text"  onChange={this.handleChange} 
									error={ errors.learnT!="Initalize" ? errors.learnT: ''} 
									value={this.state.learnT || '' } fullWidth/>
									<Box>
									<Typography variant="sublearnT2" align="left" color="error" >
									{ errors.learnT!="Initalize" ? errors.learnT: ''}
									</Typography>
									</Box>
								</Grid>
                                
                                <Grid item xs={12} >
                                <TextField error={ errors.learnC!="Initalize" ? errors.learnC:''} fullWidth
                                name="learnC"  type="text"  	
                                label="Learn more information"
                                multiline  rows={7}
                                variant="outlined" fullWidth 
                                onChange={this.handleChange} 
                                value={this.state.learnC || '' }/>
                                <Box>
                                <Typography variant="subtitle2" align="left" color="error" >
                                { errors.learnC!="Initalize" ? errors.learnC: ''}
                                </Typography>
                                </Box>
                                </Grid>
                                
								<Grid item xs={12} sm={2}>
								<Box display="flex" flexDirection="row-reverse" >
								
								<Button variant="contained" size="medium" 
								style={{padding:'11px'}}
								color="primary"  onClick={this.handleSubmit} 
								fullWidth
								  disabled={this.state.loading} >
								  {this.state.loading && <CircularProgress size={24} />}
								  {!this.state.loading && <span>
                                      {this.state.operation=='add'?"Add webinar":"Update Webinar"}</span>}
								</Button>
								
								</Box>
                                
                                
                                <Box display={this.state.operation=='update'?'block':'none'} >
                                <Typography variant="subtitle2" align="left" color="textSecondary" >
                                Please make some changes before updating webinar.
                                </Typography>
                                </Box>
								</Grid>
								
								
							
							</Grid>
							</form>
				
				
			 </Box>		
		</Grid>    
		
		</Grid>
		</CardContent>
		</Card>
        
		{this.state.operation!='add'?
                <>
                <AttendReasons webinar_id={this.state.webinar_id} />
                <ImageUpload 
                            id={this.state.webinar_id}
                            table="webinar"
                            route={webinar_img_path}
                            img={this.state.img}
                            upload_for={'webinar'}
                            keys={'webinar'}
                            />
                </>
        :''}
        
		<WebinarList parentCallback={this.handleParent} />
		</>
	    );
	
  	}
}
export default withStyles(useStyles)(CourseBlock)
