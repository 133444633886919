// src/components/GrowthServiceCard.jsx

import React from "react";
import { Box, Typography } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import growthexpert from '../assets/growthexpert.jpeg';  // Replace with your actual path or URL
import handshake from '../assets/handshake.png';
import OpengrowthAI from '../Homepage/OpengrowthAI'; // Import the OpengrowthAI component
import useGlobalStyles from "../styles/CourseStyles";
import { Link } from "react-router-dom"; // Import Link from react-router-dom

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    borderRadius: theme.spacing(1),
    textAlign: "center",
    backgroundColor: '#fff', // Light background for better contrast
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.spacing(3),
      paddingRight: theme.spacing(3),
    },
  },
  heading: {
    fontWeight: 'bold',
    fontSize: '2.2rem',
    marginBottom: theme.spacing(2),
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      textAlign: 'left',
    },
  },
  subheading: {
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
    },
  },
  subtitle1: {
    [theme.breakpoints.down("sm")]: {
      textAlign: 'center',
    },
  },
  benefitsContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: theme.spacing(8),
    marginTop: theme.spacing(4),
    justifyContent: 'center',
    [theme.breakpoints.up('md')]: {
      flexDirection: 'row',
    },
  },
  benefitCard: {
    padding: theme.spacing(2),
    width: 'auto',
    boxShadow: "0 4px 6px rgba(0,0,0,0.2)",
    textAlign: 'left', // Changed to left for better alignment
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: 'white',
    [theme.breakpoints.up('md')]: {
      width: '35%',
    },
    cursor: 'pointer', // Indicate that the card is clickable
    transition: 'transform 0.2s, box-shadow 0.2s', // Add hover effects
    "&:hover": {
      transform: "translateY(-5px)",
      boxShadow: "0 8px 12px rgba(0,0,0,0.3)",
      backgroundColor: '#f5f5f5',
    },
  },
  benefitHeader: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  benefitImage: {
    width: 50, // Reduced size for better alignment
    height: 50,
    marginRight: theme.spacing(2),
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
    },
  },
  h6font: {
    [theme.breakpoints.down('sm')]: {
      width: '100%'
    },
  },
  benefitTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    color: '#000', // Ensure the title has sufficient contrast
  },
  benefitText: {
    fontSize: '1.2rem',
    color: theme.palette.text.secondary,
  },
  // Optional: Style for the OpengrowthAI component within GrowthServiceCard
  opengrowthAIContainer: {
    marginTop: theme.spacing(4),
    width: '100%',
  },
}));

const GrowthServiceCard = ({ context, showOpengrowthAI }) => { // Accept showOpengrowthAI as a prop
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  // Determine heading and subheading based on context
  const heading = context === 'allExperts'
    ? "Tailored Expert Support to Drive Your AI Startup’s Growth"
    : "Get the expertise you need to scale your AI startup";

  const subheading = context === 'allExperts'
    ? "At OpenGrowth, our mission is to connect you with the world’s leading experts in Marketing, HR, Finance, Legal, and Branding—equipping your startup with the AI-driven insights needed to accelerate your growth journey."
    : "Connect with top growth experts and associates.";

  // Determine subtitles for the cards based on context
  const growthExpertSubtitle = context === 'allExperts'
    ? <>
        Hire a <strong>Growth Expert</strong> to help you scale your AI startup without full-time commitment.
      </>
    : <>
        Accelerate your AI startup with a dedicated <strong>Growth Expert</strong> who will craft and implement effective growth strategies without the burden of a full-time hire.
      </>;

  const growthAssociateSubtitle = context === 'allExperts'
    ? <>
        Hire a <strong>Growth Associate</strong> to help you with business development on a part-time basis.
      </>
    : <>
        For targeted business development support, hire a <strong>Growth Associate</strong> on a part-time basis to drive your startup’s growth while maintaining flexibility and efficiency.
      </>;

  return (
    <Box className={classes.container}>
      <Typography variant="h4" className={styleClasses.h4font}>
        {heading}
      </Typography>
      <Typography variant="h6" className={`${styleClasses.description} ${classes.subheading}`}>
        {subheading}
      </Typography>

      {/* Benefits Section with Small Cards */}
      <Box className={classes.benefitsContainer}>
        {/* Growth Expert Card */}
        <Box
          component={Link}
          to="/expertpage" // Route for All Experts page
          className={classes.benefitCard}
          style={{ textDecoration: "none", color: "inherit" }} // Remove link styles
        >
          <Box className={classes.benefitHeader}>
            <img
              src={growthexpert}
              alt="Growth Expert"
              className={classes.benefitImage}
              style={{ filter: "brightness(1.1)" }}
            />
            <Typography variant="h5" className={styleClasses.h5fontPara}>
              Hire a Growth Expert
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.subtitle1}`}>
            {growthExpertSubtitle}
          </Typography>
        </Box>

        {/* Growth Associate Card */}
        <Box
          component={Link}
          to="/growthassociate" // Route for Growth Associate page
          className={classes.benefitCard}
          style={{ textDecoration: "none", color: "inherit" }} // Remove link styles
        >
          <Box className={classes.benefitHeader}>
            <img
              src={handshake}
              alt="Growth Associate"
              className={classes.benefitImage}
            />
            <Typography variant="h5" className={styleClasses.h5fontPara}>
              Hire a Growth Associate
            </Typography>
          </Box>
          <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.subtitle1}`}>
            {growthAssociateSubtitle}
          </Typography>
        </Box>
      </Box>

      {/* Conditionally Render OpengrowthAI Component */}
      {/* {showOpengrowthAI && (
        <Box className={classes.opengrowthAIContainer}>
          <OpengrowthAI />
        </Box>
      )} */}
    </Box>
  );
};

export default GrowthServiceCard;
