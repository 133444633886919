import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Tooltip, Button, Box, IconButton, Card, CardContent, CardHeader, CircularProgress, Grid, Typography , makeStyles} from '@material-ui/core';
import { getCacheDetails,getKeys } from '../caching';
import { user_id, user_status_id } from '../Helper';
import AlertBox from '../AlertBox';
import CommunityRenderCard from './CommunityRenderCard';
import CommunityVerticalRenderCard from './CommunityVerticalRenderCard';
import EditIcon from '@material-ui/icons/Edit';
import { FaHotjar } from 'react-icons/fa';
import {CommunitySkeleton,ArticleSkeleton} from "../Skeleton"
import { Group, Public } from '@material-ui/icons';




const useStyles = makeStyles((theme) => ({
  large: {
    width: '102px !important',
    height: '102px !important',	
  },
  small: {
    width: '52px !important',
    height: '52px !important',	
  },
  cardparent:{  minHeight:'70vh'},
  card:{
	  height:'100%',
	 transition: 'transform 0.2s ease-in-out',
    '&:hover': {
      transform: 'translateY(2px)',
    border: '1px solid #858181',
    }
  }


}));
function CourseTab() {
  const [renderList, setRenderList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [start, setStart] = useState(0);
  const [end, setEnd] = useState(10);
  const [, forceUpdate] = useState(null);
  const [msg, setMsg] = useState(false);
  const [req, setReq] = useState((window.location.pathname).includes("/dashboard")?"creater_id":"all");
  const [msgType, setMsgType] = useState('success');
  const history = useHistory();
  const classes = useStyles();

  useEffect(() => {
    getCachePlay(start, false);
  }, []);

  const get_creater_id_community = () => {
	 let request="all"
	 if(req=="all")
	 {
		 request="creater_id"
	 }
	 else
	 {
		 request="all"
	 }
	 setReq(request)
	 setLoading(true)
		setHasMore(true)
		setStart(0)
		setRenderList([])
		forceUpdate(n=>!n)
		
		getCachePlay(0,false,request)
	 
  }; 
  
  const fetchMoreData = () => {
    if (hasMore !== false) {
      setLoading(true);
      setTimeout(() => {
        setStart(start => start + end);
          getCachePlay(start + end, true);
      }, 200);
    }
  };

  const getCachePlay = (start, fetch,request) => {
    const cacheKey = `all_community_${start}_to_${end}`;
	let payload={
		start,
		end,
		key:cacheKey
	}
	
	if(request==undefined)
	{
		request=req
	}
			if(request=="creater_id")
		{
			payload.creater_id=user_id
		}
		////console.log(payload)
		//alert(1)
    getCacheDetails(cacheKey, 'getCommunity',payload).then(data => {	
      console.log(data)
	  localStorage.removeItem(cacheKey);
      setLoading(false);
      if (data.length < end) setHasMore(false);else{setHasMore(true)}
      if (fetch === false) {
        setRenderList(data);
      } else {
        if (data.length > 0) {
          setRenderList(prevList => [...prevList, ...data]);
        } else {
          setMsg('You have seen it all!');
        }
      }
      setLoading(false);
      setTimeout(() => {setMsg(false); }, 2000);
    });
  };
	const findCommunity = (data, action) => {
		  let path = '/manage-community/update/';
		  if (action === "view") {
			path = '/community/';
		  }
		  //console.log(renderList);
		  
		  // Retrieve host values
		  let hosts_values = [];
		  if (data['host_name'] !== null) {
			const inst_id = data['hosts'];
			const host_id = inst_id.split(',');
			const inst = data['host_name'];
			const host_val = inst.split(',');
			const ar = [];
			for (let i = 0; i < host_val.length; i++) {
			  const t = {"id": Number(host_id[i]), "name": host_val[i]};
			  ar.push(t);
			}
			//console.log(ar);
			hosts_values = JSON.stringify(ar);
		  }
		  
		  // Retrieve community keys
		  const community_keys_1 = getKeys("_professor_community_");
		  const community_keys_2 = getKeys("_professor_community_");
		  const keys = community_keys_1.concat(community_keys_2);
		  
		  // Build community object
		  const community = {
			id: data['id'],
			title: data['title'],
			category: data['category'],
			description: data['description'],
			hosts: data['hosts'],
			host_email: data['host_email'],
			host_name: data['host_name'],
			host_image: data['host_image'],
			hosts_values: hosts_values,
			keys: keys
		  };
		  
		  // Push to history
		  history.push({
			pathname: path + data['slug'],
			state: community
		  });
		}

	const ProgressCardItem = ({e}) => {

	  return (
		<Box marginLeft={1} marginRight={1} marginBottom={0.5}>
		 <Card elevation={1} className={classes.card}>
	     <CardContent>
		 
		 { e['creater_id']==user_id && user_status_id=='professor' && (<Box display="flex" justifyContent="end" >
			 <Tooltip  placement="right-start"  title="Manage Community">
			  <IconButton
			  color="primary"
				onClick={()=>{findCommunity(e,"manage")}}
				size="small"
			  >		  
					<EditIcon fontSize="small" />
				
			  </IconButton>
			</Tooltip>
			</Box>)
		 }
			<Box display="flex" justifyContent="left" >
				<FaHotjar className={classes.small} />
			</Box>

			<Box
			  lineHeight={1.25}
			  fontSize="14px"
			  fontWeight="600"
			  marginTop={3}
			  textAlign="left"
			  color="primary"
			  style={{cursor:'pointer'}}
			  onClick={()=>{findCommunity(e,"view")}}
			>
			  {e['title']}
			</Box>

			
		  </CardContent>
		  </Card>
		</Box>
	  );
	};
	const CourseList = () => {

		return (
			<InfiniteScroll style={{overflow:'hidden'}}
			dataLength={renderList}
			hasMore={hasMore}
			loader={ hasMore==true?
							<Box marginTop={2} marginBottom={2} 
							alignItems='center'
			display='flex'
			flexDirection='column'>
							{
								loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={loading}
									onClick={fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
							:<CircularProgress disableShrink />
				}
			endMessage={
				<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
			    <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
				</Box>
			}>
						<Grid container spacing={0}>
						{
							renderList && 
							renderList.length>0 &&
							renderList.map((e, index) => (
								 
								 
								<Grid item
								xs={12}
								md={(window.location.pathname).includes("/dashboard")?4:4}
								lg={(window.location.pathname).includes("/dashboard")?4:3}
								>
								<CommunityVerticalRenderCard
								  key={index}
								  e={e}
								/>
								</Grid>

							
						))}
						</Grid>
		 </InfiniteScroll>		
		)
	}
const SkeletonCard = () => {
  return (
    <Grid container display="flex">
      {[0,1,2,3,4].map((e, index) => (
        <Grid item xs={12} md={4}>
		<CommunitySkeleton
          key={index}
          e={e}
        />
       </Grid>
      ))}
    </Grid>
  );
};
	return ( 
		
	
		<Box>
		{
			msg!=""
			? <AlertBox 
			show_status={true} 
			msg={msg} 
			msg_type={msgType} />
			:''
		}
		
			<Grid container  spacing={3}>
			
			
			
			<Grid item xs={12}>
			<Card>
			<CardHeader 
			title={(window.location.pathname).includes("/dashboard") ?"My Community":"Community"}
			/>
			<CardContent className={classes.cardparent}>
			<Box display='none'>{renderList.length}</Box>
			<Box  marginBottom={2} display={"flex"} justifyContent="end">
			
			

			<Box display={(window.location.pathname).includes("/dashboard")?'none':'flex'}>
			<Button
			  startIcon={req === "all" ? <Group /> : <Public />}
			  variant={req === "all"?"contained":"contained"}
			  color={req === "all"?"primary":"secondary"}
			  size="medium"
			  onClick={get_creater_id_community}
			>
			  {req === "all" ? "Switch to my community" : "Switch to all community"}
			</Button>
			</Box>

			
			</Box>
			{
				renderList.length==0
				?
					loading==true
					?<CommunitySkeleton action="vertical" />
					:
						<Typography 
						variant="subtitle1" 
						color="textSecondary" 
						align="center">
						Communities are not found.
						</Typography>
					
				:<CourseList />
						
			}
			</CardContent>
			</Card>
			</Grid>
			</Grid>
		
		</Box>		
	);

}


export default CourseTab;