import {
	Box, Card, Button, CardContent, CardHeader, CircularProgress, IconButton, makeStyles, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { getCacheDetails } from "../caching";
import { translateText } from "../Helper";
import { useHistory } from 'react-router-dom';

const StyledTableCell = withStyles((theme) => ({
	head: {
	//  backgroundColor: theme.palette.common.black,
   backgroundColor:'#497ba7',   
	 color: theme.palette.common.white,
	},
	body: {
	  fontSize: 14,
	},
  }))(TableCell);
  
  const StyledTableRow = withStyles((theme) => ({
	root: {
	  '&:nth-of-type(odd)': {
		backgroundColor: theme.palette.action.hover,
	  },
	},
  }))(TableRow);
  
const useStyles = makeStyles({
  table: {
    minWidth: 700,
  },
});


class CouponList extends Component {

    constructor(props) {
	super(props);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.translateText = translateText.bind(this);
	this.state={coupon_show:[],start:0,end:5,hasMore: true,len:0,}
    }
	
 	
	findEditCoupon = (coupon_id,coupon,amount,subscription,start_on,ends_on) => {
		var coupon =(coupon.trim()).replaceAll(" ", "-");
			

			this.props.history.push({
		 	pathname: '/coupon/update/'+coupon.toLowerCase(), 
			state: {
				coupon_id:coupon_id,
				cou_coupon:coupon,
				cou_amount:amount,
				cou_subscription:subscription,
				cou_start:start_on,
				cou_end:ends_on				
				} });

	}	
	
	getCache = (start,end,fetch) => {
		this.setState({ loading: true })
		this.getCacheDetails("allCoupons_"+start+"_to_"+end,
		"getCoupons",[start,end])
		.then(data => {	
		
				if(data.length<end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					this.setState({ coupon_show: data })
				}	
				else
				{
					this.setState({
						 coupon_show: [...this.state.coupon_show,...data]
					})
				}	
		});
		setTimeout(() => {this.setState({ loading: false })}, 1000);

	}
	
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		setTimeout(() => {
		this.state.start=this.state.start+this.state.end
		this.getCache(this.state.start,this.state.end,true)	
		}, 1000);
	}}
	componentDidMount()
	{
		this.getCache(this.state.start,this.state.end,false)	
	}	
	
	
  	render()
  	{
	
		const Tables = () => {
		return (
		<>		
		{this.state.coupon_show.map((e, index) => (
		<StyledTableRow>
		
		<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={70} />:e['coupon']}	
		</StyledTableCell>
             
		<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={30} />:e['amount']}	
		</StyledTableCell>
             
		<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={30} />:e['subscription']}	
		</StyledTableCell>
             
			     
				 {/*<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={120} />:e['start_on']}	
				 </StyledTableCell>*/}
             
			 	     
		<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={120} />:e['ends_on']}	
		</StyledTableCell>
             
			 	 	     
		<StyledTableCell  >
		{this.state.loading==true?<Skeleton height={45} width={30} />:
		<IconButton>
		<EditIcon  color="textSecondary"  onClick={()=>{this.findEditCoupon(
				  e['coupon_id'],
				  e['coupon'],
				  e['amount'],
				  e['subscription'],
				  e['start_on'],
				  e['ends_on']
		)}}    />	
		</IconButton>
		}	
		</StyledTableCell>
 
		 </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Card >
		
		<CardHeader title={"Coupons"}/>
	  
		<CardContent>
		
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.coupon_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
									   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					
					}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>
				  {
					<TableContainer component={Paper}>
				  <Table aria-label="customized table">
					<TableHead>
					  <TableRow>
						<StyledTableCell >Coupons</StyledTableCell>
						<StyledTableCell >Discount % </StyledTableCell>
						<StyledTableCell >Subscription</StyledTableCell>
						{/*  <StyledTableCell >Valid from</StyledTableCell>*/}
						<StyledTableCell >Valid till</StyledTableCell>
						<StyledTableCell ></StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<Tables />
					</TableBody>
					 </Table>
					</TableContainer>	
					
				  }
				 </InfiniteScroll>
				 
				
				
				
			
		</CardContent>
        </Card>
		  );
  	}
}



export default () => {
    const classes = useStyles();	
	const history = useHistory();

    return (
        <CouponList classes={classes}  history={history}  />
    )
}

  