import React, { createContext, useState } from "react";

export const UserContext = createContext();

// This context provider is passed to any component requiring the context
export const UserProvider = ({ children }) => {
 
  const [user_info, set_user_info] = useState({ email: null, name: null, fname: null, lname: null, img: null, ac_joined_as: null,web_joined_as: null,bookmark_count:0,course_count:0 });

  const [date_info, set_date_info] = useState({  
  current_date: null,
  second_left: 0,
  is_started: false,
  is_ended: false,
  is_text_ended:false
  });

  const [course_info, set_course_info] = useState({   date: null , publish: false});
  
  const [chapter_info, set_chapter_info] = useState({
	  id: null,
	  name: null,
	  details: null,
	  transcript: null,
	  duration: null});
	  
  const [webinar_info, set_webinar_info] = useState({participant_count: 1});
  const [socket_info, set_socket_info] = useState(null);
  const [socket_chats, set_socket_chats] = useState([]);


  return (
    <UserContext.Provider
      value={{
		  
        course_info, set_course_info,
		user_info, set_user_info,
		chapter_info, set_chapter_info,
		date_info, set_date_info,
		webinar_info, set_webinar_info,
		socket_info, set_socket_info,
		socket_chats, set_socket_chats
		
      }}
    >
      {children}
    </UserContext.Provider>
  );
};