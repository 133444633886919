
//import ForwardToInboxIcon from '@material-ui/icons/ForwardToInbox';
import {
  Box, Button, Card, CardContent, CardHeader, CircularProgress, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, withStyles
} from '@material-ui/core';
import { alpha, makeStyles } from '@material-ui/core/styles';
import EmailIcon from '@material-ui/icons/Email';
import axios from 'axios';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import AlertBox from "../../functions/AlertBox";
import { genPassword, translateText } from "../../functions/Helper";








const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);


const useStyles = makeStyles((theme) => ({
  table: {
    minWidth: 700,
  },
     search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
   backgroundColor: alpha('#0000000d', 0.05),
    '&:hover': {
      border: '0.7px solid #d8d8d8',
	     backgroundColor: 'transparent',

    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
	color:'primary'
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    fontSize:'14px',
	padding: theme.spacing(1.5, 1.5, 1.5, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '50ch',
      '&:focus': {
        width: '55ch',
      },
    },
  },

}));

class Logs extends Component {

    constructor(props) {
	super(props);
	this.translateText = translateText.bind(this);
	this.state={
		    msg:false,msg_type:false,
            user_show:[],user_count:0,start:0,end:10,
			hasMore: true,record_len:0,log_data:[],webinar_id:null,loading:'ends',
			search_for:'All',search:null,data:'logs'}
    }
	
	
	fetchMoreData = () => {
    
		if(this.state.hasMore!=false)
		{
			setTimeout(() => {
			this.setState({'start':this.state.start+this.state.end})
			
                this.getLogs('fetch')
                
			}, 1500);
		}
	};
	callFun=()=>{
        if(this.state.data=='logs')
        {this.getSubscriber()}
        else
        {this.getLogs('initial')}
    }
	generateProfile=(name,email,webinar_id,ip)=>
    {
        this.setState({loading:'starts'})
        name=name.split(" ")
        let lname=name[1]
        if(lname==undefined){
            lname=null
           // alert("jbhg6")
        }
        let payload={'webinar_id':webinar_id,'fname':name[0],'lname':lname,'email':email,'ip':ip,'loc':'admin','password':genPassword()}
              
            axios.post('/api/createProfile',payload)
            .then((response) => {
			//console.log(response.data)
            if(response.data=='sent')
            {
                this.setState({msg:'Profile generated successfully!'})
            } 
            else
            {
                this.setState({msg:'Operation failed!'})
            } 
            this.setState({loading:'ends'})
            this.getSubscriber()
			}); 
    }


	getLogs=(loc)=>
    {
			axios.post('/api/getVisitorsLogs',{start:this.state.start,end:this.state.end})
            .then((response) => {
			//console.log(response.data)
            this.setState({data:'logs'})
            if(loc=='initial')
            {
                this.setState({user_show:response.data})
            }
			else
            {
				this.setState({ user_show: [...this.state.user_show,...response.data]})
            }
            
            if(response.data.length<this.state.end)
            {this.setState({hasMore:false})}	
            //alert(this.state.log_data[0]['title'])
			}); 
    }
   
	getSubscriber=()=>
    {
			axios.post('/api/getSubscriber')
            .then((response) => {
			//console.log(response.data)
			this.setState({data:'visitors'})
			this.setState({user_show:response.data})
            this.setState({user_count:response.data.length})	
            //alert(response.data[0]['status'])
            if(response.data[0]['status']=='found')
            {
                this.setState({msg:'Below are the search result!'})
            } 
            else
            {
                this.setState({msg:'No result found!'})
                this.setState({hasMore:false})
            }    
			});
            this.state.hasMore=false

            setTimeout(() => {this.setState({msg:false})}, 2000);

    }
   
   
	
	componentDidMount()
	{
        this.getLogs('initial')
	}
  	render()
  	{
	
		const classes = this.props.classes;
		
        const GetLogs = () => {
		return (
          <TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					   <TableRow>
                       <StyledTableCell >ID</StyledTableCell>
                       <StyledTableCell >Name</StyledTableCell>
                       <StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Webinar ID</StyledTableCell>
						<StyledTableCell >Page referral</StyledTableCell>
						<StyledTableCell >Status</StyledTableCell>
						<StyledTableCell >Added on</StyledTableCell>
						<StyledTableCell >IP</StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<LogTables />
					</TableBody>
					 </Table>
		 </TableContainer>	
		)	
        }
        const GetSubscriber = () => {
		return (
          <TableContainer component={Paper}>
				  <Table className={classes.table} aria-label="customized table">
					<TableHead>
					   <TableRow>
                       <StyledTableCell >Name</StyledTableCell>
                       <StyledTableCell >Email</StyledTableCell>
						<StyledTableCell >Webinar ID</StyledTableCell>
						<StyledTableCell >Updated on</StyledTableCell>
						<StyledTableCell >IP</StyledTableCell>
						<StyledTableCell ></StyledTableCell>
					  </TableRow>
					</TableHead>
					<TableBody>
					<SubscriberTables />
					</TableBody>
					 </Table>
		 </TableContainer>	
		)
		}
	
        const LogTables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
			
            {
               e['status'] =='found'  
               
             ?<>
             <StyledTableCell  >{e['id']}</StyledTableCell>
             <StyledTableCell  >{e['name']}</StyledTableCell>
			  <StyledTableCell  >{e['email']}</StyledTableCell>
			  <StyledTableCell  >{e['webinar_id']}</StyledTableCell>						 
			  <StyledTableCell  >{e['page']}</StyledTableCell>						 
			  <StyledTableCell  >{e['status_log']}</StyledTableCell>						 
			  <StyledTableCell  >{e['logged_on']}</StyledTableCell>						 
			  <StyledTableCell  >{e['ip']}</StyledTableCell>						 
                </>:''}
              
            </StyledTableRow>
		))}
		</>
		)
		}
	
	 const SubscriberTables = () => {
		return (
		<>		
		{
            
         this.state.user_show.map((e, index) => (
		<StyledTableRow>
			
            {
               e['status'] =='found'  
               
             ?<>
             <StyledTableCell  >{e['name']}</StyledTableCell>
			  <StyledTableCell  >{e['email']}</StyledTableCell>
			  <StyledTableCell  >{e['webinar_id']}</StyledTableCell>						 
			  <StyledTableCell  >{e['updated_on']}</StyledTableCell>						 
			  <StyledTableCell  >{e['ip']}</StyledTableCell>						 
			  <StyledTableCell  >
              
                        <Box display="block">
                         <IconButton 
                         onClick={()=>{this.generateProfile(e['name'],e['email'],e['webinar_id'],e['ip'])}}>
                                <EmailIcon color="grey"   />
                         </IconButton> 
                        </Box>
                        
              </StyledTableCell>
                </>:''}
              
            </StyledTableRow>
		))}
		</>
		)
		}
	
	
		
		return ( 
		
		<Box padding={1} >
		<Card >
		{
            this.state.msg!=false 
			?<AlertBox show_status={true} 
			msg={this.state.msg}
			msg_type={this.state.msg_type=="success"?"success":"warning"} />
			: ''
        }
		<CardHeader title={
			<Box display="flex">
				
                
				<Box flexGrow={1}>
                <Typography variant="h6"
                className={classes.iconstext} >
				{"Landing page "+this.state.data}
                <Box display="inline">
                <Typography  variant="subtitle1" color="secondary">
                {this.state.loading=='starts'?'Generating profile ...':''}
                </Typography>
                </Box>
				</Typography>
				</Box>
				
				
                <Box>
                <Button  
				variant="outlined" 
				color="primary" 
                size="large"
				onClick={this.callFun}>
                {this.state.data=='logs'?'Get visitors':'Get all logs'}
				</Button>
				</Box>
 
				
		   </Box>			
			}/>
        
		<CardContent>
	
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.user_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
									   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					 
				}
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>                    	
                    {
                        this.state.data=='logs'
                        ? <GetLogs/>
                        : <GetSubscriber/>
                    }
				 </InfiniteScroll>
				 
				
				
    
		</CardContent>
        </Card>
        </Box>
		  );
  	}
}

export default () => {
    const classes = useStyles();
    return (
        <Logs classes={classes} />
    )
}