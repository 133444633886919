// src/components/GrowthBenefitsCard.jsx

import React from 'react';
import {
  Box,
  Typography,
  Card,
  Grid,
  useMediaQuery,
  Divider,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles';

// Import your images here. Ensure you have these images in the specified path.
// Replace these imports with your actual image paths or URLs.
import benefit1 from '../assets/contentBenefit1.jpg';
import benefit2 from '../assets/contentBenefit2.jpg';
import benefit3 from '../assets/contentBenefit3.jpg';

const useStyles = makeStyles((theme) => ({
  mainCard: {
    marginTop: theme.spacing(4),
    paddingTop: theme.spacing(8),
    padding: theme.spacing(5),
    border: 'none',
    marginBottom: theme.spacing(4),
    textAlign: 'center',
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: theme.shape.borderRadius * 2,
    backgroundColor: '#fff', // Optional: Set a background color
    [theme.breakpoints.down('md')]: {
      padding: theme.spacing(3),
    },
  },
  titleBox: {
    textAlign: 'left', // Align title to the left
    marginBottom: theme.spacing(4),
  },
  titleText: {
    fontWeight: 'bold',
    fontSize: '1.75rem',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'left',
      padding: '0px',
    },
  },
  benefitsGrid: {
    marginTop: theme.spacing(2),
  },
  benefitCard: {
    borderRadius: theme.shape.borderRadius * 1,
    padding: '0px 24px 8px 24px',
    // border: '7px solid #25387c',
    border: 'none',
    boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
    backgroundColor: '#f9f9f9', // Light background for contrast
    height: '100%', // Ensure all cards have equal height
    width: '100%',
    minHeight: '250px', // Added minHeight for better vertical centering
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center', // Center content vertically
    transition: 'transform 0.3s ease, box-shadow 0.3s ease',
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 6px 12px rgba(0,0,0,0.2)',
    },
  },
  benefitImage: {
    width: '80%', // Fixed width
    height: '50%', // Fixed height
    objectFit: 'cover',
    marginBottom: theme.spacing(2),
    marginTop: theme.spacing(2),
  },
  benefitDescription: {
    textAlign: 'center',
    // Removed marginTop: 'auto' to prevent pushing description to bottom
  },
  h6: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    fontWeight: '500',
    backgroundColor: '#f9bb02',
    marginBottom: 8,
    borderRadius: '12px',
    padding: '8px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: 'auto',
    },
  },
  divider: {
    backgroundColor: '#f9bb02', // Set to yellow
    height: '7px',               // Thicker divider
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
  subtitlefont: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  box: {
    justifyContent: 'flex-start',
    padding: theme.spacing(1),
  },
  body1: {
    marginBottom: theme.spacing(0),
    marginTop: theme.spacing(1),
  }
}));

const BenefitsCard = ({ context = 'allExpert' }) => { // Accept context prop with default value
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();

  // Apply column layout for screens smaller than the 'md' breakpoint
  const isMediumScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Define the benefits data for 'allExpert' context
  const allExpertBenefits = [
    {
      id: 1,
      image: benefit1,
      title: 'SEO-Optimized Blogs',
      description:
        'From strategy to execution, we create SEO-friendly blogs to secure your spot on the first page of search engines.',
    },
    {
      id: 2,
      image: benefit2,
      title: 'Newsletter and Emailers',
      description:
        'Newsletters and email campaigns drive loyalty. We’ll create engaging emails and track subscriber excitement',
    },
    {
      id: 3,
      image: benefit3,
      title: 'Ebook',
      description:
        'E-books and guides establish you as a thought leader. Our team will create the perfect e-book to engage prospects and convert leads.',
    },
  ];

  // Define the benefits data for 'studio' context

  // Choose the appropriate data set based on the context
  const benefits =  allExpertBenefits;

  // Define the heading and subheading based on the context
  const heading = 'Services We Offer';
  const subheading = 'End-to-End Content Solutions: Blogging, Social Media, Email, and More - All Aligned with Your Business Goals';

    const subheading2 = context === 'studio' 
    ? 'Growth Studio can help you get recognized as a growth expert by:'
    : '';

  return (
    <Card className={classes.mainCard}>
      {/* Card Title */}
      <Box className={classes.titleBox}>
        <Typography variant="h4" className={styleClasses.h4font} gutterBottom>
          {heading}
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitlefont}`}>
          {subheading}
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.subtitlefont}`}>
          {subheading2}
        </Typography>
      </Box>
      
      {/* Customized Divider */}
      {/* <Divider className={classes.divider} /> */}
      
      {/* Benefits Grid */}
      <Grid
        container
        spacing={4}
        className={classes.benefitsGrid}
        justifyContent="center"
      >
        {benefits.map((benefit) => (
          <Grid
            item
            xs={12}
            sm={6}
            md={4}
            key={benefit.id}
            style={{ display: 'flex' }}
          >
            <Card className={classes.benefitCard}>
              <Box
                display="flex"
                flexDirection="column"
                justifyContent="center"
                alignItems="center"
                flexGrow={1}
                className={classes.box}
              >
                <Typography
                  variant="h6" // Changed back to h6 for consistency
                  className={`${styleClasses.h6font} ${classes.h6}`}
                  gutterBottom
                >
                  {benefit.title}
                </Typography>
                <img
                src={benefit.image}
                alt={benefit.title}
                className={classes.benefitImage}
                loading="lazy" // Lazy loading for performance
              />
                {benefit.description && 
                <Typography variant="body1" className={`${styleClasses.body1font} ${classes.body1}`}>
                  {benefit.description}
                </Typography>
            }
              </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Card>
  );
};

export default BenefitsCard;
