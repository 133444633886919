// src/components/PromotionalCard.jsx

import React from 'react';
import { Box, Typography, Button, Card } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import course3 from '../assets/course_3.png';
import useGlobalStyles from '../styles/CourseStyles';
import { TbLetterSpacing } from 'react-icons/tb';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  // ... existing styles ...

  // Updated styles for PromotionalCard with partial overlap
  promotionalCard: {
    display: 'flex',
    flexDirection: 'row',
    backgroundColor: '#f9bb02', // Yellow background
    borderRadius: 12,
    paddingTop: theme.spacing(0),
    paddingLeft: theme.spacing(8),
    paddingRight: theme.spacing(4),
    marginBottom: theme.spacing(8), // Changed from '0px' to theme.spacing(4)
    marginTop: theme.spacing(8),
    alignItems: 'flex-end', // Changed from 'center' to 'flex-end'
    position: 'relative',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      padding: theme.spacing(2),
      alignItems: 'stretch', // Align items to stretch on small screens
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      flexDirection: 'row',
      paddingLeft: theme.spacing(6),
      paddingRight: theme.spacing(6),
    },
  },
  promotionalImage: {
    width: '35%',
    height: '100%', // Ensure the image takes full height of the card
    borderRadius: 8,
    objectFit: 'cover',
    [theme.breakpoints.down('sm')]: {
      width: '80%', // Reduced width for mobile
      height: 'auto', // Let the image height adjust on small screens
      marginBottom: theme.spacing(0), // Add some space below the image on small screens
      alignSelf: 'center', // Center the image on mobile
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%', // Reduced width for iPad
      height: 'auto', // Adjust height for iPad
      marginBottom: theme.spacing(0), // Remove bottom margin for iPad
    },
  },
  promotionalContent: {
    width: '60%',
    paddingLeft: theme.spacing(4),
    paddingRight: theme.spacing(8),
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8), // Ensure content padding matches card padding
    backgroundColor: 'rgba(249, 187, 2, 0.8)', // Semi-transparent to enhance overlap visibility
    borderRadius: 8,
    position: 'relative',
    zIndex: 2,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      paddingLeft: 0,
      paddingRight: 0,
      paddingBottom: theme.spacing(0),
      paddingTop: theme.spacing(2),
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      width: '50%', // Adjust content width for iPad
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  },
  promotionalTitle: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(2),
    color: '#000',
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      textAlign: 'left',
    },
  },
  promotionalDescription: {
    marginBottom: theme.spacing(3),
    fontSize: '46.4103px',
    fontWeight: 'bold',
    color: '#25387c',
    letterSpacing: '0.1rem',
    lineHeight: '1.2',
    fontFamily: 'boldFont, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '24px',
      textAlign: 'center',
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.5rem', // Adjust font size for iPad
      textAlign: 'left',
    },
  },
  promotionalDesc2: {
    marginBottom: theme.spacing(3),
    letterSpacing: '0.05rem',
    fontSize: '33.0774px',
    color: '#000',
    lineHeight: '1.1',
    fontFamily: 'boldFont, sans-serif',
    [theme.breakpoints.down('sm')]: {
      fontSize: '20px',
      textAlign: 'center',
    },
    // Media query for iPad screen sizes
    [theme.breakpoints.between('sm', 'md')]: {
      fontSize: '1.2rem', // Adjust font size for iPad
      textAlign: 'left',
    },
  },
  promotionalButton: {
    backgroundColor: '#fff', // White background
    color: '#25387c', // Dark blue text color
    borderRadius: '50px',
    padding: '8px 16px',
    fontWeight: '600',
    fontSize: '1.2rem',
    fontFamily: 'MyCustomFont, sans-serif',
    border: 'none', // Light purple border
    display: 'flex', // Flex to align the icon and text
    alignItems: 'center',
    justifyContent: 'center',
    width: 'fit-content',
    '&:hover': {
      backgroundColor: '#f4f4f4', // Slightly darker on hover
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.875rem',
      alignItems: 'center', // Center the button content on mobile
      margin: '0 auto', // Center the button horizontally on small screens
    },
  },
  promotionalButtonIcon: {
    marginRight: '8px',
    borderRadius: '50%',
    padding: '6px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fill: '#25387c', // White arrow icon color
      fontSize: '1.2em',
    },
  },
  overlapEffect: {
    position: 'absolute',
    top: '50%',
    left: '45%', // Adjust this value to control the overlap position
    transform: 'translate(-50%, -50%)',
    width: '60%',
    [theme.breakpoints.down('sm')]: {
      display: 'none', // Hide the overlap on small screens
    },
  },
}));

const PromotionalCard = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Card className={classes.promotionalCard}>
      <img
        src={course3} // Replace with your desired image URL
        alt="Promotional"
        className={classes.promotionalImage}
      />
      <Box className={classes.promotionalContent}>
        <Typography variant="h4" className={`${classes.promotionalTitle} ${styleClasses.h3font}`}>
          Did You Know?
        </Typography>
        <Typography variant="h6" className={classes.promotionalDescription}>
          77% of online course learners achieve career growth
        </Typography>
        <Typography variant="subtitle1" className={classes.promotionalDesc2}>
          Gain new skills, increase your earning potential, and open doors to exciting job opportunities.
        </Typography>
        <Button variant="contained" className={classes.promotionalButton}
        component={Link} // Use Link component from react-router-dom
        to="/register/user">
          <span className={classes.promotionalButtonIcon}>
            <ArrowForwardIcon />
          </span>
          Join for free
        </Button>
      </Box>
    </Card>
  );
};

export default PromotionalCard;
