import {
	Box, Button,
	Card, CardContent, 
    Grid,TableCell, TableRow, Typography, withStyles
} from '@material-ui/core';
import React, { Component } from 'react';





const StyledTableCell = withStyles((theme) => ({
  head: {
  //  backgroundColor: theme.palette.common.black,
 backgroundColor:'#497ba7',   
   color: theme.palette.common.white,
  },
  body: {
    fontSize: 14,
  },
}))(TableCell);

const StyledTableRow = withStyles((theme) => ({
  root: {
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
  },
}))(TableRow);

if(["/","/login","/signin"].includes(window.location.pathname))
{
	// window.location="https://www.opengrowth.com/"
}


class Logs extends Component {

    constructor(props) {
	super(props);
	}
	
  	render()
  	{
	
		const classes = this.props.classes;
		
        const Title = () => {
		return (
        <Box marginBottom={1}  marginTop={1}>
		<Box display="flex" justifyContent="center" alignItems="center" marginBottom={1}>
        
			<Typography variant="h4" color="primary">
			<Box fontWeight="600">Error 404 | Page Not Found </Box>
			</Typography>
		</Box>
		<Box display="flex" justifyContent="center" alignItems="center" marginBottom={1}>
        
			<Typography variant="h6" color="primary">
			The requested url/page was not found on the server.<br/> You can return to the homepage or contact us here.
			</Typography>
            
		</Box>

		<Box display="flex" justifyContent="center" alignItems="center">
			<Button variant="contained" size="large"    color="primary" style={{'text-transform': 'initial'}}
			onClick={()=>{ window.location='/'} } >
				Go to  Homepage
			</Button>
		
        </Box>
        </Box>
		)}
		
		
        const Content = () => {
		return (
			
								
				<Box style={{position:'relative'}}>
                <img src=
                "/assets/images/web/error.png"
                style={{borderRadius:'25px'}}
                height='100%' width="100%" />
				</Box>
				
		)}
		
		
		return ( 
		<Card elevation={2}>
		
		<CardContent style={{height:'95vh'}}>
		
        <Grid item xs={12} container direction="row"
			  alignItems="center"
			  justify="center">
   
         <Grid item xs={8} md={5} >
			<Content />
		</Grid>    

				    
		<Grid  xs={8} >
			<Title />
		</Grid>    
				         
		</Grid>
		</CardContent>
		</Card>		
		  );
  	}
}

export default Logs;