// src/components/LatestContentCard.jsx

import React from 'react';
import { Card, CardContent, Typography, CardMedia, makeStyles, Box } from '@material-ui/core';
import useGlobalStyles from '../styles/CourseStyles';

const useStyles = makeStyles((theme) => ({
  smallCard: {
    borderRadius: '0px',
    padding: theme.spacing(0),
    color: '#fff',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    border: 'none',
    transition: 'transform 0.3s, box-shadow 0.3s',
    outline: 'none', // Add this line
    boxShadow: 'none', // Add this line
    '&:hover': {
    //   transform: 'translateY(-5px)',
    //   boxShadow: '0 8px 16px rgba(0,0,0,0.3)',
    //   backgroundColor: '#ffffff30',
    },
    [theme.breakpoints.down('sm')]: {
        border: 'none'
      },
  },
  media: {
    height: 200,
    borderRadius: '0px',
    border: 'none',
  },
  h6font: {
    color: '#fff',
    padding: '0px 0px',
    marginBottom: theme.spacing(1),
  }, 
  cardContent: {
    paddingLeft: '0px',
    backgroundColor: '#25387c',
    border: 'none',
    padding: 0,
    [theme.breakpoints.down('sm')]: {
        paddingLeft: '8px',
      },
  }
}));

const LatestContentCard = ({ image, category, title, description }) => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Card className={classes.smallCard}>
      <CardMedia
        className={classes.media}
        image={image}
        title={title}
      />
      <CardContent className={classes.cardContent}>
        <Typography variant='h6' color='secondary' className={styleClasses.description}>{category}</Typography>
        <Typography variant='subtitle1' className={`${styleClasses.subtitlefont} ${classes.h6font}`}>{title}</Typography>
        <Typography variant='body2' className={styleClasses.body2font}>{description}</Typography>
      </CardContent>
    </Card>
  );
};

export default LatestContentCard;
