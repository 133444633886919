// src/components/FractionalExpertsBenefitsCard.jsx

import React from 'react';
import { Box, Typography, Card, Button } from '@material-ui/core'; // MUI v4 imports
import { makeStyles } from '@material-ui/core/styles'; // MUI v4 styling
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import useGlobalStyles from '../styles/CourseStyles';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: { // Style for the outer Card
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(0),
    padding: theme.spacing(4, 4), // Padding: top/bottom and left/right
    textAlign: 'center',
    border: 'none',
    // boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    backgroundColor: theme.palette.background.paper,
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(3, 2),
    },
  },
  heading: { // Style for the heading
    fontWeight: 'bold',
    fontSize: '2.2rem',
    marginBottom: theme.spacing(2),
    color: '#000',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.5rem',
      textAlign: 'left',
    },
  },
  description: { // Style for the description
    [theme.breakpoints.down('sm')]: {
      textAlign: 'center',
      width: '100%',
    },
  },
  learnMoreButton: { // New style for the Learn More button
    marginTop: theme.spacing(3),
    textDecoration: 'none', // Remove underline from Link
  },
}));

const OpengrowthAI = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();

  return (
    <Card className={classes.mainCard}>
      <Box>
        {/* Heading Section */}
        <Typography variant="h4" className={styleClasses.h4font}>
          Why Choose OpenGrowth.AI?
        </Typography>

        {/* Description Section */}
        <Typography variant="h6" className={`${styleClasses.description} ${classes.description}`}>
          Scaling an AI startup can be challenging, but OpenGrowth.AI makes it easier. Our on-demand services provide access to fractional consultants who are experts in AI-driven business growth. Overcome the obstacles that come with scaling, our Growth Experts offer strategic insights and solutions that drive sustainable growth. Partner with OpenGrowth.AI to fuel your next phase of success.
        </Typography>
        <br />
        <Typography variant="h6" className={`${styleClasses.description} ${classes.description}`}>
          OpenGrowth.AI offers flexible fractional consultation services, giving you access to top
          talent when you need it. We provide expert strategies and content collaboration services to
          AI-based businesses, ensuring brand growth and fostering a global Expert Economy driving
          business innovation and success.
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.description}`}>
          Additionally, our online courses have been designed to sharpen your entrepreneurial
          acumen and empower your business with essential skills for long-term success.
        </Typography>

        {/* Learn More Button */}
        <Button
          variant="contained"
          color="primary"
          className={`${classes.learnMoreButton} ${styleClasses.individualButton}`}
          component={Link} // Use Link component from react-router-dom
          to="/aboutus" // Destination route
        >
          Learn More
        </Button>
      </Box>
    </Card>
  );
};

export default OpengrowthAI;
