import React, { Suspense,useEffect, useState } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { ProfileView } from '../Routes';
import { getCacheDetails } from '../caching';

export default function Profile() {
  const location = useLocation();
  const history = useHistory();
  const [profileData, setProfileData] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location.pathname.startsWith('/profile') && location.state === undefined) {
      const loc = location.pathname.substring(location.pathname.lastIndexOf('/') + 1);
      getCacheDetails(loc, 'getProfessorPath', loc)
        .then((data) => {
          if (data) {
            const profileData = {
              profile_mail: data['mail'],
              profile_type: data['status'],
              profile_id: data['id'],
              profile_url: data['profile_url'],
              profile_name: data['name'],
            };
            setProfileData(profileData);
          } else {
            history.push('/findcourse');
          }
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      setLoading(false);
    }
  }, [location.pathname, location.state, history,window.location.pathname]);

  return (
    <div>
      {loading ? (
        <div>Loading...</div>
      ) : (
        <Suspense fallback={<div>Loading...</div>}>
          <ProfileView profile_parent={profileData} />
        </Suspense>
      )}
    </div>
  );
}
