import { Box, Button, Card, CardContent, Grid, IconButton, InputAdornment, TextField, Typography } from '@material-ui/core';
import axios from 'axios';
import React, { useState, useContext,useMemo } from 'react';
import cookie from 'react-cookies';
import { successColor } from "../../Helper";
//import ConnectionStatus from "../../chat/ConnectionSatus";
import { useStyles } from './styles/chatStyle';
//import { UserContext } from '../../courses/UserContext';
import SendIcon from '@material-ui/icons/Send';

export default function WebSocketCall({ receiver_info, parentCallback }) {

  const [chat_input, set_chat_input] = useState("");
  const [sent_msg, set_sent_msg] = useState(false);
  const [loading, set_loading] = useState(false);
  const [msg_type, set_msg_type] = useState("success");
//  const context_ref = useContext(UserContext);

  const classes = useStyles();

 

  const handleChatSubmit = (e) => {
    e.preventDefault();
    console.clear();
    //console.log("------------- Handle message request   --------------- ");

    if (chat_input === "" || chat_input === null) {
      set_sent_msg("Enter the message");
    } else {
      set_sent_msg("");
      let build_response = {
        added_on: "0 minutes",
        chat: chat_input,
        msg_id: "",
        chat_id: receiver_info.chat_id,
        receiver: receiver_info.email,
        sender: localStorage.getItem("ac_login_user")
          ? JSON.parse(localStorage.getItem("ac_login_user")).email
          : "",
        status: "found",
      };
		saveMessages();
      /*if (context_ref.socket_info.id !== false) {
        context_ref.socket_info.emit("data", build_response);
        saveMessages();
      } else {
        set_sent_msg("Connection failed!");
        parentCallback();
      }*/
    }
  };

  const saveMessages = () => {
    var chat_id = receiver_info.chat_id;
    set_loading(true);

    if (chat_input !== null && chat_input !== "") {
      let payload = {
        chat: chat_input,
        chat_id: chat_id,
        sender: localStorage.getItem("ac_login_user")
          ? JSON.parse(localStorage.getItem("ac_login_user")).email
          : "",
        receiver: receiver_info.email,
      };
      //console.log(payload);
      set_sent_msg("Processing...");
      save(payload);
      cookie.save("chat_read", parseInt(cookie.load("chat_read")) + 1, { path: "/" });
    }

    set_chat_input("");
  };

  const save = (param) => {
    axios({
      method: "post",
      url: "/api/ac_sendmsg",
      data: param,
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
      },
    }).then((response) => {
		set_loading(false);
		set_sent_msg("Message sent.");
		set_sent_msg(response.data.msg);
		set_msg_type(response.data.type);
		//console.log("is socket connected after sending message - " + context_ref.socket_info.connected);
		 /* if (context_ref.socket_info.connected === false) {
		   parentCallback();
		 }*/
		set_sent_msg(false)
		parentCallback();
		});
    }
 

/*  const memoizedConnectionStatus = useMemo(() => {
    return <ConnectionStatus user={receiver_info} parentCallback={parentCallback} />;
  }, [receiver_info, parentCallback]);
*/

  return (
    <>
      <div>
        <Card elevation={0} style={{ borderRadius: '0px' }}>
          <CardContent style={{ backgroundColor: 'rgb(253 253 254)', padding: '0px' }}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {sent_msg === false ? (
                  <Box marginTop={2}></Box>
                ) : (
                  <Typography variant="caption" style={{ color: successColor }}>
                    {sent_msg}
                  </Typography>
                )}
                <Box marginBottom={2}>
                  <form onSubmit={handleChatSubmit} id="submitForm">
                    <TextField
					  className={classes.textField}
                      style={{ backgroundColor: '#fff' }}
                      id="outlined-end-adornment"
                      name="chat_input"
                      size="large"
                      InputProps={{
                        classes: { adornedEnd: classes.endAdornment },
                        endAdornment: (
                          <InputAdornment position="end">
                            <IconButton
                              variant="contained"
                              size="large"
                              type="submit"
                              color="primary"
                              disabled={loading}
                            >
                            <SendIcon />
                            </IconButton>
                          </InputAdornment>
                        ),
                      }}
                      placeholder="Send messages..."
                      variant="outlined"
                      fullWidth
                      onChange={(e) => {
                        set_chat_input(e.target.value);
                      }}
                      value={chat_input}
                    />
                  </form>
                </Box>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </div>
    </>
  );
}
