// src/components/FeaturesSection.jsx

import React from 'react';
import {
    Box,
    Typography,
    Card,
    Button,
    Grid,
    CardContent,
    CardMedia,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useGlobalStyles from '../styles/CourseStyles'; // Ensure this path is correct
import featureImage from '../assets/home_banner2.webp'; // Replace with your actual image path or use a placeholder
import { BsAlignBottom } from 'react-icons/bs';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    // Styles for FeaturesSection
    featuresSectionContainer: {
        borderRadius: '8px',
        marginTop: theme.spacing(6),
        padding: theme.spacing(4),
        boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
        backgroundColor: '#f9f9f9', // Optional: Add a background color for better contrast
        [theme.breakpoints.down('sm')]: {
            padding: theme.spacing(2),
        },
    },
    sectionHeading: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(2),
        color: '#25387c', // Dark blue color
    },
    subHeading: {
        fontWeight: '600',
        marginBottom: theme.spacing(1),
        color: '#25387c',
    },
    description: {
        marginBottom: theme.spacing(2),
        color: '#555', // Darker gray for readability
    },
    benefitsList: {
        listStyleType: 'disc',
        paddingLeft: theme.spacing(3),
        '& li': {
            marginBottom: theme.spacing(1),
        },
    },
    benefitItem: {
        color: '#555',
    },
    featureCard: {
        border: '6none',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between', // Ensure content is spaced out
        backgroundColor: '#f5f5f5',
        borderRadius: '12px',
        boxShadow: '0 4px 6px rgba(0,0,0,0.1)',
        height: '100%', // Make the card fill the height of its container
    },
    featureCardMedia: {
        height: 200,
        width: '100%',
        objectFit: 'cover',
    },
    featureCardTitle: {
        fontWeight: '600',
        color: '#25387c',
        marginBottom: theme.spacing(1),
    },
    featureCardDescription: {

        paddingRight: theme.spacing(2),
        paddingLeft: theme.spacing(2),
    },
    getStartedButton: {
        backgroundColor: '#f5f5f5',
        color: '#000',
        borderRadius: '50px',
        textTransform: 'none',
        padding: '8px 16px',
        marginTop: '8px',
        '&:hover': {
            backgroundColor: '#f5f5f5',
        },
    },
    h5: {
        marginBottom: theme.spacing(1),
    },
    h6font: {
        color: '#25387c'
    },
    leftColumnBox: {
        padding: '0 0px 8px 0px',
        marginBottom: '1.5rem', // Add spacing between boxes
    },
    leftGrid: {
        display: 'flex',
        flexDirection: 'column',
        padding: '32px !important',
        paddingLeft: '16px !important',
        justifyContent: 'center',
    }
}));

const FeaturesSection = () => {
    const classes = useStyles();
    const styleClasses = useGlobalStyles();
    const theme = useTheme();

    return (
        <Box className={classes.featuresSectionContainer}>
            {/* Section Heading */}

            <Typography
                variant="h6"
                className={`${styleClasses.description} ${classes.h6font}`}
                gutterBottom
            >
                Supercharge your startup with expert-led, targeted lead generation and data
                enrichment. Our seasoned Growth Associates specialize in dynamic business
                development, offering:
            </Typography>

            {/* Two-Column Layout */}
            <Grid container spacing={4} alignItems="stretch"> {/* Ensure both columns stretch to the same height */}
                {/* Left Column - 60% */}
                <Grid item xs={12} md={9} className={classes.leftGrid}>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Data Enrichment:
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}    >
                            Elevate your customer database with insights that fuel personalized outreach, connecting you with your
                            audience on a deeper level for stronger engagement.
                        </Typography>
                    </Box>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Targeted Lead Generation:
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Identify and connect with high-potential leads precisely, ensuring you engage the right audience to maximize conversions and grow your business.
                        </Typography>
                    </Box>
                    <Box className={classes.leftColumnBox}>
                        <Typography variant="h5" className={`${styleClasses.h5font} ${classes.h5}`}>
                            Strategic Marketing:
                        </Typography>
                        <Typography variant="subtitle1" className={`${styleClasses.subtitlefont} ${classes.h6font}`}>
                            Transform raw data into actionable insights to create
                            compelling, personalized marketing strategies that
                            resonate with your audience and drive results.
                        </Typography>
                    </Box>
                </Grid>

                {/* Right Column - 40% */}
                <Grid item xs={12} md={3}>
                    <Card className={classes.featureCard}>
                        <CardMedia
                            className={classes.featureCardMedia}
                            image={featureImage} // Replace with your desired image
                            title="Growth Expert"
                        />
                        <CardContent style={{ flexGrow: 1, padding: 0 }}> {/* Allow content to grow */}
                            <Typography variant="h6" className={`${styleClasses.h6font} ${classes.h6font}`} align='center'>
                                Effortless Growth,
                                Expert-Driven Results
                            </Typography>
                            <Typography
                                variant="subtitle2"
                                className={`${styleClasses.subtitle2font} ${classes.featureCardDescription}`}
                                align='center'
                            >
                                Tap into Expert advice to optimize your
                                business processes and drive
                                sustainable growth with less effort.
                            </Typography>
                        </CardContent>
                        <Box textAlign="center" paddingBottom={2}> {/* Center the button and add padding */}
                            <Button
                                variant="contained"
                                color="primary"
                                className={classes.getStartedButton}
                                component={Link} // Use Link component from react-router-dom
                                to="/expertpage"
                            >
                                Get Started
                            </Button>
                        </Box>
                    </Card>
                </Grid>
            </Grid>
        </Box>
    );
};

export default FeaturesSection;
