import { Avatar, Box, Grid, Link, Typography } from '@material-ui/core';
import { deepPurple } from '@material-ui/core/colors';
import {
  createTheme, makeStyles
} from '@material-ui/core/styles';
import React, { Component } from 'react';
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaTwitter } from "react-icons/fa";
import { useLocation } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
  box:{position:'absolute',bottom:'15%',left:'7%'},
  root: {
    flexGrow: 1,
	minHeight:"100%",
	'background-color':'white'
	
  },
  paper: {
  'border-radius':"12px",
    margin: 'auto',
    maxWidth: "100%",
    maxHeight: "100%",
  },
  image: 
  {
	'border-radius':"12px !important",
	position:'relative',
	backgroundColor:'rgb(0 0 0 / 75%)',
	width:"100%",height:"400px"
 },
  img: {	
	'border-radius':'12px',
	margin: 'auto',
	display: 'block',
	maxWidth: '100%',
	maxHeight: '100%',
	width: 'inherit',
	height: 'inherit',
  },
  card: {
 
   padding: '16px',
   margin: '16px 24px 16px 16px',
  
  }, 
  card1: {
 
  // padding: '16px',
   margin: '16px 0px 16px 0px',
  
  },  
   avatar: {
 
   height: '170px !important',
   width: '170px !important',
   border:'2px solid #eee !important',
	'background-color': '#254180 !important'
  
  },avatarInner: {
 
   height: '130px !important',
   width: '130px !important',
      border:'3px solid #eee !important',
  
  },
 footer:{backgroundColor:'#4B74E7  !important'},
 fb:{backgroundColor:'#38539E  !important'},
 tw:{backgroundColor:'#3AA9F0  !important'},
 ln:{backgroundColor:'#287AB8  !important'},
 insta:{backgroundColor:'#fff  !important',color:'pink  !important'},  link:{textDecoration: "none !important"}

}));

const customTheme = createTheme({
  palette: {
    primary: {
      main: deepPurple[500],
    },
	MuiDialog:{paperWidthXs: {
    'border-radius': '12px'}}
  },
});

let webinar_id='';
let seconds='';

class AccordionBlock extends Component {
    constructor(props) {
    super(props);
    }
	
	
	
	render(){
	const classes = this.props.classes;
	const location = this.props.location;

	
	const SocialIconsGrid = () => {
	return (
    <Grid container 
		direction="row"
			  alignItems="center"
			  justify="center" spacing={0}>
	
		<Grid item xs={12} sm={2} lg={1}>           
        <SocialIcons Icon={<FaFacebookF/>} text="Facebook" url="https://www.facebook.com/OpenGrowth-Academy-111119503970136" />
        </Grid>

  		<Grid item xs={12}  sm={2} lg={1}>           
         <SocialIcons Icon={<FaTwitter/>} text="Twitter"
         url="https://twitter.com/OpengrowthA"/>
        </Grid>
  
         <Grid item xs={12}  sm={2} lg={1}>                      
         <SocialIcons Icon={<FaLinkedinIn/>} text="LinkedIn" 
         url="https://www.linkedin.com/company/opengrowthacademy/"/>
         </Grid>

        <Grid item xs={12}  sm={2} lg={1}>           
        <SocialIcons Icon={<FaInstagram fontSize="28"/>} text="Instagram" url="https://instagram.com/opengrowth_academy" />
        </Grid>

    </Grid>
    )}	
	const SocialIcons = ({Icon,text,url}) => {
	return (
    <Link href={url} target="_blank" className={classes.link}>
    <Box display="flex" flexDirection="row" 
    textAlign="center" justifyContent="center" marginBottom={4} 
      >
           
            <Box lineHeight={1.3}  marginLeft={2} >
             <Avatar className={
                text=='Facebook'?classes.fb
                : text=='Twitter'?classes.tw
                : text=='LinkedIn'?classes.ln
                : classes.insta
                 }>
                 {Icon}
             </Avatar>
            </Box>
              
              <Box lineHeight={1.3}  display="flex" flexDirection="column" 
                textAlign="center" justifyContent="center"
                marginRight={2} marginLeft={1} >
              <Typography
				className={"MuiTypography--subheading"}
				variant="subtitle2"
				color="textSecondary">
                {text}
               </Typography>
              </Box>
    </Box>
   </Link>
    )}	
	
       return(
         <SocialIconsGrid/>
	   );
}
}
export default () => {
    const classes = useStyles();
     const location = useLocation();

    return (
        <AccordionBlock classes={classes} location={location} />
    )
}

  