// src/styles/globalStyles.js

import { makeStyles } from '@material-ui/core/styles';
import { PopupButton } from 'react-calendly';

const useExpertStyles = makeStyles((theme) => ({

    container: {
        padding: theme.spacing(0, 2), // Reduced horizontal padding for smaller screens
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(0, 0),
        },
    },
    customPaper: {
      position: 'relative',
      overflow: 'hidden',
      backgroundColor: '#ffffff',
      // Add a dark blue overlay using linear-gradient
      background: `linear-gradient(#455DA4, #1C3682), 
                  url('https://www.opengrowth.com/assets/public/opengrowth/images/banner/detail-banner.png') 
                  no-repeat center center`,
      backgroundSize: 'cover',
      paddingTop: theme.spacing(3),
      paddingBottom: theme.spacing(2),
      marginBottom: theme.spacing(4),
      borderTopRightRadius: 220,
      borderBottomRightRadius: 220,
      color: '#fff',
      minHeight: 'auto',
      width: '83%',
      boxShadow: 'none',
      paddingLeft: theme.spacing(15),
      [theme.breakpoints.down('sm')]: {
        paddingLeft: theme.spacing(2),
        borderTopRightRadius: 100,
        borderBottomRightRadius: 100,
        width: '100%', // Full width on small screens
        paddingTop: theme.spacing(6),
        paddingBottom: theme.spacing(6),
      },
    },
    mobileAvatarSection: {
        display: 'none',
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            marginBottom: theme.spacing(2),
        },
    },
    expertAvatar: {
        width: 370,
        height: 370,
        border: '20px solid #fff',
        borderRadius: '50%',
        boxShadow: 'none',
        [theme.breakpoints.down('md')]: {
          width: 320,
          height: 320,
        },
        [theme.breakpoints.down('sm')]: {
          width: 200,
          height: 200,
          border: '10px solid #fff',
        },
    },
    button: {
        marginTop: 8,
        marginRight: 48,
        padding: '10px 16px',
        [theme.breakpoints.down('sm')]: {
          padding: '8px 12px',
          fontSize: '0.9em',
        },
    },

    button2: {
      marginTop: 16,
      padding: '4px 16px',
      backgroundColor: '#f5f5f5',
      [theme.breakpoints.down('sm')]: {
        padding: '4px 16px',
        fontSize: '0.9em',
      },
  },
    aboutSection: {
        padding: theme.spacing(2),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        marginTop: theme.spacing(2),
        paddingBottom:theme.spacing(5),
      },
      offersSection: {
        padding: theme.spacing(2),
        backgroundColor: '#f5f5f5',
        borderRadius: 8,
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        marginTop: theme.spacing(4),
        marginBottom: theme.spacing(2),
      },
    h5: {
        textAlign: 'left !important',  
        padding: 8,
    },

    clientReviews: {
        padding: theme.spacing(3),
        paddingBottom:theme.spacing(5),
        backgroundColor: theme.palette.background.paper,
        borderRadius: 8,
        // boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        border: 'none',
        marginTop: theme.spacing(4),
      },

      
  ctaCard: {
    position: 'relative',
    margin: 'auto',
    marginTop: theme.spacing(2),
    padding: theme.spacing(3),
    border: '1px solid #e0e0e0',
    borderRadius: 8,
    textAlign: 'center',
    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
    backgroundColor: '#fff',
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
      maxWidth: '100%',
      padding: theme.spacing(2),
    },
  },
      additionalCTACard: {
        padding: theme.spacing(3),
        backgroundColor: '#ffffff',
        border: '1px solid #e0e0e0',
        borderRadius: 8,
        textAlign: 'center',
        boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.2)',
        marginTop: theme.spacing(7),
        [theme.breakpoints.down('sm')]: {
          padding: theme.spacing(2),
          marginTop: theme.spacing(5),
        },
      },

}));

export default useExpertStyles;
