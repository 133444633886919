
import {
	Avatar, Box, Button, Card, CardActionArea, CardHeader, CircularProgress, Grid, Typography
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import React, { Component } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { api, translateText, userTimeline_path } from "../../Helper";
  
	
	



let user_email=null;
let timeline_img=null;
let id=null;
let status=null;
if([null,"admin"].includes(localStorage.getItem('login_token'))==false)
{
	user_email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	timeline_img=JSON.parse(localStorage.getItem('ac_login_user'))['timeline_img']
	id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
}

//import {useStyles} from "../useStyles";
const styles ={

  root: {width:'100%'  },
  button: {
    color: 'white',
    backgroundColor: '#25387c',
    margin: 10
  },
  large :{height:'150px',width:'150px',border:'3px solid #e5e5e5'}
};

let cls='btn btn-lg btn-success btn-block';
const location = window.location.pathname;

class TimelineUpload extends Component {

    constructor(props) {
    super(props);
    this.translateText = translateText.bind(this);
	this.state={checkedA: true,
    checkedB: true,
	    timeline_profileImg:this.get_img(),
		timeline_upload_status:false,
		loading:false,upload:false,
		timeline_imgDetails:false,
		changesFound:false,
		
	}
    }
	
	get_img = () => {
		let img=null
		if([null,"admin","webinar"].includes(localStorage.getItem('login_token'))==false)
		{
				img=JSON.parse(localStorage.getItem('ac_login_user'))['timeline_img']
		}
		return img;
	}
	 timeImgHandler = event => {
		this.setState({
			
		timeline_imgDetails: event.target.files[0],
		changesFound: true
		
		});
		
		let reader = new FileReader();
		reader.onloadend = () => {
		
			this.setState({ timeline_profileImg: reader.result});
		
		}
		reader.readAsDataURL(event.target.files[0])

		let name=event.target.files[0]['name']		
		if(name.match(/\s/g))
		{
			this.setState({
				 timeline_upload_status:'Filename cannot contain whitespaces.',
				 timeline_imgDetails:false})
				 
		}
		else if(name.length>20)
		{
			this.setState({
				 timeline_upload_status:'Filename length must be <=20 characters.',
				 timeline_imgDetails:false})
		}
		else
		{  
			if(!name.match(/\.(jpg|jpeg|png|gif)$/)) 
			{
				 this.setState({timeline_upload_status:'Please upload an image file.',
				 timeline_imgDetails:false})				
			}
			else
			{
				this.setState({changesFound: true});
			}

		}
		setTimeout(() => {this.setState({timeline_upload_status:false})}, 2000); 

    }

	checkIfImage = (file) => {
    return file && file['type'].split('/')[0] === 'image';
	}
	
	timelineSubmit = (e) =>
	{
		
		if(this.state.changesFound==true)
		{
			this.setState({ loading: true});	

			e.preventDefault();
			 const header = {}; //headers
			 let data = new FormData();
			 header.Accept = 'application/json';
			 if(this.state.timeline_imgDetails!=false)
			 {				
				let boolValue=this.checkIfImage(this.state.timeline_imgDetails)
				if(boolValue==true)	
				{	
					let n=(this.state.timeline_imgDetails['name']).substring(0, (this.state.timeline_imgDetails['name']).indexOf('.'))
					let img_name='timeline_'+id+'_'+status.toLowerCase()+'_'+n+'.png'

					this.setState({ upload: "Starts"});	
					data.append( 'file', this.state.timeline_imgDetails);
					data.append( 'image',this.state.timeline_imgDetails['name'] );
					data.append( 'imageFor',"timeline" );
					data.append( 'table_access',status);
					data.append( 'image_name',img_name.toString());
					data.append( 'id',id );
					data.append( 'email',user_email);
					fetch(api+'/uploadfile',{
					method:"post",
					headers: header,
					body:data}).then((resp)=>{
						//console.log(resp)
						
						if(resp['status']==200)
						{
							this.setState({ loading: false});
							this.setState({ upload: "Uploaded"});
							
							let t=JSON.parse(localStorage.getItem('ac_login_user'))
							localStorage.removeItem('ac_login_user')
							localStorage.removeItem(user_email)
							localStorage.removeItem(user_email+"_profileData")
							
							t['timeline_img']=img_name
							//console.log(t)
							localStorage.setItem('ac_login_user',JSON.stringify(t));
							localStorage.setItem(user_email,JSON.stringify(t));
							localStorage.setItem(user_email+"_profileData",JSON.stringify(t));
							
							this.setState({timeline_upload_status:"Timeline image updated successfully"});
							setTimeout(() => {
								
							//window.location.pathname = '/dashboard';
			
							this.props.history.push({
							pathname: '/profile',
							  state: {
									profile_mail:user_email,
									profile_type:status,
									profile_id:id,
							  } })	
							}, 2000);
							
						}
						else
						{
							this.setState({ loading: false});
							this.setState({timeline_upload_status:"Timeline image update operation failed"});
						}	
					})
					this.setState({ changesFound: false});
				}
				else
				{
					this.setState({ loading: false});
					this.setState({timeline_upload_status:"Only image file are accepted"});
				}

			}
			else
			{
				this.setState({ loading: false});
				this.setState({timeline_upload_status:"Profile image upload failed"});
			}

		}
		else
		{
			this.setState({timeline_upload_status:"Timeline image has not been updated"});
		}
		setTimeout(() => {this.setState({timeline_upload_status:false})}, 3000); 
		
	}
  	render()
  	{
		const classes = this.props.classes;
		if(this.state.loading==true){cls=cls+" disabled"}
		const img_name='timeline_'+status.toLowerCase()+'_'+id+'.png'
		return (
	       

		<div style={styles.root}>
        {
				this.state.timeline_upload_status!=false  
				? <AlertBox show_status={true} 
				msg={this.state.timeline_upload_status} 
				msg_type="success" />
				: ''
		}
		<Card >
		<CardHeader title="Upload profile banner here !" />

		 <CardActionArea >
			<Grid
			  container
			  direction="column"
			  justifyContent="flex-start"
			  alignItems="center"
			>      

		
		<Grid item xs={12}>
		
		<Box marginTop={3} marginBottom={3}>
		{/*<Avatar    className={classes.large} src={this.state.selectedFile}  /> */}
		
		<label htmlFor="timeline-image">
		  <input accept="image/*" id="timeline-image" multiple type="file" 
		  onChange={this.timeImgHandler}  hidden/>
			<Avatar style={styles.large} 
			src={this.state.timeline_imgDetails===false
			?userTimeline_path+this.state.timeline_profileImg:this.state.timeline_profileImg} > 
		  </Avatar>
		</label>
		
		
		
        </Box>
		
        </Grid>
		
		<Grid item xs={12}>
		<Box padding={2} textAlign="center" >
		<Typography variant="subtitle2" color="textSecondary">
		Allowed *.jpeg, *.jpg, .*png, *.gif
        Max size of 3.1MB</Typography>
		
		{
						this.state.upload==='Starts'
						?
							<Typography  variant="subtitle2"  color="secondary">
							{
								this.state.timeline_upload_status===false
								?""
								:
									this.state.timeline_upload_status==="Timeline image update operation failed"
									?"Uploading failed"
									:"Uploading ..."
							}
							</Typography> 
						:
							<Typography  variant="subtitle2"  color="secondary">
							{this.state.timeline_imgDetails['name']}
							</Typography> 
		}
        </Box>
        </Grid>
		
   
        
	
        <Grid item xs={12}>
		
		
	<Box marginTop={2} marginBottom={5}>
	<Button
        variant="contained"
        color="primary"
        size="large"
        startIcon={<SaveIcon />}
		onClick={this.timelineSubmit}  disabled={this.state.loading}>
		{this.state.loading && <CircularProgress size={24} />}
		 {!this.state.loading &&  <span>Save image</span>}
		</Button>
		
		</Box>
		</Grid>
		 
		  </Grid>
        
		
		 </CardActionArea>
		</Card>
        </div>



	    );
  	}
}
export default () => {
    const location = useLocation();
    const history = useHistory();
    return (
        <TimelineUpload location={location}  history={history} />
    )
}
