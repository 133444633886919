
import {Avatar, Badge, Box, Card, CardActions, CardActionArea, CardContent, CardHeader, Divider, Grid, IconButton, List, ListItem, ListItemAvatar, ListItemSecondaryAction, ListItemText, Paper, Typography } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import {
	Skeleton,
	Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineItem, TimelineSeparator
} from '@material-ui/lab';
import React from 'react';
import { userTimeline_path,successColor,user_status_id } from "./Helper";
import './mentors/dashboard/webinars/link.css'
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
 const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: window.location.pathname!="/trending-courses"?3:5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: window.location.pathname!="/trending-courses"?3:5
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: window.location.pathname!="/trending-courses"?3:5
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1
    }
  };
   const resp = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 3
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items:3
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2
    }
  };
  
export const Action=({title})=>{		
	return(	
		<Typography className="web" display="inline" variant="caption" color="primary">
		{title}
		</Typography>

	)}	

export const CommunitySkeleton = ({ e,key,action }) => {
  const path = window.location.pathname;
  const arr = path === '/dashboard' ? [1,2,3] : [1,2,3,4,5];

const avatarProps = {
  variant: 'circle',
  animation: 'wave',
  width: 35,
  height: 35,
};
  if (action === 'vertical') {
    return (
      <Grid container spacing={3}>
        {arr.map((item, index) => (
          <Grid item xs={12} sm={6} md={4} key={index}>
            <Card style={{border: '1px solid #ebebeb'}}>
               
                <Box marginBottom={2}>
                  <Skeleton variant="rect"  animation="wave" width="100%" height={150} />
                </Box>
                
				<Box  padding={2}>
                <Box marginBottom={1}>
                  <Skeleton variant="text"  animation="wave" width={'80%'} height={50} />
                </Box>

                <Box display="flex">
                  {Array.from({ length: 1 }).map((_, index) => (
					<AvatarGroup key={index} max={3}>
					  {Array.from({ length: 3 }).map((_, avatarIndex) => (
						<Skeleton key={avatarIndex} {...avatarProps} />
					  ))}
					</AvatarGroup>
				  ))}
                </Box>
                </Box>
            </Card>
          </Grid>
        ))}
      </Grid>
    );
  } else {
    return (
      <Box margin={1} position="relative">
        <Box
          display="flex"
          padding="0px 24px"
          marginBottom="-80px"
          justifyContent="space-between"
          alignItems="center"
        >
          <Skeleton variant="circle" width={100} height={100} />
        </Box>

        <Card>
          <CardContent style={{ padding: '0px' }}>
            <Box marginTop={10} padding={2}>
              <Skeleton variant="rect" width={'50%'} height={30} />
              <Box padding={1}></Box>
              <Skeleton variant="rect" width={'100%'} height={70} />
            </Box>
          </CardContent>
        </Card>
      </Box>
    );
  }
};



export const ChoiceAccordian=({action})=>{		
	let arr=[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
	<Box>
	{
		action==1
		?
			<Box width="100%">
			
			<Box height={50} margin="auto" marginBottom={2} width="45%"><Skeleton variant="rect" width={"100%"} height={"inherit"} /></Box>
			
			<Box height={100} margin="auto" marginBottom={2}><Skeleton variant="rect" width={"100%"} height={"inherit"} /></Box>
			
			</Box>
		:""
			
	}		
	<Card>
	<CardHeader title={
		<Box>
		
		<Box marginBottom={2} display="flex" >	
		<Skeleton variant="rect" width={"70%"} height={"50px"} />
	    </Box>
		
		<Box>	
		<Skeleton variant="rect" width={"45%"} height={"30px"} />
	    </Box>
						
		</Box>
	} />
	<CardContent>
	{
		action==1
		?
			
			<Box>
			{
				[0,1,2,4,5,6].map((e, index) => (
				<Box marginBottom={2} >	
				{
					![3,4].includes(index)
					?<Skeleton variant="rect" width={"100%"} height={50} />
					:
						<Box display="flex" justifyContent="space-between" height={50}>
						<Skeleton variant="rect" width={"49%"} height={"inherit"} />
						<Skeleton variant="rect" width={"49%"} height={"inherit"} />
						</Box>
				}
				</Box>
				))
			}
			</Box>
		:
			<Box>
			{
				[0,1,2,4,5].map((e, index) => (
				<Box marginBottom={2} display="flex" justifyContent="space-between"	>
				<Box display="flex">
				<Box marginRight={2}><Skeleton variant="circle" height={40} width={40} /></Box>
				<Skeleton variant="rect" width={200} height={40} />
				</Box>
				<Box display="flex">
				{
					[0,1,3].map((e, index) => (<Box marginLeft={2}><Skeleton variant="circle" height={40} width={40}  /></Box>))
				}
				</Box>
				</Box>
				))
			}
			</Box>
	}		
					
		
					
	</CardContent>
	</Card>
	</Box>
)
}			

  
export const CategoriesView_Skeleton=()=>{		
	let arr=[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
	<Box >		
			
	{
				arr.map((e, index) => (
					
					<Box marginBottom={2} key={index}>
					<Card elevation={0}>
					<CardContent style={styles.card}>
					
					 <ListItem alignItems="flex-start">
					 <ListItemAvatar style={styles.avatar} >
					 <Skeleton animation="wave" variant='circle' 
					 height={72} width={72}  />
					</ListItemAvatar>
					  <ListItemText
						primary={
						<Box  marginLeft={2}>
						<Grid container spacing={0}
						alignItems="flex-start"
						justifyContent="flex-start" >
						
						<Grid item xs={12}>
						<Box marginBottom={1} >
						<Skeleton animation="wave" variant='rect' 
						height={25} width={"70%"}  />
						</Box>
						<Box marginBottom={1} >
						<Skeleton animation="wave" variant='rect' 
						height={55} width={"100%"}  />
						</Box>
						</Grid>
						
						</Grid>
						</Box>
						}
					  />
					</ListItem>
					
					
					</CardContent >
					</Card >
					</Box>
				))
			
			}

	</Box>
)
}			



export const SlotSkeleton = ({action}) => {
	let arr=window.location.pathname=="/dashboard"?[1,2,3]:[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
	
	<React.Fragment>
		
		<Grid container spacing={2}>
		<Grid item xs={12} sm={7}>
		{[0,1].map((e, k) => (
			
			<Box marginBottom={4}>
			<Box marginTop={2}  marginBottom={1} >
				<Skeleton animation="wave" variant='rect' height={20} width={"25%"}  />
			</Box>
			
			<Box display="flex"  marginBottom={2} >
			<Skeleton animation="wave" variant='rect' height={20} width={"45%"}  />
			</Box>
			
			<Carousel responsive={resp} arrows={false}>
			{arr.map((e, index) => (
			
			
				<Card style={{marginRight:"16px"}} elevation={1}>
				
				<Box display="flex" justifyContent="center"
				alignItems="center">
				<Skeleton animation="wave" variant='rect' 
						 height={k==1?90:72} width={"100%"}  />
				</Box>
				
				
				</Card>
					
			))}
			</Carousel>
			</Box>
			
		))}	
		</Grid>
		{
			(window.location.pathname).startsWith("/profile")==false && 
			(window.location.pathname).startsWith("/my-profile")==false  
			?<Grid item xs={12} sm={5}>
			<Box marginBottom={2}><Skeleton animation="wave" variant='rect'  height={50} width={"100%"}  /></Box>
			<Box marginBottom={2}><Skeleton animation="wave" variant='rect'  height={170} width={"100%"}  /></Box>
			<Box marginBottom={2} display="flex" flexDirection="row-reverse"><Skeleton animation="wave" variant='rect'  height={50} width={"76%"}  /></Box>
			</Grid>
			:''
		}
		</Grid>

	</React.Fragment>
		
)
}			
	



export const BookedSession_Skeleton = ({action}) => {
	let arr=window.location.pathname=="/dashboard"?[1,2,3]:[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
		<Carousel responsive={responsive}  arrows={false}>
		{arr.map((e, index) => (
		
		
			<Card style={{marginRight:"16px"}} elevation={1}>
			<CardContent>
			
			<Box display="flex" justifyContent="center"
			alignItems="center">
			<Skeleton animation="wave" variant='circle' 
					 height={72} width={72}  />
			</Box>
			<Box marginTop={2}  marginBottom={1} display="flex" justifyContent="center"
			alignItems="center">
				<Skeleton animation="wave" variant='rect' 
				height={20} width={"45%"}  />
			</Box>
			
			<Box display="flex"  marginBottom={1} justifyContent="center"
			alignItems="center">
				<Skeleton animation="wave" variant='rect' 
				height={20} width={"65%"}  />
			</Box>
			
			<Box marginBottom={1}
			display="flex" justifyContent="center"
			alignItems="center">
				
				<Box>
				<Skeleton animation="wave" variant='rect' 
				height={20} width={40}  /> 
				</Box>
				
				<Box marginLeft={0.5} marginRight={0.5}>
					{" "}
				</Box>
				
				<Box>
				<Skeleton animation="wave" variant='rect' 
				height={20} width={40}  />
				</Box>
				
			</Box>
			
			<Box marginBottom={1}
			display="flex" justifyContent="center"
			alignItems="center">
				
				<Skeleton animation="wave" variant='rect' 
				height={20} width={"37%"}  />
				
			</Box>
			
			</CardContent>
			</Card>
				
		))}
		</Carousel>
)
}			
	


export const Vertical_Blogs_Skeleton = ({action}) => {
	let arr=[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
		<Grid container spacing={2}>
		{arr.map((e, index) => (
		
			<Grid item xs={12} sm={3} >
			<Box 
			paddingBottom={1} 
			padding={0.6} 
			paddingTop={0} 
			paddingRight={1.5} >
			<Card elevation={3} >
		
			<Skeleton 
			animation="wave" 
			variant='rect' 
			height={150} width={"100%"}  />
			
			<CardContent>
			
			
			
			<Box display="flex" marginBottom={2} justifyContent="space-between">
			
			<Box  display="flex" alignItems="center"  justifyContent="center" >
			<Box >
				<Skeleton variant="circle" width={52} height={52} />
			</Box>
 			<Box display="block" marginLeft={2}>
				<Box >
				<Skeleton variant="text" width={40} height={25} />
				</Box>
				<Box marginTop={0.2}	>
				<Skeleton variant="text" width={60} height={25} />
				</Box> 
			</Box> 
			</Box> 
			
			
			<Box >
			<Skeleton variant="rect" width={80} height={30} />
			</Box> 
			
			
			</Box>
			
			
			<Skeleton 
			animation="wave" 
			variant='rect' 
			height={70} width={"100%"}  />
			
			
			
			<Box display="flex" justifyContent="space-between">
			{[0,1,2].map((e, index) => (		
			
			<Box marginTop={2}>
			<Skeleton variant="rect" width={30} height={25} />
			</Box> 
			
			))}
			</Box>
			
			</CardContent>
			</Card>
			</Box>	
			</Grid>	
				
		))}
		</Grid>
)
}			


export const QuizRenderSkeleton = ({action}) => {
	let arr=action!="webinar"?[1,2,3,4,5]:[1,2,3]
	let styles={	card : {padding:'0px 0px'}}
	return (
	
		<Grid container spacing={1}>
		{
			arr.map((e,index) => (
		
			<Grid item xs={12} lg={action!="webinar"?3:4} >
			<Box 
			paddingBottom={1} 
			padding={0.6} 
			paddingTop={0} 
			paddingRight={1.5} >
			<Card elevation={3} >
		
			<Skeleton 
			animation="wave" 
			variant='rect' 
			height={180} width={"100%"}  />
			<Box position="relative" marginTop={-8}>
			<Card>
			<CardContent>
				
				<Box marginTop={2.5}>
				<Box marginBottom={2} display="flex" justifyContent="flex-end">
				<Skeleton 
				animation="wave" 
				variant='rect' 
				height={25} width={"30%"}  />
				</Box>				
				<Skeleton 
				animation="wave" 
				variant='rect' 
				height={60} width={"90%"}  />
				</Box>
			
			
			{action!="webinar"
				?<Box marginTop={2}>
				<Skeleton 
				animation="wave" 
				variant='rect' 
				height={35} width={"40%"}  />
				</Box>
				:""
			}
			
			</CardContent>
			</Card>
			</Box>
			
			</Card>
			</Box>	
			</Grid>
				
		))}
		</Grid>
)
}			



export const CarouselSkeleton = ({action}) => {
	let arr=user_status_id=="student"?[1]:window.location.pathname=="/dashboard"?[1,2,3,]:[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
		<Carousel responsive={responsive}  arrows={false}>
		{arr.map((e, index) => (
		
		
			<Box 
			paddingBottom={1} 
			padding={0.6} 
			paddingTop={0} 
			paddingRight={1.5} >
			<Card elevation={3} >
		
			<Skeleton 
			animation="wave" 
			variant='rect' 
			height={150} width={"100%"}  />
			
			{action=="scroll"
			?<CardContent>
			<Skeleton 
			animation="wave" 
			variant='rect' 
			height={70} width={"100%"}  />
			</CardContent>
			:''}
			
			</Card>
			</Box>	
				
		))}
		</Carousel>
)
}			



export const ArticleSkeleton = ({action}) => {
	let arr=window.location.pathname=="/dashboard"?[1,2,3,]:[1,2,3,4,5]
	let styles={	card : {padding:'0px 0px'}}
	return (
	<Box >		
	<List >			
	{
				arr.map((e, index) => (
					
					<React.Fragment key={index}>
					<Card elevation={0}>
					<CardContent style={styles.card}>
					
					 <ListItem alignItems="flex-start">
					 <ListItemAvatar style={styles.avatar} >
					 <Skeleton animation="wave" variant='circle' 
					 height={72} width={72}  />
					</ListItemAvatar>
					  <ListItemText
						primary={
						<Box  marginLeft={2}>
						<Grid container spacing={0}
						alignItems={action=="webinar"?"flex-start":"center"} 
						justifyContent={action=="webinar"?"flex-start":"center"} >
						
						<Grid item xs={12}>
						<Box marginBottom={1} >
						<Skeleton animation="wave" variant='rect' 
						height={25} width={"70%"}  />
						</Box>
						<Box marginBottom={1} >
						<Skeleton animation="wave" variant='rect' 
						height={55} width={"100%"}  />
						</Box>
						</Grid>
						
						</Grid>
						</Box>
						}
					  />
					</ListItem>
					
					
					</CardContent >
					</Card >
					</React.Fragment>
				))
			
			}
	</List>
	</Box>
)
}			

export const AttendSkeleton = ({action}) => {
	let arr=window.location.pathname=="/dashboard"?[1,2,3]:[1,2,3,4,5]
	let styles={	
		card : {padding:'0px 0px'} , 
		nopad : {paddingLeft:'0px',paddingRight:'0px',backgroundColor:''},
		bg : {backgroundColor:''}
	}
	return (
	<Box >		
	<List >			
	{
				arr.map((e, index) => (
					
					<React.Fragment>
					
					<Card elevation={0}>
					<CardContent style={styles.card}>
					
					 <ListItem 
					 alignItems="flex-start"
					 style={(window.location.pathname=="/dashboard"
					 && action=="mentors") || 
					 window.location.pathname=="/upcoming-mentorship"?styles.nopad:styles.bg}>
					 <ListItemAvatar style={styles.avatar} >
					 <Skeleton animation="wave" variant='circle' 
					 height={72} width={72}  />
					</ListItemAvatar>
					  <ListItemText
						primary={
						
						<Box display={action=="webinar"?"block":"none"}  
						marginLeft={2} marginBottom={1}>
						<Skeleton animation="wave" variant='rect' 
						height={20} width={"40%"}  />
						</Box>
						
						}
						secondary={
						<Box marginLeft={2}  marginTop={action==="mentors"?1:0} >
						<Grid container spacing={0}
						alignItems={action==="mentors"?"center":"flex-start"}
						justifyContent="center" >
						
						<Grid item xs={8}>
						<Box marginBottom={1}>
						<Skeleton animation="wave" variant='rect' 
						height={30} width={"100%"}  />
						</Box>
						<Box marginBottom={1}>
						<Skeleton animation="wave" variant='rect' 
						height={20} width={"80%"}  />
						</Box>
						</Grid>
						
						<Grid item xs={4} >
						<Box  display="flex" flexDirection="row-reverse">
						<Skeleton animation="wave" variant='rect' 
						height={25} width={"60%"}  />
						</Box>
						</Grid>
						
						</Grid>
						</Box>
						}
					  />
					</ListItem>
					
					
					</CardContent >
					</Card >
					</React.Fragment>
				))
			
			}
	</List>
	</Box>
)
}		

export const ViewMentors_Skeleton = ({ card }) => {
  const t = ["/my-mentors", "/mentors-and-experts"].includes(window.location.pathname);
  const arr = t ? [1, 2, 3, 4, 5] : [1, 2];

  const full_page = ["/my-mentors"].includes(window.location.pathname);

  const SmallCard = () => {
    return (
      <Box padding={2} >
        <Grid container spacing={3}>
          {arr.map((e, index) => (
            <Grid item xs={12} md={4} lg={3} key={index}>
              <Card>
                <Box display="flex" alignItems="center">
                  <Avatar style={{
					width: '85px',
					height: '85px',
					borderRadius:0
				  }} />
                  <Box width="100%" height='85px' display="flex" justifyContent="center" textAlign="left" alignItems="center">
                    <Box width="100%" padding="0px 16px">
                      <Box marginBottom={0.5}>
                        <Skeleton  animation="wave"  variant='rect' height={20} width={"30%"} />
                      </Box>
                      <Skeleton   animation="wave" variant='rect' height={20} width={"56%"} />
                    </Box>
                  </Box>
                </Box>
              </Card>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  const DefaultCard = () => {
    return (
      <Box>
        <Grid container spacing={3}>
          {arr.map((e, index) => (
            <Grid item xs={12} lg={full_page ? 12 : 6} key={index}>
              <Box marginBottom={0.2}>
                <Box>
                  <Card elevation={3}>
                    <CardActionArea>
                      <Grid container spacing={0}>
                        <Grid item xs={5}>
                          <Skeleton variant='rect' height={"100%"} width={"100%"} />
                        </Grid>
                        <Grid item xs={7}>
                          <Box
                            sx={{
                              display: 'flex',
                              alignItems: 'flex-start',
                              flexDirection: 'column'
                            }}
                            width="100%"
                            padding={2}
                          >
                            <Box width="inherit">
                              <Box display="flex" justifyContent="flex-end">
                                <Skeleton variant='rect' height={20} width={"30%"} />
                              </Box>
                            </Box>
                            <Box width="inherit">
                              <Box display="block" alignItems="center" justifyContent="center" marginBottom={2}>
                                <Box marginBottom={2}>
                                  <Skeleton variant='rect' height={20} width={"30%"} />
                                </Box>
                                <Box>
                                  <Skeleton variant='rect' height={t ? 80 : 50} width={"100%"} />
                                </Box>
                              </Box>
                            </Box>
                            <Box width="inherit">
                              <Grid container spacing={2}>
                                <Grid item xs={12}>
                                  <Box width="inherit" display="flex" flexDirection="row-reverse">
                                    <Box marginLeft={2}>
                                      <Skeleton variant='rect' height={20} width={85} />
                                    </Box>
                                    <Box>
                                      <Skeleton variant='rect' height={20} width={85} />
                                    </Box>
                                  </Box>
                                </Grid>
                              </Grid>
                            </Box>
                          </Box>
                        </Grid>
                      </Grid>
                    </CardActionArea>
                  </Card>
                </Box>
              </Box>
            </Grid>
          ))}
        </Grid>
      </Box>
    );
  };

  return (
    <>
      {card !== undefined && card === 'small' ? <SmallCard /> : <DefaultCard />}
    </>
  );
};

export const Cart_Skeleton = () => {
return (
	<Grid container spacing={3}>
		
		<Grid item xs={12} sm={8}  >
			<Quiz_Skeleton />	
		</Grid>
		
		<Grid item xs={12} sm={4}  >
		<Cart_Summary_Skeleton />
		</Grid>
	</Grid>
)
}	
export const Billing_Skeleton = () => {
return (
	<Grid container spacing={3}>
		
		<Grid item xs={12} sm={8}  >
					<Card elevation={1}>
			<CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
			<CardContent>	
			<List>
			{
				[1,2].map((e,index) => (

				<ListItem style={{paddingLeft:'0px',paddingRight:'0px'}}>
				<ListItemAvatar> 
					<Skeleton animation="wave" variant='circle' 
					height={25} width={25}  />	
				</ListItemAvatar>
				<ListItemText 
				primary={<Skeleton  animation="wave"  variant='rect'  
				height={35} width={"30%"}  />} 
				secondary={<Box marginTop={1}><Skeleton  animation="wave"  variant='rect' 
				height={27} width={"50%"}  /></Box>}
				/>
				<ListItemSecondaryAction>
				<Box display="flex" flexDirection="row">
				<Skeleton variant='rect'  height={35} width={45}  />
				<Box marginLeft={2}>
				<Skeleton  variant='rect'   height={35} width={45}  /></Box>
				<Box marginLeft={2}>
				<Skeleton  variant='rect'   height={35} width={45}  /></Box>
				</Box>
				</ListItemSecondaryAction>
				</ListItem>
			))}
			</List>	
			 </CardContent>
			</Card>
		</Grid>
		
		<Grid item xs={12} sm={4}  >
		<Cart_Summary_Skeleton />
		</Grid>
	</Grid>
)
}	
export const Cart_Summary_Skeleton = () => {
return (
	<>
			<Card elevation={1}>
			<CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
			<CardContent>		
			<Grid container spacing={2}>
			
			
			{[1,2,3].map((e,index)=>(
			<>
			<Grid item xs={12} sm={10}>
			  <Skeleton variant="rect"   height={25} width={"50%"} />
			  </Grid>
			  
			  <Grid item xs={12} sm={2}>
			 	 <Skeleton variant="rect"   height={25} width={"100%"} />
			  </Grid>
			</>))}
		
			<Grid item xs={12} >
				<Divider/>
			</Grid>	
		
			{[1].map((e,index)=>(
			<>
			<Grid item xs={12} sm={10}>
			  <Skeleton variant="rect"   height={25} width={"50%"} />
			  </Grid>
			  
			  <Grid item xs={12} sm={2}>
			 	 <Skeleton variant="rect"   height={25} width={"100%"} />
			  </Grid>
			</>))}
			
			
			<Grid item xs={8} ></Grid>	
			
			<Grid item xs={4} >
			 <Skeleton variant="rect"   height={30} width={"100%"} />
			</Grid>		
			 
			<Grid item xs={12} >
			 <Skeleton variant="rect"   height={35} width={"100%"} />
			</Grid>		
			 
             </Grid>
			 </CardContent>
			</Card>
			
			<Box marginTop={2}>
			 <Skeleton variant="rect"   height={35} width={"100%"} />
		    </Box >
			</>
	)
}	

export const Course_Keys_Skeleton = () => {
	let arr=[1,2,3,4,5,6]
	let j=0;
	return (
			    
				<Card>
				<CardContent>
				<Grid>				
	
				
				<Grid container spacing={2}>
				{ 
					arr.map((e, index) => (
					
					
					
					<Grid item xs={12} sm={6}>
					<Box component="span" display="none">	{j==3?j=0:''}</Box>

					<List >
					  <ListItem alignItems="flex-start" style={{padding:'0px'}}>
						<ListItemAvatar>
						  <Badge overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}>
							<Skeleton variant="circle" width={45} height={45} />
						 </Badge>
						</ListItemAvatar>
						
						
					<ListItemText  
					primary={<Typography color="textPrimary" variant="subtitle1">
					<Skeleton variant="text" width={"30%"} height={35} />
					
					</Typography>} 
					secondary={<Typography color="textSecondary" variant="subtitle2">
					<Skeleton variant="text" width={"100%"} height={80} /> 
					</Typography>} 
					 />
						
					  </ListItem>
					  <Divider variant="inset" component="li" />
					  </List>
						<Box component="span" display="none">	{j=j+1}</Box>
					  </Grid>
				))}
				</Grid>
				
				</Grid>
                    
				</CardContent>
			</Card>
	    )
 }
export const Course_Bot_Skeleton = () => {
	
		return (
				<Card>
				<CardContent>
				
				<Grid container spacing={2}>
			
				<Grid item xs container direction="column" xs={12} sm={8} spacing={3} padding={0}>
					
					<Grid item xs>
						<Typography  color="textPrimary" variant="subtitle1" gutterBottom >
						 <Skeleton variant="text" width={"20%"} height={35} />
						</Typography>
						
						<Typography color="textSecondary" variant="subtitle2">
						<Skeleton variant="rect" width={"100%"} height={70} />
						</Typography>
					</Grid>
					
					<Grid item>
					<Typography color="textPrimary" variant="subtitle1" gutterBottom>
					<Skeleton variant="text" width={"30%"} height={35} />
					</Typography>
					<Typography color="textSecondary" variant="subtitle2">
					<Skeleton variant="rect" width={"100%"} height={70} />
					</Typography>
					</Grid>
				
					<Grid item>
					<Typography color="textPrimary" variant="subtitle1" gutterBottom>
					<Skeleton variant="text" width={"15%"} height={35} />
					</Typography>
					<Typography color="textSecondary" component="div" variant="subtitle2">
					 
						<Box display="flex" flexDirection="row">
						<Box marginRight={2}><Skeleton variant="text" width={70} height={40} /></Box>
						<Box marginRight={2}><Skeleton variant="text" width={70} height={40} /></Box>
						<Box marginRight={2}><Skeleton variant="text" width={70} height={40} /></Box>
						</Box>
						
				
					</Typography>
					</Grid>
				</Grid>
			
				<Grid item xs={12} sm={4}>
				<List >
				  <ListItem>
					<ListItemAvatar>
						<Badge overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}>
						 <Skeleton variant="circle" width={40} height={40} />
						</Badge>
					</ListItemAvatar>
					
					<ListItemText  
					primary={<Typography color="textPrimary" variant="subtitle1">
					 Category
					</Typography>} 
					secondary={<Typography color="textSecondary" variant="subtitle2">
					<Skeleton variant="text" width={"50%"} height={30} /> 
					</Typography>} 
					 />
				 
				 
				 
					 </ListItem>
					  <ListItem>
						<ListItemAvatar>
						<Badge overlap="circle" anchorOrigin={{ vertical: 'bottom', horizontal: 'right',}}>
						 <Skeleton variant="circle" width={40} height={40} />
						</Badge>
						</ListItemAvatar>
						
						<ListItemText  
						primary={<Typography color="textPrimary" variant="subtitle1">
						Level
						</Typography>} 
						secondary={<Typography color="textSecondary" variant="subtitle2">
						<Skeleton variant="text" width={"50%"} height={30} /> 
						</Typography>} 
						 />
						
					  </ListItem>
					</List>
				</Grid>
				</Grid>
		
		</CardContent>
        </Card>
	    )
 }

export const Course_Module_Skeleton = () => {
	return (

	<Box>
						{[0,1,2,4,5].map((e, index) => (		
						<Box marginBottom={2} display="flex">
						<Box marginRight={2}>
						<Skeleton variant="rect" width={40} height={35} />
						</Box>
						<Skeleton variant="rect" width={"80%"} height={35} />
						</Box> 
						))}
	</Box>
	
	
)}

export const Course_Top_Skeleton = () => {
	return (
	<div>
	<Paper square style={{padding:'0px'}}>

	<Grid container >
	
	<Grid item xs={12}  >
	<Skeleton variant="rect" width={"100%"} height={410} />
	</Grid>
	
	<Grid item xs={12}  >
	
		<Box padding={2}   >
		<Grid container spacing={3} >
		
		<Grid item xs={12} style={{padding:'0px 12px'}}>
         <Box display="flex" marginTop={0} flexDirection="row-reverse" >
			<Box marginTop={1.3}  display="flex"  flexDirection="row" >
			
			<Box marginRight={2}>
			 <Skeleton variant="rect" width={130} height={35} />
			 </Box>
			 
			<Box marginRight={2}>
			 <Skeleton variant="rect" width={130} height={35} />
			 </Box>
			 
			<Box marginRight={2}>
			 <Skeleton variant="rect" width={130} height={35} />
			 </Box>
			 
			 </Box>
			</Box>
        </Grid>
		
		
        <Grid item xs={12} >
             <Box display="flex" flexDirection="row" >
			 <Box> 
			 
			 <Box display="flex" alignItems="flex-start">
				<Typography component="div" variant="h6" color="textPrimary">
				<Skeleton variant="rect" width={250} height={35} />
				</Typography>
				</Box>
				
				<Box display="flex" alignItems="flex-start" >
				<Typography variant="subtitle1" color="textSecondary">
				<Box marginTop={1}>
				<Skeleton variant="rect" width={500} height={20} />
				</Box>
				</Typography>
				</Box>
			 
			 
			</Box>
		  </Box>
        </Grid> 
		
        <Grid item xs={12} >
			<Box display="flex" alignItems="flex-start" marginBottom={2} >
			<Typography component="div" variant="subtitle1" color="textPrimary">
			  <Box fontWeight="fontWeightBold" >
				Instructors
			  </Box>
			</Typography>
			</Box>
			<Box display="flex" flexDirection="row">
						  
				<Box  display="flex" flexDirection="column" marginRight={3}>
				 <Skeleton variant="circle" width={85} height={85} />
				<Box marginTop={2} alignItems="center">
				 <Skeleton variant="text" width={85} height={37} />
				</Box>
				</Box>
						  
				<Box  display="flex" flexDirection="column" marginRight={3}>
				<Skeleton variant="circle" width={85} height={85} />
				<Box marginTop={2}>
				<Skeleton variant="text" width={85} height={37} />
				</Box>
				</Box>
						  
				<Box  display="flex" flexDirection="column">
				<Skeleton variant="circle" width={85} height={85} />
				<Box marginTop={2}>
				<Skeleton variant="text" width={85} height={37} />
				</Box>
				</Box>
				
			</Box>
			
        </Grid> 
      
		</Grid>
		</Box>

	</Grid>
	
	</Grid>
	
	</Paper>
	</div>
	
)}

export const Course_Mid_Skeleton = () => {
	return (
	 <Grid item xs={12}>
          <Paper square >
		  


		<Grid container  spacing={2} >
			<Grid item xs={4} xl={12}>	
			<Box sx={{  alignItems: 'center', display: 'flex',  flexDirection: 'column' }}  >
				<Skeleton variant="circle" width={40} height={40} />
				
				
				<Typography variant="subtitle2" color="textPrimary">
				 <Box marginTop={1}>
				 <Skeleton variant="text" width={80} height={25} />
				 </Box>
				 </Typography>
				 
				 
				<Typography variant="subtitle2"  color="textSecondary">
				 <Skeleton variant="text" width={120} height={25} />
				 </Typography>
				 
				 				
			</Box>
		    </Grid>
			
			<Grid item xs={4} xl={12}>	
			<Box sx={{  alignItems: 'center', display: 'flex',  flexDirection: 'column' }}  >
			
				<Skeleton variant="circle" width={40} height={40} />
				
				
				<Typography variant="subtitle2" color="textPrimary">
				<Box marginTop={1}>
				<Skeleton variant="text" width={80} height={25} />
				</Box>
				 </Typography>
				 
				 
				<Typography variant="subtitle2"  color="textSecondary">
				 <Skeleton variant="text" width={120} height={25} />
				 </Typography>
				 
				 				
			</Box>
		    </Grid>
	
			<Grid item xs={4} xl={12}>	
			<Box sx={{  alignItems: 'center', display: 'flex',  flexDirection: 'column' }}  >
			
				<Skeleton variant="circle" width={40} height={40} />
				
				
				<Typography variant="subtitle2" color="textPrimary">
				<Box marginTop={1}>
				 <Skeleton variant="text" width={80} height={25} />
				</Box>
				</Typography>
				 
				 
				<Typography variant="subtitle2"  color="textSecondary">
				 <Skeleton variant="text" width={120} height={25} />
				 </Typography>
				 
				 				
			</Box>
		    </Grid>

			
			</Grid>
			
		</Paper>
		</Grid>			
			)
	}

export const Horizontal_Course_Skeleton = () => {
	let arr=[1,2,3,4,5];
	return (
		 <Card elevation={1}>
         <CardHeader title={<Skeleton animation="wave" height={45} width={"35%"}  />} />
	  
			<CardContent>
			 <Grid container spacing={2}>
			
				{window.location.pathname=='/findcourse'
				?<Grid item xs={12}>
				<Skeleton variant="rect"  width={"100%"} height={40} />
				</Grid>:''}
			
				{window.location.pathname!='/course/view'
				?<Grid item xs={12}>
				<Box display="flex" flexDirection="row" paddingLeft={2} paddingTop={2}>
				{arr.map((e, index) => ( 			
				<Box marginRight={2}>
				<Skeleton variant="text"  width={110} height={55} />
				</Box>
				))}
				</Box>	
				</Grid>:""}
			
			</Grid>
			
			{arr.map((e, index) => ( 	
					
					   
					   <Grid container spacing={2}>
						 
						 <Grid item>
							<Skeleton variant="rect"   width={282} height={178} style={{borderRadius:'12px'}} />
						  </Grid>
						  <Grid item xs={12}  xl={12} lg container>
							<Grid item xs container direction="column" spacing={2}>
							  <Grid item xs>
								
								<Skeleton variant="text"  width={100} height={30} />
								
								<Skeleton variant="rect" width={'100%'} height={70} />
							  </Grid>
							  
							   <Grid item  xl={12} lg container spacing={1}
							   style={{marginTop:'20px'}}>
								<Grid item xs={2}><Skeleton variant="text" width={'100%'} height={45} /></Grid>
								<Grid item xs={2}><Skeleton variant="text" width={'100%'} height={45} /></Grid>
								<Grid item xs={2}><Skeleton variant="text" width={'100%'} height={45} />
								</Grid>
						
							  </Grid>
							</Grid>
						  
						  </Grid>
						</Grid>
				))}
			</CardContent>
		</Card>
)
}	

export const CourseAccordian_Skeleton = () => {
	let arr=[1,2,3];
	return (
		 <Card elevation={1}>
         <CardHeader title={<Skeleton animation="wave" height={45} width={"20%"}  />} />
	  
			<CardContent>
			 <Grid container spacing={2} justifyContent="center">
			
				<Grid item xs={5}>
				<Box marginBottom={3}>
				<Skeleton variant="rect"  width={"100%"} height={40} />
				</Box>
				</Grid>
			
				<Grid item xs={8}>
				{arr.map((e, index) => ( 			
				<Box marginBottom={2}>
				<Skeleton variant="rect"  width={"100%"} height={70} />
				</Box>
				))}
				</Grid>
			
			</Grid>
			
			</CardContent>
		</Card>
)
}	

export const Course_Structure_Skeleton = () => {
	let arr=[1,2,3];	
		return (
		 <Card elevation={1}>
         <CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
	  
			<CardContent>
			
			 <Grid item xl={12} lg container>
					<Grid item xs container direction="column" spacing={2}>
					  <Grid item lg>
					 <Skeleton variant="text"  width={'45%'} height={60} />
					  </Grid>
					</Grid>
			 </Grid>
			{	
				arr.map((e, index) => ( 
			   
			   <Grid container spacing={2}>
				 <Grid item>
					<Skeleton variant="circle"   width={55} height={55}  />
				</Grid>
				  <Grid item xl={12} lg container>
					<Grid item xs container direction="column" spacing={2}>
					  <Grid item lg>
					 <Skeleton variant="text"  width={'15%'} height={60} />
					  </Grid>
					</Grid>
					<Grid item>
					<Box display="flex" flexDirection="row">
					
					 <Box marginLeft={2}>
						<Skeleton variant="circle"   width={45} height={45} />
					 </Box>
					 <Box marginLeft={2}>
						<Skeleton variant="circle"   width={45} height={45}  />
					 </Box>
					  

					 </Box>
					 </Grid>
				  </Grid>
			
				</Grid>
			
			))
			}
			</CardContent>
		</Card>
)
}	

export const Quiz_Skeleton = () => {
	let arr=window.location.pathname=='/quiz'?[1,2,3,4,5]:[1,2,3];	
		return (
		 <Card elevation={1}>
         <CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
	  
			<CardContent>
			{	
				arr.map((e, index) => ( 
			   
			   <Grid container spacing={2}>
				 <Grid item>
					<Skeleton variant="rect"   width={62} height={62} style={{borderRadius:'12px'}} />
				</Grid>
				  <Grid item xl={12} lg container>
					<Grid item xs container direction="column" spacing={2}>
					  <Grid item lg>
					 <Skeleton variant="text"  width={'40%'} height={35} />
					 <Skeleton variant="text" 
					 width={window.location.pathname=='/orders'?'50%':'90%'} 
					 height={30} />
					  </Grid>
					</Grid>
					<Grid item>
					<Box display="flex" flexDirection="row">
						<Skeleton variant="rect"  width={80} height={27} />
					 
					 {window.location.pathname=='/orders'?
					 <>
					 <Box marginLeft={2}>
						<Skeleton variant="rect"  width={80} height={27} />
					 </Box>
					 <Box marginLeft={2}>
						<Skeleton variant="rect"  width={80} height={27} />
					 </Box>
					 </>
					 :''
					 } 

					 </Box>
					 </Grid>
				  </Grid>
			
				</Grid>
			
			))
			}
			</CardContent>
		</Card>
)
}	

 
export const Quiz_Attempted_Skeleton = () => {
	let arr=[1,2,3,4,5];	
		return (
		
			<Card elevation={1}>
			<CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
	  
			<CardContent>
			{	
				arr.map((e, index) => ( 
			   <Grid container  spacing={3}>
			   
				<Grid item >
					<Skeleton variant="rect"   height={64} width={64} />
				</Grid>
				
				<Grid item xs={12}  xl={12} lg container >
				<Grid item xs container direction="column" >
				<Grid item xs>
					
					<Typography component="div" variant="subtitle1">
					<Skeleton variant="text"  height={35} width={70} />
					</Typography>
						
					<Typography variant="subtitle2" color="textSecondary" s>
					<Skeleton variant="rect"  height={30} width={"90%"} />
					</Typography>
					
					
					<Box display="flex" flexDirection="row" marginTop={2} >
					<Box marginRight={2} >
					  <Skeleton variant="text"  height={25} width={70} />
					 </Box>
					 <Box >
					  <Skeleton variant="text"  height={25} width={70} />
					 </Box>
					 </Box>	
                
					
				</Grid>
				</Grid>
				</Grid>
			
				
			 <Grid item>
              	<Box display="flex" flexDirection="row" >
					<Box marginRight={2} >
					  <Skeleton variant="text"  height={25} width={70} />
					 </Box>
					 <Box >
					  <Skeleton variant="text"  height={25} width={70} />
					 </Box>
				</Box>	
             </Grid>
			 
             </Grid>
			))}
			</CardContent>
			</Card>		
		
	)
}	

  	

export const Quiz_Wel_Skeleton = () => {
return (
	
			<Card elevation={1}>
			<CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
			<CardContent>		
			<Grid container>
			
			  <Grid item xs={12} sm={11}>
			  <Skeleton variant="rect"   height={25} width={130} />
			  </Grid>
			  
			  <Grid item xs={12} sm={1}>
			 	 <Skeleton variant="rect"   height={35} width={80} />
			  </Grid>
			  
			  
			  { [1,2,3,4,5].map((e,index)=>(
			  <Grid item xs={12} >
			  <Box marginTop={2}>
			  <Skeleton variant="rect"   height={35} width={130} />
			  <Box marginTop={1}> <Skeleton variant="rect"   height={35}
			  width={200} />
			  </Box> 
			  </Box> 
			  </Grid> 
				))}			
		
             </Grid>
			 </CardContent>
			</Card>
	)
}	

  	

export const Quiz_Chart_Skeleton = () => {
return (
		
			<Card elevation={1}>
			<CardHeader title={<Skeleton animation="wave" height={45} width={"30%"}  />} />
			<CardContent>		
			 <Grid container  spacing={3}>
			   
				<Grid item  xs={2} >
					<Skeleton variant="rect"   height={27} width={80} />
				</Grid>
				
				<Grid item  xs={2} >
				<Box marginLeft={3}>
				<Skeleton variant="rect"   height={27} width={80} />
				</Box>
				</Grid>
				
				
				<Grid item xs={12}  >
				<Box display="flex" justifyContent="center">
				<Skeleton variant="circle"   height={250} width={250} />
				</Box>
				</Grid>
			
			 
             </Grid>
			 </CardContent>
			</Card>
	)
}	

  	
export const Account_Skeleton = () => {
		return (
			<Card elevation={1}>
			<CardContent style={{padding:'0px'}}>
				<Skeleton  variant="rect"  width={"100%"}  height={200}   />
			
				<Box sx={{alignItems: 'center', display: 'flex',flexDirection: 'column'}}>
				
				<Box  display="block" textAlign="center" marginTop={2}>
				<Skeleton  variant="rect"  width={100} height={30}   />
				</Box>
				
				<Box  display="block" textAlign="center" marginTop={2}>
				<Skeleton  variant="rect"  width={150} height={30}   />
				</Box>
				
				
				<Box display="flex">
				<Box display="block" m={2}>
				<Box  display="block" textAlign="center"  padding={2}>
				<Skeleton  variant="rect"  width={45} height={40}   />
				</Box>
				<Box  display="block" textAlign="center" marginBottom={2}>
				 <Skeleton  variant="rect"  width={90} height={30}   />
				</Box>
				</Box>
				
				<Box display="block" m={2}>
				<Box  display="block" textAlign="center" padding={2}>
				<Skeleton  variant="rect"  width={45} height={40}   />
				</Box>
				<Box  display="block" textAlign="center" marginBottom={2}>
				 <Skeleton  variant="rect"  width={90} height={30}   />
				</Box>
				</Box>
				</Box>
				
			</Box>
		    </CardContent>
		    </Card>
)}
		
export const Graph_Skeleton = () => {
		return (
		 <Card elevation={1}>
         <CardHeader title={
			<><Skeleton animation="wave" height={35} width={"30%"}  />
			<Skeleton animation="wave" height={35} width={"40%"}  />
			</>
		} />
	  
			<CardContent>
			<Skeleton animation="wave" variant="rect" height={230} width={"100%"}  />
			</CardContent>
		</Card>
)
}	

export const Calender_Skeleton = () => {
		return (
		 <Card elevation={1}>
         <CardHeader title={
			<Skeleton animation="wave" height={50} width={"30%"}  />
		} />
	  
			<CardContent>
			<Grid container spacing={2}>
			
			<Grid item xs={1} >
			<Skeleton animation="rect" height={50} width={"100%"}  />
			</Grid>
		

			<Grid item xs={9} ></Grid>
		

			<Grid item xs={1} >
			<Skeleton animation="rect" height={50} width={"100%"}  />
			</Grid>
		

			<Grid item xs={1} >
			<Skeleton animation="rect" height={50} width={"100%"}  />
			</Grid>
		

	
			<Grid item xs={12} >
			<Skeleton animation="wave" variant="rect"
			height={580} width={"100%"}  />
			</Grid>
			</Grid>
		
			</CardContent>
		</Card>
)
}	
  	
 export const Profile_About_Skeleton = () => {
 return (
	  	<Card  elevation={1}>
		<CardContent >
	<Box flexDirection="row" padding={2}>
  
	<Box display="flex" flexDirection="row"  marginBottom={2} > <Skeleton animation="wave" height={35} width="30%"  /></Box>						
	<Box display="flex" flexDirection="row"  marginBottom={2} > <Skeleton animation="wave" height={35} width="40%"  /></Box>						
					
	<Box display="flex" flexDirection="row"  marginBottom={0} > <Skeleton animation="wave" height={35} width="70%"  /></Box>						
	<Box display="flex" flexDirection="row"  marginBottom={0} > <Skeleton animation="wave" height={35} width="89%"  /></Box>						
	<Box display="flex" flexDirection="row"  marginBottom={0} > <Skeleton animation="wave" height={35} width="80%"  /></Box>						
	<Box display="flex" flexDirection="row"  marginBottom={0} > <Skeleton animation="wave" height={35} width="80%"  /></Box>						
						
	</Box>
		</CardContent >
  	</Card>

	)
}
		

export const Timeline_Skeleton = () =>
{
	let arr =[1,2,3];
	return(
		<Card  elevation={1}>
		<CardHeader title={
		<Skeleton animation="wave" height={45} width={"45%"}  />
		} />
		<CardContent >
		<Timeline>
		{ 
				arr.map((e, index) => (
				<TimelineItem>
				<TimelineSeparator>
			    <TimelineDot   />
				<TimelineConnector />
				</TimelineSeparator>
				<TimelineContent >
				<Skeleton variant="text"  width={'50%'} height={35} />
				<Skeleton variant="text"  width={'80%'} height={35} />
				</TimelineContent>
				</TimelineItem>
		))}
		</Timeline>	
	</CardContent>
	</Card>	
)}  
   export const User_Course_Skeleton = () => {
	let arr =[1,2,3,4,5];
	return (
			
		<Card  elevation={1}>
		
		
		<CardHeader title={
			<Skeleton animation="wave" height={45} width={"45%"}  />
		} />
		
		<CardContent style={{paddingLeft:'0px'}} >
		{ 
			arr.map((e, index) => (
			<ListItem key={index} alignItems="flex-start">			
			<ListItemAvatar>
			<Skeleton variant="circle" width={45} height={45} />
			</ListItemAvatar>
			<ListItemText primary={<Skeleton animation="wave" height={30} width={"60%"} />}
			secondary={
			<React.Fragment>
			<Skeleton animation="wave" height={30} width={"90%"}  />
			<Box display="flex" >
			<Box flexGrow={1}><Skeleton animation="wave" height={30} width={"60%"} /> </Box>
			<Skeleton animation="wave" height={30} width={"30%"}  /></Box>
			</React.Fragment>}
			/>
			</ListItem>
			))}
			</CardContent>
			</Card>
	  
			)
		}
  
	export const Certificates = () => {
		return (
		<Card>
			<CardHeader title='Certificates' />
			<CardContent >
			Our certificates of completion will help you stay ahead of the game: with online certificates you'll be able to prove your professional growth to anyone.
			</CardContent >
			</Card >
			)
		}
  
	export const Certificate_Skeleton = () => {
		return (
			
		<Card  elevation={1}>
		
		
		<CardHeader title={
			<Skeleton animation="wave" height={45} width={"40%"}  />
		} />
		
		<CardContent >
		
			<Skeleton animation="wave" width={"100%"} height={180} />
			
		</CardContent>
		</Card>
	  
			)
		}
  

export const Vertical_Course_Skeleton = () =>
{
	let arr =[1,2,3,4];
	let path =['/mycourses','/searchcourse','/viewedcourse','/bookmarked',
	'/popularcourses'];
	
	return(
	<Card  elevation={1}>
	<CardHeader title={
	<Skeleton animation="wave" height={50} width={"20%"}  />
	} />
	<CardContent >
	<Grid container spacing={2}>
	
	{window.location.pathname=='/searchcourse'
		?<><Grid item xs={9} ><Skeleton animation="rect" height={50} width={"100%"}  /></Grid>
		<Grid item xs={3} ><Skeleton animation="rect" height={50} width={"100%"}  /></Grid></>
		
	:''}
	
	{
		arr.map((e, index) => (
			<Grid item xs={12} 
			sm={path.includes(window.location.pathname)?3:4}>
			<VCard />
			</Grid>
		))}
		
		
	</Grid>
	</CardContent>
	</Card>
	)
}

export const Recommendation_Skeleton = () =>
{
	let arr =[1];
	return(
	<Card  elevation={0}>
	<CardHeader title={
	<Skeleton animation="wave" height={45} width={"40%"}  />
	} />
	<CardContent style={{padding:'0px',paddingTop:'16px',backgroundColor:'#fbfbfb'}} >
	<Grid container spacing={2}>
	{
		arr.map((e, index) => (
			<Grid item xs={12} >
			<VCard />
			</Grid>
		))}
	</Grid>
	</CardContent>
	</Card>
	)
}

export const VCard = () =>
{
	return(
		<Card elevation={3}>
		<Box  >
		<Skeleton variant="rect" width={'100%'} height={150} />
		</Box>
							
		<CardContent>
		<Box  marginTop={1}  marginBottom={1} >
		<Skeleton variant="text"  width={100} height={25} />
		</Box>
		<Skeleton variant="rect" width={"100%"} height={60} />
		 </CardContent>
					
		<CardActions disableSpacing className="float-right">
		<IconButton > 
		<Skeleton variant="rect" width={25} height={25} />
		</IconButton>
		<IconButton aria-label="share">
		<Skeleton variant="rect" width={25} height={25} />
		</IconButton>
		<IconButton aria-label="share">
		<Skeleton variant="rect" width={25} height={25} />
		</IconButton>
		</CardActions>
		</Card>
	)
}

export const Course_Progress_Skeleton = () =>
		{

			let arr =[1,2,3];
			return(
			<Card  elevation={0}>
		
		
			<CardHeader title={
				<Skeleton animation="wave" height={45} width={"40%"}  />
			} />
			
			<CardContent style={{padding:'0px',backgroundColor:'#FBFBFB'}}>
			<Grid container spacing={2}>
			{
				arr.map((e, index) => (
								 
				<Grid item xs={12} sm={4}>
				<Box margin={0.5}>
				<Card elevation={1} >
				<Grid container spacing={0}>
			
				<Grid item xs={5} >
				<Box>
				<Skeleton  variant="square"  width={100}   height={100} />
				</Box>
				</Grid>
				
				<Grid item xs={7}>
				
				<Box display="flex"  alignItems="center"
				justifyContent="flex-end"  padding={1}>
				
					 <Skeleton variant="circle"  width={80}  height={80} />
					</Box>
							
					</Grid>
						
				</Grid>
				</Card>
			   </Box>
				</Grid>
			))}
			</Grid>
			</CardContent>
			</Card>
			)
}
export const Profile_Upper_Skeleton= () => {
	const bg_img={height:'250px',
	background:'cover',
	background:'linear-gradient(rgba(0,0,0,0.3), rgba(0,0,0,0.2))',
	'border-top-left-radius': '12px',
    'border-top-right-radius': '12px',
	background:'url('+userTimeline_path+')'}
	const style={height:{'line-height':'1.25rem'}}

	return (
	
	
	   <Grid container spacing={3}>
        <Grid item xs={12}>
        <Paper style={bg_img } elevation={0} >  
		 <Box display="flex" flexDirection="row" color="white" paddingTop={1} m={2} 
		 style={{transform:'translateY(8.5rem) translateX(0.1rem)'}}>
		
			
			<Box paddingRight={1} >
			<Skeleton variant="circle" width={120} height={120} />
			</Box>
			
			<Box   style={{transform:'translateY(2rem) ',color:'white'}}>
			<Typography variant="h6" style={style.height} component="div" >
			<Box fontWeight="fontWeightBold" marginBottom={1} >
			<Skeleton  variant="rect" width={140} height={35}  />
			</Box>
			</Typography>
			<Skeleton  variant="text" width={85} height={28}  />
			</Box>
			
		</Box>	
		</Paper>
        </Grid>
		</Grid>
	)
	}

 export const Accordian_Skeleton = () => {
	return (
			
			<Card elevation={3}>
			<CardContent>
			
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"30%"} height={35} />
			</Box>
			
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"40%"} height={35} />
			</Box>
			
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"60%"} height={35} />
			</Box>
			
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"35%"} height={35} />
			</Box>
			
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"45%"} height={35} />
			</Box>
		
				
			<Box  marginTop={1}  marginBottom={1} >
		    <Skeleton variant="text"  width={"100%"} height={180} />
			</Box>
			
			
		   </CardContent>
			</Card>
				
	)
}

export const Course_University_Skeleton = () => {
	return (
			
				<div style={{ width: '100%' }}>
				<Card>
				
			
				<CardContent>
				 <Box display="flex" justifyContent="center" >
					 <Skeleton variant="text" width={180} height={100} />
					<Box  marginLeft={2}>
					 <Typography variant="h6"  color="textPrimary" >
					<Skeleton variant="text" width={180} height={100} />
					</Typography>
					</Box>
				  </Box> 
				  
				  <Skeleton variant="text" width={"100%"} height={100} />
				</CardContent>
			 
			</Card>
			</div>
				
            
	
	)
	}
export const Course_Transcript_Skeleton = () => {
	return (
			
				<Card elevation={0} >
				
				<CardContent >
			
				<Typography  color="textPrimary" variant="subtitle1" gutterBottom>
				<Skeleton variant="text" width={"15%"} height={35} />
				</Typography>

				<Typography  color="textSecondary" variant="subtitle2">
				<Skeleton variant="text" width={"100%"} height={70} />
				</Typography>

				
				</CardContent >
                </Card >    
				
            
	
	)
	}
export const Unit_Skeleton = ({action}) => {
	let arr=[1,2];
			
		return (
	
				<Grid container spacing={2}>
				
					
					{
							action=="top"
							?
							<Grid item xs={12}>
						
							<Card>
							<CardContent>
							
							<Box marginBottom={4} height="50px">	
							 <Skeleton variant="rect" width={"70%"} height={"inherit"} />
							</Box>
							
							<Box marginBottom={2} height="30px">	
							 <Skeleton variant="rect" width={"50%"} height={"inherit"} />
							</Box>
							
							<Box height="30px" >
							 <Skeleton variant="rect" width={"35%"} height={"inherit"} />
							</Box >
							</CardContent>  
							</Card> 
						
						
					</Grid>
						:""
					}		
					{
							action=="center"
							?
							<Grid item xs={12}  md={12} >
								<Card>
								<CardContent>
								<Skeleton variant="rect" width={"100%"} height={800} />
								</CardContent>  
								</Card> 
							</Grid>
						:""
							
					}
					{
							action=="navigation"
							?
							
							<Grid item xs={12}  md={12} >
								<Card>
								<CardContent>
								<Box  display="flex" justifyContent="space-between" 
								 marginBottom={2}>
								<Skeleton variant="rect" width={"40%"} height={50} />
								<Skeleton variant="rect" width={"40%"} height={50} />
								</Box>
								{
									[0,1,2,4,5].map((e, index) => (
									<Box marginBottom={1}  height="50px">	
									 <Skeleton variant="rect" width={"100%"} height={"inherit"} />
									</Box>
									))
								}
								</CardContent>  
								</Card> 
							</Grid>
						:""
							
					}
					
					
				</Grid>  
		
	)
	}
		
export const UnitSkeleton = () => {
	let arr=[1,2];
			
		return (
	
				<Grid container spacing={2}>
				
					
					<Grid item xs={12}>
						<Card>
						<CardContent>
						
						<Box marginBottom={4} height="50px">	
						 <Skeleton variant="rect" width={"70%"} height={"inherit"} />
						</Box>
						
						<Box marginBottom={2} height="30px">	
						 <Skeleton variant="rect" width={"50%"} height={"inherit"} />
						</Box>
						
						<Box height="30px" >
						 <Skeleton variant="rect" width={"35%"} height={"inherit"} />
						</Box >
						</CardContent>  
						</Card> 
						
					</Grid>
					
					<Grid item xs={12}  md={9} >
						<Card>
						<CardContent>
						<Skeleton variant="rect" width={"100%"} height={800} />
						</CardContent>  
						</Card> 
					</Grid>
					
					<Grid item xs={12}  md={3} >
						<Card>
						<CardContent>
						<Box  display="flex" justifyContent="space-between" 
						 marginBottom={2}>
						<Skeleton variant="rect" width={"40%"} height={50} />
						<Skeleton variant="rect" width={"40%"} height={50} />
						</Box>
						{
							[0,1,2,4,5].map((e, index) => (
							<Box marginBottom={1}  height="50px">	
							 <Skeleton variant="rect" width={"100%"} height={"inherit"} />
							</Box>
							))
						}
						</CardContent>  
						</Card> 
					</Grid>
					
					
				</Grid>  
		
	)
	}
		
export const Course_Instructors_Skeleton = () => {
	let arr=[1,2];
			
		return (
			<Card elevation={0} >
	
				<CardContent >

				<Grid container spacing={2}>
				
				{ 
				
				
					arr.map((e, index) => (
						
					<Grid item xs={12} sm={6}>
					
					 <Card elevation={3}>
					  <CardHeader
						avatar={
						<Skeleton variant="circle" width={120} height={120} style={{marginBottom: '48px'}}/>}
						
						title={
						<React.Fragment>	
						<Typography variant="subtitle1" >
						 <Skeleton variant="text" width={"25%"} height={35} />
						</Typography>
						
						<Typography variant="subtitle2" color='textSecondary' >
						 <Skeleton variant="text" width={"35%"} height={30} />
						</Typography >
						
						</React.Fragment>}
						
						subheader={
						<React.Fragment>
						<Box marginTop={1} display="flex" direction="row">
						
						<Box marginRight={1}>
						<Skeleton variant="text" width={20} height={30} />
						</Box>
						<Box >
						<Typography component="div" variant="subtitle2">
						<Skeleton variant="text" width={300} height={30} />
						</Typography> </Box>
						</Box>
						
						<Box display="flex" direction="row">
						
						<Box marginRight={1}>
						<Skeleton variant="text" width={20} height={30} />
						</Box>
						<Box >
						<Typography component="div" variant="subtitle2">
						<Skeleton variant="text" width={20} height={30} />
						</Typography>
						</Box>
						
						<Box marginLeft={2} marginRight={2}>
						</Box>
						
						<Box marginRight={1} >
					    <Skeleton variant="text" width={20} height={30} />
						</Box>
						<Box >
						<Typography component="div" variant="subtitle2">
						<Skeleton variant="text" width={20} height={30} />
						</Typography> </Box>
						</Box>
						
						
						</React.Fragment>}
					  />

					  <CardContent>
						<Typography variant="subtitle2" color="textSecondary" component="p">
						<Skeleton variant="text" width={"100%"} height={180} />
						</Typography>
					  </CardContent>
					 </Card>
					
						
					</Grid>  
					
				))}
				</Grid>  
				
		
			</CardContent>
			</Card>

                    
           
	
	)
	}
		
export const Course_Syllabus_Skeleton = () => {
	let arr=[1,2,3,4]	
		return (
			<Card>
			<CardContent>
			
			<Box display="flex" >

				<Box flexGrow={1} >
				<Typography color="textPrimary" variant="subtitle1" gutterBottom>
				<Skeleton variant="text" width={"30%"} height={35} />
				</Typography>	
				</Box>
				
				
				<Box>
				<Typography variant="subtitle2" color="textSecondary">
				<Skeleton variant="text" width={"20%"} height={35} />
				</Typography>
				</Box>
				
			
			
			</Box>
			
			<Box display="block" justifyContent="center">
			
				<Typography variant="subtitle2" color="textSecondary">
				<Skeleton variant="text" width={"100%"} height={100} />
				</Typography>
			
			
				<Box  marginTop={2}   marginBottom={1} >
				<Typography color="textPrimary" variant="subtitle1">
				<Skeleton variant="text" width={"80%"} height={100} />
				</Typography>
				</Box >
			
			
			
			</Box>
			

			<Box >
			<Grid>				
				<Grid container spacing={2}>
				{ 
					arr.map((e, index) => (
					
					
					
					<Grid item xs={12} sm={6}>

					<List >
					  <ListItem alignItems="flex-start" style={{padding:'0px'}}>
						<ListItemAvatar>
						<Skeleton variant="rect"   height={64} width={64} />
						</ListItemAvatar>
						
						
					<ListItemText  
					primary={<Typography component="div" variant="subtitle1">
					<Skeleton variant="text"  height={35} width={70} />
					</Typography>} 
					
					secondary={<Typography variant="subtitle2" color="textSecondary" >
					<Skeleton variant="rect"  height={30} width={"90%"} />
					</Typography>} 
					 />
						
					  </ListItem>
					  </List>
					  </Grid>
				))}
				</Grid>
				
				</Grid>
			</Box>
			</CardContent>
			</Card>
			)
			}

	