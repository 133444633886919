import { makeStyles } from '@material-ui/core/styles';
import { barColor } from "../../functions/Helper";
export const participntStyles  = makeStyles(theme => ({
	 small: {
    width: theme.spacing(5),
    height: theme.spacing(5),
	border:'3px solid #ebebeb'
      }, 
      card:{    padding: '16px 0px 16px 16px ',
      backgroundColor:barColor ,borderRadius:'0px',
          'border-bottom-left-radius': '12px',
        'border-bottom-right-radius': '12px'
    },
    avatar: {
	  marginTop:"0px",
    'min-width':'48px !important',
  }, 
 
  root:{ 'height':'calc(100vh - 227px)','overflow-y':'auto',
  padding: '0px 16px 0px 0px'},
  title:{fontWeight:'bold'},

  }));
export const chatStyle = makeStyles(theme => ({
 
    green_col:
	{color:"rgb(0, 171, 85) !important", margin:'4px'},
	
    green_bg:
	{backgroundColor:"rgb(40 124 37 / 8%) !important",	  margin:'4px',color:"rgb(0, 171, 85) !important"},
	
	yellow_menu:
	{backgroundColor:"rgb(255 198 30 / 10%) !important",color:"#aebcb8 !important",border:'transparent',margin:'4px'},
	
	blue_menu:
	{backgroundColor:"rgba(37, 56, 124, 0.04) !important",color:"rgba(37, 56, 124, 1) !important",margin:'4px'},
 
  icon:{
	  color:'#rgba(0, 0, 0, 0.54)',
	  backgroundColor:'transparent',
  },
 webTitle:{color:'#5d676b',fontSize:'16px',fontFamily: ['Public Sans','sans-serif',].join(',')},
 
 avatarTab: {
    height: '18px !important',
    width: '18px !important',
    fontSize: '12px !important',
    backgroundColor: '#dc3545',
  },
  

      title:{padding:'8px 16px 8px 16px'},
      iconstext:{color:'#5d676b'},


 avatar: {
	  marginTop:"0px",
    minWidth:'48px',
  },
    
  card:{     padding: '0px 16px 0px 16px',	backgroundColor:barColor ,borderRadius:'0px',
      'border-bottom-left-radius': '12px',
    'border-bottom-right-radius': '12px'
	},
	question:{
		'border-radius': '12px',
		'padding': '4px !important',
		'width':'95%'
	},
    read:{'background-color': '#FAFAFA'},
    unread:{'background-color': '#3bff4329'},
  root:{ 'height':'54vh','overflow-y':'auto',padding: '8px'},
  msg:{'word-break': 'break-all'},
 icons:{
	 // backgroundColor:'#CDD4D9',
	  backgroundColor:'transparent',
	  margin:'4px'
  }
  ,
   iconsactive:{
	  backgroundColor:'#0000000a',
	  margin:'4px'
  },
  details: { 
  overflow: "hidden",display: "-webkit-box",marginLeft:'10px',
	WebkitLineClamp: 1,WebkitBoxOrient: "vertical" },
  
  bg:{backgroundColor:'transparent',borderRadius:'0px',
            marginTop:'10px'},
    
 
  aboveTab:{
    backgroundColor: '#cdd4d9',
    padding: '2px',
    width:'fit-content',
    'border-bottom-right-radius': '50px',
    'border-top-right-radius': '50px',
  },
      title:{padding:'8px 16px 8px 16px'},
      iconstext:{color:'#5d676b',fontSize:'1.12rem'},
      
      	question:{
		'border-radius': '12px',
		'width':'95%'
	},
  read:{'background-color': '#FAFAFA'},
  unread:{'background-color': '#f2fff338'},
  root:{ 'height':'calc(100vh - 281px)','overflow-y':'auto',padding: '8px 16px'},
  msg:{'word-break': 'break-all'},
  avatar: {marginTop:"8px",minWidth:'48px'},
      
}));
