
import {
	Box, Button, ButtonBase,
	Card, CardContent, CardHeader, CircularProgress,
	Grid,
	Typography
} from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import React, { Component } from 'react';
import InfiniteScroll from "react-infinite-scroll-component";
import { getCacheDetails,getKeys } from "../caching";
import {  translateText, university_path } from "../Helper";
import { useHistory } from 'react-router-dom';


class ChapterList extends Component {

  constructor(props) {
    super(props);
	this.getCacheDetails = getCacheDetails.bind(this);
	this.getKeys = getKeys.bind(this);
	this.translateText = translateText.bind(this);
	//this.findEditUniversity = findEditUniversity.bind(this);
	this.state={university_show:[],start:0,end:8,hasMore: true,len:0,loading:false}
    }
		
 	findEditUniversity = (university_id,university_de,university_name,university_logo) => {
		
			let keys=this.getKeys("university")
			var university =(university_name.trim()).replaceAll(" ", "-");
			this.props.history.push({
		 	pathname: '/university/update/'+university.toLowerCase(), 
			state: {
				university_id:university_id,
				university_de:university_de,
				university_name:university_name,
				university_logo:university_logo,
				keys:keys
				} });
				

	}

 	getCache = (start,end,fetch) => {
		
		this.setState({ loading: true })
		//alert(start)
		//alert(end)
		//alert(fetch)
		this.getCacheDetails("all_university_"+start+"_to_"+end,
		"getUniversity",[start,end])
		.then(data => {	
		
				if(data.length<end ){this.setState({hasMore:false})}
				if(fetch==false)
				{
					this.setState({ university_show: data })
				}	
				else
				{
					this.setState({
						 university_show: [...this.state.university_show,...data]
					})
				}	
		});
		setTimeout(() => {this.setState({ loading: false })}, 1000);

	}
	
	fetchMoreData = () => {
    if(this.state.hasMore!=false)
	{
		setTimeout(() => {
		this.state.start=this.state.start+this.state.end
		this.getCache(this.state.start,this.state.end,true)	
		}, 1000);
	}}
	componentDidMount()
	{
		this.getCache(this.state.start,this.state.end,false)	
	}


  	render()
  	{
	
		const styles={
			  image: {
				width: 62,
				height: 62,
				position:'relative',
				'border-radius': '12px',
				 backgroundColor:'rgb(0 0 0 / 75%)',
			  },
			  img: {
				margin: 'auto',
				display: 'block',
				maxWidth: '100%',
				maxHeight: '100%',
				width: 'inherit',opacity:'0.5',
				height: 'inherit',borderRadius:'12px', 
			  },
			  details: {   overflow: "hidden",display: "-webkit-box",
				WebkitLineClamp: 1,WebkitBoxOrient: "vertical" },
			}
		let img='not-available.png'
		const Cards = () => {
		return (
		<>
		{	
			this.state.university_show.map((e) => ( 
		<Grid container  spacing={4}>
				 
				<Grid item style={{paddingRight:'0px'}}>
					{ this.state.loading==true
					? <Skeleton variant="rect"   height={64} width={64} style={{borderRadius:'12px'}} />
					:<ButtonBase style={styles.image}>
					<img style={styles.img} alt="complex" src={e['university_logo'] !== null && e['university_logo'] !== ''?university_path+e['university_logo']:university_path+img} />
					</ButtonBase>}
				</Grid>
				
				<Grid item xs={12}  xl={12} lg container >
				<Grid item xs container direction="column" >
				<Grid item xs>
					
					<Typography component="div" variant="subtitle1">
					{ this.state.loading==true
					? <Skeleton variant="text"  height={35} width={70} />
					:<Box display="inline" lineHeight={1.5}> {e['university_name']}</Box>}
					</Typography>
						
					<Typography variant="subtitle2" color="textSecondary" style={styles.details}>
					{ this.state.loading==true
					? <Skeleton variant="rect"  height={30} width={"90%"} />
					: e['university_de']}
					</Typography>
					
				</Grid>
				</Grid>
				</Grid>
			
				
				<Grid item >
				<Box display="flex" justifyContent="center" alignItems="center" minHeight="100%">
				{ this.state.loading==true
				? <Skeleton variant="rect"  height={30} width={80} />
				:<Button color="primary" size="large"
				onClick={()=>this.findEditUniversity(e['university_id'],e['university_de'],e['university_name'],e['university_logo'])}>
				Edit University
				</Button>}
				</Box>
				</Grid>
			
				</Grid>
			))
		}
		</>
		)
		}
		
	
		return ( 
		
		<Card>
		
		<CardHeader title={"Universities"}/>
	  
		<CardContent>
		
		    <InfiniteScroll
				style={{overflow:'hidden'}}
				  dataLength={this.state.university_show.length}
				  hasMore={this.state.hasMore}
				  loader={
					  
					   this.state.hasMore==true?
							<Box marginTop={2} marginBottom={2} alignItems='center'
							display='flex'
							flexDirection='column'
							>
							{
								this.state.loading
								  ? <CircularProgress disableShrink />
								  :<Button 
									variant="outlined" 
									size="medium"
									color={"primary"}
									disable={this.state.loading}
									onClick={this.fetchMoreData}>
									Load More
								</Button>
							}
							</Box>
					:
						<CircularProgress disableShrink />  
					  
					  
					  }
				 

				 endMessage={
					<Box sx={{ alignItems: 'center', display: 'flex', flexDirection: 'column'}} marginTop={2} marginBottom={2} >
					 <Typography variant="subtitle2" color="textPrimary" >You have seen it all</Typography>
					 </Box> 
				   
				  }>

				  <Cards /> 
			

				</InfiniteScroll>
				 
				
				
				
			
		</CardContent>
        </Card>
		  );
  	}
}
export default () => {
	const history = useHistory();
    return (
        <ChapterList  history={history} />
    )
}
  
  