// src/components/BusinessIndividualSection.jsx

import React from 'react';
import { Box, Typography, Button, Card, Grid, useMediaQuery } from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import BusinessIcon from '@material-ui/icons/Business';
import PersonIcon from '@material-ui/icons/Person';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import useGlobalStyles from '../styles/CourseStyles';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
  sectionContainer: {
    padding: theme.spacing(4),
    backgroundColor: '#fff',
    borderRadius: 12,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    boxShadow: '0 4px 10px rgba(0,0,0,0.1)',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(2),
  },
  },
  title: {
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    textAlign: 'center',
    
  },
  subtitle: {
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      width: '100%'
  },
  },
  cardContainer: {
    paddingLeft:  theme.spacing(6),
    paddingRight:  theme.spacing(6),
    marginTop:  theme.spacing(2),
    marginBottom:  theme.spacing(2),
    width: 'calc(100% + 0px)',
    margin: '0px',
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(0),
    },
  },
  grid: {
    [theme.breakpoints.down('sm')]: {
      padding: '0px 0px 0px 0px !important',
    },
  },
  card: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start', // Align content to the left
    padding: theme.spacing(3),
    borderRadius: 12,
    backgroundColor: '#f5f5f5',
    boxShadow: '0 2px 8px rgba(0,0,0,0.2)',
    border: 'none',
    transition: 'transform 0.3s, box-shadow 0.3s',
    height: '100%', // Ensure the card takes full height
    [theme.breakpoints.down('sm')]: {
      padding: theme.spacing(1),
      backgroundColor: '#fff',
    boxShadow: 'none',
    },
    '&:hover': {
      transform: 'translateY(-5px)',
      boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
    },
  },
  icon: {
    fontSize: 50,
    color: theme.palette.primary.main,
    marginBottom: theme.spacing(2),
  },
  cardTitle: {
    fontWeight: 'bold',
    marginTop: theme.spacing(1),
    marginLeft: theme.spacing(1.5),
    backgroundColor: '#f5f5f5',
    color: '#25387c',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      backgroundColor: '#fff',

    },
  },
  cardDescription: {
    textAlign: 'left',
    marginBottom: theme.spacing(2),
    lineHeight: 1.5,
  },
  actionButton: {
    borderRadius: '50px',
    padding: '8px 24px',
    fontWeight: '600',
    textTransform: 'none',
    marginTop: 'auto', // Push the button to the bottom
    backgroundColor: '#f9bb02',
    color: '#000',
    width: 'fit-content',
    fontSize: '1.2rem',
    fontFamily: 'MyCustomFont, sans-serif',
    '&:hover': {
      backgroundColor: "#f9bb02",
    },
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem'
  },
  },
  promotionalButtonIcon: {
    marginRight: '8px',
    borderRadius: '50%',
    padding: '2px',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    '& svg': {
      fontSize: '1.2em',
    },
  },
}));

const BusinessIndividualSection = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));

  return (
    <Box className={classes.sectionContainer}>
      {/* Title and Subtitle */}
      <Typography variant="h4" className={`${classes.title} ${styleClasses.h4font}`}>
        What’s in it for you?
      </Typography>
      <Typography variant="h6" className={`${classes.subtitle} ${styleClasses.description}`}>
        Our courses provide tangible results for both businesses and individuals. Whether you're looking to boost organizational performance or advance your personal career, the benefits of completing these courses are clear and immediate.
      </Typography>

      {/* Business and Individual Cards */}
      <Grid container spacing={10} className={classes.cardContainer}>
        {/* Business Card */}
        <Grid item xs={12} md={6} className={classes.grid}>
          <Card className={classes.card}>
            <Box display={'flex'}>
            <BusinessIcon className={classes.icon} />
            <Typography variant="h5" className={`${classes.cardTitle} ${styleClasses.h5font}`}>
              For Business
            </Typography>
            </Box>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Transform your team with the help of experts steeped in real-world experience, offering practical insights.
            </Typography>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Upskill your team to improve overall performance, critical thinking, problem-solving, or concepts.
            </Typography>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Learners and team members will enjoy a social and collaborative learning experience with hands-on activities.
            </Typography>
            <Button variant="contained" className={classes.actionButton} component={Link} // Use Link component from react-router-dom
              to="/businesscourse">
              Learn More
              <span className={classes.promotionalButtonIcon}>
            <ArrowForwardIcon />
          </span>
            </Button>
          </Card>
        </Grid>

        {/* Individual Card */}
        <Grid item xs={12} md={6} className={classes.grid}>
          <Card className={classes.card}>
          <Box display={'flex'}>
            <PersonIcon className={classes.icon} />
            <Typography variant="h5" className={`${classes.cardTitle} ${styleClasses.h5font}`}>
              For Individuals
            </Typography>
            </Box>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Upskill yourself to advance your career, improve your skills, or reinvent yourself completely.
            </Typography>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Sharpen your entrepreneurial skills to lead your team from the front.
            </Typography>
            <Typography variant="h6" className={`${styleClasses.subtitlefont} ${classes.cardDescription}`}>
              Recommend members in your team who need to brush up their skills.
            </Typography>
            <Button variant="contained" className={classes.actionButton}
            component={Link} // Use Link component from react-router-dom
            to="/individualcourse">
              
              Learn More
              <span className={classes.promotionalButtonIcon}>
            <ArrowForwardIcon />
          </span>
            </Button>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default BusinessIndividualSection;
