import {
	Box, Button, Card, CardContent, CircularProgress,
	FormControl,
	Grid, TextField,
	Typography, makeStyles
} from '@material-ui/core';
import axios from 'axios';
import React, { Component } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useHistory, useLocation } from 'react-router-dom';
import AlertBox from "../../AlertBox";
import { deleteLocal, getCacheDetails } from "../../caching";
import { api, successColor } from '../../Helper';
import { SlotSkeleton } from '../../Skeleton';
import { useStyles } from "../../useStyles";
import PayButton from './PayButton'


let email=null;
let student_id=null;
let mentee_name=null;
let status=null;
if(["webinar",null,undefined].includes(localStorage.getItem('login_token'))==false)
{
	email=JSON.parse(localStorage.getItem('ac_login_user'))['email']
	status=JSON.parse(localStorage.getItem('ac_login_user'))['status']
	student_id=JSON.parse(localStorage.getItem('ac_login_user'))['id']
	mentee_name=JSON.parse(localStorage.getItem('ac_login_user'))['name']
}		



const customUseStyles = makeStyles((theme) => ({
	border: {
		border: '1px solid black',
		width: '100%',
	},
	disableborder: {
		border: '1px solid #ebebeb',
		width: '100%',
	},
	cardcontent: {
		padding: '8px 16px !important',
		cursor: 'pointer',
	},
	cardborder: {
		width: '100%',
	},
	button:{ textTransform: 'revert' },
	maxh:{height:"max-content"}
}));


let slot_limit=5;
class Scroll extends Component {

    constructor(props) {
    super(props);
	
	this.deleteLocal = deleteLocal.bind(this);
	this.getCacheDetails = getCacheDetails.bind(this);

	this.state={ 
	date_slot:[],
	time_slot:[],
	from_date:null,
	time_slot_data:null,
	loading:false,msg:false,msg_type:"success",
	mentorship_on:new Date(),
	idea:null,quest:null,
	duration:null,
	time_slot_id:null,
	day:null,
	profile_id:this.getData("profile_id"),
	mentor_id:null,
	mentor_name:null,
	isExpert:false,
	ifPendingPayemnt:false,
	errors: {idea:"Initalize",quest:"Initalize"}
	}
	}
	
	getUserData(email)
	{
			  
			axios.post(api+'/get_user', {
			'email':email,
			})
			.then((response) => {
			
				this.setCustomState("profile_id",response.data['id'])
			});
		
	};
	componentDidMount()
	{
		
		/*if (this.props?.mentor_data) {
		  if ('industry' in this.props.mentor_data) {
			if (this.props.mentor_data['industry'].includes('Expert')) {
			  this.setCustomState("isExpert", true);
			  this.get_paid_req_count();
			}
		  }
		}
		*/
		
		this.setCustomState("loading",true)
		if((
		(window.location.pathname).startsWith('/profile')==true ||
		(window.location.pathname).startsWith('/my-profile')==true  ) &&
		localStorage.getItem('login_token')== null)
		{
			let loc=window.location.pathname
			loc=loc.substring(loc.lastIndexOf("/") + 1,loc.length)
//alert(loc)
			this.getCacheDetails(loc,"getProfessorPath",loc)
			.then((data) => { 
			this.getUserData(data['mail'])
			console.log(data)
			})
		}
			setTimeout(() => {
					//alert(1)		
							this.get_date_slots();
							
			}, 70);
		//this.get_time_slots();
		//alert(this.state.profile_id)
		//alert(1)

	}
	getData=(d)=>{
		if((window.location.pathname).startsWith('/profile')==true 
		&& this.props?.location?.state!=null
		&& status!=null
		)
		{
			console.log(this.props.location.state)
			//alert('PROFILSOTES')
			return this.props.location.state[d]
		}
		else 
	    {
			return null;
		}
	}
   
	deleteKeys=()=>{
		
		    let keys_1=this.deleteLocal(student_id+"_all_mentors_")
		    let keys_2=this.deleteLocal(student_id+"_mentors_")
		    let keys_4=this.deleteLocal(student_id+"_my_mentors_")
			let keys_3=this.deleteLocal(student_id+"_upcoming_mentorship_")		
			let keys=[keys_1,keys_2,keys_3,keys_4]
			return keys
	}
	setCustomState=(state,val)=>{
		this.setState({[state]:val})
	}
	
	get_paid_req_count=()=>{
		let payload={
			'mentor_id':this.props.active_mentor_id==undefined?null:this.props.active_mentor_id,
			'user_uid':this.state.profile_id
			}
		
		axios.post(api+'/if_mentee_paid_monthly',
		payload)
		.then((response) => {
				//console.clear();
				console.log(payload);
				let t=response.data
				console.log(response.data);
				//alert(1)
				if(t['paid']==0)
				{
					this.setCustomState("ifPendingPayemnt",true);
					this.setCustomState("payMsg",t['msg']);
				}
				
				this.forceUpdate(n=>!n);
		})
	}
	get_date_slots=()=>{
		this.setCustomState("loading",true)
		let payload={
			'mentor_id':this.props.active_mentor_id==undefined?null:this.props.active_mentor_id,
			'user_uid':this.state.profile_id
			}
		
		axios.post(api+'/get_mentors_date_slots',
		payload)
		.then((response) => {
				//console.clear();
				console.log(payload);
				console.log(response.data);
				//alert(1)
				this.setCustomState("date_slot",response.data);
				if((response.data).length>0)
				{
					let data=response.data[0]
					console.log(data)
					this.setCustomState("mentor_id",data['mentor_id']);
					this.setCustomState("mentor_name",data['mentor_name']);
					// alert(4)
				}
				
				this.setCustomState("loading",false)
				this.forceUpdate(n=>!n);
		})
	}
	
	get_time_slots=(e)=>{
		this.setCustomState("day",e['day'])
		this.setCustomState("duration",e['duration'])
		this.setCustomState("from_date",e['from_date'])
		this.setCustomState("time_slot_data",null)
		let payload={
			'mentor_id':this.props.active_mentor_id==undefined?null:this.props.active_mentor_id,
			'user_uid':this.state.profile_id,
			'from_date':e['from_date']
		}
		//console.log(payload)
		//alert(1)
		axios.post(api+'/get_time_slots',payload)
		.then((response) => {
				//console.log(response.data);
				this.setCustomState("time_slot",response.data);
					
		})
		
	}
	
	share_data=(e,request_count)=>{
		if(request_count>slot_limit)
		{
			this.setCustomState("msg","Open request count for this slot has already reached.")
			setTimeout(() => {this.setCustomState("msg",false)}, 1500);
		}
		else
		{
			this.setCustomState("time_slot_data",e['time_slot_data'])
			this.setCustomState("time_slot_id",e['id'])
			this.setCustomState("duration",e['duration'])

		}
	}
		
			
	
	handleChange = event => {event.preventDefault();
	let errors = this.state.errors;
	const { name, value } = event.target;
	errors[name]=value==null|| value==''?"Please fill out this field.":"Initalize";
	this.state[name]=value;
		let min=2
		let max=100	
		let min_word=1
		if(name=="quest")
		{
				min=2
				max=200
				min_word=1
		}
		errors[name]= value.length<min || value.length>max 
		?
			" Character count : "+value.length+" | Length must be between "+min+" to "+max+" characters."
		:	
			value.split(' ').length<min_word 
			?
				" Word count : "+value.split(' ').length+" | Specify minimum in "+min_word+" words."
				:"Initalize"
		this.state[name]=value;
		this.setState({ errors, [name]: value });
	}
	checkError=(name,value)=>
    {
		if(this.state.errors[name]=="Initalize")
		{
			this.state.errors[name]=value==null||value==''?"Please fill out this field.":"Initalize";
		}
	 	if(this.state.errors[name]!="Initalize"){return false;}
	 	else{this.state.errors[name]="Initalize";return true;}	
		
	}
	validForm=(list)=>
	{
		let allFilled=false;
		for (let i = 0; i < list.length; i++)
		{
			let value=list[i];
			allFilled=this.checkError(value,this.state[value]);
			if (allFilled===false)
			{
				break;
			}
		}
		return allFilled
	}
	
	manage_mentorship = () => {
	
		if ([null, undefined, ''].includes(this.state.idea) || [null, undefined, ''].includes(this.state.quest)) {
		  const { idea, quest } = this.state;
		  const errors = {};

		  if (idea == null || idea === '') {
			errors.idea = "Please fill out this field.";
		  }

		  if (quest == null || quest === '') {
			errors.quest = "Please fill out this field.";
		  }

		  this.setCustomState("msg", "Please fill all the fields");
		  this.setCustomState("loading", false);

		  setTimeout(() => {
			this.setCustomState("msg", false);
		  }, 2500);

		  this.setState({ errors });
		  //return;
		}

		else 
		{
			let isValidate= this.validForm(["idea","quest"]) 
			if(isValidate==true)
			{		
		
			
				this.setCustomState("loading",true)

				let keys=(this.deleteKeys()).toString()
				//alert(keys)
				let payload={
					'mentor':this.props.mentor_email,
					'mentee':email,
					'request':this.props.mentor_req=='1'?0:1,
					'keys':keys
				}
			//	alert(typeof(keys))
				let pay={
					'workspace':'academy',
					'idea':this.state.idea,
					'question':this.state.quest,
					'day':this.state.day,
					//'slots':this.state.slots,
					'time_slot_id':this.state.time_slot_id,
					'date_on':this.state.from_date,
					'start_time':this.state.time_slot_data,
					'duration':this.state.duration,
					'id':'',
					'mentor_name':this.props.active_mentor_name,
					'mentee_name':mentee_name,
					'mentor_email':this.props.active_mentor_email,
					'mentor_id':this.props.active_mentor_id,
					'user_uid':student_id,
					'request':this.props.mentor_req=='1'?0:1,
					'keys':keys,
					
				}
				//alert(mentor_name)
				
				//console.log(pay)
				axios.post(api+'/og_manage_mentor_request',
				pay)
				.then((response) => {
					
						this.state.loading=false;
						if(response.data[0]['msg']=="success")
						{
							this.setCustomState("msg","Mentorship upated")
							this.setCustomState("msg_type","success")
						}
						else
						{
							if(response.data[0]['message']!='')
							{
								this.setCustomState("msg",response.data[0]['message'])
							}
							this.setCustomState("msg","Please try again later.")
							this.setCustomState("msg_type","warning")
						}
						
						this.setCustomState("loading",false)

						//alert(this.state.msg)
						setTimeout(() => {
							this.setCustomState("msg",false)
							if((window.location.pathname).startsWith("/profile"))
							{
									this.props.history.push("/dashboard")
							}
							else
							{
								this.props.parentCallback();
							}
						}, 2500);
						
				});
			}
		}
			
	};
	
	info_user=()=>{
		this.setCustomState("msg","User must login as user to avail mentorship services.")
		setTimeout(() => {
					this.setCustomState("msg",false)
		}, 5000);
	}
	redirect_mentor=()=>{
		if(status!=null)
		{
			this.props.history.push({
			pathname: "/mentors-and-experts",
			  state: {
					action:"slot",
					mentor_name:this.state.mentor_name,
					mentor_id:this.state.mentor_id,
					from_date:this.state.from_date,
					time_slot_data:this.state.time_slot_data
			  },
			})
		}			
	}
    render()
	{
		
				const { errors } = this.state;
				const customclasses = this.props.customclasses;
				const classes = this.props.classes;

		let t= (window.location.pathname).startsWith("/profile") || (window.location.pathname).startsWith("/my-profile") 
		const responsivedate = {
			superLargeDesktop: {
			  // the naming can be any, depends on you.
			  breakpoint: { max: 4000, min: 3000 },
			  items: t==true?2:3
		   //   items: window.location.pathname=="/add-slots"?3:2
			},
			desktop: {
			  breakpoint: { max: 3000, min: 1024 },
			  items: t==true?2:3
			//  items: window.location.pathname=="/add-slots"?3:2
			},
			tablet: {
			  breakpoint: { max: 1024, min: 464 },
			  items: t==true?2:3
		   //   items: window.location.pathname=="/add-slots"?3:2
			},
			mobile: {
			  breakpoint: { max: 464, min: 0 },
			  items: 1
			}
		  };

		const DateCarousel=()=>{
			let dt='';
			let year='';
			return (
			<Carousel 
						  swipeable={false}
						  draggable={false}
						  responsive={responsivedate}
						  ssr={true} // means to render carousel on server-side.
						  infinite={false}
						  autoPlaySpeed={1000}
						  customTransition="all .5"
						  transitionDuration={500}
						   centerMode={true}>
						{
					
							this.state.date_slot.map((e, index) => (
							
							<Box width="auto"
							margin={0.5}
							marginRight={2}
							
							onClick={()=>{this.get_time_slots(e)}}>
							<Card 
							 className={e['from_date']==this.state.from_date?customclasses.border:customclasses.cardborder}
							>
										<CardContent className={customclasses.cardcontent}>
										<Typography variant="caption"
										 color="textSecondary" align="center">
										 <Box fontWeight="100">{e['day'].slice(0,3)}</Box>
										 </Typography>
										
										<Box display="none">
										{
											dt=/[a-z]/i.exec(e['date']).index,
											year=((e['from_date']).toString()).slice(0,4)
										}
										</Box>
										
										<Typography variant="subtitle1" color="secondary"
										 align="center">
										<Box fontWeight="700" margin="8px 0px" lineHeight={1.25}>
										{e['date'].slice(0,7)}
										<Box color="#5a6978" fontSize="12px" fontWeight="100">{year}</Box>
										</Box>
										</Typography>
										
										<Typography variant="caption" align="center">
										<Box fontWeight="600" color="secondary">
										{e['slot_count']} Slot</Box>
										</Typography>
										
										</CardContent>
										</Card>
								</Box>
								
						))}

						
						</Carousel>
			)
		}
		const TimeCarousel=()=>{
			return (
			<Box marginTop={
				window.location.pathname!="/mentors-and-experts"
				?status==null?2:0
				:2}
				
				display={this.state.from_date==null?"none":"block"}
				>
								<Typography variant="subtitle1" align="left" color="textPrimary" >
								<Box fontWeight="600"
								marginTop={2}
								marginBottom={this.state.time_slot.length==0?0:1}>
								Available time slots
								</Box>
								{
									this.state.time_slot_id==null  
									?
									<Typography variant="subtitle2" 
									align="left"  >
									<Box marginBottom={1} marginTop={-0.5} color={successColor} >
									Choose available time slot from below to connect with mentor & expert.
									</Box>
									</Typography>
									:<Box marginTop={1} marginBottom={2}>{/*<Divider />*/}</Box>
								}
								</Typography>
								
								
								
				<Box marginTop={1} display={this.state.time_slot.length==0?'none':'block'}>
						<Grid container spacing={2} >
						{
					
							this.state.time_slot.map((e, index) => (
								
									<Grid item xs="auto">
									<Box  onClick={()=>{this.share_data(e,e['request_count'])}}>
										<Card 
										 className={e['request_count']>slot_limit?customclasses.disableborder:e['time_slot_data']==this.state.time_slot_data?customclasses.border:customclasses.cardborder}>
										<CardContent className={customclasses.cardcontent}>
								
										<Typography
										variant="subtitle2" 
										color={"textPrimary"}
										 align="center">
										<Box fontWeight="700"
										color={e['request_count']>slot_limit?"#dddddd":"textPrimary"}>
										{e['time_title']}</Box>
										</Typography>
									
									
										</CardContent>
										</Card>
									</Box>
										</Grid>
								
								
										
							))}
							</Grid>	
							</Box>	
				</Box>	
			
			)
		}
		return ( 
		<Box>
		<>
		{			this.state.msg!=false
					? <AlertBox show_status={true} 
					msg={this.state.msg}
					msg_type={this.state.msg_type} />
					:''
		}
		</>
				<Card elevation={0}>
				<CardContent  className={t==true?{}:{padding:'0px'}}>
				
				{
					
					/*this.state.isExpert && this.state.ifPendingPayemnt
					?
					
						<Box display="flex" alignItems="center" justifyContent="center" textAlign="center">
						<Box>
						  <Box marginBottom={2}>
							<Typography variant="h6"  color="textPrimary">
							  Premium Membership Unlocks Your Network
							</Typography>
						  </Box>
						  <Box marginBottom={2}>
							<Typography variant="subtitle1" color="textSecondary">
							{this.state.payMsg}
							</Typography>
						  </Box>
						  <PayButton />
						</Box>
						</Box>

						

					:
					*/
						this.state.date_slot.length==0 && this.state.loading==true
						?
						
							<SlotSkeleton />
						
						:
							this.state.date_slot.length==0
							?
							
								<Typography variant="subtitle1" align="left" color="textSecondary" >
									<Box >Available slots are not found.</Box>
								</Typography>
							
							:
							<Grid container spacing={2}>
								 
								 {t==true?"":<Grid item xs={12}>
									<Typography variant="h6" align="left" color="primary" >
									<Box fontWeight="600">Connect with {this.props.active_mentor_name}</Box>
									</Typography>
								 </Grid>}
									
							<Grid item xs={12} 
							sm={this.state.time_slot_id==null &&
							(window.location.pathname).startsWith("/profile")?12:6}
							container spacing={1}
							  className={customclasses.maxh}>
									<Grid item xs={12} >
									
									<Typography variant="subtitle1" align="left" color="textPrimary" >
											<Box fontWeight="600" marginBottom={1}>Available Slot</Box>
											{
												this.state.time_slot.length==0
												?
												<Typography variant="subtitle2" 
												align="left">
												<Box  color={successColor}  marginTop={-1.5}>
												Choose available date slot from below to connect with mentor & expert.
												</Box>
												</Typography>
												:""
											}
											</Typography>
											
											<Box marginTop={1}>
											<DateCarousel />
											</Box>		
											</Grid>		
									
									<Grid item xs={12} >
										
											<TimeCarousel />
											
										</Grid>
									</Grid>
									
									<Grid container item xs={12} sm={6} spacing={2}
									 className={
										this.state.time_slot_data==null
										?
											(window.location.pathname).startsWith("/profile")
											?{display:"none"}
											:{}
							
										:{}}
									>
									<Grid item xs={12}>
										
										<FormControl variant="outlined" fullWidth
										error={ errors.idea!="Initalize" ? errors.idea:''} 									>
										<TextField  label="Idea for discussion" 
										color="primary" variant="outlined"  
										name="idea" type="text" 
										onChange={this.handleChange} 
										defaultValue={this.state.idea}
										fullWidth 
										disabled={this.state.time_slot_data==null }
										error={ errors.idea!="Initalize" ? errors.idea: ''} />
										<Typography variant="subtitle2" align="left" color="error" >
										{ errors.idea!="Initalize" ? errors.idea: ''}
										</Typography>
										</FormControl>
									</Grid>
									
									<Grid item xs={12}>
										
										<FormControl variant="outlined" fullWidth 
										error={ errors.quest!="Initalize" ? errors.quest:''} >
										<TextField 
											fullWidth
											name="quest"
											type="text"  	
											label="Any specific query"
											multiline  rows={7}
											variant="outlined"  
											onChange={this.handleChange} 
											defaultValue={this.state.quest}
											disabled={this.state.time_slot_data==null }
											error={ errors.quest!="Initalize" ? errors.quest: ''} />
											<Typography variant="subtitle2" align="left" color="error" >
											{ errors.quest!="Initalize" ? errors.quest: ''}
											</Typography>
											
										</FormControl>
										
									</Grid>
							
									
										
									<Grid item xs={12}>
									<Box display={t==true?"block":"none"}>
									<Typography variant="subtitle2" color="error">
									{this.state.msg}
									</Typography>
									</Box>
									</Grid>
									
									<Grid item xs={12}>
									<Box display="flex" flexDirection="row-reverse"  marginBottom={2}>					
									<Box>
									<Button 
									variant="contained" 
									size="large"  
									color="primary"  
									onClick={
										status=='student'
										?this.manage_mentorship
										:this.info_user}
									className={customclasses.button}	
									fullWidth
									disabled={this.state.loading || this.state.time_slot_data==null }>
									  {this.state.loading && <CircularProgress size={24} />}
									  {!this.state.loading && <span> Schedule a meeting </span> }
									</Button>
									</Box>
									</Box>
									</Grid>
									</Grid>
								  
								  </Grid>	
							  
						}
						  </CardContent>	
						  </Card>	
					  
				
			</Box>

		);
	
   }
}


export default (props) => {
	const location = useLocation();
	const history = useHistory();
	const classes = useStyles();
	const customclasses = customUseStyles();
	console.log(props)
	return (

	<Scroll 
		customclasses={customclasses}
		mentor_data={props.mentor_data || props.profile_parent}
		mentor_email={props.mentor_email || (props.profile_parent?.profile_mail || null)}
		mentor_req={props.mentor_req}
		active_mentor_name={props.active_mentor_name}
		active_mentor_email={props.active_mentor_email || props.profile_parent?.profile_mail || null}
		active_mentor_id={props.active_mentor_id || props.profile_parent?.profile_id || null}
		location={location}
		history={history}
		parentCallback={props.parentCallback}
	/>

    )
}
