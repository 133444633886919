import { ThemeProvider } from "@material-ui/core";
import {Box , Grid} from '@material-ui/core';
import React, { Component, Suspense } from 'react';
import { Academytheme} from "../../functions/useStyles";




const LoginCard = React.lazy(() => import('./LoginCard'));
const CarouselCard = React.lazy(() => import('./CarouselCard'));


class Login extends Component {

    constructor(props) {
    super(props);
    this.state = {

    showPassword: false,
 
    };

  }

 
  

    

  //componentClicked = () => console.log("clicked");
  
 
    render() {

	return(
    <ThemeProvider theme={Academytheme}>
	<Box padding={3}>

	<Grid container 
			  direction="row"
			  alignItems="center"
			  justify="center"
			  spacing={0}>
  
        <Grid item xs={12} sm={8}>
            <Suspense fallback={<div> </div>}>
						<CarouselCard /> 
			</Suspense>   
        </Grid>
		
		
        <Grid item xs={12} sm={4}>
            <Suspense fallback={<div> </div>}>
						<LoginCard /> 
			</Suspense>
        </Grid>
        
        
     </Grid>
     </Box>
    
	</ThemeProvider>  
	)
  
    }
}
export default Login;



