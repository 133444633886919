// src/components/AllCourses.jsx

import React, { useState, useRef } from 'react';
import { useLocation, Link } from "react-router-dom";
import {
    Box,
    Typography,
    TextField,
    Button,
    Chip,
    Grid,
    Card,
    CardContent,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import coursebusiness from '../assets/coursemain.jpg';
import coursefeature from '../assets/coursefeature.jpg';
import PromotionalCard from './CourseFeature';
import BusinessIndividualSection from './BusinessIndividualSection';
import FAQSection from '../Experts/FaqSection';
import Testimonials from '../Homepage/Testimonials';
import useGlobalStyles from '../styles/CourseStyles';
import BrowseAllCourses from './AllCourseCard';
import courses from './CourseData';

const useStyles = makeStyles((theme) => ({

    filterChipsContainer: {
        gap: 0,
    },
    bannerImageContainer: {
        top: '50px !important',
    },
    })
)

const Banner = () => {
    const theme = useTheme();
    const classes = useGlobalStyles();
    const styleClasses = useStyles();
    const isSmallScreen = useMediaQuery(theme.breakpoints.down('sm'));
    const isIpad = useMediaQuery('(min-width:768px) and (max-width:1024px)'); // Detect iPad screen sizes

    return (
        <Box className={classes.bannerContainer}>
            <Box className={classes.bannerTextContainer}>
            <Typography
                    variant="h1"
                    className={classes.h1banner}
                    gutterBottom
                >
                    OpenGrowth Startup Academy
                </Typography>
                <Typography
                    variant="h3"
                    className={classes.h3font}
                >
                    Access Expert-led <span className= {classes.span}>Online Courses</span> for Individuals and Businesses
                </Typography>
                <Typography variant="h6" className={`${classes.h6banner} ${classes.h6HeadingDesc}`} width={'65%'}>
                    Whether you're a business or an individual, our online courses are
                    built to equip you with the skills needed to overcome challenges
                    and succeed in any industry.
                </Typography>
                <Box className={`${classes.filterChipsContainer} ${styleClasses.filterChipsContainer}`}>
                    <Button
                        variant="outlined"
                        component={Link}
                        to="/businesscourse"
                        className={classes.businessCourseButton}
                    >
                        For Business
                    </Button>
                    <Button
                        variant="contained"
                        component={Link}
                        to="/individualcourse"
                        className={classes.individualCourseButton}
                    >
                        For Individuals
                    </Button>
                </Box>
            </Box>
            <Box className={`${classes.bannerImageContainer} ${styleClasses.bannerImageContainer}`}
            >
                <img
                    src={coursebusiness}
                    alt="Banner Image"
                    className={classes.bannerImage}
                />
            </Box>
        </Box>
    );
};

const CourseFeatures = () => {
    const classes = useGlobalStyles();
    const theme = useTheme();
    const isTabletOrMobile = useMediaQuery(theme.breakpoints.down('md'));
    const features = [
        {
            title: 'Social and Collaborative Learning',
            description:
                'Social and collaborative learning experiences crafted to ensure retention and absorption of concepts and the ability to apply them for life.',
        },
        {
            title: 'Immersive Live Activities',
            description:
                'Group and individual activities that involve you, ensuring a sound grasp of the subject matter being covered.',
        },
        {
            title: 'Instructional Benefits',
            description:
                'High standards of instructional design guaranteed to impart knowledge seamlessly and motivate you.',
        },
        {
            title: 'Blog-like Lecture Notes',
            description:
                'Churn through the course material with considerable ease as our lecture notes emulate blogs, with infographics and videos to enhance your learning experience.',
        },
    ];

    return (
        <Box
        className={classes.courseFeaturesContainer}
        >
            <Typography variant="h4" className={classes.h4font} align='left'>
                    Course Features
                </Typography>
            <Grid container spacing={4} alignItems="stretch" className={classes.mainGrid}>
                {/* Left Side: Heading and Image */}
                <Grid item xs={12} md={6} className={classes.grid}>
                    <Box style={{ marginBottom: 16 }}>
                        <Typography variant="h6" className={classes.description}>
                            Learn from industry experts, engage in hands-on projects, and enjoy a learning journey that's as flexible as it is impactful.
                        </Typography>
                    </Box>
                    <Box className={classes.courseFeatureImgBox}>
                        <img
                            src={coursefeature}
                            alt="Student learning"
                            className={classes.courseFeatureImg}
                        />
                    </Box>
                </Grid>

                {/* Right Side: Feature Cards */}
                <Grid item xs={12} md={6} style={{ display: 'flex', flexDirection: 'column' }}>
                    <Grid container spacing={2} style={{ flexGrow: 1 }}>
                        {features.map((feature, index) => (
                            <Grid item xs={12} key={index} style={{ flexGrow: 1 }}>
                                <Card className={classes.featureCard}>
                                    <CardContent style={{ padding: 0, flexGrow: 1 }}>
                                        <Box style={{ display: 'flex', alignItems: 'center', marginBottom: 8, width: 'fit-content', }}>
                                            {/* Custom Arrow */}
                                            <Box className={classes.customArrow}>
                                                &gt; {/* ">" symbol */}
                                            </Box>
                                            <Typography
                                                variant="h5"
                                                className={classes.h5font}
                                            >
                                                {feature.title}
                                            </Typography>
                                        </Box>
                                        <Typography variant="subtitle1" className={classes.subtitlefont}>
                                            {feature.description}
                                        </Typography>
                                    </CardContent>
                                </Card>
                            </Grid>
                        ))}
                    </Grid>
                </Grid>
            </Grid>
        </Box>
    );
};

// Main AllCourses Component
const AllCourses = () => {
    const classes = useGlobalStyles();
    const sliderRef = useRef(null);
    const [search, setSearch] = useState('');
    const location = useLocation();

    const theme = useTheme();
    const { course } = location.state || {};
    const isLarge = useMediaQuery(theme.breakpoints.up("lg"));
    const isMedium = useMediaQuery(theme.breakpoints.between("sm", "lg"));
    const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
    const getSlidesToShow = () => {
        if (isLarge) return 3;
        if (isMedium) return 2;
        if (isSmall) return 1;
        return 4;
    };

    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: getSlidesToShow(),
        slidesToScroll: 1,
        responsive: [
            {
                breakpoint: theme.breakpoints.values.lg,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                },
            },
            {
                breakpoint: theme.breakpoints.values.sm,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <Box style={{ padding: 8, paddingTop: 0 }}>
            {/* <Header /> */}
            <Banner />
            <CourseFeatures />

            {/* New Information Card with Bold Paragraph */}
            <Card className={classes.newInfoCard}>
                <Typography variant="h5" className={classes.h5fontPara} >
                    At OpenGrowth Startup Academy, we help businesses across industries, including AI startups, punch above
                    their weight with actionable, expert-led online courses. Gain deep, specialized skills while
                    expanding your knowledge across multiple domains with access to our diverse courses
                </Typography>
            </Card>

            {/* Browse All Courses Section */}
            <BrowseAllCourses courses={courses} />
            <PromotionalCard />
            <BusinessIndividualSection />
            <Testimonials context="allcourses" />
            <FAQSection />
            {/* <Footer /> */}
        </Box>
    );
};

export default AllCourses;
