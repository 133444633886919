import React from 'react';
import { useHistory } from 'react-router-dom';
import {Tooltip,Box,IconButton,Button, Avatar, Card,Chip ,CardContent, Typography, makeStyles } from '@material-ui/core';
import { AvatarGroup } from '@material-ui/lab';
import { FaChevronRight } from 'react-icons/fa';

import { getKeys } from '../caching';
import {  user_id, user_status_id,community_path,userProfile_path,getString } from '../Helper';
import { FaUsers } from 'react-icons/fa';
import EditIcon from '@material-ui/icons/Edit';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import ForumIcon from '@material-ui/icons/Forum';


const member_useStyles = makeStyles((theme) => ({
	round: {
	cursor: 'pointer',
	height: '36px',
	width: '36px',
	backgroundColor: '#25387c',
	},
	roundicon: {
	fontSize: '18px',
	},
	small: {
	cursor: 'pointer',
	width: '100%',
	height: window.location.pathname=='/dashboard'?'100px':'210px',
	borderRadius: '8px',
	'border-bottom-left-radius': '0px',
	'border-bottom-right-radius': '0px',
	},
	card: {
	height: 'fit-content',
	transition: 'transform 0.2s ease-in-out',
	'&:hover': {
	marginBottom: '8px',
	transform: 'translateY(2px)',
	//border: '1px solid #858181',
	'box-shadow': '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)'
	},
	},
	green_menu: {
	borderRadius: '18px',
	},
	color:{
		backgroundColor: "#e3420f",
		fontSize: "0.85rem",
	},
	title: {
    overflow: 'hidden',
   // height: '40px',
    display: '-webkit-box',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
	cursor:'pointer'
  },
	count: {
    overflow: 'hidden',
    display: '-webkit-box',
    WebkitLineClamp: 1,
    WebkitBoxOrient: 'vertical',
	cursor:'pointer'
  },
  font:{
	fontSize:'12px !important'
  },
}));

const CommunityVerticalRenderCard = (props) => {
	const { e } = props;
	const history = useHistory();
	const classes = member_useStyles();


  const findCommunity = (data, action) => {
    let path = '/manage-community/update/';
    if (action === 'view') {
      path = '/community/';
    }

    if (action === 'request') {
      path = '/community-join/';
    }

    let hosts_values = [];
    if (data['host_name'] !== null) {
      const inst_id = data['hosts'];
      const host_id = inst_id.split(',');

      const inst = data['host_name'];
      const host_val = inst.split(',');

      const ar = [];
      for (let i = 0; i < host_val.length; i++) {
        const t = { id: Number(host_id[i]), name: host_val[i] };
        ar.push(t);
      }
      //console.log(ar);
      //console.log(JSON.stringify(ar));
      hosts_values = JSON.stringify(ar);
    }

    const community_keys_1 = getKeys('_professor_community_');
    const community_keys_2 = getKeys('_professor_community_');
    const keys = community_keys_1.concat(community_keys_2);

    const community = {
      id: data['id'],
      title: data['title'],
      access: data['access'],
      creater_id: data['creater_id'],
      category: data['category'],
      description: data['description'],
      banner: data['banner'],
      slug: data['slug'],
      hosts: data['hosts'],
      host_email: data['host_email'],
      host_name: data['host_name'],
      host_user: data['host_user'],
      host_image: data['host_image'],
      hosts_values,
      keys,
    };

    history.push({
      pathname: path + data['slug'],
      state: community
		})
	}
	


  const getChatbox = (data) => {
    let path = '/discussion-forum/'+data['slug'];
   
    const community = {
      id: data['id'],
      slug: data['slug']
    };

    history.push({
      pathname: path,
      state: community
		})
	}
	



  const handleClick = (community, action) => {
    if (action === "view") {
      findCommunity(community, "view");
    } else if (action === "manage") {
      findCommunity(community, "manage");
    }
  };

	const Hosts = ({ community }) => {
		let host_image = community['host_image'];
		let host_name = community['host_name'];
		const nameIsList = host_name !== null ? getString(host_name).split(',') : [];
		const imgIsList = host_image !== null ? getString(host_image).split(',') : [];
		const avatarCards = imgIsList
		.map((d, index) => ({
		nameIs: nameIsList[index]?.replaceAll('"', ''),
		imgIs: imgIsList[index]?.replaceAll('"', ''),
		}))
		.filter(card => card.imgIs.length > 3)
		.map((card, index) => (
		<Avatar
		alt={card.nameIs}
		src={userProfile_path + card.imgIs}
		/>
		));
		return (
			<AvatarGroup max={1}>
			{avatarCards}
			</AvatarGroup>
		);
	};

	const MembersCard = ({ members, total_members }) => {
	  const avatarCards = members.length > 0 && members.map((member, index) => (
		member.img ? (
		  <Avatar
			key={member.id}
			alt={member.name}
			src={userProfile_path + member.img}
		  />
		) : null
	  ));

	  let remainingMembersCount = total_members - members.length;
	  remainingMembersCount = remainingMembersCount > 9 ? 9 : remainingMembersCount;

	  const leftCard = remainingMembersCount > 0 && (
		<Avatar className={classes.color}>
		  <Box fontSize="1rem">
			{`+${remainingMembersCount} `}
		  </Box>
		</Avatar>
	  );

	  return (
		<AvatarGroup max={4}>
		  {avatarCards}
		  {leftCard}
		</AvatarGroup>
	  );
	};

	
	



	const RenderCard = ({ e,onClick }) => {
		  const isProfessor = e.creater_id === user_id ;

		  const handleManageCommunity = () => {
			onClick(e, "manage");
		  };

		return (
			<Box marginLeft={1} marginRight={1} marginBottom={window.location.pathname=='/dashboard'?0:2} >
			<Card elevation={1} className={classes.card}>
			{/*<Box display="flex" justifyContent="space-between">
			<Box fontSize="14px" color="rgb(90, 105, 120)">
			Admin
			</Box>
			<Hosts community={e} />
			</Box>*/}
			<Avatar
			  src={community_path + e.banner}
			  alt={e.title}
			  className={classes.small}
			  onClick={() => { findCommunity(e, "view"); }}
			/>
			{isProfessor && (
			  <Box position="absolute" display="flex" top={0} right={0} zIndex={1} padding={2}>
				<Tooltip placement="right-start" title="Manage Community">
				  <IconButton color="primary" onClick={() => { findCommunity(e, "manage") }} size="small">
					<EditIcon fontSize="small" />
				  </IconButton>
				</Tooltip>
				<Box marginLeft={2}>
				<Tooltip placement="right-start" title="View Join Request">
				  <IconButton color="primary" onClick={() => { findCommunity(e, "request") }} size="small">
					<InfoOutlinedIcon fontSize="small" />
				  </IconButton>
				</Tooltip>
			  </Box>
			  </Box>
			)}

			<Box padding={2} height="130px">
			<Typography component="div" color="primary" variant="subtitle1">
			<Box
			lineHeight={1.25}
			fontWeight="600"
			marginBottom={2}
			className={classes.title}
			onClick={() => { findCommunity(e, "view"); }}
			>
			{e['title']}
			</Box>
			</Typography>
			{/*
			<Box display="flex" justifyContent="space-between">
			<Box>
			<Chip
			className={classes.green_menu}
			label={e['category']}
			size="medium"
			variant="outlined"
			color="secondary"
			/>
			</Box>
			</Box>
			
			<Box>
			<Typography component="div" color="textSecondary" variant="subtitle2">
			<Box display="flex" alignItems="center" paddingLeft={2}>
			<FaUsers fontSize="medium" style={{ marginRight: '8px' }} />
			<Box  className={classes.count}>{e.total_members} Members</Box>
			</Box>
			</Typography>
			</Box>
			
			
			*/}
			
			

			<Box display="flex"  >
			<Box marginTop={0}   marginLeft={"-4px"} flexGrow={1} >
				<MembersCard
				  members={e['members'] ? JSON.parse(e['members']) : []}
				  total_members={e.total_members}
				/>
				{/*<Button 
				onClick={() => { findCommunity(e, "view"); }}
				color="primary"
				variant="text"
				size="medium"
				endIcon={ <FaChevronRight className={classes.font} />}>
				Explore Now
				</Button>
				*/}
		    </Box>

		    <Box 
			display={window.location.pathname=="/joined-community"?"flex":"none"}
			>
			<Avatar 
			className={classes.round} 
			onClick={() => { getChatbox(e); }}
			>
			  <ForumIcon color="white" className={classes.roundicon}  />
			</Avatar>
		    </Box>
		    </Box>
			
			</Box>
			</Card>
			</Box>
		);
	};

	return <RenderCard e={e}  onClick={handleClick} />;
};

export default CommunityVerticalRenderCard;