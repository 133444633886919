// src/components/GrowthStudioHome.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid,
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import GrowthBenefitsCard from '../Experts/GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp';
import growthstudiovideo from '../assets/growthstudiovideo.mp4';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css'; // Ensure theme CSS is imported
import Slider from 'react-slick'; // Import Slider from react-slick
import LatestContentCard from './LatestGrowthCard';
import ExploreGrowthCard from './ExploreGrowthCard';
import { color } from 'highcharts';

const latestContents = [
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/1.jpg',
    category: 'Podcast',
    title: 'Valeria Vahorovska Founder and CEO - FONDY, A one-stop online payments platform',
    videoLink: 'https://www.blogs.opengrowth.com/valeria-vahorovska-founder-and-ceo-fondy', // Blog link
    type: 'blog',
  },
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/6.jpg',
    category: 'Blog',
    title: 'Entrepreneurial Education by Gunjan Sinha | Chairman Opengrowth',
    videoLink: 'https://www.youtube.com/watch?v=czxCvPJXI50', // Video link
    type: 'video',
  },
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/2.jpg',
    category: 'Podcast',
    title: 'Fireside Chat: Access to Global Markets and Globalisation',
    videoLink: 'https://www.youtube.com/watch?v=iTjDYFYbkPc', // Video link
    type: 'video',
  },
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/3.jpg',
    category: 'Blog',
    title: 'Navigating the challenges of the Indian Startup Ecosystem',
    videoLink: 'https://www.youtube.com/watch?v=ZzD0-WLt6VY', // Video link
    type: 'video',
  },
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/4.jpg',
    category: 'Blog',
    title: 'Women Entrepreneur - Boosting Startups & Challenging Gender Inequality | Usha Raghunath',
    videoLink: 'https://www.youtube.com/watch?v=o2JEQdAFt94', // Video link
    type: 'video',
  },
  {
    image: 'https://www.opengrowth.com/assets/public/opengrowth/images/expert-community/thumbnails/5.jpg',
    category: 'Blog',
    title: 'AI and Sales Coaching',
    videoLink: 'https://www.youtube.com/watch?v=vZ0Ezss0oiE', // Video link
    type: 'video',
  },
  {
    image: 'https://i.ytimg.com/vi/rv4KaYofdfo/hqdefault.jpg?sqp=-oaymwEcCNACELwBSFXyq4qpAw4IARUAAIhCGAFwAcABBg==&rs=AOn4CLD4wYyBiyDZX8TJHgBx2shevKlsWg',
    category: 'Blog',
    title: "Women's Day Special -Fireside Chat with Rachna Prasad",
    videoLink: 'https://www.youtube.com/watch?v=vZ0Ezss0oiE', // Video link
    type: 'video',
  },
];

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingLeft: theme.spacing(1),
    paddingRight: theme.spacing(1),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  bannerContainer: {
    position: 'relative',
    flexDirection: 'column', // Stack content and ribbon vertically
    border: 'none',
    paddingLeft: '0rem',
    borderRadius: '4px',
    overflow: 'hidden',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      height: 'auto',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'auto',
      flexDirection: 'column', // Ensure vertical stacking on small screens
      alignItems: 'center',
    },
  },

  videoBox: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    zIndex: 1, // Lower z-index to stay behind content
    '&::after': {
      content: '""',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      pointerEvents: 'none',
      zIndex: 2, // Overlay on video
      backgroundColor: 'rgba(0,0,0,0.3)', // Optional: dark overlay for better text visibility
    },
  },
  video: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    zIndex: 1,
  },
  contentBox: {
    position: 'relative',
    zIndex: 3, // Above videoBox and overlay
    width: '65%', // Adjust as needed
    color: 'white',
    textAlign: 'left',
    paddingTop: 56,
    paddingLeft: 56,
    paddingBottom: 56,
    [theme.breakpoints.down('sm')]: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
      maxWidth: '100%',
      width: '100%',
      paddingTop: theme.spacing(4),
      paddingBottom: theme.spacing(4),
      paddingLeft: theme.spacing(4),
      textAlign: 'left',
    },
  },
  h3font: {
    fontWeight: '500',
    color: '#f9bb02',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '45px',
      textAlign: 'left',
    },
  },
  h5font: {
    padding: '8px 12px',
    fontWeight: '600',
    fontSize: '50px',
    border: 'none',
    [theme.breakpoints.down('sm')]: {
      fontSize: '35px',
    },
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },

  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '400px',
    height: '100%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    padding: '8px 12px',
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  // Styles for the subscribe ribbon
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(5),
    position: 'relative',
    zIndex: 4, // Higher z-index to appear above video and content
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
    },
  },
  subscribeText: {
    fontSize: '1.2rem',
    flex: '1 1 60%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flex: '1 1 100%',
    },
  },
  subscribeButton: {
    backgroundColor: '#25387c',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '10px 24px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1e2a5a',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0',
    },
  },
  subscribeInput: {
    marginRight: theme.spacing(2),
    flex: '1 1 60%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
      flex: '1 1 100%',
    },
  },
  gridCard: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: '#25387c',
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
    [theme.breakpoints.down('sm')]: {
      border: 'none',
    },
  },
  gridCard2: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  // Styles for the slider
  sliderContainer: {
    width: '100%',
    paddingTop: theme.spacing(3),
    paddingLeft: theme.spacing(0.5),
    paddingRight: theme.spacing(1),
    marginBottom: theme.spacing(2),
    backgroundColor: '#25387c',
    borderRadius: '6px',
    '& .slick-slide > div': {
      padding: theme.spacing(1),
    },
    '& .slick-prev': {
      zIndex: 1, // Ensure arrows are above other elements
      left: -10,
    },
    '& .slick-next': {
      right: 18,
      zIndex: 1,
    },
    // Optional: Adjust arrow positioning and size
    '& .slick-prev:before, .slick-next:before': {
      color: '#e0e0e0', // Change arrow color if needed
      fontSize: '50px', // Change arrow size if needed
    },
  },
  sliderContainer2: {
    width: '100%',
    paddingTop: theme.spacing(3),
    marginBottom: theme.spacing(0),
    backgroundColor: '#fff',
    '& .slick-slide > div': {
      padding: theme.spacing(1),
    },
    '& .slick-prev': {
      zIndex: 1, // Ensure arrows are above other elements
      left: -10,
      color: '#e0e0e0'
    },
    '& .slick-next': {
      right: 18,
      zIndex: 1,
      color: '#e0e0e0'
    },
    // Optional: Adjust arrow positioning and size
    '& .slick-prev:before, .slick-next:before': {
      color: '#e0e0e0', // Change arrow color if needed
      fontSize: '50px', // Change arrow size if needed
    },
  },
  // Styles for the video modal
  videoModal: {
    '& iframe': {
      width: '100%',
      height: '400px',
      [theme.breakpoints.down('sm')]: {
        height: '250px',
      },
    },
  },
}));

// BannerSlide Component
const BannerSlide = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down('sm'));

  // State for subscribe modal
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    setSubscriptionStatus(null);
    setEmail('');
    setIsLoading(false);
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      // TODO: Replace with your actual subscription logic (e.g., API call)
      // Example using fetch:
      /*
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error('Subscription failed');
      }
      */

      // Simulating successful subscription
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate network delay
      setSubscriptionStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
        {/* Video Background */}
        <Box className={classes.videoBox}>
          <video
            className={classes.video}
            src={growthstudiovideo}
            autoPlay
            muted
            loop
            playsInline
            preload="auto"
          >
            Your browser does not support the video tag.
          </video>
        </Box>

        {/* Overlay Content */}
        <Box className={classes.contentBox}>
          <Typography
            variant={isSmall ? 'h4' : 'h1'}
            gutterBottom
            className={`${styleClasses.h3font} ${classes.h3font}`}
          >
            Growth Studio
          </Typography>
          <Typography
            variant={isSmall ? 'subtitle1' : 'h6'}
            paragraph
            className={`${styleClasses.h6banner} ${styleClasses.h6HeadingDesc}`}
          >
            Share Your Story, Showcase Your Expertise, and Empower Others
          </Typography>
          <Box className={`${classes.filterChipsContainer}`}>
            <Button variant="contained" className={styleClasses.individualButton}>
              Explore Now
            </Button>
          </Box>
        </Box>

        {/* Subscribe Ribbon */}
        <Box className={classes.subscribeRibbon}>
          <Typography variant="h6" className={styleClasses.h6font} style={{ color: '#f9bb02' }}>
            Don’t miss out on exclusive updates, free resources, and special offers—delivered right to you!
          </Typography>
          <Button
            variant="contained"
            className={styleClasses.individualButton}
            onClick={handleOpenSubscribe}
          >
            Subscribe Now
          </Button>
        </Box>

        {/* Subscribe Modal */}
        <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
          <DialogTitle>Subscribe to Our Newsletter</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Email Address"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
              />
            </FormControl>
            {isLoading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            )}
            {subscriptionStatus === 'success' && (
              <Typography color="primary" align="center" style={{ marginTop: theme.spacing(2) }}>
                Successfully subscribed!
              </Typography>
            )}
            {subscriptionStatus === 'error' && (
              <Typography color="error" align="center" style={{ marginTop: theme.spacing(2) }}>
                Subscription failed. Please try again.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscribe} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubscribe}
              color="primary"
              variant="contained"
              disabled={!email || isLoading}
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

// GrowthStudioHome Component
const GrowthStudioHome = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendluUrl] = useState(
    'https://calendly.com/vedika-5m0y/30min?month=2024-10'
  );

  // State for video modal
  const [openVideoModal, setOpenVideoModal] = useState(false);
  const [selectedVideoLink, setSelectedVideoLink] = useState('');

  const handleCardClick = (content) => {
    if (content.type === 'video') {
      setSelectedVideoLink(content.videoLink);
      setOpenVideoModal(true);
    } else {
      window.open(content.videoLink, '_blank', 'noopener,noreferrer');
    }
  };

  const handleCloseVideoModal = () => {
    setOpenVideoModal(false);
    setSelectedVideoLink('');
  };

  // Slider settings using default arrows
  const sliderSettings = {
    dots: false, // Disable dots if not needed
    infinite: true,
    speed: 500,
    slidesToShow: 4, // Number of slides to show at once
    slidesToScroll: 1,
    arrows: true, // Enable default arrows
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
          arrows: true,
        },
      },
    ],
  };

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <BannerSlide />

      <Card className={classes.mainCard}>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.h6font}`}>
          Narrate your story, share your experiences, pitfalls, and successes, and help others learn from the wealth
          of your experience.
        </Typography>
        <Typography variant="h4" className={styleClasses.h4font}>
          Latest from our{' '}
          <span variant="h5" className={`${styleClasses.span} ${classes.h5font}`}>
            Growth Studio
          </span>
        </Typography>

        {/* Slider Container */}
        <Box className={classes.sliderContainer}>
          <Slider {...sliderSettings}>
            {latestContents.map((content, index) => (
              <Box
                key={index}
                onClick={() => handleCardClick(content)}
                style={{ cursor: 'pointer' }}
              >
                <LatestContentCard
                  image={content.image}
                  category={content.category}
                  title={content.title}
                  description={content.description}
                />
              </Box>
            ))}
          </Slider>
        </Box>
      </Card>

      {/* Latest card content end */}

      <GrowthBenefitsCard context="studio" />

      <Card className={classes.mainCard}>
        <Typography variant="h4" className={styleClasses.h4font}>
          Explore Growth Studio
        </Typography>
        <Typography variant="h6" className={`${styleClasses.description} ${classes.h6font}`}>
          Insights and Stories from Industry Leaders Driving Growth
        </Typography>
        <Box className={classes.sliderContainer2}>
          <Slider {...sliderSettings}>
          {latestContents.map((content, index) => (
            <Box
            key={index}
            onClick={() => handleCardClick(content)}
            style={{ cursor: 'pointer' }}
          >
              <ExploreGrowthCard
                image={content.image}
                category={content.category}
                title={content.title}
                description={content.description}
              />
            </Box>
            ))}
          </Slider>
        </Box>
      </Card>
      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography variant="h4" className={`${styleClasses.h4font}`} style={{ color: 'white' }}>
              Looking for a Growth Associate?
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
              Let us help you drive user acquisition, boost engagement, and scale your business—all at a fraction of
              the cost of a full-time hire.
            </Typography>
            <PopupButton
              url={calendlyUrl}
              rootElement={document.getElementById('root') || undefined}
              text="Book a discovery call"
              className={`${styleClasses.individualButton} ${classes.promoButton}`}
              variant="contained"
            />
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
        />
      </Box>

      {/* Video Modal */}
      <Dialog
        open={openVideoModal}
        onClose={handleCloseVideoModal}
        maxWidth="md"
        fullWidth
        aria-labelledby="video-dialog-title"
      >
        <DialogTitle id="video-dialog-title">Video</DialogTitle>
        <DialogContent className={classes.videoModal}>
          {selectedVideoLink && (
            <iframe
              src={`https://www.youtube.com/embed/${extractYouTubeID(selectedVideoLink)}`}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseVideoModal} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

// Helper function to extract YouTube video ID from URL
const extractYouTubeID = (url) => {
  const regex =
    /(?:youtube\.com\/(?:[^\/\n\s]+\/\S+\/|(?:v|e(?:mbed)?)\/|.*[?&]v=)|youtu\.be\/)([a-zA-Z0-9_-]{11})/;
  const match = url.match(regex);
  return match ? match[1] : null;
};

export default GrowthStudioHome;
