import { createStyles, fade, Theme, makeStyles } from '@material-ui/core/styles';
import { createMuiTheme, ThemeProvider } from "@material-ui/core";

export const Academytheme = createMuiTheme({
	palette: 
	{
			primary: { main: "#25387c"},
			secondary: {  main: "#f9bb02" },
			success: { main : "#02a24e", },
			white: { main: "#fff" },

			   text: {
				primary:"rgb(33, 43, 54)",
				secondary:"rgb(99, 115, 129)",
				},
			
			
	},
	overrides: 
	{

		MuiToolbar: {root: { display:'block'}, },
		MuiDrawer:{paperAnchorLeft: { backgroundColor:'white'}, },

		MuiPaper: { 
		
		root: {'background-color': 'transparent', borderRadius: '0px'},
		elevation1:{
			//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
		},

		},
		MuiGrid:{root:{'background-color': 'transparent'}},
		MuiPopover: { 
		paper: {
		"&.pop-paper": 	{borderRadius: '12px',padding:'24px'}
			},
		},
		MuiOutlinedInput:{input: { padding: '14.5px 14px'}},

		MuiIconButton: { root: { fontSize: '1rem',  }},
		MuiCard: { root: { 
		
		borderRadius: '12px'  
		//'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'

		/* 'box-shadow': '0px 3px 3px -2px rgb(0 0 0 / 20%), 0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%)'*/
		
		//'box-shadow': 'rgba(145, 158, 171, 0.24) 0px 0px 2px 0px, rgba(145, 158, 171, 0.24) 0px 16px 32px -4px',
		
		}, },
		MuiChip: { root: { backgroundColor: '#f3f3f3'}, },
		MuiCardHeader: {
        root:{
			position:'relative',
			'text-align':'center',
		
		},
		title: {
			fontSize: '2rem',
			fontWeight:'600',
			lineHeight: '2rem',
			'margin-bottom':'10px',
        },
		},
		MuiCardContent:{root:{ '&:last-child': { 
		   paddingBottom: '16px', 
		}}},
		MuiList:{padding:{paddingTop:'0px',paddingBottom:'0px'}},
		MuiListItemIcon:{root:{'min-width':'34px'}},
		
		   MuiTimelineItem: {
			  missingOppositeContent: {
				"&:before": {
				  display: "none"
				}
			  }
		},
		

		MuiDialog:{paperWidthXs :{ 'border-radius': '12px'}},
		MuiDialogTitle: {
		root: {'& h6': {fontWeight: '700'}}},
	//	MuiDialogContent:{root:{'padding':'16px'}},
		
		MuiSkeleton:{text:{'transform-origin': '0 0%'}},
		
		//MuiAvatar:{img:{'object-fit': 'contain'}},
		//MuiTabPanel:{root:{'padding':'0px'}},
	//	MuiListItem:{gutters:{'padding-top': '0.85px','padding-bottom': '0.85px'}},
		MuiButton : {root :{padding: '7.3px 16px',textTransform: 'capitalize'},
		containedPrimary:{'box-shadow': 'rgb(37 56 124 / 24%) 0px 8px 16px 0px'},
		containedSecondary:{'box-shadow': 'rgb(255 193 7 / 24%) 0px 8px 16px 0px'},},
	

	//MuiTab: {root: {    'min-width': '130px'}},
		/*MuiTab: {
		root: {
        "&:hover": {
          backgroundColor: 'red',
          color: 'blue'
        }
      },
      selected: {
        backgroundColor: 'yellow',
        color: 'green',
        "&:hover": {
          backgroundColor: 'white',
          color:'pink'
        }
		}}
		 */
		/*MuiTab: {
			wrapper: {flexDirection: "row",textTransform: 'capitalize',},
			labelIcon: {minHeight: "48px",wrapper: {  '& > :first-child': {           'margin-bottom': '0px'     },'margin-bottom': '0px'   },
		}}*/
	},

	typography: { 
	fontFamily: ['Public Sans','sans-serif',].join(','),}, 
	button: { textTransform: 'capitalize','box-shadow': 'rgba(255, 193, 7, 0.24) 0px 8px 16px 0px'
}
});
export const BeforeLoginStyles = makeStyles((theme) => ({

	Link: {
    color: '#25387c',
    '&:hover': {
		color: '#394a88',
		'text-decoration':'underline',
	}
	},

	grow: {flexGrow: 1},
   
  }));
export const constStyles ={
	list:{'padding-top': '2px',
	'padding-bottom': '2px',},
	link:{
    color: '#25387c',
    '&:hover': {
		color: '#394a88',
		'text-decoration':'underline',
	}
	}
}
						
						
export const courseSort_useStyles = makeStyles(theme => ({
formControl: {
    margin: '16px 0px 16px 16px !important',
	minWidth: '120px !important',
  },
  
   
    chip: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
    '& > *': {
      marginRight: '4px',
    },
  },
}));

export const useStyles = makeStyles(theme => ({
   large: {
    width: '116px !important',
    height: '116px !important',
  //  height: theme.spacing(15) ,
	border: '2px solid #d8d8d8 !important'
  },
  medium: {
    //width: theme.spacing(10) , height: theme.spacing(10) ,	
	width: '80px !important',
    height: '80px !important',
  }, 
  small: {
    //width: theme.spacing(4) ,height: theme.spacing(4) ,	
	width: '64px !important',
    height: '64px !important',
	border: '2px solid #d8d8d8 !important'

  },
 root: {
    flexGrow: 1,height:"100%"
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
	'box-shadow': 'rgba(140, 152, 164, 0.25) 0px 3px 6px 0px'
  },
	tab: { '& .MuiBox-root': {padding: '0px'}},
	playerpaper: {  '& .MuiPaper-root': {'box-shadow': 'unset'} },
 
 
	 chips: {
    display: 'flex',
    justifyContent: 'left',
    flexWrap: 'wrap',
	backgroundColor:'#fbfbfb',
    '& > *': {
      margin: theme.spacing(1),
    },
  },
	green_menu:
	{backgroundColor:"rgb(40 124 37 / 8%) !important",color:"rgb(0, 171, 85) !important",border:'transparent'},
	
	yellow_menu:
	{backgroundColor:"rgb(255 198 30 / 10%) !important",color:"rgb(253, 169, 45) !important",border:'transparent'},
	
	blue_menu:
	{backgroundColor:"rgba(37, 56, 124, 0.04) !important",color:"rgba(37, 56, 124, 1) !important"}

}));
