// src/components/GrowthStudioHome.jsx

import React, { useState } from 'react';
import {
  Box,
  Typography,
  Card,
  Button,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  CircularProgress,
  Grid
} from '@material-ui/core';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { PopupButton } from 'react-calendly';
import GrowthBenefitsCard from '../Experts/GrowthBenefitsCard';
import useGlobalStyles from '../styles/CourseStyles';
import promoImageSrc from '../assets/expert1.webp';
import growthexpert1 from '../assets/growthexpert1.webp';
import growthexpert2 from '../assets/growthexpert2.webp';
import growthexpert3 from '../assets/growthexpert3.webp';
import growthexpert4 from '../assets/growthexpert4.jpg';
import bannerImage from '../assets/expert_profile_2.jpg';
import growthstudiovideo from '../assets/growthstudiovideo.mp4';

// Import slick-carousel CSS
import 'slick-carousel/slick/slick.css';
import LatestContentCard from './LatestGrowthCard';
import ExploreGrowthCard from './ExploreGrowthCard';
import FeaturesSection from './FeatureSection';
import WhyHireGrowthAssociate from './WhyHireGrowthAssociate';

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  mainCard: {
    boxShadow: '0 4px 6px rgba(0,0,0,0.2)',
    borderRadius: '8px',
    overflow: 'hidden',
    margin: 'auto',
    border: 'none',
    padding: theme.spacing(3),
    paddingTop: theme.spacing(8),
    marginTop: theme.spacing(4),
    [theme.breakpoints.down('sm')]: {
      padding: 8,
    },
  },
  // Updated banner styles
  bannerContainer: {
    position: "relative",
    display: "flex",
    flexDirection: "column",
    border: 'none',
    paddingLeft: '0rem',
    borderRadius: "4px",
    alignItems: 'flex-start',
    overflow: "hidden",
    width: '100%',
    [theme.breakpoints.up("md")]: {
      height: "auto",
    },
    [theme.breakpoints.down("sm")]: {
      height: "100%",
      flexDirection: 'column', // Ensure vertical stacking on small screens
      alignItems: 'center',
    },
  },
  imageBox: {
    width: '100%',
    height: '550px', // Adjust the height as needed
    overflow: 'hidden',
    [theme.breakpoints.down('sm')]: {
        height: '700px'
    },
    position: 'relative',
  },
  bannerImage: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
  contentOverlay: {
    position: 'absolute',
    top: 50,
    left: 0,
    width: 'auto',
    height: 'auto',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    padding: theme.spacing(4),
    backgroundColor: 'rgba(0, 0, 0, 0.3)', // Optional: Add a slight overlay for better text visibility
    [theme.breakpoints.down("sm")]: {
      backgroundColor: 'rgba(0, 0, 0, 0.2)',
      top: 20,
      justifyContent: 'flex-start',
      padding: theme.spacing(2),
    },
  },
  contentBox: {
    backgroundColor: 'transparent',
    color: "white", // Adjust text color based on image
    maxWidth: '100%', // Adjust as needed
    [theme.breakpoints.down("sm")]: {
      maxWidth: '100%',
      textAlign: 'left',
    },
  },
  bannerButton: {
    marginTop: theme.spacing(2),
    borderRadius: "50px",
    backgroundColor: "#f9bb02",
    color: "black",
    fontWeight: 600,
    fontSize: '1rem',
    padding: '8px 24px',
    textTransform: "none",
    cursor: 'pointer',
    "&:hover": {
      boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)",
      backgroundColor: "#f9bb02",
    },
    [theme.breakpoints.down("sm")]: {
      fontSize: "0.8rem",
      padding: "6px 12px",
    },
  },
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-4), // Adjust to overlay on the image
    marginBottom: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4, // Higher z-index to appear above image and content
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: theme.spacing(0),
    },
  },
  h3font: {
    fontWeight: "500",
    color: '#fff',
    border: 'none',
    width: '713px',
    [theme.breakpoints.down('sm')]: {
        textAlign: 'left',
        
      },
  },
  h4font: {
    padding: '8px 12px',
    fontWeight: "600",
    fontSize: '49px',
    border: 'none',
    
  },
  h6font: {
    color: '#25387c',
    marginBottom: theme.spacing(2),
  },
  featureCard: {
    display: 'flex',
    alignItems: 'center',
    textAlign: 'center',
    padding: theme.spacing(3),
    boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
    borderRadius: '8px',
    backgroundColor: '#ffffff',
    transition: 'transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out',
    width: '100%',
    '&:hover': {
      boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
      transform: 'translateY(-2px)',
      backgroundColor: '#0000000a',
    },
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      textAlign: 'center',
    },
  },
  title: {
    fontWeight: 'bold',
    fontSize: '2.6rem',
    fontFamily: 'MyCustomFont, sans-serif',
    color: 'white',
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.8em',
    },
  },
  searchBar: {
    backgroundColor: '#fff',
    borderRadius: '50px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '400px',
    },
    '& .MuiOutlinedInput-root': {
      borderRadius: '50px',
      height: '2.1em',
      padding: '0 14px',
    },
    '& .MuiInputBase-input': {
      padding: 0,
      height: '100%',
      fontSize: '0.9rem',
    },
  },
  searchBarBox: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginBottom: '2.5rem',
    [theme.breakpoints.down('sm')]: {
      marginTop: '16px',
    },
  },
  chipsearchbox: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'space-between',
    marginBottom: '16px',
    paddingLeft: '16px',
    paddingRight: '16px',
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      paddingLeft: '4px',
      paddingRight: '0px',
    },
  },
  searchButton: {
    marginLeft: theme.spacing(1),
    backgroundColor: '#f9bb02',
    color: '#000',
    height: '2.4em',
    fontWeight: 'bold',
    borderRadius: '50px',
    '&:hover': {
      backgroundColor: '#d6a302',
    },
    whiteSpace: 'nowrap',
  },
  newHeading: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    textAlign: 'center',
  },
  filterChips: {
    display: 'flex',
    flexWrap: 'wrap',
    gap: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
    },
  },
  filterChip: {
    [theme.breakpoints.down('sm')]: {
      flex: '0 0 40%',
      boxSizing: 'border-box',
      marginBottom: theme.spacing(1),
      paddingLeft: theme.spacing(0.4),
      paddingRight: theme.spacing(0.4),
    },
  },
  loadMoreButtonContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
  },
  loadMoreButton: {
    border: `2px solid ${theme.palette.primary.main}`,
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '8px 24px',
    minWidth: '150px',
    '&:hover': {
      backgroundColor: theme.palette.primary.main,
      color: '#fff',
      border: `2px solid ${theme.palette.primary.main}`,
    },
  },
  loadingIndicator: {
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(4),
    display: 'flex',
    justifyContent: 'center',
  },
  promoCardContainer: {
    display: 'flex',
    backgroundColor: '#25387c',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    borderRadius: '4px',
    border: 'none',
    marginTop: theme.spacing(6),
    padding: theme.spacing(2),
    paddingBottom: theme.spacing(0),
    [theme.breakpoints.down('sm')]: {
      flexDirection: 'column',
      alignItems: 'center',
    },
  },
  promoCard: {
    display: 'flex',
    border: 'none',
    flexDirection: 'column',
    backgroundColor: '#25387c',
    color: '#fff',
    padding: theme.spacing(4),
    borderRadius: '12px',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: theme.spacing(1.5),
      textAlign: 'center',
    },
  },
  promoImage: {
    width: '400px',
    height: '100%',
    marginLeft: theme.spacing(4),
    borderRadius: '8px',
    [theme.breakpoints.down('sm')]: {
      width: '200px',
      marginLeft: 0,
      marginTop: theme.spacing(3),
    },
    objectFit: 'cover',
  },
  promoTitle: {
    fontSize: '1.8rem',
    fontWeight: 'bold',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      fontSize: '1.4rem',
    },
  },
  promoSubtitle: {
    fontSize: '1rem',
    marginBottom: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      fontSize: '0.9rem',
    },
  },
  promoButton: {
    marginTop: '16px',
    padding: "8px 12px",
    alignSelf: 'flex-start',
    [theme.breakpoints.down('sm')]: {
      alignSelf: 'center',
    },
  },
  // Styles for the subscribe ribbon
  subscribeRibbon: {
    backgroundColor: '#25387c', // Standout color for the ribbon
    width: '100%',
    padding: theme.spacing(2),
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexWrap: 'wrap',
    marginTop: theme.spacing(-4), // Adjust to overlay on the image
    marginBottom: theme.spacing(2),
    position: 'absolute',
    bottom: 0,
    left: 0,
    zIndex: 4, // Higher z-index to appear above image and content
    [theme.breakpoints.down('sm')]: {
      width: 'auto',
      flexDirection: 'column',
      textAlign: 'center',
      padding: theme.spacing(2),
      marginTop: theme.spacing(-2),
    },
  },
  subscribeText: {
    fontSize: '1.2rem',
    flex: '1 1 60%',
    [theme.breakpoints.down('sm')]: {
      marginBottom: theme.spacing(2),
      flex: '1 1 100%',
    },
  },
  subscribeButton: {
    backgroundColor: '#25387c',
    color: '#fff',
    fontWeight: 'bold',
    borderRadius: '50px',
    padding: '10px 24px',
    textTransform: 'none',
    '&:hover': {
      backgroundColor: '#1e2a5a',
    },
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      padding: '10px 0',
    },
  },
  subscribeInput: {
    marginRight: theme.spacing(2),
    flex: '1 1 60%',
    [theme.breakpoints.down('sm')]: {
      marginRight: 0,
      marginBottom: theme.spacing(1),
      flex: '1 1 100%',
    },
  },
  gridCard: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    backgroundColor: '#25387c',
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
  },
  gridCard2: {
    marginTop: theme.spacing(2),
    paddingTop: theme.spacing(2),
    borderRadius: '4px',
    marginBottom: theme.spacing(1.5),
  },
  h6: {
    [theme.breakpoints.down('sm')]: {
        width: '100%',
      },
  }
}));

// BannerSlide Component
const BannerSlide = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const isSmall = useMediaQuery(theme.breakpoints.down("sm"));
  const [calendlyUrl, setCalendluUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10')

  // State for subscribe modal
  const [openSubscribe, setOpenSubscribe] = useState(false);
  const [email, setEmail] = useState('');
  const [subscriptionStatus, setSubscriptionStatus] = useState(null); // null, 'success', 'error'
  const [isLoading, setIsLoading] = useState(false); // Loading state

  const handleOpenSubscribe = () => {
    setOpenSubscribe(true);
  };

  const handleCloseSubscribe = () => {
    setOpenSubscribe(false);
    setSubscriptionStatus(null);
    setEmail('');
    setIsLoading(false);
  };

  const handleSubscribe = async () => {
    setIsLoading(true);
    try {
      // TODO: Replace with your actual subscription logic (e.g., API call)
      // Example using fetch:
      /*
      const response = await fetch('/api/subscribe', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ email }),
      });
      if (!response.ok) {
        throw new Error('Subscription failed');
      }
      */
      
      // Simulating successful subscription
      await new Promise((resolve) => setTimeout(resolve, 1000)); // Simulate network delay
      setSubscriptionStatus('success');
      setEmail('');
    } catch (error) {
      console.error('Subscription error:', error);
      setSubscriptionStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <Box className={`${styleClasses.bannerContainer} ${classes.bannerContainer}`}>
        {/* Banner Image */}
        <Box className={classes.imageBox}>
          <img
            src={bannerImage}
            alt="Growth Studio Banner"
            className={classes.bannerImage}
          />

          {/* Content Overlay */}
          <Box className={classes.contentOverlay}>
            <Box className={classes.contentBox}>
              <Typography
                variant={isSmall ? "h4" : "h1"}
                gutterBottom
                className={`${styleClasses.h3font} ${classes.h3font}`}
              >
                Take your business to the next level with a <span className= {styleClasses.span}>Growth Associate</span>
              </Typography>
              
              <PopupButton
                url={calendlyUrl}
                rootElement={document.getElementById('root') || undefined}
                text="Book a discovery call"
                className={`${styleClasses.individualButton} ${classes.promoButton}`}
                variant="contained"
              />
            </Box>
          </Box>

          {/* Subscribe Ribbon */}
          <Box className={classes.subscribeRibbon}>
            <Typography variant='h6' className={styleClasses.h6font} style={{color: '#f9bb02'}}>
              Don’t miss out on exclusive updates, free resources, and special offers—delivered right to you!
            </Typography>
            <Button
              variant="contained"
              className={styleClasses.individualButton}
              onClick={handleOpenSubscribe}
            >
              Subscribe Now
            </Button>
          </Box>
        </Box>

        {/* Subscribe Modal */}
        <Dialog open={openSubscribe} onClose={handleCloseSubscribe}>
          <DialogTitle>Subscribe to Our Newsletter</DialogTitle>
          <DialogContent>
            <FormControl fullWidth>
              <TextField
                label="Email Address"
                type="email"
                variant="outlined"
                required
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                aria-label="Email Address"
              />
            </FormControl>
            {isLoading && (
              <Box display="flex" justifyContent="center" mt={2}>
                <CircularProgress />
              </Box>
            )}
            {subscriptionStatus === 'success' && (
              <Typography color="primary" align="center" style={{ marginTop: theme.spacing(2) }}>
                Successfully subscribed!
              </Typography>
            )}
            {subscriptionStatus === 'error' && (
              <Typography color="error" align="center" style={{ marginTop: theme.spacing(2) }}>
                Subscription failed. Please try again.
              </Typography>
            )}
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseSubscribe} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleSubscribe}
              color="primary"
              variant="contained"
              disabled={!email || isLoading}
            >
              Subscribe
            </Button>
          </DialogActions>
        </Dialog>
      </Box>
    </>
  );
};

// GrowthAssociate Component
const GrowthAssociate = () => {
  const classes = useStyles();
  const styleClasses = useGlobalStyles();
  const theme = useTheme();
  const history = useHistory();
  const [calendlyUrl, setCalendluUrl] = useState('https://calendly.com/vedika-5m0y/30min?month=2024-10')

  return (
    <Box style={{ padding: '8px', paddingTop: 0 }}>
      {/* Header Component (if any) */}
      {/* <Header /> */}

      {/* Banner Carousel */}
      <BannerSlide calendlyUrl= {calendlyUrl} />

      <FeaturesSection />
      <WhyHireGrowthAssociate />
      {/* Promotional Card */}
      <Box className={classes.promoCardContainer}>
        {/* Promotional Text Content */}
        <Card className={classes.promoCard}>
          <Box>
            <Typography
              variant="h4"
              className={`${styleClasses.h4font}`}
              style={{ color: 'white' }}
            >
              Looking for a Growth Associate?
            </Typography>
            <Typography variant="h6" className={`${styleClasses.description} ${classes.h6}`}>
              Let us help you drive user acquisition, boost engagement, and scale your
              business—all at a fraction of the cost of a full-time hire.
            </Typography>
            <PopupButton
                url={calendlyUrl}
                rootElement={document.getElementById('root') || undefined}
                text="Book a discovery call"
                className={`${styleClasses.individualButton} ${classes.promoButton}`}
                variant="contained"
              />
          </Box>
        </Card>

        {/* Promotional Image */}
        <img
          src={promoImageSrc} // Ensure this path is correct or replace with your desired image URL
          alt="Growth Associate"
          className={classes.promoImage}
        />
      </Box>
    </Box>
  );
};

export default GrowthAssociate;
